import { createContext, useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Typography from "@mui/material/Typography";
import EboForm from "./EboForm/EboForm";
import ApprovePending from "./ApprovePending/ApprovePending";
import ActiveStore from "./ActiveStore/ActiveStore";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import NsmScreen from "./NsmScreen/NsmScreen";
import EboFormV2 from "./EboForm/EboFormV2";

//for tab section
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const tabStyle = { textTransform: "capitalize", fontSize: "16px", width: "170px" };

const initialState = {
	store_type: "Select",
	rr_code: "Select",
	rr_number: "",
	brand: "Select",
	store_name: "",
	ginesys_name: "",
	store_type_as_per_acc: "Select",
	name_of_franchise_company: "",
	unit_no: 0,
	floor: 0,
	address: "",
	state: "",
	city: "",
	tier: "Select",
	pincode: 0,
	billing_name_as_per_aggrement: "",
	billing_name_as_per_acc: "",
	mall_hs: "Select",
	zone: "",
	carpet_area: 0,
	chargable_area: 0,
	facade_in_ft: 0,
	deposit_paid_to_party: 0,
	security_deposit: 0,
	cam_deposit: 0,
	store_opening_date: new Date().toJSON().slice(0, 10),
	rental_start_date: new Date().toJSON().slice(0, 10),
	financial_year: 0,
	lease_term: "",
	start_date: new Date().toJSON().slice(0, 10),
	end_date: new Date().toJSON().slice(0, 10),
	lock_in: "",
	notice: "",
	rent_renewal_date: new Date().toJSON().slice(0, 10),
	revenue_share: "",
	rent_y1: 0.0,
	rent_y2: 0.0,
	rent_y3: 0.0,
	rent_y4: 0.0,
	rent_y5: 0.0,
	rent_y6: 0.0,
	rent_y7: 0.0,
	rent_y8: 0.0,
	rent_y9: 0.0,
	rent_y10: 0.0,
	rent_y11: 0.0,
	rent_y12: 0.0,
	rent_y13: 0.0,
	rent_y14: 0.0,
	rent_y15: 0.0,
	cam_y1: 0.0,
	cam_y2: 0.0,
	cam_y3: 0.0,
	cam_y4: 0.0,
	cam_y5: 0.0,
	cam_y6: 0.0,
	cam_y7: 0.0,
	cam_y8: 0.0,
	cam_y9: 0.0,
	cam_y10: 0.0,
	cam_y11: 0.0,
	cam_y12: 0.0,
	cam_y13: 0.0,
	cam_y14: 0.0,
	cam_y15: 0.0,
	billing_unit: 0,
	billing_city: "",
	billing_state: "",
	billing_pincode: 0,
	billing_address: "",
	stock_delivery_unit: 0,
	stock_delivery_city: "",
	stock_delivery_state: "",
	stock_delivery_pincode: 0,
	stock_delivery_address: "",
	marketing_charges_per_month: "",
	gift_vouchers_per_month: "",
	capex: "",
	deposit_against_goods: 0,
	base_stock_qty_ss: 0,
	base_stock_qty_aw: 0,
	board_resolution_date: new Date().toJSON().slice(0, 10),
	authorized_signatory: "",
	stamp_duty_and_registration: 0,
	gstin: "",
	agreement_registered: "Select",
	gst_registered: "Select",
	is_active: false,
	insurance_amount: 0,
	insurance_end_date: new Date().toJSON().slice(0, 10),
	agreement_expiry_date: new Date().toJSON().slice(0, 10),
	capex_insurance: 0,
	sellable_insurance: 0,
	eboStoreVmData: {},
};

const EboStore = () => {
	const [tab, setTab] = useState(0);
	const [history, setHistory] = useState();
	const { pending, setPending, activeStore, setActiveStore } = StoreContext();
	// const [formData, setformData] = useState(initialState);
	const [isLoading, setIsLoading] = useState(false);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		// setIsLoading(true);
		axios
			.get("/api/store/pending-approval")
			.then((res) => setPending(res.data))
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});

		axios
			.get("/api/store/active-store")
			.then((res) => {
				// let data = res.data;
				console.log(res.data);
				setActiveStore(res.data);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.response.data.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		// setIsLoading(false)
	}, []);

	return (
		<Stack maxWidth="1200px" margin="auto" spacing={2}>
			<Box height="450px">
				{/* <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example" sx={{}}> */}
				{/* <Tab sx={tabStyle} label="BD Screen" {...a11yProps(0)} /> */}
				{/* <Tab sx={tabStyle} label="VM screen" {...a11yProps(1)} />
					<Tab sx={tabStyle} label="NSM Screen" {...a11yProps(1)} />
					<Tab sx={tabStyle} label="Approve Pending" {...a11yProps(1)} /> */}
				{/* </Tabs> */}
				<Box display={tab === 0 ? "block" : "none "}>
					<EboFormV2 history={history} setHistory={setHistory} />
				</Box>
				{/* <Box display={tab === 1 ? "block" : "none "}>
					<ActiveStore isLoading={isLoading} />
				</Box>
				<Box display={tab === 2 ? "block" : "none "}>
					<NsmScreen />
				</Box>
				<Box display={tab === 3 ? "block" : "none "}>
					<ApprovePending isLoading={isLoading} />
				</Box> */}
			</Box>
		</Stack>
	);
};

export default EboStore;

const StateContext = createContext();

export const StoreProvider = ({ children }) => {
	const [pending, setPending] = useState([]);
	const [activeStore, setActiveStore] = useState([]);

	return (
		<StateContext.Provider value={{ pending, setPending, activeStore, setActiveStore }}>
			<EboStore />
		</StateContext.Provider>
	);
};

export const StoreContext = () => {
	return useContext(StateContext);
};
