import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import { useNavigate, useOutletContext } from "react-router-dom";
import { foreignKeys, pimDrawerElements, productDepartments, statusOptions } from "data/pimData";

import PimProvider, { PimState } from "context/PimContext";
import DesignerTab from "./Departments/DesignerTab/DesignerTab";
import { Divider, Fab, Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import MDMTab from "./Departments/MDMTab/MDMTab";
import { Add, KeyboardArrowDown, PersonRounded } from "@mui/icons-material";
import { AuthState } from "context/AuthContext";
import { yellow } from "@mui/material/colors";
import BuyerTab from "./Departments/BuyerTab/BuyerTab";
import MerchandiserTab from "./Departments/MerchandiserTab/MerchandiserTab";
import FabricAndTrimsTab from "./Departments/FabricAndTrimsTab/FabricAndTrimsTab";

const ProductPage = () => {
	const [activeDepartment, setActiveDepartment] = useState("designer");
	const { user } = AuthState();
	const { selectedStyle } = PimState();
	const handleDepartmentChange = (e, val) => {
		setActiveDepartment(val);
	};
	const status = "drafted";
	const navigate = useNavigate();
	const tabComponents = [
		{
			...productDepartments.designer,
			component: <DesignerTab foreignKey={""} />,
		},
		{
			...productDepartments.mdm,
			component: <MDMTab foreignKey={""} />,
		},
		{
			...productDepartments.buying,
			component: <BuyerTab />,
		},
		{
			...productDepartments.sourcing,
			component: <MerchandiserTab />,
		},
		{
			...productDepartments.trims_fabric,
			component: <FabricAndTrimsTab />,
		},
		// {
		// 	...productDepartments.fabric,
		// 	component: <MerchandiserTab />,
		// },
		// {
		// 	...pimDrawerElements.product_page,
		// 	component: <ProductPage foreignKey={foreignKeys.product} />,
		// },
		// {
		// 	...pimDrawerElements.create_product,
		// 	component: <CreateProduct foreignKey={foreignKeys.product} />,
		// },
		// {
		// 	...pimDrawerElements.order,
		// 	component: <OrderPage foreignKey={foreignKeys.product} />,
		// },
	];
	let scroll = 600;
	const handleScroll = () => {
		if (scroll === 1800) scroll = 0;
		window.scrollTo({ top: scroll, behavior: "smooth" });
		scroll += 600;
	};
	return (
		<Box sx={{ width: "100%", p: 1 }} position="relative">
			<Box width="100%" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
				<Stack>
					<Box display="flex" columnGap={1}>
						{/* Created By: */}
						<PersonRounded />
						<Typography ba variant="subtitle1">
							{user.name}
						</Typography>
					</Box>
				</Stack>
				<Box
					display="flex"
					alignItems="center"
					height={"22px"}
					// columnGap={1}
					sx={{
						backgroundColor: statusOptions[selectedStyle?.status]?.color || yellow[400],
						color: "white",
						padding: "6px",
						borderRadius: "5px",
					}}>
					{statusOptions[selectedStyle?.status]?.icon}{" "}
					<Typography pl="3px"> {statusOptions[selectedStyle?.status]?.name}</Typography>
				</Box>
				{/* <Divider variant="fullWidth" orientation="horizontal" flexItem sx={{ width: "100%", mt: 1 }} /> */}
			</Box>
			<Tabs value={activeDepartment} onChange={handleDepartmentChange} sx={{ mt: 2 }}>
				{Object.keys(productDepartments).map((row) => {
					return <Tab label={productDepartments[row].name} value={productDepartments[row].key} />;
				})}
			</Tabs>

			{tabComponents?.map((row) => (
				<Box width="98%" margin="auto" key={row.key} display={row.key === activeDepartment ? "block" : "none"}>
					{console.log(row.key)}
					{row.no}
					{row.component}
				</Box>
			))}
			<Fab
				size="small"
				color="primary"
				aria-label="add"
				sx={{ position: "fixed", right: "5%", bottom: "5%" }}
				onClick={handleScroll}>
				<KeyboardArrowDown />
			</Fab>
		</Box>
	);
};

export default ProductPage;
