import RareBoxLogo from "Logos/RareBoxLogo";
import axios from "axios";
import { CustomLinearProgress } from "components/ReusableComponents/CustomLinearProgress/CustomLinearProgress";
import {
	attributepriorityTypes,
	productDummyData,
	colorProductDummyData,
	attributeGroups,
	buyingSheetdata,
} from "data/pimData";
import React, { createContext, useContext, useEffect, useState } from "react";

const PimContext = createContext();

const PimProvider = ({ children }) => {
	const [attributesWithValues, setAttributeWithValues] = useState();
	const [attributesWithValuesGroups, setAttributeWithValuesGroups] = useState();

	const [selectedStyle, setSelectedStyle] = useState();
	const [selectedProduct, setSelectedProduct] = useState();
	const [coloredProduct, setcoloredProduct] = useState(colorProductDummyData);
	const [buyingSheet, setBuyingSheet] = useState(buyingSheetdata);

	useEffect(() => {
		const fetch = async () => {
			try {
				await axios.get(`/api/pim/get_attribute_with_values`).then((res) => {
					const arr = res.data;
					// let seperatedData = Object.keys(attributepriorityTypes)?.reduce((acc, row) => {
					// 	let data = arr?.filter((val) => attributepriorityTypes[row].key === val.priority);
					// 	console.log("data", data);
					// 	acc.push(data);
					// 	return acc;
					// }, []);
					setAttributeWithValues(arr);
				});
			} catch (error) {
				console.log(error);
			}
		};
		fetch();
	}, []);

	useEffect(() => {
		if (attributesWithValues && attributesWithValues.length) {
			let obj = {};
			Object.keys(attributeGroups).forEach((group) => (obj[group] = []));
			attributesWithValues.forEach((attr) => {
				obj[attr.group].push(attr);
			});
			console.log(obj);
			setAttributeWithValuesGroups(obj);
		}
	}, [attributesWithValues]);
	return (
		<PimContext.Provider
			value={{
				attributesWithValues,
				setAttributeWithValues,
				selectedProduct,
				setSelectedProduct,
				selectedStyle,
				setSelectedStyle,
				attributesWithValuesGroups,
				coloredProduct,
				setcoloredProduct,
				buyingSheet,
				setBuyingSheet,
			}}>
			{attributesWithValuesGroups ? (
				children
			) : (
				<div
					style={{
						width: "200px",
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%,-50%)",
					}}>
					<RareBoxLogo />
					<CustomLinearProgress />
				</div>
			)}
		</PimContext.Provider>
	);
};

export const PimState = () => {
	return useContext(PimContext);
};

export default PimProvider;
