import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
	attributepriorityTypes,
	barcodeTableColumns,
	colorsChannelObjName,
	colorsObjName,
	designerAttributeObjName,
	designerTableColumns,
	groups,
	inputTypes,
	orderData,
	orderTableAttributeColumns,
	pimDrawerElements,
	pimParamName,
	priority,
	productColumns,
	productDetailsColumns,
	productDummyData,
	productImagesObjName,
	styleTableColumns,
} from "data/pimData";
import { useEffect, useState } from "react";
import DisplayInfoWithEditTab from "components/Pim/CommonComponents/DisplayInfoWithEditTab";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import BarcodeModal, { BarcodeModalWithCost } from "../../BarcodeModal/BarcodeModal";
import { DataGrid } from "@mui/x-data-grid";
import { AddRoad, DeleteRounded, EditRounded } from "@mui/icons-material";
import { PimState } from "context/PimContext";
import CreateColorModal from "../../CreateColorModal/CreateColorModal";
import { rootProtectedPath, services } from "data/authData";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import EditImageDataModal from "../../EditImageDataModal/EditImageDataModal";
import AddRounded from "@mui/icons-material/AddRounded";
import AddNewCosting from "components/OwnProductionCosting/OwnProductionCostingDashbord/AddNewCosting/AddNewCosting";
import AddNewImageModal from "../../AddNewImageModal/AddNewImageModal";
import ImageSlider from "components/Pim/CommonComponents/ImageSlider/ImageSlider";
import DisplayDatagrid from "./DisplayDatagrid/DisplayDatagrid";
import { MenuItem, Select, TextField } from "@mui/material";
import { AuthState } from "context/AuthContext";
import moment from "moment";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const colorTableColumns = [
	{
		field: "url",
		headerName: "Image",
		attribute_name: "",
		width: 120,
		renderCell: (params) => {
			let imgWidth = 43;
			let imgHeight = 50;
			return (
				<img
					style={{
						width: `${imgWidth}px`,
						height: `${imgHeight}px`,
						objectFit: "cover",
						objectPosition: "50% 0%",
						borderRadius: 2,
					}}
					src={params.row.url}
				/>
			);
		},
		...colStyle,
	},
	{
		field: "color",
		headerName: "Color",
		attribute_name: "",
		width: 120,
		...colStyle,
	},
	{
		field: "pallete",
		headerName: "Pallete",
		width: 120,
		...colStyle,
	},
	{
		field: "total_qty",
		headerName: "Total Quantity",
		width: 120,
		...colStyle,
		// renderCell: (params) => {
		// 	return (
		// 		<Box
		// 			display="flex"
		// 			alignItems="center"
		// 			height="30px"
		// 			columnGap={1}
		// 			sx={{
		// 				backgroundColor: statusOptions["drafted"]?.color || yellow[400],
		// 				color: "white",
		// 				padding: "5px",
		// 				borderRadius: "5px",
		// 			}}>
		// 			{statusOptions["drafted"]?.icon} <Typography> {statusOptions["drafted"]?.name}</Typography>
		// 		</Box>
		// 	);
		// },
	},

	// {
	// 	// field: "pallette",
	// 	headerName: "Delete",
	// 	width: 60,
	// 	...colStyle,
	// 	renderCell: (params) => {
	// 		return (
	// 			<IconButton onClick={(e) => handleBarcodeDeleteClick(e, params.row)}>
	// 				<DeleteRounded />
	// 			</IconButton>
	// 		);
	// 	},
	// },
];
const channelTableColumns = [
	{
		field: "name",
		headerName: "Channel",
		width: 120,
		...colStyle,
	},
	{
		field: "total_qty",
		headerName: "Total Qty",
		attribute_name: "",
		width: 120,
		...colStyle,
	},
	{
		field: "created_date",
		headerName: "Created Date",
		attribute_name: "",
		width: 120,
		...colStyle,
	},
	{
		field: "created_by",
		headerName: "Created By",
		attribute_name: "",
		width: 120,
		...colStyle,
	},
];

const BuyerTab = ({ foreignKey }) => {
	const { setSnackBar, setSnackBarOpen, snackBar } = GlobalState();
	const { user } = AuthState();
	const { attributes, attributeValues, selectedStyle, setSelectedStyle } = PimState();

	useEffect(() => {
		const fetch = async () => {
			// if (!selectedStyl) {
			// 	await axios.get("/").then((res) => setProductData(res.data));
			// } else setProductData(selectedStyle);
		};
		fetch();
	}, [selectedStyle]);

	const navigate = useNavigate();

	const [selectedColor, setSelectedColor] = useState();
	const [selectedChannel, setSelectedChannel] = useState();
	const [channelInput, setChannelInput] = useState("select");

	const sizeTableColumns = [
		{
			field: "name",
			headerName: "Size",
			attribute_name: "",
			width: 120,
			...colStyle,
		},
		{
			field: "qty",
			headerName: "Qty",
			attribute_name: "",
			width: 120,
			editable: true,
			...colStyle,
		},
		{
			field: "delete",
			headerName: "Qty",
			attribute_name: "",
			width: 120,
			...colStyle,
			renderCell: (params) => {
				return (
					<IconButton onClick={(e) => deleteSizeClick(params.row, e)}>
						<DeleteRounded />
					</IconButton>
				);
			},
		},
	];
	const dummy = [
		{ name: "S", id: 0, qty: 0 },
		{ name: "M", id: 1, qty: 0 },
		{ name: "L", id: 2, qty: 0 },
		{ name: "XL", id: 3, qty: 0 },
		{ name: "XXL", id: 4, qty: 0 },
		{ name: "XXXL", id: 5, qty: 0 },
	];
	const [sizes, setSizes] = useState();
	const deleteSizeClick = (size, e) => {
		e.stopPropagation();
		setSizes(sizes.filter((row) => row.name !== size.name));
	};
	// modal var

	const [mdmDataEditModal, setMdmDataEditModal] = useState(false);
	let scroll = 600;
	const handleScroll = () => {
		if (scroll === 1800) scroll = 0;
		window.scrollTo({ top: scroll, behavior: "smooth" });
		scroll += 600;
	};

	const handleColorSelect = (color, e) => {
		setSelectedColor(color);
		handleScroll();
	};
	const handleChannelSelect = (channel, e) => {
		setSelectedChannel(channel);
		setSizes(dummy);
		handleScroll();
	};
	const handleChannelAddClick = () => {
		const channels = selectedColor[colorsChannelObjName];

		let isPresent = channels.filter((row) => row.name === channelInput);
		if (isPresent.length) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Channel is alrady present",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		const newChannelObj = {
			name: channelInput,
			total_qty: 0,
			id: channels.length,
			created_by: user.email,
			created_date: moment().format("DD/MM/YYYY"),
		};
		setSelectedColor({
			[colorsChannelObjName]: [...channels, newChannelObj],
		});
		setSelectedChannel(newChannelObj);
		console.log([...channels, newChannelObj]);
	};

	if (selectedStyle)
		return (
			<Stack width="100%" spacing={2}>
				{/* Colors */}
				<Box width="85%">
					<Typography pb={0} variant="h6">
						Colors
					</Typography>
					{/* <Divider orientation="horizontal" variant="fullWidth" /> */}
					<Box>
						{/* <Button variant="contained" onClick={() => setMdmDataEditModal(true)}></Button> */}
						{/* <IconButton sx={{ ml: 2 }} size="large" onClick={() => handleArrowDownClick(groups.mdm.key)}>
							{activeGroup === groups.mdm.key ? (
								<KeyboardArrowUpRoundedIcon sx={{ transform: "scale(1.6)" }} />
							) : (
								<KeyboardArrowDownRoundedIcon sx={{ transform: "scale(1.6)" }} />
							)}
						</IconButton> */}
					</Box>
					<Box width="100%">
						<DisplayDatagrid
							columns={colorTableColumns}
							rows={selectedStyle ? selectedStyle[colorsObjName] : []}
							onRowClick={handleColorSelect}
							rowHeight={70}
						/>
					</Box>
				</Box>

				{/* channel */}
				{selectedColor && (
					<Box width="85%">
						<Box display="flex" justifyContent="space-between" p={2}>
							<Typography pb={0} variant="h6">
								Channel
							</Typography>
							<Box display="flex" columnGap={1}>
								<Select
									size="small"
									sx={{ width: 200 }}
									value={channelInput}
									onChange={(e) => setChannelInput(e.target.value)}>
									<MenuItem value={"select"}>Select Channel</MenuItem>
									<MenuItem value={"EBO"}>EBO</MenuItem>
									<MenuItem value={"MBO"}>MBO</MenuItem>
									<MenuItem value={"LFS"}>LFS</MenuItem>
								</Select>
								<Button onClick={handleChannelAddClick} variant="contained">
									Add
								</Button>
							</Box>
						</Box>

						{/* <Divider orientation="horizontal" variant="fullWidth" /> */}
						<Box>
							{/* <Button variant="contained" onClick={() => setMdmDataEditModal(true)}></Button> */}
							{/* <IconButton sx={{ ml: 2 }} size="large" onClick={() => handleArrowDownClick(groups.mdm.key)}>
							{activeGroup === groups.mdm.key ? (
								<KeyboardArrowUpRoundedIcon sx={{ transform: "scale(1.6)" }} />
							) : (
								<KeyboardArrowDownRoundedIcon sx={{ transform: "scale(1.6)" }} />
							)}
						</IconButton> */}
						</Box>
						<Box width="100%">
							<DisplayDatagrid
								columns={channelTableColumns}
								rows={selectedColor[colorsChannelObjName]}
								onRowClick={handleChannelSelect}
							/>
						</Box>
					</Box>
				)}
				{/* Size */}
				{selectedColor && selectedChannel && (
					<Box width="85%">
						<Typography pb={0} variant="h6">
							Sizes
						</Typography>
						{/* <Divider orientation="horizontal" variant="fullWidth" /> */}
						<Box>
							{/* <Button variant="contained" onClick={() => setMdmDataEditModal(true)}></Button> */}
							{/* <IconButton sx={{ ml: 2 }} size="large" onClick={() => handleArrowDownClick(groups.mdm.key)}>
							{activeGroup === groups.mdm.key ? (
								<KeyboardArrowUpRoundedIcon sx={{ transform: "scale(1.6)" }} />
							) : (
								<KeyboardArrowDownRoundedIcon sx={{ transform: "scale(1.6)" }} />
							)}
						</IconButton> */}
						</Box>
						<Box width="100%">
							<DisplayDatagrid columns={sizeTableColumns} rows={sizes} />
						</Box>{" "}
					</Box>
				)}
			</Stack>
		);
	else return <div></div>;
};

export default BuyerTab;
