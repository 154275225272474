import { Box, Button, Dialog, DialogActions, Stack, Typography, colors } from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import {
	colorChannelBuyingObj,
	colorImgAssociationObjName,
	colorsObjName,
	statusOptions,
	styleImagesObjName,
	styleTableColumns,
} from "data/pimData";
import moment from "moment";
import { useState } from "react";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const PublishModal = ({ modal, setModal, submitFunction, styleData, colorData }) => {
	const { snackBar, setSnackBar, setSnackBarOpen } = GlobalState();
	const colorTableColumns = [
		{
			field: "url",
			headerName: "Image",
			attribute_name: "",
			width: 120,
			renderCell: (params) => {
				let imgWidth = 43;
				let imgHeight = 50;
				return (
					<img
						style={{
							width: `${imgWidth}px`,
							height: `${imgHeight}px`,
							objectFit: "cover",
							objectPosition: "50% 0%",
							borderRadius: 2,
						}}
						src={params.row[colorImgAssociationObjName][0]?.url}
					/>
				);
			},
			...colStyle,
		},
		{
			field: "color",
			headerName: "Color",
			attribute_name: "",
			width: 120,
			...colStyle,
		},
		{
			field: "pallete",
			headerName: "Pallete",
			width: 120,
			...colStyle,
		},
		{
			field: "status",
			headerName: "Status",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return (
					<Box
						display="flex"
						alignItems="center"
						height={"22px"}
						// columnGap={1}
						sx={{
							backgroundColor: statusOptions[row.status]?.color || yellow[400],
							color: "white",
							padding: "6px",
							borderRadius: "5px",
						}}>
						{statusOptions[row.status]?.icon}{" "}
						<Typography pl="3px"> {statusOptions[row.status]?.name}</Typography>
					</Box>
				);
			},
		},

		// {
		// 	// field: "pallette",
		// 	headerName: "Delete",
		// 	width: 60,
		// 	...colStyle,
		// 	renderCell: (params) => {
		// 		return (
		// 			<IconButton onClick={(e) => handleBarcodeDeleteClick(e, params.row)}>
		// 				<DeleteRounded />
		// 			</IconButton>
		// 		);
		// 	},
		// },
	];
	const [colorsToPublish, setColorsToPublish] = useState([]);
	const handleCloseModal = () => {
		setModal(false);
		setColorsToPublish([]);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		if (!colorsToPublish || colorsToPublish.length === 0) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Select Colors to Publish ",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		try {
			const res = await axios.put("/api/pim/publish_style_with_color", {
				id: styleData.id,
				published_date: moment().format("YYYY-MM-DD"),
				[colorsObjName]: colorsToPublish,
			});
			console.log("resad", res.data);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Style Published ",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			if (typeof submitFunction === "function") submitFunction(res.data);
			setModal(false);
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "unable to publish data",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modal}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Box
				width="95%"
				margin="auto"
				pt={2}
				display="flex"
				flexWrap="wrap"
				columnGap={2}
				rowGap={1}
				// justifyContent="space-around"
			>
				<Typography width="100%" variant="h6">
					PUBLISH STYLE
				</Typography>
				<Box display="flex" columnGap="3%" width="100%">
					<img
						style={{
							width: `100px`,
							height: `120px`,
							objectFit: "cover",
							objectPosition: "50% 0%",
							borderRadius: 2,
						}}
						src={styleData && styleData[styleImagesObjName] ? styleData[styleImagesObjName][0]?.url : ""}
					/>
					<Box display="flex" flexWrap="wrap" columnGap={1} rowGap={1} height={90}>
						{styleTableColumns?.map((column) => {
							return (
								<Box display="flex" columnGap="3px" width="30%">
									<Typography>{column.name} :</Typography>
									<Typography fontSize={18} color={grey[800]} fontWeight={600}>
										{styleData ? styleData[column.key] : "----"}
									</Typography>
								</Box>
							);
						})}
					</Box>
				</Box>

				<Stack width="80%" minHeight={200} pt={2}>
					<Typography variant="h6">Select Colors </Typography>

					<DataGrid
						rows={styleData && styleData[colorsObjName] ? styleData[colorsObjName] : []}
						columns={colorTableColumns}
						sx={{
							minHeight: 200,
							maxHeight: 300,
							border: "none",
							"& .MuiDataGrid-row": {
								// borderBottom: "1px solid lightgray",
								cursor: "pointer",
								border: "none",
							},
							"& .MuiDataGrid-columnHeader": {
								cursor: "pointer",
							},
							"& .MuiDataGrid-columnHeaders": {
								background: grey[300],
								mb: 2,
								border: "none",
								borderRadius: 1,
								// padding: "10px 0px",
							},
						}}
						columnHeaderHeight={35}
						rowHeight={60}
						hideFooter
						// showCellVerticalBorder
						// onRowClick={(params, e) => handleStyleSelect(params.row)}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 10 },
							},
						}}
						isRowSelectable={(params) => params.row.is_published === false}
						pageSizeOptions={[10, 50]}
						checkboxSelection
						onRowSelectionModelChange={(newSelectionModel) => {
							console.log("dfsd", newSelectionModel);
							let obj = newSelectionModel?.reduce((acc, row) => {
								acc[row] = true;
								return acc;
							}, {});

							setColorsToPublish(styleData[colorsObjName]?.filter((row) => obj[row["id"]] === true));
						}}
					/>
				</Stack>

				<DialogActions sx={{ marginLeft: "auto" }}>
					<Button variant="outlined" onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button
						autoFocus
						variant="contained"
						sx={{ mr: 1 }}
						type="submit"
						disabled={colorsToPublish?.length === 0}>
						Publish
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default PublishModal;
