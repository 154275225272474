import { CloseRounded, DeleteRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import { GlobalState } from "context/GlobalContext";
import { colorChannelBuyingObj, colorselectedChannelBuyBuyingObj, sizeLevelBuyingObj } from "data/pimData";
import { useEffect, useState } from "react";

const defaultSize = "Size";
const colStyle = {
	headerAlign: "center",
	align: "center",
};
const allSizes = ["S", "M", "L", "XL", "XXL", "XXXL"];
const SizeBuyingModal = ({ modalOpen, handleCloseModal, selectedChannelBuy }) => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [sizeToAdd, setSizeToAdd] = useState(defaultSize);
	const [unUsedSizes, setUnUsedSizes] = useState(allSizes);
	const [channelTotalQty, setChannelTotalQty] = useState(selectedChannelBuy ? selectedChannelBuy.total_qty : 0);
	const [channelSizes, setChannelSizes] = useState(selectedChannelBuy ? selectedChannelBuy[sizeLevelBuyingObj] : []);

	const [remainingQty, setRemainingQty] = useState(0);
	const [remainingPercentage, setRemainingPercentage] = useState(0);
	const [filteredSize, setFilteredSize] = useState([]);

	useEffect(() => {
		if (selectedChannelBuy?.id) CalculateSizeQtyAndTotalRemainigData(selectedChannelBuy[sizeLevelBuyingObj]);
	}, [selectedChannelBuy]);

	const CustomEditableCell = (props) => {
		const handleInputChange = (event) => {
			const newValue = event.target.value || 0;
			// Add your validation logic here
			// For example, to restrict input to numbers only:
			if (!isNaN(Number(newValue))) {
				props.api.setEditCellValue({
					id: props.id,
					field: props.field,
					value: newValue,
				});
			}
		};
		return (
			<div style={{ width: "100%", height: "100%" }}>
				<input
					value={props.value}
					onChange={handleInputChange}
					onKeyPress={preventMinus}
					min={0}
					style={{ width: "100%", height: "100%", border: "none", outline: "none" }}
				/>
			</div>
		);
	};

	const handleResetModal = () => {
		setSizeToAdd(defaultSize);
		handleCloseModal(false);
		setUnUsedSizes(allSizes);
		setRemainingQty(0);
		setRemainingPercentage(0);
	};
	const handleAddSize = () => {
		let isPresent = channelSizes.filter((row) => row.size === sizeToAdd);

		if (isPresent.length) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Size already Added `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		let data = [
			...channelSizes,
			{
				size: sizeToAdd,
				id: new Date().valueOf(),
				total_qty: 0,
				qty_percent: 0,
				size_metadata_id: 0,
				channel_level_buying_id: 0,
				is_new: true,
			},
		];
		setChannelSizes(data);
	};

	const deleteSizeClick = (size, e) => {
		e.stopPropagation();
		let deletedData = channelSizes.filter((row) => row.id !== size.id);
		CalculateSizeQtyAndTotalRemainigData(deletedData);
		setChannelSizes(deletedData);
		// handleCheckSizes(data);
	};
	const sizeTableColumns = [
		{
			field: "size",
			headerName: "Size",
			attribute_name: "",
			width: 120,
			...colStyle,
		},
		{
			field: "qty_percent",
			headerName: "Percentage %",
			attribute_name: "",
			width: 120,
			type: "number",
			editable: channelTotalQty > 0,
			...colStyle,
			renderEditCell: (params) => <CustomEditableCell {...params} />,
		},
		{
			field: "total_qty",
			headerName: "Qty",
			attribute_name: "",
			width: 120,
			// type: 'number',
			// editable: true,
			...colStyle,
			// renderEditCell: (params) => <CustomEditableCell {...params} />,
		},
		{
			field: "action",
			headerName: "Action",
			attribute_name: "",
			width: 120,
			...colStyle,
			renderCell: (params) => {
				return (
					<IconButton disabled={filteredSize[0]?.published} onClick={(e) => deleteSizeClick(params.row, e)}>
						<DeleteRounded />
					</IconButton>
				);
			},
		},
	];
	const preventMinus = (e) => {
		if (e.code === "Minus" || e.key === "+") {
			e.preventDefault();
		}
	};
	const getCellParams = (params) => {
		// Check if the cell belongs to the 'Gender' column and if the age is greater than 18
		if (params.field === "gender" && params.row.age > 18) {
			return {
				...params,
				editable: true, // Make it editable
			};
		}
		return params;
	};
	const handleQty = (percentage, total) => {
		return (percentage * total) / 100;
	};

	const handle_total_qty_save = (mainData) => {
		let updateTotalQty = [];
		mainData.forEach((val) => {
			let total = 0;
			const channelBuy = val[colorChannelBuyingObj];
			channelBuy.forEach((data) => {
				total += data.total_qty;
			});
			updateTotalQty.push({
				...val,
				product_total_qty: total,
			});
		});
		// setSelectedChannelBuy(updateTotalQty);
	};

	const handle_total_qty = () => {
		let updateTotalQty = [];
		selectedChannelBuy.forEach((val) => {
			let total = 0;
			const channelBuy = val[colorChannelBuyingObj];
			channelBuy.forEach((data) => {
				total += data.total_qty;
			});
			updateTotalQty.push({
				...val,
				product_total_qty: total,
			});
		});
		selectedChannelBuy(updateTotalQty);
	};
	// console.log("filteredSize", filteredSize);
	let buttonDisable =
		filteredSize[0]?.published ||
		remainingPercentage < 0 ||
		remainingPercentage > 0 ||
		remainingQty < 0 ||
		remainingQty > 0 ||
		parseInt(filteredSize[0]?.blr_warehouse) + parseInt(filteredSize[0]?.ggn_warehouse) !==
			parseInt(filteredSize[0]?.total_qty);
	const disableAftrPublish = false;
	// const disableAftrPublish = unUsedSizes.length <= 0 || filteredSize[0]?.published ;

	const handle_Total_input_Change = () => {
		let datas = [];
		if (filteredSize.length)
			filteredSize[0][sizeLevelBuyingObj]?.forEach((value) => {
				datas.push({
					...value,
					total_qty: 0,
					percentage: 0,
				});
			});
		setFilteredSize([{ ...filteredSize[0], [sizeLevelBuyingObj]: datas }]);
		setRemainingQty(0);
		setRemainingPercentage(0);
	};

	const handleFinalSubmitClick = (submitType) => {
		// e.preventDefault()
	};

	const handle_total_val_change = (filterData) => {
		let datas = [];
		let renderData = filterData === "" ? filteredSize : filterData;
		let remainingQty = filteredSize ? filteredSize[0]?.total_qty : 0;
		let usedPer = 0;
		renderData[0][sizeLevelBuyingObj].forEach((value) => {
			let qty = handleQty(value.percentage, filteredSize[0].total_qty);
			usedPer += value.percentage;
			if (remainingQty - Math.round(qty) < 0) {
				qty = remainingQty;
				remainingQty = 0;
			} else {
				remainingQty = remainingQty - Math.round(qty);
			}
			datas.push({
				...value,
				total_qty: Math.round(qty),
			});
		});
		if (datas.length <= 0) {
			datas = renderData[0][sizeLevelBuyingObj];
		}
		setFilteredSize([{ ...renderData[0], [sizeLevelBuyingObj]: datas }]);
		setRemainingQty(remainingQty);
		setRemainingPercentage(filteredSize[0].total_qty > 0 ? 100 - usedPer : 0);
	};
	const CalculateSizeQtyAndTotalRemainigData = (sizes) => {
		let remainingQtyVar = channelTotalQty;
		let remainingPercent = 100;
		if (sizes && sizes?.length) {
			let updatedSizes = sizes.map((row) => {
				let rowQty = 0;
				if (row.qty_percent > 0) {
					remainingPercent = remainingPercent - (row.qty_percent > 0 ? row.qty_percent : 0);
					rowQty = Math.ceil((row.qty_percent / 100) * channelTotalQty);
					if (remainingQtyVar - rowQty < 0) {
						rowQty = remainingQtyVar;
						remainingQtyVar = 0;
					} else remainingQtyVar = remainingQtyVar - rowQty;
				}
				return { ...row, qty: rowQty };
			});
			setChannelSizes(updatedSizes);
			setRemainingPercentage(remainingPercent);
			setRemainingQty(remainingQtyVar);
		}
		console.log(remainingQtyVar, remainingPercent, channelTotalQty);
		return { remainingQtyVar, remainingPercent };
	};
	const handleChannelTotalQtyChange = (total_qty) => {
		let totalValue = total_qty === "" ? 0 : total_qty;
		setChannelTotalQty(Number(totalValue));
		let { remainingQty, remainingPercent } = CalculateSizeQtyAndTotalRemainigData(channelSizes);
	};
	const processRowUpdateSize = (newRow, original) => {
		let percent = newRow.qty_percent === "" ? 0 : Number(newRow.qty_percent);
		let totalNumber = Math.floor((percent * channelTotalQty) / 100);

		let newUpdatedRow = {
			...newRow,
			qty_percent: percent,
			total_qty: totalNumber,
		};
		let newSizeArr = channelSizes?.map((val) => {
			if (val.id === newRow.id) {
				return newUpdatedRow;
			} else return val;
		});

		let { remainingQty, remainingPercent } = CalculateSizeQtyAndTotalRemainigData(newSizeArr);
		return newUpdatedRow;
	};
	if (selectedChannelBuy)
		return (
			<Dialog
				maxWidth="sm"
				PaperProps={{ style: { padding: "15px" } }}
				fullWidth
				className="Channel_dialog"
				open={modalOpen}
				onClose={handleCloseModal}>
				<IconButton
					aria-label="close"
					onClick={() => {
						handleResetModal();
					}}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}>
					<CloseRounded />
				</IconButton>
				<Box width="98%">
					<Typography
						variant="body1"
						width="100%"
						fontSize={20}
						fontWeight={600}
						sx={{ margin: "15px 0 0 5px" }}>
						<span style={{ marginRight: "10px" }}>{"Style"}</span>:
						<span style={{ marginLeft: "10px" }}>
							{selectedChannelBuy?.productStyle?.style_name}-{selectedChannelBuy.color}-
							{selectedChannelBuy.pallete}
						</span>
					</Typography>
					<Typography
						variant="body1"
						width="100%"
						fontSize={20}
						fontWeight={600}
						sx={{ margin: "5px 0 0 5px" }}>
						<span style={{ marginRight: "10px" }}>{"Channel"}</span>:
						<span style={{ marginLeft: "10px" }}>{selectedChannelBuy?.channel}</span>
					</Typography>
					<Typography
						variant="body1"
						width="100%"
						fontSize={20}
						fontWeight={600}
						sx={{ margin: "5px 0 0px 5px" }}>
						<span style={{ marginRight: "10px" }}>{"Total"}</span>:
						<span style={{ marginLeft: "10px" }}>{selectedChannelBuy?.total_qty}</span>
					</Typography>
					<Box
						width="98%"
						sx={{
							margin: "5px 0px",
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "column",
						}}>
						<Stack direction={"row"} spacing={1}>
							<Stack sx={{ width: "28%" }} spacing={0}>
								<InputLabel>Total*</InputLabel>
								<NumberTextField
									size="small"
									type="number"
									onKeyPress={preventMinus}
									value={channelTotalQty}
									// onChange={(e) => {

									// 	let value = e.target.value === "" ? 0 : e.target.value;
									// 	setChannelTotalQty(Number(value));
									// 	setFilteredSize([{ ...filteredSize[0], total_qty: value }]);
									// 	setRemainingQty(value);
									// 	setRemainingPercentage(e.target.value === "" ? 0 : 100);
									// }}
									onChange={(e) => handleChannelTotalQtyChange(e.target.value)}
									// onBlur={(e) => {
									// 	if (e.target.value > 0) handle_total_val_change("");
									// 	else {
									// 		handle_Total_input_Change();
									// 	}
									// }}
									placeholder={`Enter Total`}
									disabled={selectedChannelBuy.is_published}
									required
								/>
							</Stack>
							<Stack sx={{ width: "28%" }} spacing={0}>
								<InputLabel>For BLR warehouse*</InputLabel>
								<NumberTextField
									size="small"
									type="number"
									onKeyPress={preventMinus}
									value={filteredSize[0]?.blr_warehouse > 0 ? filteredSize[0]?.blr_warehouse : ""}
									onChange={(e) => {
										let value = e.target.value === "" ? 0 : e.target.value;
										setFilteredSize([{ ...filteredSize[0], blr_warehouse: value }]);
									}}
									placeholder={`Enter Total Qnatity`}
									disabled={filteredSize[0]?.published}
									required
								/>
							</Stack>
							<Stack sx={{ width: "28%" }} spacing={0}>
								<InputLabel>For GGN warehouse*</InputLabel>
								<NumberTextField
									size="small"
									type="number"
									onKeyPress={preventMinus}
									value={filteredSize[0]?.ggn_warehouse > 0 ? filteredSize[0]?.ggn_warehouse : ""}
									onChange={(e) => {
										let value = e.target.value === "" ? 0 : e.target.value;
										setFilteredSize([{ ...filteredSize[0], ggn_warehouse: value }]);
									}}
									placeholder={`Enter Total Qnatity`}
									disabled={filteredSize[0]?.published}
									required
								/>
							</Stack>
						</Stack>
						<Stack direction="row">
							<Select
								size="small"
								sx={{ width: 100, marginTop: "22px" }}
								value={sizeToAdd?.size}
								disabled={disableAftrPublish}
								onChange={(e) => setSizeToAdd(e.target.value)}>
								<MenuItem value={"Size"}>Sizes</MenuItem>
								{unUsedSizes?.map((row) => {
									return (
										<MenuItem key={row} value={row}>
											{row}
										</MenuItem>
									);
								})}
							</Select>
							<Button
								disabled={disableAftrPublish}
								sx={{ margin: "22px 10px 0 10px" }}
								variant="contained"
								onClick={handleAddSize}>
								Add
							</Button>
						</Stack>
					</Box>
					<DataGrid
						rows={channelSizes ? channelSizes : []}
						columns={sizeTableColumns}
						height={0}
						sx={{
							minHeight: 220,
							// maxHeight: 250,
							height: "67%",
							border: "none",
							"& .MuiDataGrid-row": {
								// borderBottom: "1px solid lightgray",
								cursor: "pointer",
								border: "none",
							},
							"& .MuiDataGrid-columnHeader": {
								cursor: "pointer",
							},
							"& .MuiDataGrid-columnHeaders": {
								background: grey[300],
								// mb: 2,
								border: "none",
								borderRadius: 1,
								// padding: "10px 0px",
							},
						}}
						columnHeaderHeight={35}
						rowHeight={40}
						processRowUpdate={processRowUpdateSize}
						onProcessRowUpdateError={(error) => console.log(error)}
						// showCellVerticalBorder
						// onRowClick={(params, e) => setselectedChannelBuy(params.row)}
						// initialState={{
						// 	pagination: {
						// 		paginationModel: { pageSize: 30 },
						// 	},
						// }}
						disableRowSelectionOnClick
						// pageSizeOptions={[50, 100]}
						hideFooter={true}
						getCellParams={getCellParams}
					/>
				</Box>
				<DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
					<Box>
						<Stack spacing={0}>
							<Typography variant="body1" fontSize={12} fontWeight={400} sx={{ margin: "5px 0 0px 5px" }}>
								<span style={{ marginRight: "5px" }}>Remaining Qty</span>:
								<span style={{ marginLeft: "5px" }}>{remainingQty}</span>
							</Typography>
							<Typography variant="body1" fontSize={12} fontWeight={400} sx={{ margin: "5px 0 0px 5px" }}>
								<span style={{ marginRight: "5px" }}>Remaining Percentage</span>:
								<span style={{ marginLeft: "5px" }}>{remainingPercentage} %</span>
							</Typography>
						</Stack>
					</Box>
					<Stack direction="row">
						<Button
							variant="outlined"
							autoFocus
							onClick={() => {
								handleResetModal();
							}}>
							Cancel
						</Button>
						<Button
							disabled={buttonDisable}
							sx={{ margin: "0 0 0 7px" }}
							variant="contained"
							autoFocus
							onClick={() => handleFinalSubmitClick(false)}>
							Save
						</Button>
						<Button
							disabled={buttonDisable}
							sx={{ margin: "0 0 0 7px" }}
							variant="contained"
							onClick={() => handleFinalSubmitClick(false)}>
							Publish
						</Button>
					</Stack>
				</DialogActions>
			</Dialog>
		);
	else return <></>;
};

export default SizeBuyingModal;
