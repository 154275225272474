import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { GlobalState } from "../../../context/GlobalContext";
import FactoryDetails from "./FactoryDetails/FactoryDetails";
import { stateList } from "../../../data/stateMasterList";
import { Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ClearRounded, Delete } from "@mui/icons-material";
import moment from "moment";
import { isStartDateGraterThanEndDate } from "utils/isStartDateGreaterThanEndDate";
import ContactPersonDetails from "./ContactPersonDetails/ContactPersonDetails";

function SupplierForm() {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	console.log(stateList);
	const [companyData, setCompanyData] = useState({
		supplier_name: "",
		company_name: "",
		supplier_nature: "select",
		company_type: "select",
		no_of_factory: 0,
		gst_no: "",
		name_of_owner: "",
		gender: "select",
		position: "",
		office_address: "",
		office_state: "select",
		office_city: "",
		office_pin_code: "",
		monthly_capacity: 0,
		capacity_for_thor: 0,
		no_of_labour: 0,
		labour_roll: "select",
		company_establishment_date: new Date(),

		compliance_certificate_issuer: "",
		compliance_certificate_issue_date: new Date(),
		compliance_certificate_exp_date: new Date(),
		website_link: "",
		email: "",
		mobile_no_primary: "",
		mobile_no_secondary: "",
		pan_no: "",
		bank_name: "",
		bank_ifsc_code: "",
		bank_acc_no: "",
		micr_code: "",
		rtgs_code: "",
		is_verified: false,
		industry_type: "select",
	});

	const [files, setFiles] = useState([
		{ name: "comp_cert", file: null, tag: "Compliance_Certificate" },
		{ name: "pan_card_photo", file: null, tag: "Pan_Card_Photo" },
		{ name: "blank_check_photo", file: null, tag: "Blank_Check_photocopy" },
		{ name: "office_gst_certificate", file: null, tag: "Office_gst_certificate" },
	]);
	const [factoryFiles, setFactoryFiles] = useState([]);

	const [modalOpen, setModalOpen] = useState(false);
	const [successful, setSuccessful] = useState(false);
	const [factories, setFactories] = useState([]);

	const [mobileNumberError, setMobileNumberError] = useState("");
	const [mobileNumberSecError, setMobileNumberSecError] = useState("");
	const [officePincodeError, setOfficePinCodeError] = useState("");

	const [contactPersons, setContactPersons] = useState([
		{
			no: 0,
			department: "Management/Director",
			name: "",
			email: "",
			position: "",
			gender: "select",
			phone_no: "",
		},
		{ no: 1, department: "Finance", name: "", email: "", position: "", gender: "select", phone_no: "" },
		{ no: 2, department: "Merchandiser", name: "", email: "", position: "", gender: "select", phone_no: "" },
	]);
	const newContactPerson = {
		department: "Management/Director",
		name: "",
		email: "",
		position: "",
		gender: "select",
		phone_no: "",
	};
	const [newContactType, setNewContactType] = useState("select");

	const handleAddContactClick = () => {
		if (newContactType === "select") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Select Contact Type",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setContactPersons([
			...contactPersons,
			{ ...newContactPerson, department: newContactType, no: new Date().valueOf() },
		]);
		setNewContactType("select");
	};
	const deleteContactClick = () => {
		if (contactPersons.length > 3) {
			const arr = contactPersons;
			arr.pop();
			setContactPersons([...arr]);
		}
	};
	const [complianceCerts, setComplianceCerts] = useState([]);
	const newCert = {
		no: 0,
		name: "Compliance Cert 1",
		compliance_certificate_issue_date: moment(new Date()).format("YYYY-MM-DD"),
		compliance_certificate_exp_date: moment(new Date()).format("YYYY-MM-DD"),
		compliance_cert: null,
		compliance_certificate_issuer: "",
	};
	const [newComplianceCert, setNewComplianceCert] = useState(newCert);
	const handleAddCertificate = () => {
		if (newComplianceCert.compliance_cert === null || newComplianceCert.compliance_certificate_issuer === "") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Fill All The Fields",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		if (
			isStartDateGraterThanEndDate(
				newComplianceCert.compliance_certificate_issue_date,
				newComplianceCert.compliance_certificate_exp_date
			) === true
		) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Exp Date must be greater than Issue Date",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setComplianceCerts([...complianceCerts, newComplianceCert]);
		setNewComplianceCert({ ...newCert, no: new Date().valueOf() });
	};
	const handleDeleteCertClick = (row) => {
		setComplianceCerts(complianceCerts.filter((cert) => row.no !== cert.no));
	};
	const [message, setMessage] = useState("");
	const handleModalClose = () => {
		if (successful === true) setModalOpen(false);
	};
	const handleNoOfFactoryChange = (count) => {
		const countNo = +count;

		setCompanyData({ ...companyData, no_of_factory: countNo });
		const factArray = [];
		const factFiles = [];
		for (let i = 0; i < countNo; i++) {
			const factoryNo = i + 1;
			console.log(i);
			factArray.push({
				key: `factory_${factoryNo}`,
				name: `factory_${factoryNo}`,
				factory_liscence_no: "",
				factory_liscence_expiry_date: new Date(),
				factory_address: "",
				factory_city: "",
				factory_state: "select",
				factory_pin_code: "",
				factory_gst_no: "",
			});
			factFiles.push({
				key: `factory_${factoryNo}`,
				type: "factory_liscence_cert",
				name: "",
				file: null,
			});
			factFiles.push({
				key: `factory_${factoryNo}`,
				type: "gst_cert",
				name: "",
				file: null,
			});
		}
		setFactories(factArray);
		setFactoryFiles(factFiles);
	};

	const handleMobileNumberChange = (noType, event) => {
		const value = event.target.value;
		if (noType === "primary") {
			setCompanyData({ ...companyData, mobile_no_primary: value });
		} else {
			setCompanyData({ ...companyData, mobile_no_secondary: value });
		}

		const isValidMobileNumber = /^\d{10}$/.test(value);
		if (!isValidMobileNumber) {
			if (noType === "primary") setMobileNumberError("Mobile number must be 10 digits");
			else setMobileNumberSecError("Mobile number must be 10 digits");
		} else {
			if (noType === "primary") {
				setMobileNumberError("");
			} else {
				setMobileNumberSecError("");
			}
		}
	};

	const handlePinCodeChange = (event) => {
		const value = event.target.value;
		setCompanyData({ ...companyData, office_pin_code: value });

		const isValidPinCode = /^\d{6}$/.test(value);
		if (!isValidPinCode) {
			setOfficePinCodeError("pincode must be 6 digits");
		} else {
			setOfficePinCodeError("");
		}
	};
	const date = new Date();

	const handleFileChange = (event, index) => {
		const selectedFile = event.target.files[0];
		const newFiles = [...files];
		let newFileWithUpdatedName;
		let updatedFileName;
		if (newFiles[index].tag)
			updatedFileName = `${newFiles[index].tag}_${date.valueOf() + index}_${selectedFile.name
				.split(" ")
				.join("_")}`;
		else updatedFileName = `${date.valueOf() + index}_${selectedFile.name.split(" ").join("_")}`;

		newFileWithUpdatedName = new File([selectedFile], updatedFileName, {
			type: selectedFile.type,
			lastModified: selectedFile.lastModified,
		});
		newFiles[index] = {
			...newFiles[index],
			name: updatedFileName,
			file: newFileWithUpdatedName,
		};
		setFiles(newFiles);
	};

	const handleAddFile = () => {
		setFiles([...files, { name: "", file: null }]);
	};

	const handleRemoveFile = (index) => {
		const newFiles = [...files];
		newFiles.splice(index, 1);
		setFiles(newFiles);
	};

	const handleFileUpload = async () => {
		const formData = new FormData();
		const filesMaster = [...files, ...factoryFiles];
		for (let i = 0; i < filesMaster.length; i++) {
			formData.append("files", filesMaster[i].file);
		}
		return await axios
			.post("/api/supplier/file_upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				return "Error in file Upload";
			});
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();

		if (officePincodeError !== "") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Enter correct Pincodes",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		if (
			companyData.supplier_nature === "select" ||
			companyData.company_type === "select" ||
			companyData.industry_type === "select" ||
			companyData.gender === "select"
		) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Select all Dropdowns",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setModalOpen(true);

		let files = await handleFileUpload();
		console.log(files);
		if (!Array.isArray(files)) {
			files = [];
			setMessage("Error in Uploading files");
			setSuccessful(true);
			return;
		}
		await axios
			.post("/api/supplier", { ...companyData, files: JSON.stringify(files), supplierFactories: factories })
			.then((res) => {
				setSuccessful(true);
				setMessage("Details submitted successfully");
			})
			.catch((err) => {
				setSuccessful(true);
				setMessage("Error unable to submit data");
			});
		setSuccessful(true);
	};

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "48%" },
	};
	const contactFormStack = {
		width: { xs: "80%", sm: "40%", md: "19%" },
	};

	const tableCell = {
		component: "th",
		scope: "row",
		align: "center",
	};
	const tableCellHead = {
		align: "center",
	};

	return (
		<Box component="form" onSubmit={onFormSubmit} sx={{ maxWidth: "1300px", margin: "auto" }}>
			<Typography
				textAlign="center"
				variant="h6"
				pt={4}
				pb={4}
				mt={2}
				sx={{ background: "var(--grayBgColor)", borderRadius: 4 }}>
				Supplier Details Form
			</Typography>
			{/* top Layout */}
			<Box display="flex" width="100%" justifyContent="space-around" flexWrap="wrap">
				{/* company Details */}
				<Box width="45%">
					<Typography textAlign="center" variant="h6" p={2} width="100%">
						Company Info
					</Typography>
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Company Name:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={companyData.company_name}
								onChange={(e) => setCompanyData({ ...companyData, company_name: e.target.value })}
								placeholder="Enter company name"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Director/Owner Name:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={companyData.name_of_owner}
								onChange={(e) => setCompanyData({ ...companyData, name_of_owner: e.target.value })}
								placeholder="Enter director/owner name"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel htmlFor="companyType">
								Company Type<span>*</span>:
							</InputLabel>
							<Select
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								value={companyData.company_type}
								onChange={(e) => setCompanyData({ ...companyData, company_type: e.target.value })}
								size="small"
								required>
								<MenuItem value="select">Select</MenuItem>
								<MenuItem value="LTD">LTD</MenuItem>
								<MenuItem value="PVT LTD">PVT LTD</MenuItem>
								<MenuItem value="LLP">LLP</MenuItem>
								<MenuItem value="PARTNERSHIP">PARTNERSHIP</MenuItem>
								<MenuItem value="PROPRIETOR">PROPRIETOR</MenuItem>
							</Select>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>
								Industry Type<span>*</span>:
							</InputLabel>
							<Select
								value={companyData.industry_type}
								onChange={(e) => setCompanyData({ ...companyData, industry_type: e.target.value })}
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								size="small"
								required>
								<MenuItem value="select">Select</MenuItem>
								<MenuItem value="Small Scale">Small Scale</MenuItem>
								<MenuItem value="Large Scale">Large Scale</MenuItem>
								<MenuItem value="Government">Government</MenuItem>
								<MenuItem value="Contractors">Contractors</MenuItem>
								<MenuItem value="Contractors">MSME</MenuItem>
								<MenuItem value="Others">Others</MenuItem>
							</Select>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel htmlFor="industry_type_cert">Industry Type Certification</InputLabel>
							<TextField
								size="small"
								type="file"
								id="industry_type_cert"
								accept=".pdf, .jpeg, .jpg, .png"
								required
								onChange={(event) => handleFileChange(event, "industry_type_cert")}
							/>
						</Stack>

						<Stack sx={formStack} spacing={0}>
							<InputLabel>Company Establishment Date</InputLabel>
							<TextField
								size="small"
								type="date"
								value={companyData.company_establishment_date}
								onChange={(e) =>
									setCompanyData({ ...companyData, company_establishment_date: e.target.value })
								}
								placeholder="Enter labour count"
								required
							/>
						</Stack>
						{/* PAN Number */}
						<Stack className="vendor-input" sx={formStack} spacing={0}>
							<InputLabel htmlFor="panNumber">PAN Number:</InputLabel>
							<TextField
								size="small"
								type="text"
								id="panNumber"
								value={companyData.pan_no}
								onChange={(e) => setCompanyData({ ...companyData, pan_no: e.target.value })}
								placeholder="Enter PAN Number"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel htmlFor="pan_img">Upload Pancard</InputLabel>
							<TextField
								size="small"
								type="file"
								id="pan_img"
								accept=".pdf, .jpeg, .jpg, .png"
								required
								onChange={(event) => handleFileChange(event, "pan_img")}
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Office GST Number:</InputLabel>
							<TextField
								type="text"
								size="small"
								value={companyData.gst_no}
								onChange={(e) => setCompanyData({ ...companyData, gst_no: e.target.value })}
								placeholder="Enter GST Number"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel htmlFor="office_gst_cert">Office GST Certificate</InputLabel>
							<TextField
								size="small"
								type="file"
								id="office_gst_cert"
								accept=".pdf, .jpeg, .jpg, .png"
								required
								onChange={(event) => handleFileChange(event, "office_gst_cert")}
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Website Link </InputLabel>
							<TextField
								size="small"
								type="text"
								value={companyData.website_link}
								onChange={(e) => setCompanyData({ ...companyData, website_link: e.target.value })}
								placeholder="Enter Address"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>{"Location Link (Google Maps)"} </InputLabel>
							<TextField
								size="small"
								type="text"
								value={companyData.gmap_location_link}
								onChange={(e) => setCompanyData({ ...companyData, gmap_location_link: e.target.value })}
								placeholder="Enter Address"
								required
							/>
						</Stack>
					</Stack>
				</Box>
				<Divider orientation="vertical" variant="middle" flexItem />

				<Box width="42%">
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Typography textAlign="center" variant="h6" pt={2} width="100%">
							Registered Address
						</Typography>
						<Stack sx={{ width: "100%" }} spacing={0}>
							<InputLabel>Office Address:</InputLabel>
							<TextField
								size="small"
								type="text"
								multiline
								rows={2}
								value={companyData.office_address}
								onChange={(e) => setCompanyData({ ...companyData, office_address: e.target.value })}
								placeholder="Enter Address"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Office City Name:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={companyData.office_city}
								onChange={(e) => setCompanyData({ ...companyData, office_city: e.target.value })}
								placeholder="Enter city name"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Office State:</InputLabel>
							<Select
								value={companyData.office_state}
								onChange={(e) => setCompanyData({ ...companyData, office_state: e.target.value })}
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								size="small"
								fullWidth
								required>
								<MenuItem value={"select"}>Select State</MenuItem>
								{stateList &&
									stateList.map((row) => <MenuItem value={row.state}>{row.state}</MenuItem>)}
							</Select>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Office Pincode:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={companyData.office_pin_code}
								onChange={(e) => handlePinCodeChange(e)}
								placeholder="Enter 6 digit pincode"
								required
							/>
							<span className="error">{officePincodeError}</span>
						</Stack>
					</Stack>
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Typography textAlign="center" variant="h6" pt={2} width="100%">
							Communication Address
						</Typography>
						<Stack sx={{ width: "100%" }} spacing={0}>
							<InputLabel>Office Address:</InputLabel>
							<TextField
								size="small"
								type="text"
								multiline
								rows={2}
								value={companyData.office_address}
								onChange={(e) => setCompanyData({ ...companyData, office_address: e.target.value })}
								placeholder="Enter Address"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Office City Name:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={companyData.office_city}
								onChange={(e) => setCompanyData({ ...companyData, office_city: e.target.value })}
								placeholder="Enter city name"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Office State:</InputLabel>
							<Select
								value={companyData.office_state}
								onChange={(e) => setCompanyData({ ...companyData, office_state: e.target.value })}
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								size="small"
								fullWidth
								required>
								<MenuItem value={"select"}>Select State</MenuItem>
								{stateList &&
									stateList.map((row) => <MenuItem value={row.state}>{row.state}</MenuItem>)}
							</Select>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Office Pincode:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={companyData.office_pin_code}
								onChange={(e) => handlePinCodeChange(e)}
								placeholder="Enter 6 digit pincode"
								required
							/>
							<span className="error">{officePincodeError}</span>
						</Stack>
					</Stack>
				</Box>
			</Box>
			<Box display="flex" width="100%" justifyContent="space-around" flexWrap="wrap">
				<Box width="45%">
					<Typography textAlign="center" variant="h6" p={2} width="100%">
						Capacity Details
					</Typography>
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Stack className="vendor-input" sx={formStack} spacing={0}>
							<InputLabel>
								No of Labour <span>*</span>:
							</InputLabel>
							<TextField
								size="small"
								value={companyData.supplier_name}
								onChange={(e) => setCompanyData({ ...companyData, supplier_name: e.target.value })}
								placeholder="Enter supplier name"
								required
							/>
						</Stack>
						<Stack className="vendor-input" sx={formStack} spacing={0}>
							<InputLabel>
								Capacity for House Of Rare <span>*</span>:
							</InputLabel>
							<TextField
								size="small"
								value={companyData.supplier_name}
								onChange={(e) => setCompanyData({ ...companyData, supplier_name: e.target.value })}
								placeholder="Enter supplier name"
								required
							/>
						</Stack>
					</Stack>
					<Table
						sx={{ width: 400, border: `1px solid ${grey[300]}`, mt: 2 }}
						size="small"
						aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Labour Roll*:</TableCell>
								<TableCell>Count</TableCell>
								<TableCell>percent</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell component="th" scope="row">
									On company Roll
								</TableCell>
								<TableCell component="th" scope="row">
									<TextField sx={{ width: 80 }} size="small" variant="standard" />
								</TableCell>
								<TableCell component="th" scope="row">
									0%
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">
									On Contract
								</TableCell>
								<TableCell component="th" scope="row">
									<TextField sx={{ width: 80 }} size="small" variant="standard" />
								</TableCell>

								<TableCell component="th" scope="row">
									0%
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Box>
				<Divider orientation="vertical" variant="middle" flexItem />

				<Box width="42%">
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Typography textAlign="center" variant="h6" p={2} width="100%">
							Financial Details
						</Typography>
						<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
							{/* Bank Name */}
							<Stack className="vendor-input" sx={formStack} spacing={0}>
								<InputLabel htmlFor="bankName">Bank Name:</InputLabel>
								<TextField
									size="small"
									type="text"
									id="bankName"
									value={companyData.bank_name}
									onChange={(e) => setCompanyData({ ...companyData, bank_name: e.target.value })}
									placeholder="Enter Bank Name"
									required
								/>
							</Stack>

							{/* IFSC Code */}
							<Stack className="vendor-input" sx={formStack} spacing={0}>
								<InputLabel htmlFor="ifscCode">IFSC Code:</InputLabel>
								<TextField
									size="small"
									type="text"
									id="ifscCode"
									value={companyData.bank_ifsc_code}
									onChange={(e) => setCompanyData({ ...companyData, bank_ifsc_code: e.target.value })}
									placeholder="Enter IFSC Code"
									required
								/>
							</Stack>

							{/* Bank Account Number */}
							<Stack className="vendor-input" sx={formStack} spacing={0}>
								<InputLabel htmlFor="bankAccountNumber">Bank Account Number:</InputLabel>
								<TextField
									size="small"
									type="text"
									id="bankAccountNumber"
									value={companyData.bank_acc_no}
									onChange={(e) => setCompanyData({ ...companyData, bank_acc_no: e.target.value })}
									placeholder="Enter Bank Account Number"
									required
								/>
							</Stack>

							{/* MICR Code */}
							<Stack className="vendor-input" sx={formStack} spacing={0}>
								<InputLabel>MICR Code:</InputLabel>
								<TextField
									size="small"
									type="text"
									id="micrCode"
									value={companyData.micr_code}
									onChange={(e) => setCompanyData({ ...companyData, micr_code: e.target.value })}
									placeholder="Enter MICR Code"
									required
								/>
							</Stack>

							{/* RTGS Code */}
							<Stack className="vendor-input" sx={formStack} spacing={0} mr="auto">
								<InputLabel htmlFor="rtgsCode">RTGS Code:</InputLabel>
								<TextField
									size="small"
									type="text"
									id="rtgsCode"
									value={companyData.rtgs_code}
									onChange={(e) => setCompanyData({ ...companyData, rtgs_code: e.target.value })}
									placeholder="Enter RTGS Code"
									required
								/>
							</Stack>
							<Stack sx={formStack} spacing={0}>
								<InputLabel htmlFor="blank_check_photo">Blank Check Photo</InputLabel>
								<TextField
									size="small"
									type="file"
									id="blank_check_photo"
									accept=".pdf, .jpeg, .jpg, .png"
									required
									onChange={(event) => handleFileChange(event, "blank_check_photo")}
								/>
							</Stack>
							<Stack className="vendor-input" sx={formStack} spacing={0} mr="auto">
								<InputLabel htmlFor="rtgsCode">Payment Term:</InputLabel>
								<TextField
									size="small"
									type="text"
									id="rtgsCode"
									value={companyData.payment_term}
									onChange={(e) => setCompanyData({ ...companyData, payment_term: e.target.value })}
									placeholder="Enter Payment Term"
									required
								/>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			</Box>

			<Box width="90%" margin="auto">
				<Typography textAlign="center" variant="h6" p={2} width="100%">
					Contact Details
				</Typography>
				{contactPersons &&
					contactPersons.map((row) => {
						return (
							<ContactPersonDetails
								currentPerson={row}
								contactPersons={contactPersons}
								setContactPersons={setContactPersons}
							/>
						);
					})}

				<Box display="flex" columnGap={2} width="100%" justifyContent="center" pt={3}>
					<Select
						value={newContactType}
						onChange={(e) => setNewContactType(e.target.value)}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						size="small"
						sx={{ width: 200 }}
						required>
						<MenuItem value="select">Select Contact Type</MenuItem>
						<MenuItem value="Management/Director">Management/Director</MenuItem>
						<MenuItem value="Finance">Finance</MenuItem>
						<MenuItem value="Merchandiser">Merchandiser</MenuItem>
					</Select>
					<Button variant="contained" onClick={handleAddContactClick}>
						Add Contact
					</Button>
					<Button
						variant="contained"
						onClick={deleteContactClick}
						sx={{ width: 100, marginLeft: "auto", display: "" }}
						disabled={contactPersons.length > 3 ? false : true}>
						Delete
					</Button>
				</Box>
			</Box>

			<Box display="flex" width="90%" justifyContent="space-between" flexWrap="wrap" margin="auto">
				<Typography textAlign="center" variant="h6" p={2} width="100%">
					Compliance Certificates
				</Typography>
				<Box width="47%">
					<Table sx={{ width: "100%", border: `1px solid ${grey[300]}`, mt: 2 }} size="small">
						<TableHead>
							<TableRow>
								<TableCell {...tableCellHead}>Issuer</TableCell>
								<TableCell {...tableCellHead}>Issue Date</TableCell>
								<TableCell {...tableCellHead}>Expiry Date</TableCell>
								{/* <TableCell {...tableCellHead}>File</TableCell> */}
								<TableCell {...tableCellHead}>Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{complianceCerts &&
								complianceCerts.map((row) => (
									<TableRow>
										<TableCell {...tableCell}>{row.compliance_certificate_issuer}</TableCell>
										<TableCell {...tableCell}>{row.compliance_certificate_issue_date}</TableCell>
										<TableCell {...tableCell}>{row.compliance_certificate_exp_date}</TableCell>
										{/* <TableCell {...tableCell}>{URL.createObjectURL(row.compliance_cert)}</TableCell> */}
										{/* <TableCell {...tableCell}></TableCell> */}
										<TableCell {...tableCell}>
											<IconButton size="small" onClick={() => handleDeleteCertClick(row)}>
												<Delete />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Box>
				<Stack direction="row" width="45%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Typography variant="h6" pt={2} width="100%">
						Add Certificate
					</Typography>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Certificate Issuer:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={newComplianceCert.compliance_certificate_issuer}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_issuer: e.target.value,
								})
							}
							placeholder="Enter Issuer Name"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Cert Issue Date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={newComplianceCert.compliance_certificate_issue_date}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_issue_date: e.target.value,
								})
							}
							placeholder="Enter Issue Date"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Cert Expiry Date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={newComplianceCert.compliance_certificate_exp_date}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_exp_date: e.target.value,
								})
							}
							placeholder="Enter Expiry Date"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Certificate:</InputLabel>
						<TextField
							size="small"
							type="file"
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_cert: e.target.files[0],
								})
							}
							placeholder="Enter director/owner name"
							required
						/>
					</Stack>
					<Box width="100%" display="flex" justifyContent="center">
						<Button
							variant="contained"
							sx={{ margin: "auto", width: 250, mt: 1 }}
							onClick={handleAddCertificate}>
							Add Certificate
						</Button>
					</Box>
				</Stack>
				{/* {complianceCerts &&
					complianceCerts.map((row) => {
						return (
							<Stack
								direction="row"
								width="45%"
								justifyContent="space-between"
								flexWrap="wrap"
								rowGap={1}>
								<Typography variant="subtitle1" pt={2} width="100%">
									{row.name}
								</Typography>
								<Stack sx={formStack} spacing={0}>
									<InputLabel>Compliance Certificate Issuer:</InputLabel>
									<TextField
										size="small"
										type="text"
										value={row.compliance_certificate_issuer}
										onChange={(e) =>
											setComplianceCerts([
												...complianceCerts,
												{
													...complianceCerts.filter((person) => person.no === row.no)[0],
													name: e.target.value,
												},
											])
										}
										placeholder="Enter director/owner name"
										required
									/>
								</Stack>
								<Stack sx={formStack} spacing={0}>
									<InputLabel>Compliance Cert Issue Date:</InputLabel>
									<TextField
										size="small"
										type="text"
										value={row.compliance_certificate_issue_date}
										onChange={(e) =>
											setComplianceCerts([
												...complianceCerts,
												{
													...complianceCerts.filter((person) => person.no === row.no)[0],
													compliance_certificate_issue_date: e.target.value,
												},
											])
										}
										placeholder="Enter director/owner name"
										required
									/>
								</Stack>
								<Stack sx={formStack} spacing={0}>
									<InputLabel>Compliance Cert Expiry Date:</InputLabel>
									<TextField
										size="small"
										type="text"
										value={row.phone_no}
										onChange={(e) =>
											setContactPersons([
												...contactPersons,
												{
													...contactPersons.filter((person) => person.no === row.no)[0],
													phone_no: e.target.value,
												},
											])
										}
										placeholder="Enter director/owner name"
										required
									/>
								</Stack>
								<Stack sx={formStack} spacing={0}>
									<InputLabel>Compliance Certificate:</InputLabel>
									<TextField
										size="small"
										type="text"
										value={row.gender}
										onChange={(e) =>
											setContactPersons([
												...contactPersons,
												{
													...contactPersons.filter((person) => person.no === row.no)[0],
													gender: e.target.value,
												},
											])
										}
										placeholder="Enter director/owner name"
										required
									/>
								</Stack>
							</Stack>
						);
					})} */}
			</Box>
			<Box display="flex" width="90%" justifyContent="space-between" flexWrap="wrap" margin="auto">
				<Typography textAlign="center" variant="h6" pt={4}>
					Factory Info
				</Typography>

				{/* Select No Of factories */}
				<Stack spacing={3} direction="row" alignItems="center" width="100%" justifyContent="center">
					<InputLabel>Select Number of Factories</InputLabel>
					<Select
						sx={{ width: 100 }}
						value={companyData.no_of_factory}
						onChange={(e) => handleNoOfFactoryChange(e.target.value)}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						size="small"
						required>
						<MenuItem value={0}>0</MenuItem>
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
							<MenuItem value={row}>{row}</MenuItem>
						))}
					</Select>
				</Stack>

				{factories &&
					factories.map((row, index) => {
						return (
							<FactoryDetails
								currentFactory={row}
								factories={factories}
								setFactories={setFactories}
								factoryFiles={factoryFiles}
								setFactoryFiles={setFactoryFiles}
								index={index}
							/>
						);
					})}
			</Box>

			{/* <Box sx={{ maxWidth: "1100px", margin: "auto" }}>
				<Box
					display="flex"
					flexWrap="wrap"
					pt={2}
					rowGap={1}
					className="vendorInputForm"
					justifyContent="space-around">

					<Typography width="100%" variant="h6" textAlign={{ xs: "center", md: "left" }}>
						Upload Files{" "}
					</Typography>
					<span style={{ color: "red", width: "100%" }}>
						( Compliance certificate and Pancard photocopy are required)
					</span>
					<Box
						display="flex"
						flexWrap="wrap"
						width="100%"
						columnGap={2}
						justifyContent={{ xs: "center", sm: "space-around", md: "flex-start" }}>
						{files.map((file, index) => (
							<Stack key={index} className="vendor-input" spacing={0}>
								<InputLabel htmlFor={`file-${index}`}>
									{file.tag ? file.tag : `Upload File" ${index + 1}`}:
								</InputLabel>
								<TextField
									size="small"
									type="file"
									id={`file-${index}`}
									accept=".pdf, .jpeg, .jpg, .png"
									required
									onChange={(event) => handleFileChange(event, index)}
								/>
								{files.length !== 1 && !file.tag ? (
									<Button type="button" onClick={() => handleRemoveFile(index)}>
										Remove
									</Button>
								) : (
									<></>
								)}
							</Stack>
						))}
					</Box>
					{files.length < 4 && (
						<Button
							// variant="contained"
							type="button"
							onClick={handleAddFile}
							sx={{ height: "40px", alignSelf: "center", textTransform: "capitalize" }}>
							+ Add More Files
						</Button>
					)}
				</Box>
			</Box> */}

			<Box sx={{ width: "100%" }} display="flex" justifyContent="center" mb={4}>
				<Button variant="contained" type="submit">
					Submit Form
				</Button>
			</Box>
			<Modal
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				open={modalOpen}
				onClose={handleModalClose}>
				<Box
					width="300px"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{ background: "white", borderRadius: 4, p: 4, height: "100px" }}>
					{setSuccessful ? (
						<Stack alignItems="center" spacing={2}>
							<Typography variant="h6">{message}</Typography>
							<Button variant="outlined" onClick={handleModalClose}>
								Close
							</Button>
						</Stack>
					) : (
						<CircularProgress />
					)}
				</Box>
			</Modal>
		</Box>
	);
}

export default SupplierForm;
