import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { Chip, DialogActions, MenuItem, Select, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { admin, custom, departments, externalTypes, services, userTypes } from "data/authData";

const AddRole = ({ submitFunction, options }) => {
	const [roleToAdd, setRoleToAdd] = useState("select");
	const handleSelectChange = (value) => {
		submitFunction(value);
	};
	return (
		<Box sx={{ display: "flex", columnGap: 2 }}>
			<Select
				MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
				value={roleToAdd}
				sx={{ width: 250 }}
				onChange={(e) => handleSelectChange(e.target.value)}
				size="small"
				required>
				<MenuItem value="select">Select</MenuItem>
				{options &&
					Object.keys(options).map((role) => {
						const roleData = options[role];
						return (
							<MenuItem key={roleData.key} value={roleData.key}>
								{roleData.name}
							</MenuItem>
						);
					})}
			</Select>
		</Box>
	);
};

function UserInfoModal({
	modalEditable,
	selectedUser,
	setSelectedUser,
	allUsers,
	setAllUsers,
	setEditModalOpen,
	setModalEditable,
}) {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [user, setUser] = useState(selectedUser);
	const [roles, setRoles] = useState(selectedUser.role);
	const [month, setMonth] = useState(dayjs());

	const handleCancelEditClick = () => {
		setUser(selectedUser);
		setRoles(selectedUser.role);
		setModalEditable(!modalEditable);
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();

		if (!Object.keys(roles).length) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Add atlest one role",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}

		const apibody = { ...user, role: roles };
		await axios
			.put(`/api/auth/user_update/${user.id}`, apibody)
			.then((res) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "User updated",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setAllUsers([apibody, ...allUsers.filter((row) => row.id !== selectedUser.id)]);
				setModalEditable(true);
				// setSelectedUser();
				setEditModalOpen(false);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message:
						err.response && err.response.data ? err.response.data.error : "Unable to update User Details ",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	const handleAddAdminFunction = () => {
		const obj = roles;
		obj[admin] = true;
		setRoles({ ...obj });
	};

	const handleRoleDelete = (roleToDelete) => {
		return () => {
			let obj = { ...roles };
			delete obj[roleToDelete];
			setRoles({ ...obj });
		};
	};

	const handleAddNewRole = (userType) => {
		return (roleToAdd) => {
			if (roles[roleToAdd]) {
				setSnackBarOpen(true);
				setSnackBar({ ...snackBar, message: "Role already Exists", severity: "error" });
			} else {
				let obj = {};
				if (userType === userTypes.external) {
					let other_data =
						user && user.other_data
							? { ...user.other_data, root_path: roleToAdd }
							: { root_path: roleToAdd };
					setUser({ ...user, other_data });
				} else obj = { ...roles };
				obj[roleToAdd] = true;
				setRoles({ ...obj });
			}
		};
	};

	return (
		<Box component="form" onSubmit={onFormSubmit} sx={{ maxWidth: "1100px", margin: "auto" }}>
			{/* form */}
			<Box direction="row" width="100%" justifyContent="space-around" flexWrap="wrap">
				<Box
					display="flex"
					flexWrap="wrap"
					rowGap={2}
					className="vendorInputForm"
					justifyContent="space-between">
					<Stack sx={formStack} spacing={1}>
						<InputLabel>User Type:</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={user.type}
							sx={{ width: 250 }}
							onChange={(e) => setUser({ ...user, type: e.target.value })}
							size="small"
							disabled
							required>
							<MenuItem value="select">Select</MenuItem>
							<MenuItem value="INTERNAL">INTERNAL</MenuItem>
							<MenuItem value="EXTERNAL">EXTERNAL</MenuItem>
						</Select>
					</Stack>
					{user.type !== userTypes.external && (
						<Stack sx={formStack} spacing={1}>
							<InputLabel>Employee ID:</InputLabel>
							<TextField
								disabled={modalEditable}
								size="small"
								type="text"
								value={user.employee_id}
								onChange={(e) => setUser({ ...user, employee_id: e.target.value })}
								placeholder="Enter employee_id"
								required
							/>
						</Stack>
					)}
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Name:</InputLabel>
						<TextField
							disabled={modalEditable}
							size="small"
							type="text"
							value={user.name}
							onChange={(e) => setUser({ ...user, name: e.target.value })}
							placeholder="Enter name"
							required
						/>
					</Stack>
					{user.type !== userTypes.external && (
						<Stack sx={formStack} spacing={1}>
							<InputLabel>Department:</InputLabel>
							<TextField
								disabled={modalEditable}
								size="small"
								type="text"
								value={user.department}
								onChange={(e) => setUser({ ...user, department: e.target.value })}
								placeholder="Enter department"
								required
							/>
						</Stack>
					)}
					{user.type !== userTypes.external && (
						<Stack sx={formStack} spacing={1}>
							<InputLabel>Designation</InputLabel>
							<TextField
								disabled={modalEditable}
								size="small"
								type="text"
								value={user.designation}
								onChange={(e) => setUser({ ...user, designation: e.target.value })}
								placeholder="Enter designation"
								required
							/>
						</Stack>
					)}
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Mobile No</InputLabel>
						<TextField
							disabled={modalEditable}
							size="small"
							type="text"
							value={user.mobile_no}
							onChange={(e) => setUser({ ...user, mobile_no: e.target.value })}
							placeholder="Enter designation"
							required
						/>
					</Stack>

					<Stack sx={formStack} spacing={1}>
						<InputLabel>Email:</InputLabel>
						<TextField
							disabled={modalEditable}
							size="small"
							type="email"
							value={user.email}
							onChange={(e) => setUser({ ...user, email: e.target.value })}
							placeholder="Enter email"
							required
						/>
					</Stack>

					<Stack width="95%">
						<Typography variant="h6" width="100%">
							User Access
						</Typography>
						<Box key={roles} display="flex" p={1} columnGap={2}>
							{roles &&
								Object.keys(roles).map((role) => {
									return (
										<Chip
											// disabled={modalEditable}
											key={role}
											label={role}
											onDelete={!modalEditable ? handleRoleDelete(role) : null}
										/>
									);
								})}
						</Box>
						{!modalEditable &&
							(user.type !== userTypes.external ? (
								<Stack direction="row" flexWrap="wrap" justifyContent="space-between" rowGap={1}>
									<Box width="30%">
										<Typography variant="subtitle1">Department Level</Typography>
										<AddRole
											submitFunction={handleAddNewRole(userTypes.internal)}
											options={departments}
										/>
									</Box>
									<Box width="30%">
										<Typography variant="subtitle1">Service Level</Typography>

										<AddRole
											submitFunction={handleAddNewRole(userTypes.internal)}
											options={services}
										/>
									</Box>
									<Box width="30%">
										<Typography variant="subtitle1">Custom Tags</Typography>

										<AddRole
											submitFunction={handleAddNewRole(userTypes.internal)}
											options={custom}
										/>
									</Box>
									<Box width="30%">
										<Button variant="outlined" onClick={handleAddAdminFunction}>
											Add Admin
										</Button>
									</Box>
								</Stack>
							) : (
								<Box width="30%">
									<Typography variant="subtitle1">Custom Tags</Typography>

									<AddRole
										submitFunction={handleAddNewRole(userTypes.external)}
										options={externalTypes}
									/>
								</Box>
							))}
					</Stack>
				</Box>
			</Box>
			{/* form Actions */}
			<DialogActions sx={{ p: 3 }}>
				{!modalEditable ? (
					<>
						<Button variant="outlined" onClick={handleCancelEditClick}>
							Cancel Edit
						</Button>
						<Button variant="contained" type="submit">
							Save Changes
						</Button>
					</>
				) : (
					<Button variant="contained" onClick={() => setModalEditable(!modalEditable)}>
						Edit Data
					</Button>
				)}
			</DialogActions>
		</Box>
	);
}

export default UserInfoModal;
