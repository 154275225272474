import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import { GlobalState } from "context/GlobalContext";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import axios from "axios";
import MboStoreForm from "./MboStoreForm";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { CircularProgress, InputLabel, MenuItem, Select } from "@mui/material";
import FileSaver from "file-saver";
import moment from "moment";

const MboStore = () => {
	const columns = [
		{ field: "brand", headerName: "BRAND", width: 120, headerClassName: "super-app-theme--header" },
		{
			field: "channel",
			headerName: "CHANNEL",
			width: 110,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "short_name",
			headerName: "SHORT NAME",
			width: 180,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "site_name",
			headerName: "SITE NAME",
			width: 270,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "state",
			headerName: "STATE",
			width: 140,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "city",
			headerName: "CITY",
			width: 130,
			headerClassName: "super-app-theme--header",
		},
		{
			field: "status",
			headerName: "STATUS",
			width: 150,
			headerClassName: "super-app-theme--header",
		},
	];

	const DOWNLOAD_OPTIONS = [
		{
			label: "SELECT",
			value: "SELECT",
		},
		{
			label: "DOWNLOAD MBO STORES",
			value: "MBO",
		},
		{
			label: "DOWNLOAD LFS STORES",
			value: "LFS",
		},
		{
			label: "DOWNLOAD MBO + LFS STORES",
			value: "ALL",
		},
	];

	const [open, setOpen] = useState(false);
	const [distributer, setDistributer] = useState([]);
	const [action, setAction] = useState("CREATE");
	const [mboStores, setMboStores] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [value, setValue] = useState({});
	const [downloadOption, setDownloadOption] = useState("SELECT");
	const navigate = useNavigate();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	async function fetchData() {
		setIsLoading(true);
		await axios
			.get("/api/store/mbo-store")
			.then((res) => {
				if (res.status === 200 && res.data) {
					console.log(res.data);
					setMboStores(res.data);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		setIsLoading(false);
	}

	useEffect(() => {
		fetchData();
	}, []);

	const handleDownload = async () => {
		try {
			setDownloading(true);
			await axios({
				url: `/api/store/mbo-store-download/${downloadOption}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(
					url,
					downloadOption === "MBO"
						? `MBO_store_${new Date().toJSON().slice(0, 10)}.xlsx`
						: downloadOption === "LFS"
						? `LFS_store_${new Date().toJSON().slice(0, 10)}.xlsx`
						: `MBO+LFS_${new Date().toJSON().slice(0, 10)}.xlsx`
				);
				setDownloading(false);
			});
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Failed to download file",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setDownloading(false);
		}
	};

	return (
		<Stack maxWidth="1100px" margin="auto" spacing={3}>
			<Box m="auto" pt={4}>
				<Button
					startIcon={<KeyboardBackspaceRounded />}
					variant="outlined"
					onClick={() => navigate(-1)}
					sx={{ width: 100 }}>
					Back
				</Button>
			</Box>

			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="h4" width="100%" p={2}>
					MBO - LFS Stores
				</Typography>

				<Box display="flex" alignItems="center" columnGap={3}>
					<Select
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						value={downloadOption}
						sx={{ width: 255, height: 52 }}
						onChange={(e) => setDownloadOption(e.target.value)}
						size="small">
						{DOWNLOAD_OPTIONS.map((option) => (
							<MenuItem key={option.label} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>

					<Button
						disabled={downloadOption === "SELECT" ? true : false}
						onClick={handleDownload}
						startIcon={
							downloading ? (
								<CircularProgress color="inherit" size="20px" />
							) : (
								<ArrowDownwardRoundedIcon />
							)
						}
						sx={{ height: "50px" }}
						variant="contained">
						DOWNLOAD
					</Button>
					<Button
						variant="contained"
						sx={{ height: "50px" }}
						startIcon={<AddRoundedIcon />}
						onClick={() => {
							setOpen(true);
							setAction("CREATE");
						}}>
						CREATE
					</Button>
				</Box>
			</Box>

			<DataGrid
				rows={mboStores}
				columns={columns}
				autoHeight
				sx={{
					"& .super-app-theme--header": {
						backgroundColor: grey[300],
					},
					minHeight: "65vh",
					minWidth: "100%",
					cursor: "pointer",
				}}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 5,
						},
					},
				}}
				pageSizeOptions={[5, 10]}
				disableRowSelectionOnClick
				onRowClick={(params) => {
					setAction("UPDATE");
					setValue({ ...params.row, open_date: moment(params.row.open_date).format("YYYY-MM-DD") });
					setOpen(!open);
				}}
			/>
			<Modal
				open={open}
				onClose={() => setOpen(!open)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ minHeight: "100%" }}
				disableEnforceFocus>
				<Stack
					sx={{
						width: 1040,
						bgcolor: "background.paper",
						p: 4,
						borderRadius: 2,
						maxHeight: 450,
						overflow: "auto",
						margin: "auto",
						my: 8,
					}}>
					<MboStoreForm
						setOpen={setOpen}
						action={action}
						value={value}
						setMboStores={setMboStores}
						mboStores={mboStores}
					/>
				</Stack>
			</Modal>
		</Stack>
	);
};

export default MboStore;
