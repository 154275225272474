import { roundDecimalValueUptoTwo } from "./roundDecimalValue";

export const calculateAsp = (total_sp, qty) => {
	let asp;
	if (total_sp && qty) {
		asp = roundDecimalValueUptoTwo(total_sp / qty);
		return asp;
	} else return 0;
};
export const calculateDiscount = (total_mrp, total_sp) => {
	let dis;
	if (total_mrp && total_sp) {
		dis = roundDecimalValueUptoTwo(((total_mrp - total_sp) / total_mrp) * 100);
		return dis;
	} else return 0;
};
export const calculateRos = (qty) => {
	let ros;
	if (qty) {
		ros = roundDecimalValueUptoTwo(qty / 180);
		return ros;
	} else return 0;
};
export const calculateST = (dispatch_qty, sale_qty) => {
	let st;

	if (!dispatch_qty || sale_qty === 0) {
		return 0;
	} else {
		st = roundDecimalValueUptoTwo((sale_qty / dispatch_qty) * 100);
		// console.log(dispatch_qty, sale_qty, st);
		return st;
	}
};
export const calculateNSVByMRPDis = (qty, mrp, dis) => {
	let nsv;

	if (dis !== 0) {
		nsv = roundDecimalValueUptoTwo(qty * mrp - qty * mrp * (dis / 100));
		return nsv;
	} else {
		nsv = roundDecimalValueUptoTwo(qty * mrp);
		return nsv;
	}
};
export const calculateTotalPcs = (qty, st) => {
	let total_pcs;
	const convertedSt = roundDecimalValueUptoTwo(st / 100);
	if (qty !== 0 && st !== 0) {
		total_pcs = parseInt(qty / convertedSt);
		// total_pcs = parseInt((qty / convertedSt) * 100);
		console.log("total pcs", qty, st, total_pcs);
		return total_pcs;
	} else return 0;
};

// -------
export const calculateSTByTotalPcsAndTotalQty = (total_qty, total_pcs) => {
	let st;

	if (total_qty !== 0 || total_pcs !== 0) {
		st = roundDecimalValueUptoTwo((total_qty / total_pcs) * 100);
		// console.log("stByTPcs", total_qty, total_pcs, st);

		return st;
	} else return 0;
};
export const calculateDisByTotalMrpAndTotalNSV = (total_mrp, total_nsv) => {
	let dis;

	if (total_mrp !== 0 || total_nsv !== 0) {
		dis = roundDecimalValueUptoTwo(((total_mrp - total_nsv) / total_mrp) * 100);
		// console.log("dis", total_mrp, total_nsv, dis);
		return dis;
	} else return 0;
};
