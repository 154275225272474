import { TextField } from "@mui/material";
import React, { useState } from "react";

function NumberTextField({ value, onChange, disableHelperText, ...props }) {
	const [error, setError] = useState(false);

	const validateInput = (event) => {
		const { value } = event.target;
		if (value === "") event.target.value = 0;
		// Check if value is numeric or a valid decimal number
		const isNumeric = value === "" || /^[0-9]+(\.[0-9]*)?$/.test(value);
		if (isNumeric || event.target.value.length === 0) {
			onChange(event);
		}
		setError(!isNumeric);
	};

	return (
		<TextField
			{...props}
			error={error}
			inputProps={{
				inputMode: "numeric",
				// pattern: '[0-9]*',  Restrict input to numeric characters
			}}
			value={value}
			onChange={validateInput}
			helperText={error && !disableHelperText ? "Please enter a valid numeric value" : ""}
		/>
	);
}

export default NumberTextField;
