import {
	Box,
	Button,
	Dialog,
	DialogActions,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import {
	EditCompanyDetailsModalTypes,
	companyCommunicationAddressDetailColumns,
	companyRegistredAddressDetailColumns,
	factoryAddressDetailColumns,
} from "data/supplierPageData";
import { useState } from "react";
import { stateList } from "data/stateMasterList";

const EditAddressDetails = ({ addressData, setAddressData, modalOpen, setModalOpen, submitFunction }) => {
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const [updatedDetails, setUpdatedDetails] = useState(addressData);
	const [officePincodeError, setOfficePinCodeError] = useState("");

	const handlePinCodeChange = (event, col) => {
		const value = event.target.value;
		let obj = {};
		obj[col] = value;
		setUpdatedDetails({ ...updatedDetails, ...obj });

		const isValidPinCode = /^\d{6}$/.test(value);
		if (!isValidPinCode) {
			setOfficePinCodeError("pincode must be 6 digits");
		} else {
			setOfficePinCodeError("");
		}
	};

	const handleCloseModal = () => {
		setUpdatedDetails(addressData);
		setModalOpen(false);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		let obj = {};
		Object.keys(factoryAddressDetailColumns).forEach((col) => (obj[col] = updatedDetails[col]));
		if (typeof submitFunction === "function") submitFunction(obj, setModalOpen);
	};
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Box width="98%" p={2} boxSizing="border-box">
				{/* <Typography textAlign="center" variant="h6" p={2} width="100%">
					Edit Addresses
				</Typography> */}
				<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Typography textAlign="center" variant="h6" width="100%">
							Registered Address
						</Typography>
						<Stack sx={{ width: "100%" }} spacing={0}>
							<InputLabel>Factory Address:</InputLabel>
							<TextField
								size="small"
								type="text"
								multiline
								rows={2}
								value={updatedDetails.factory_address}
								onChange={(e) =>
									setUpdatedDetails({ ...updatedDetails, factory_address: e.target.value })
								}
								placeholder="Enter Address"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Factory City Name:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={updatedDetails.factory_city}
								onChange={(e) => setUpdatedDetails({ ...updatedDetails, factory_city: e.target.value })}
								placeholder="Enter city name"
								required
							/>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel>Factory State:</InputLabel>
							<Select
								value={updatedDetails.factory_state}
								onChange={(e) =>
									setUpdatedDetails({ ...updatedDetails, factory_state: e.target.value })
								}
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								size="small"
								fullWidth
								required>
								<MenuItem value={"select"}>Select State</MenuItem>
								{stateList &&
									stateList.map((row) => <MenuItem value={row.state}>{row.state}</MenuItem>)}
							</Select>
						</Stack>
						<Stack sx={formStack} spacing={0}>
							<InputLabel> Pincode:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={updatedDetails.factory_pin_code}
								onChange={(e) => handlePinCodeChange(e, "factory_pin_code")}
								placeholder="Enter 6 digit pincode"
								required
							/>
							<span className="error">{officePincodeError}</span>
						</Stack>
					</Stack>
				</Stack>
			</Box>
			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditAddressDetails;
