export const categories = [
	{
		name: "Finance",
		key: "finance",
		isGroup: true,
		items: [
			{ name: "EBO/Franchise Payment Working", key: "ebo_pw_finance" },
			{ name: "MBO/Franchise Payment Working ", key: "mbo_pw_finance" },
			{
				name: "Refund Modal ",
				key: "refund_modal",
				isGroup: true,
				items: [
					{ name: "Calculate Refund", key: "calc_refund_RM" },
					{ name: "Update Bulkmaster", key: "update_bm_RM" },
					{ name: "Vendor Form", key: "vendorForm" },
				],
			},
		],
	},
	{
		name: "Business Reporting Online",
		key: "business_rep_online",
		isGroup: true,
		items: [
			{ name: "D2C", key: "d2c_bro" },
			{ name: "Marketplace", key: "marketplace_bro" },
		],
	},
	{
		name: "Business Reporting Offline",
		key: "business_rep_offline",
		isGroup: true,
		items: [
			{ name: "EBO", key: "ebo_bus_rep_offline" },
			{ name: "MBO", key: "mbo_bus_ref_offline" },
			{ name: "LFS", key: "lfs_bus_ref_offline" },
		],
	},
	{
		name: "Operation Module",
		key: "opration_mod",
		items: [
			// {name:"EBO",key:"ebo_bus_rep_offline",},
			// {name:"MBO",key:"mbo_bus_ref_offline"},
			// {name:"LFS",key:"lfs_bus_ref_offline"},
		],
	},
	{
		name: "Logistics Module",
		key: "logistics_mod",
		items: [
			// {name:"EBO",key:"ebo_bus_rep_offline",},
			// {name:"MBO",key:"mbo_bus_ref_offline"},
			// {name:"LFS",key:"lfs_bus_ref_offline"},
		],
	},
	{
		name: "Buying,Planning and Merchandising Module",
		key: "buy_merch_module",
		items: [
			{ name: "Business Planning", key: "business_planning" },
			// {name:"MBO",key:"mbo_bus_ref_offline"},
			// {name:"LFS",key:"lfs_bus_ref_offline"},
		],
	},
];
