import { useState } from "react";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import {
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Divider,
	IconButton,
	InputLabel,
	MenuItem,
	Popover,
	Select,
	TextField,
} from "@mui/material";
import InputWithChips from "components/Pim/CommonComponents/InputWithChips";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { grey } from "@mui/material/colors";

const DATATYPE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "NUMBER",
		value: "number",
	},
	{
		label: "STRING",
		value: "string",
	},
	{
		label: "DECIMAL",
		value: "float",
	},
	{
		label: "JSON",
		value: "json",
	},
	{
		label: "DATE",
		value: "date",
	},
];

const INPUT_TYPE_OPTIONS = [
	{
		label: "-------",
		value: "-------",
	},
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "ENTER",
		value: "ENTER",
	},
	{
		label: "AUTO GENERATE",
		value: "AUTO_GENERATE",
	},
];

const GROUP_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "MAIN",
		value: "MAIN",
	},
	{
		label: "DESIGNER",
		value: "DESIGNER",
	},
	{
		label: "COLOR",
		value: "COLOR",
	},
];

function AttributeForm({ action, attributeDetails, attributeName, attributeList, setAttributeList }) {
	const textInfo = {
		fontSize: 14,
		color: grey[600],
	};

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const initialState = {
		name: "",
		key: "",

		value: [],
		input_type: "-------",
		group: "SELECT",

		type: {
			notNull: false,
			unique: false,
			datatype: "SELECT",
		},
		data: {},
		sort_no: attributeList.length + 1,
	};
	const [attributeFormData, setAttributeFormData] = useState(action === "UPDATE" ? attributeDetails : initialState);
	const [toAdd, setToAdd] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (action === "UPDATE") {
			const id = attributeFormData.id;
			const key = attributeFormData.key;
			attributeFormData["toAdd"] = toAdd;
			setLoading(true);
			console.log("TOUPDATE", attributeFormData);
			let availableValues = attributeFormData?.value.map((val) => val.value);
			console.log(availableValues);
			let duplicateValues = toAdd.filter((value) => availableValues.includes(value));
			console.log("duplicateValues", duplicateValues);
			if (duplicateValues.length) {
				setLoading(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `duplicate values ${duplicateValues} found`,
					severity: "warning",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				return;
			}
			await axios
				.put(`/api/pim/update_attributes/${key}`, attributeFormData)
				.then((res) => {
					if (res.status === 200) {
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Attribute updated successfully",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						setAttributeList([
							...attributeList.map((attr) => {
								if (attr.id === key) return attributeFormData;
								else return attr;
							}),
						]);
						setLoading(false);
					}
				})
				.catch((err) => {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: err.message,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoading(false);
					console.log(err);
				});
		} else {
			if (!validateData(attributeFormData)) {
				return;
			}
			setLoading(true);
			await axios
				.post("/api/pim/set_attributes", attributeFormData)
				.then((res) => {
					if (res.status === 200) {
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Attribute created successfully",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						setAttributeList([...attributeList, res.data]);
						setAttributeFormData(initialState);
						setLoading(false);
					}
				})
				.catch((err) => {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: err.message,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					console.log(err);
					setLoading(false);
				});
		}
	};

	const validateData = (data) => {
		if (data.type.datatype === "SELECT" || data.group === "SELECT" || data.input_type === "-------") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "please select all the dropdown values",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
		return true;
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleDeleteChips = (value) => {
		let data = toAdd;
		const index = data.indexOf(value);
		if (index > -1) {
			data.splice(index);
			setToAdd([...data]);
		}
	};

	const handleAtrValue = (e) => {
		const value = e.target.value;
		if (!attributeFormData?.value.includes(value.toUpperCase()) && !toAdd.includes(value.toUpperCase())) {
			setToAdd([...toAdd, value.toUpperCase()]);
		} else {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Attribute all ready present",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	return (
		<Box component="form" onSubmit={handleSubmit}>
			<Box direction="row" width="100%" justifyContent="space-around" flexWrap="wrap">
				<Stack maxWidth="1000px" spacing={0} margin="auto" direction={"row"} justifyContent={"center"}>
					<Box>
						<Typography variant="h4" pt={0} width={"100%"}>
							{action === "UPDATE" ? `Update ${attributeName}` : "Add Attribute"}
						</Typography>
						<Divider sx={{ width: "100%" }}></Divider>
					</Box>
				</Stack>
				<Box
					display="flex"
					flexWrap="wrap"
					pt={4}
					rowGap={3}
					className="vendorInputForm"
					columnGap={6}
					zIndex={-1}
					justifyContent="center">
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Datatype</InputLabel>
						<Select
							disabled={action === "UPDATE"}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={attributeFormData.type.datatype}
							onChange={(e) =>
								setAttributeFormData({
									...attributeFormData,
									type: { ...attributeFormData.type, datatype: e.target.value },
								})
							}
							size="small">
							{DATATYPE_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							Atribute name <span>*</span>
						</InputLabel>
						<TextField
							required
							size="small"
							value={attributeFormData.name}
							onChange={(e) => {
								action === "UPDATE"
									? setAttributeFormData({
											...attributeFormData,
											name: e.target.value,
									  })
									: setAttributeFormData({
											...attributeFormData,
											name: e.target.value,
											key: e.target.value?.split(" ").join("_").toUpperCase(),
									  });
							}}
							placeholder="Enter attribute name"
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							Atribute key <span>*</span>
						</InputLabel>
						<TextField
							size="small"
							value={attributeFormData.key}
							placeholder="Enter attribute key"
							disabled
						/>
					</Stack>

					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Input type</InputLabel>
						<Select
							disabled={action === "UPDATE"}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={attributeFormData.input_type}
							onChange={(e) =>
								setAttributeFormData({
									...attributeFormData,
									input_type: e.target.value,
								})
							}
							size="small">
							{INPUT_TYPE_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Group</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={attributeFormData.group}
							onChange={(e) =>
								setAttributeFormData({
									...attributeFormData,
									group: e.target.value,
								})
							}
							size="small">
							{GROUP_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Sort No</InputLabel>
						<NumberTextField
							size="small"
							value={attributeFormData.sort_no}
							placeholder="Enter sorting number"
							onChange={(e) =>
								setAttributeFormData({
									...attributeFormData,
									sort_no: e.target.value,
								})
							}
						/>
					</Stack>
					<Box
						className="vendor-input"
						sx={formStack}
						spacing={0}
						direction={"column"}
						display={"flex"}
						columnGap={7}>
						<Stack className="vendor-input" spacing={0}>
							<InputLabel>Required</InputLabel>
							<Checkbox
								checked={attributeFormData.type && attributeFormData.type.notNull}
								onChange={() =>
									setAttributeFormData({
										...attributeFormData,
										type: { ...attributeFormData.type, notNull: !attributeFormData.type.notNull },
									})
								}
								inputProps={{ "aria-label": "controlled" }}
								disabled={action === "UPDATE" ? true : false}
							/>
						</Stack>

						<Stack className="vendor-input" spacing={0}>
							<InputLabel>IsUnique</InputLabel>
							<Checkbox
								checked={attributeFormData.type && attributeFormData.type.isUnique}
								onChange={() =>
									setAttributeFormData({
										...attributeFormData,
										type: { ...attributeFormData.type, isUnique: !attributeFormData.type.isUnique },
									})
								}
								disabled={action === "UPDATE" ? true : false}
								inputProps={{ "aria-label": "controlled" }}
							/>
						</Stack>
						<Stack className="vendor-input" spacing={0}>
							<InputLabel>AutoGenerate</InputLabel>
							<Checkbox
								checked={attributeFormData.data && attributeFormData.data.autoGenerate}
								onChange={() =>
									setAttributeFormData({
										...attributeFormData,
										data: {
											...attributeFormData.data,
											autoGenerate: !attributeFormData.data.autoGenerate,
										},
									})
								}
								disabled={action === "UPDATE" ? true : false}
								inputProps={{ "aria-label": "controlled" }}
							/>
						</Stack>
					</Box>

					<Stack sx={{ ...formStack, width: "100%" }} spacing={0}>
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handlePopoverClose}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							sx={{ boxShadow: "none" }}
							disableRestoreFocus>
							<Typography fontWeight={{ ...textInfo, fontWeight: "bold" }} p={1}>
								Attribute field is disabled when value of input type field is either ENTER or AUTO
								GENERATE
							</Typography>
						</Popover>
						<InputLabel>
							Enter attribute options{" "}
							<IconButton size="small" aria-describedby={id} onClick={handleClick}>
								<InfoRoundedIcon sx={{ transform: "scale(0.7)" }} />
							</IconButton>
						</InputLabel>
						<InputWithChips
							attributeFormData={attributeFormData}
							setAttributeFormData={setAttributeFormData}
							action={action}
							toAdd={toAdd}
							setToAdd={setToAdd}
							onDelete={(value) => handleDeleteChips(value)}
							handleAtrValue={(e) => handleAtrValue(e)}
							disable={true}
						/>
					</Stack>

					{action === "UPDATE" ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "wrap",
								rowGap: 1,
								columnGap: 1,
								mx: 3,
								textTransform: "uppercase",
							}}>
							{attributeFormData?.value
								? attributeFormData?.value.map((val, index) => <Chip label={val} key={index} />)
								: ""}
						</Box>
					) : (
						""
					)}
				</Box>

				<Stack alignItems="center" my={4}>
					<Button
						variant="contained"
						sx={{ height: "50px" }}
						type="submit"
						disabled={loading}
						startIcon={loading ? <CircularProgress color="inherit" /> : ""}>
						{action === "UPDATE" ? (loading ? "UPDATING" : "UPDATE") : loading ? "CREATING" : "CREATE"}
					</Button>
				</Stack>
			</Box>
		</Box>
	);
}

export default AttributeForm;
