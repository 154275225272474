import { DownloadRounded } from "@mui/icons-material";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { green, grey, lightGreen } from "@mui/material/colors";
import axios from "axios";
import { agreementData, companyRegistredAddressDetailColumns } from "data/supplierPageData";
import FileSaver from "file-saver";
import { useState } from "react";

export const suppliers_atrr = {
	basic_details: "Basic Details",
	address_details: "Address Details",
	financial_details: "Financial Details",
	files: "Files",
	compliance_certificates: "Compliance Certificates",
	contact_details: "Contact Details",
	vendor_agreement: "Vendor Agreements",
};
export const factory_atrr = {
	basic_details: "Basic Details",
	address_details: "Address Details",
	files: "Files",
	compliance_certificates: "Compliance Certificates",
	contact_details: "Contact Details",
};
const SupplierStatus = ({ companyData, allFactories }) => {
	let supplierStatus = [];
	let factoriesStatus = [];
	companyData?.supplierApproval.forEach((data) => {
		const datas = {
			nameType: suppliers_atrr[data.type],
			approvedBy: data.approve_by,
			remark: data.remark,
			approved: data.approved,
		};
		supplierStatus.push(datas);
	});
	allFactories.forEach((fact) => {
		let modifyFactoryData = [];
		fact?.factoryApproval.forEach((data) => {
			const datas = {
				nameType: factory_atrr[data.type],
				approvedBy: data.approve_by,
				remark: data.remark,
				approved: data.approved,
			};
			modifyFactoryData.push(datas);
		});
		let result = { name: fact.name, modifyFactoryData };
		factoriesStatus.push(result);
	});
	return (
		<Box>
			<Typography variant="h4" mb={2}>
				Approval Status
			</Typography>
			<Box sx={{ p: 2, border: `1px solid ${grey[300]} `, borderRadius: "5px", margin: "10px 0px" }} width="95%">
				<Stack spacing={1}>
					<Typography fontWeight={700} variant="h6" width="40%">
						Company Info
					</Typography>
					{supplierStatus.map((val) => (
						<Box
							sx={{
								p: 1,
								border: "1px solid grey",
								borderRadius: "5px",
								borderColor: val.approved ? green["A700"] : val.remark !== "" ? "red" : grey[300],
							}}
							display="flex"
							width="inherit"
							alignItems="center">
							<Stack width="90%">
								<Typography variant="body1" fontSize={18} fontWeight={600} width="100%">
									{val.nameType}
								</Typography>
								{val.remark && (
									<Typography variant="body1" fontSize={14} width="100%" color="error">
										{`Remark*: ${val.remark}`}
									</Typography>
								)}
							</Stack>
							<Typography
								variant="body1"
								fontSize={16}
								fontWeight={val.approved ? 600 : 400}
								// width="10%"
								// sx={{ background: val.approved ? green["A700"] : "white", p: 1, borderRadius: 1 }}
								color={val.approved ? green["A700"] : val.remark !== "" ? "error" : "black"}>
								{val.approved ? "Approved" : val.remark !== "" ? "Reject" : "Pending"}
							</Typography>
						</Box>
					))}
				</Stack>
			</Box>
			{factoriesStatus.map((val) => (
				<Box
					sx={{ p: 2, border: `1px solid ${grey[300]} `, borderRadius: "5px", margin: "10px 0px" }}
					width="95%">
					<Stack spacing={1}>
						<Typography variant="h6" fontWeight={700} width="40%">
							Factory Name : {val.name}
						</Typography>
						{val.modifyFactoryData.map((val) => (
							<Box
								sx={{
									p: 1,
									border: "1px solid grey",
									borderRadius: "5px",
									borderRadius: "5px",
									borderColor: val.approved ? green["A700"] : val.remark !== "" ? "red" : grey[300],
								}}
								display="flex"
								width="inherit"
								alignItems="center">
								<Stack width="90%">
									<Typography variant="body1" fontSize={18} fontWeight={600} width="100%">
										{val.nameType}
									</Typography>
									{val.remark && (
										<Typography variant="body1" fontSize={14} width="100%" color="error">
											{`Remark*: ${val.remark}`}
										</Typography>
									)}
								</Stack>
								<Typography
									variant="body1"
									fontSize={16}
									fontWeight={val.approved ? 600 : 400}
									width="10%"
									color={val.approved ? green["A700"] : val.remark !== "" ? "error" : "black"}>
									{val.approved ? "Approved" : val.remark !== "" ? "Reject" : "Pending"}
								</Typography>
							</Box>
						))}
					</Stack>
				</Box>
			))}
		</Box>
	);
};

export default SupplierStatus;
