import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import MenuIcon from "@mui/icons-material/Menu";
import RareBoxLogoWhite from "../../../../Logos/RareBoxLogoWhite";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import Profile from "../../../Profile/Profile";
import { useNavigate } from "react-router-dom";
import { AuthState } from "../../../../context/AuthContext";
import { GlobalState } from "../../../../context/GlobalContext";

const Navbar = ({ drawerOpen, setDrawerOpen }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(false);
	const [open, setOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const { user, setUser } = AuthState();
	const { setLoginUser, rootPath, loginUser } = GlobalState();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setOpen(!open);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(!open);
	};
	const handleLogoutClick = () => {
		localStorage.removeItem("user_token");
		setUser();
		setLoginUser();
		navigate("/");
	};
	const handleChangePasswordClick = () => {
		navigate(`/change_password`);
	};

	return (
		<Box
			sx={{
				zIndex: (theme) => {
					return theme.zIndex.drawer + 1;
				},
				flexGrow: 1,
				position: "fixed",
			}}>
			<AppBar>
				<Toolbar>
					{/* <IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
						onClick={() => setDrawerOpen(!drawerOpen)}>
						<MenuIcon />
					</IconButton> */}
					<Box width="130px" mr="auto" onClick={() => navigate(rootPath ? rootPath : "/")}>
						<RareBoxLogoWhite />
					</Box>

					<IconButton size="large" edge="start" color="inherit" sx={{ mr: 2 }} onClick={handleClick}>
						<AccountBoxIcon sx={{ transform: "scale(1.2)" }} />
						<Typography sx={{ marginLeft: "5px" }}>{loginUser?.name.split(" ")[0]}</Typography>
					</IconButton>
					<Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
						<MenuItem onClick={() => setModalOpen(!modalOpen)}>Profile</MenuItem>
						<MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
						<MenuItem onClick={handleChangePasswordClick}>Change Password</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
			<Modal
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				open={modalOpen}
				onClose={() => setModalOpen(!modalOpen)}>
				<div>
					<Profile setModalOpen={setModalOpen} />
				</div>
			</Modal>
		</Box>
	);
};

export default Navbar;
