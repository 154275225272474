import { InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { inputTypes } from "data/pimData";

const filtercomponents = [
	{ name: "Brand", key: "BRAND", values: ["RARE RABBIT", "RAREISM"], input_type: inputTypes.select },
	{ name: "Division", key: "DIVISION", values: ["APPARELS", "ADDONS", "FOOT WEAR"], input_type: inputTypes.select },
	{ name: "EXT", key: "EXT", values: ["Y", "N"], input_type: inputTypes.select },
];

const FilterInputs = ({ updatedData, setUpdatedData }) => {
	return (
		<Stack direction="row" width="100%" flexWrap="wrap" columnGap="6%" rowGap={1}>
			{filtercomponents?.map((row) => {
				let name = row?.name;
				return (
					<Stack sx={{ width: "28%" }} spacing={0}>
						<InputLabel>{name}:</InputLabel>
						{row.input_type === inputTypes.enter ? (
							<TextField
								size="small"
								type="text"
								value={updatedData[row?.key]}
								onChange={(e) =>
									setUpdatedData({
										...updatedData,
										[row.key]: e.target.value,
									})
								}
								placeholder={`Enter ${row.name}`}
								required
							/>
						) : (
							<Select
								size="small"
								fullWidth
								value={updatedData[row.key] ? updatedData[row.key] : "ALL"}
								onChange={(e) =>
									setUpdatedData({
										...updatedData,
										[row.key]: e.target.value,
									})
								}>
								<MenuItem value={"ALL"}>All</MenuItem>
								{row?.values?.map((option) => {
									return (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									);
								})}
							</Select>
						)}
					</Stack>
				);
			})}
		</Stack>
	);
};

export default FilterInputs;
