import {
	Box,
	Button,
	CircularProgress,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { fileData, fileTypesData } from "data/supplierPageData";
import { useState } from "react";

const AddFiles = ({ fullWidth, files, setFiles, submitFunction, disableAdd }) => {
	const [updatedDetails, setUpdatedDetails] = useState(fileData);
	const [file, setFile] = useState();
	const [loading, setLoading] = useState(disableAdd);

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (typeof submitFunction === "function") {
			const created = await submitFunction({ ...updatedDetails, file }, false);
			if (created) {
				setUpdatedDetails(fileData);
				setLoading(false);
			}
		} else console.log("No Submit Function Passed");
	};
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "90%" },
	};
	return (
		<Stack
			component="form"
			onSubmit={handleFormSubmit}
			width={fullWidth ? "100%" : "30%"}
			alignItems="center"
			flexWrap="wrap"
			rowGap={1}
			sx={{ backgroundColor: grey[100], borderRadius: 2 }}>
			<Typography variant="h6" pt={2} width="100%" textAlign="center">
				Add Files
			</Typography>
			<Stack sx={formStack} spacing={0}>
				<InputLabel>File Name:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={updatedDetails?.name}
					onChange={(e) =>
						setUpdatedDetails({
							...updatedDetails,
							name: e.target.value,
						})
					}
					placeholder="Enter Issuer Name"
					required
				/>
			</Stack>
			<Stack sx={formStack} spacing={0}>
				<InputLabel>File Type</InputLabel>
				<Select
					value={updatedDetails && updatedDetails.type ? updatedDetails.type : "select"}
					onChange={(e) =>
						setUpdatedDetails({
							...updatedDetails,
							type: e.target.value,
						})
					}
					MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
					size="small"
					required>
					<MenuItem value="select">Select</MenuItem>
					{fileTypesData &&
						fileTypesData.map((row) => (
							<MenuItem Key={row.Key} value={row.Key}>
								{row.name}
							</MenuItem>
						))}
				</Select>
			</Stack>

			<Stack sx={formStack} spacing={0}>
				<InputLabel>Upload File:</InputLabel>
				<TextField
					size="small"
					type="file"
					onChange={(e) => setFile(e.target.files[0])}
					placeholder="Enter director/owner name"
					required
				/>
			</Stack>
			<Box width="100%" display="flex" justifyContent="center">
				<Button
					disabled={loading}
					startIcon={!disableAdd && loading && <CircularProgress color="inherit" size="20px" />}
					variant="contained"
					sx={{ margin: "auto", width: 180, m: 2 }}
					type="submit">
					Add File
				</Button>
			</Box>
		</Stack>
	);
};

export default AddFiles;
