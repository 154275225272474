import dayjs from "dayjs";

export const isStartDateGraterThanEndDate = (startDateText, endDateText) => {
	const startDate = dayjs(startDateText);
	const endDate = dayjs(endDateText);

	const startday = startDate.get("D");
	const startYear = startDate.get("y");
	const startMonth = startDate.get("M");

	const endDay = endDate.get("D");
	const endYear = endDate.get("y");
	const endMonth = endDate.get("M");

	// console.log(startday, startYear, startMonth);
	// console.log(endDay, endYear, endMonth);
	// console.log(startDate, endDate);
	if (startYear > endYear) return true;

	if (startYear === endYear && startMonth > endMonth) return true;
	if (startYear === endYear && startMonth === endMonth && startday > endDay) {
		return true;
	}

	// returns true if start date >end date
	return false;
};
