import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { stateList } from "../../../../data/stateMasterList";
import moment from "moment";
import { GlobalState } from "context/GlobalContext";
import { isStartDateGraterThanEndDate } from "utils/isStartDateGreaterThanEndDate";
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

const FactoryDetails = ({ currentFactory, factories, setFactories, setFactoryFiles, factoryFiles, index }) => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [factoryPincodeError, setFactoryPinCodeError] = useState("");

	const [factory, setFactory] = useState(currentFactory);

	useEffect(() => {
		if (factories.length > 0)
			setFactories([
				...factories.map((row) => {
					if (row.key === currentFactory.key) {
						return factory;
					} else return row;
				}),
			]);
	}, [factory]);

	const handlePinCodeChange = (event) => {
		const value = event.target.value;
		setFactory({ ...factory, factory_pin_code: value });

		const isValidPinCode = /^\d{6}$/.test(value);
		if (!isValidPinCode) {
			setFactoryPinCodeError("pincode must be 6 igits");
		} else {
			setFactoryPinCodeError("");
		}
	};
	const date = new Date();
	const handleFileChange = (event, type) => {
		const selectedFile = event.target.files[0];
		const updatedFileName = `${type}_${+currentFactory.key + 1}_${date.valueOf()}
			.split(" ")
			.join("_")}`;

		const newFileWithUpdatedName = new File([selectedFile], updatedFileName, {
			type: selectedFile.type,
			lastModified: selectedFile.lastModified,
		});
		setFactoryFiles([
			...factoryFiles.map((row) => {
				if (row.key === currentFactory.key && row.type === type) {
					return { ...row, name: updatedFileName, file: newFileWithUpdatedName };
				} else return row;
			}),
		]);
	};
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	// Compliance
	const [complianceCerts, setComplianceCerts] = useState([]);
	const newCert = {
		no: 0,
		name: "Compliance Cert 1",
		compliance_certificate_issue_date: moment(new Date()).format("YYYY-MM-DD"),
		compliance_certificate_exp_date: moment(new Date()).format("YYYY-MM-DD"),
		compliance_cert: null,
		compliance_certificate_issuer: "",
	};
	const [newComplianceCert, setNewComplianceCert] = useState(newCert);
	const handleAddCertificate = () => {
		if (newComplianceCert.compliance_cert === null || newComplianceCert.compliance_certificate_issuer === "") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Fill All The Fields",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		if (
			isStartDateGraterThanEndDate(
				newComplianceCert.compliance_certificate_issue_date,
				newComplianceCert.compliance_certificate_exp_date
			) === true
		) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Exp Date must be greater than Issue Date",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setComplianceCerts([...complianceCerts, newComplianceCert]);
		setNewComplianceCert({ ...newCert, no: new Date().valueOf() });
	};
	const handleDeleteCertClick = (row) => {
		setComplianceCerts(complianceCerts.filter((cert) => row.no !== cert.no));
	};
	const tableCellHead = {
		align: "center",
	};
	const tableCell = {
		component: "th",
		scope: "row",
		align: "center",
	};

	return (
		// factory form
		<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
			<Typography variant="h6" p={2} width="100%">
				Enter Factory {++index} Details
			</Typography>
			<Stack sx={formStack} spacing={0}>
				<InputLabel>Factory Liscence Number</InputLabel>
				<TextField
					size="small"
					type="text"
					value={factory.factory_liscence_no}
					onChange={(e) => setFactory({ ...factory, factory_liscence_no: e.target.value })}
					placeholder="Enter Liscence No"
					required
				/>
			</Stack>

			<Stack sx={formStack}>
				<InputLabel>Factory Liscence Expiry Date</InputLabel>
				<TextField
					size="small"
					type="date"
					value={factory.factory_liscence_expiry_date}
					onChange={(e) => setFactory({ ...factory, factory_liscence_expiry_date: e.target.value })}
					placeholder="Factory Liscence Expiry Date"
					required
				/>
			</Stack>
			<Stack sx={formStack}>
				<InputLabel>Factory GST NO:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={factory.factory_gst_no}
					onChange={(e) => setFactory({ ...factory, factory_gst_no: e.target.value })}
					placeholder="Enter Gst No"
					required
				/>
			</Stack>

			<Stack sx={{ width: { xs: "80%", sm: "50%", md: "60%" } }}>
				<InputLabel>Factory Address:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={factory.factory_address}
					onChange={(e) => setFactory({ ...factory, factory_address: e.target.value })}
					placeholder="Enter Factory Address"
					required
				/>
			</Stack>
			<Stack sx={formStack}>
				<InputLabel htmlFor="cityName">Factory City Name:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={factory.factory_city}
					onChange={(e) => setFactory({ ...factory, factory_city: e.target.value })}
					placeholder="Enter city name"
					required
				/>
			</Stack>
			<Stack sx={formStack}>
				<InputLabel htmlFor="cityName">Factory State:</InputLabel>

				<Select
					value={factory.factory_state}
					onChange={(e) => setFactory({ ...factory, factory_state: e.target.value })}
					MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
					size="small"
					fullWidth
					required>
					<MenuItem value={"select"}>Select State</MenuItem>
					{stateList && stateList.map((row) => <MenuItem value={row.state}>{row.state}</MenuItem>)}
				</Select>
			</Stack>
			<Stack sx={formStack}>
				<InputLabel>Factory Pincode:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={factory.factory_pin_code}
					onChange={(e) => handlePinCodeChange(e)}
					placeholder="Enter 6 digit pincode"
					required
				/>
				<span className="error">{factoryPincodeError}</span>
			</Stack>
			<Stack className="vendor-input">
				<InputLabel>Factory Gst Certificate</InputLabel>
				<TextField
					size="small"
					type="file"
					accept=".pdf, .jpeg, .jpg, .png"
					required
					onChange={(event) => handleFileChange(event, "gst_cert")}
				/>
			</Stack>
			<Stack className="vendor-input">
				<InputLabel>Factory Liscence Certificate</InputLabel>
				<TextField
					size="small"
					type="file"
					accept=".pdf, .jpeg, .jpg, .png"
					required
					onChange={(event) => handleFileChange(event, "factory_liscence_cert")}
				/>
			</Stack>

			<Box display="flex" width="100%" justifyContent="space-between" flexWrap="wrap" margin="auto">
				<Typography textAlign="left" variant="h6" p={2} width="100%">
					Compliance Certificates
				</Typography>
				<Box width="47%">
					<Table sx={{ width: "100%", border: `1px solid ${grey[300]}` }} size="small">
						<TableHead>
							<TableRow>
								<TableCell {...tableCellHead}>Issuer</TableCell>
								<TableCell {...tableCellHead}>Issue Date</TableCell>
								<TableCell {...tableCellHead}>Expiry Date</TableCell>
								{/* <TableCell {...tableCellHead}>File</TableCell> */}
								<TableCell {...tableCellHead}>Delete</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{complianceCerts &&
								complianceCerts.map((row) => (
									<TableRow>
										<TableCell {...tableCell}>{row.compliance_certificate_issuer}</TableCell>
										<TableCell {...tableCell}>{row.compliance_certificate_issue_date}</TableCell>
										<TableCell {...tableCell}>{row.compliance_certificate_exp_date}</TableCell>
										{/* <TableCell {...tableCell}>{URL.createObjectURL(row.compliance_cert)}</TableCell> */}
										{/* <TableCell {...tableCell}></TableCell> */}
										<TableCell {...tableCell}>
											<IconButton size="small" onClick={() => handleDeleteCertClick(row)}>
												<Delete />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Box>
				<Stack direction="row" width="45%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
					<Typography variant="h6" width="100%">
						Add Certificate
					</Typography>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Certificate Issuer:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={newComplianceCert.compliance_certificate_issuer}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_issuer: e.target.value,
								})
							}
							placeholder="Enter Issuer Name"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Cert Issue Date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={newComplianceCert.compliance_certificate_issue_date}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_issue_date: e.target.value,
								})
							}
							placeholder="Enter Issue Date"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Cert Expiry Date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={newComplianceCert.compliance_certificate_exp_date}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_exp_date: e.target.value,
								})
							}
							placeholder="Enter Expiry Date"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Certificate:</InputLabel>
						<TextField
							size="small"
							type="file"
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_cert: e.target.files[0],
								})
							}
							placeholder="Enter director/owner name"
							required
						/>
					</Stack>
					<Box width="100%" display="flex" justifyContent="center">
						<Button
							variant="contained"
							sx={{ margin: "auto", width: 250, mt: 1 }}
							onClick={handleAddCertificate}>
							Add Certificate
						</Button>
					</Box>
				</Stack>
			</Box>
		</Stack>
	);
};

export default FactoryDetails;
