import {
	Box,
	Button,
	Dialog,
	DialogActions,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import { EditCompanyDetailsModalTypes, contactPersonData, labourTableColumns } from "data/supplierPageData";
import { useState } from "react";
import LabourDataTable from "../../CompanyDetails/LabourDataTable/LabourDataTable";
import { stateList } from "data/stateMasterList";
import ContactPersonDetails from "../ContactPersonDetails/ContactPersonDetails";

const EditContactPersons = ({ modalOpen, setModalOpen, selected, setSelected, submitFunction }) => {
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const [updatedDetails, setUpdatedDetails] = useState(selected ? selected : contactPersonData);

	const handleCloseModal = () => {
		setUpdatedDetails(contactPersonData);
		setModalOpen(false);
		setSelected();
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		if (typeof submitFunction === "function") {
			let result = await submitFunction(updatedDetails);
			if (result) {
				setSelected();
				setModalOpen(false);
			}
		}
	};
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Box width="95%" margin="auto" pt={2}>
				<Typography variant="h6">{selected ? "Edit Person Details" : "Add Contact"} </Typography>
				<ContactPersonDetails person={updatedDetails} setPerson={setUpdatedDetails} />
			</Box>

			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditContactPersons;
