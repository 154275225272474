export const brandList = [
	{
		name: "Rare Rabbit",
		key: "RARE RABBIT",
		subBrands: [
			{
				name: "Rare Rabbit Mainline",
				key: ".",
				division: [
					{
						name: "Apparels",
						key: "APPARELS",
					},
					{
						name: "Addons",
						key: "ADDONS",
					},
					{
						name: "Footwear",
						key: "FOOTWEAR",
					},
				],
			},
			{
				name: "Guild",
				key: "GUILD",
				division: [
					{
						name: "Apparels",
						key: "APPARELS",
					},
				],
			},
			{
				name: "Articale",
				key: "ARTICALE",
				division: [
					{
						name: "Apparels",
						key: "APPARELS",
					},
					{
						name: "Addons",
						key: "ADDONS",
					},
				],
			},
		],
	},
	{
		name: "Rareism",
		key: "RAREISM",
		subBrands: [
			{
				name: "Rareism Mainline",
				key: ".",
				division: [
					{
						name: "Apparels",
						key: "APPARELS",
					},
					{
						name: "Addons",
						key: "ADDONS",
					},
				],
			},
			{
				name: "Articale",
				key: "ARTICALE",
				division: [
					{
						name: "Apparels",
						key: "APPARELS",
					},
					{
						name: "Addons",
						key: "ADDONS",
					},
				],
			},
		],
	},
	// {
	// 	name: "Rareone",
	// 	key: "RAREONE",
	// 	subBrands: [{ name: "Rareone Mainline", key: "RAREONE MAINLINE" }],
	// },
];

export const channelData = [
	{
		name: "Exclusive Brand Outlets(EBO)",
		key: "EBO",
	},
	{
		name: "Multi brand outlet(MBO)",
		key: "MBO",
	},
	{
		name: "Large Format Store(LFS)",
		key: "LFS",
	},
	{
		name: "Direct To Consumer(D2C)",
		key: "D2C",
	},
	{
		name: "Market Place",
		key: "MARKETPLACE",
		items: [
			{ name: "Myntra", key: "MYNTRA" },
			{ name: "AJIO", key: "AJIO" },
			{ name: "NYKAA", key: "NYKAA" },
			{ name: "FLIPKART", key: "FLIPKART" },
			{ name: "TATA CLIQ", key: "TATA_CLIQ" },
		],
	},
];
export const regions = [
	{
		name: "North",
		key: "NORTH",
	},
	{
		name: "South",
		key: "SOUTH",
	},
	{
		name: "East",
		key: "EAST",
	},
	{
		name: "West",
		key: "WEST",
	},
];
export const divisionData = [
	{
		name: "APPARELS",
		key: "APPARELS",
	},
	{
		name: "ADDONS",
		key: "ADDONS",
	},
	{
		name: "FOOTWEAR",
		key: "FOOT WEAR",
	},
];

export const itemsCategoryData = [
	{
		name: "Shirts",
		key: "shirts",
	},
	{
		name: "T-Shirts",
		key: "tshirts",
	},
];
export const itemSubCategoryData = [
	{
		name: "Polo",
		key: "polo",
	},
	{
		name: "Stripe",
		key: "stripe",
	},
];
export const marketPlaceData = [
	{
		store_name: "MYNTRA",
		id: "myntra",
	},
	{
		store_name: "AJIO",
		id: "ajio",
	},
	{
		store_name: "NYKAA",
		id: "nyka",
	},
	{
		store_name: "FLIPKART",
		id: "flipkart",
	},
	{
		store_name: "TATA CLIQ",
		id: "tata_cilq",
	},
];
