import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GlobalState } from "../../../context/GlobalContext";
import AddNewCosting from "./AddNewCosting/AddNewCosting";

import axios from "axios";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ClearRounded from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CostingInfoModal from "./CostingInfoModal/CostingInfoModal";

const supplierTablecolumns = [
	{
		field: "month",
		headerName: "Month",
		width: 130,
		renderCell: (params) => {
			const month = new Date(params.row.month);
			return <Typography>{month.toLocaleString("en-us", { month: "long", year: "numeric" })}</Typography>;
		},
	},
	{ field: "salary_liability", headerName: "Salary Liability", width: 120 },
	{ field: "electricity", headerName: "Electricity", width: 140 },
	{ field: "electricity_warehouse", headerName: "Electricity Warehouse", width: 130 },
	{ field: "diesel", headerName: "Diesel", width: 130 },

	{ field: "leave_encashment", headerName: "Leave Encashment", width: 150 },
	{
		field: "is_verified",
		headerName: "Status",
		width: 145,
		renderCell: (params) => {
			return (
				<Box
					sx={{
						backgroundColor: params.row.is_verified ? "#4caf50" : "#ef5350",
						color: "white",
						padding: "5px",
						borderRadius: "5px",
					}}>
					<></>
					{params.row.is_verified ? (
						<Box display="flex" alignItems="center" columnGap={1}>
							<CheckCircleOutlineRoundedIcon /> Verified
						</Box>
					) : (
						<Box display="flex" alignItems="center" columnGap={1}>
							<ErrorOutlineRoundedIcon />
							Not Verified
						</Box>
					)}
				</Box>
			);
		},
	},
];

const OwnProductionCostingDashboard = () => {
	const [allMonthlyCostings, setAllMonthlyCostings] = useState([]);
	const [selectedCosting, setSelectedCosting] = useState();

	// def value is true becoz for textfield disabled =true;
	const [modalEditable, setModalEditable] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);

	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleEditModalClose = () => {
		setModalEditable(true);
		setEditModalOpen(false);
	};
	const handleNewCostingModalClose = () => {
		setModalOpen(false);
	};
	// fetch costings on load
	useEffect(() => {
		const fetch = async () => {
			try {
				await axios.get("/api/production_costing").then((res) => {
					setAllMonthlyCostings(res.data);
				});
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Network Error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		};
		fetch();
	}, []);

	const handleGridRowClick = (data) => {
		setEditModalOpen(true);
		setSelectedCosting(data);
	};
	return (
		<Stack maxWidth="1100px" spacing={3} margin="auto">
			<Box display="flex" alignItems="center" pt={4} justifyContent="space-between">
				<Typography variant="h3">Production Costing</Typography>
				<Button
					variant="contained"
					sx={{ height: "50px" }}
					startIcon={<AddRoundedIcon />}
					onClick={() => setModalOpen(true)}>
					New Costing
				</Button>
			</Box>
			{/* table */}
			<Stack width="100%">
				<DataGrid
					key={allMonthlyCostings}
					rows={allMonthlyCostings}
					columns={supplierTablecolumns}
					sx={{
						minHeight: 430,
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-cell": {
							padding: "10px",
						},
					}}
					showColumnVerticalBorder
					onRowClick={(params) => {
						handleGridRowClick(params.row);
					}}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
					}}
					autoHeight
					disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Stack>
			{/* new Costing Modal */}
			<Dialog open={modalOpen} onClose={handleNewCostingModalClose} scroll="body" maxWidth="md" fullWidth>
				<Box display="flex" justifyContent="space-between" p="5px 10px" alignItems="center">
					<Box display="flex" alignItems="center">
						<DialogTitle>Add New Costing</DialogTitle>
					</Box>
					<IconButton sx={{ width: "50px", height: "50px" }} onClick={handleNewCostingModalClose}>
						<ClearRounded sx={{ transform: "scale(1.3)" }} />
					</IconButton>
				</Box>
				<Box p={2}>
					<AddNewCosting
						allMonthlyCostings={allMonthlyCostings}
						setAllMonthlyCostings={setAllMonthlyCostings}
						setModalOpen={setModalOpen}
					/>
				</Box>
			</Dialog>
			{/* edit Costing Modal */}
			<Dialog open={editModalOpen} onClose={handleEditModalClose} scroll="body" maxWidth="md" fullWidth>
				<Box display="flex" justifyContent="space-between" p="5px 10px" alignItems="center">
					<Box display="flex" alignItems="center">
						<DialogTitle>Edit Costing</DialogTitle>
					</Box>
					{modalEditable ? (
						<Button variant="contained" onClick={() => setModalEditable(false)} sx={{ height: "40px" }}>
							Edit
						</Button>
					) : (
						<Button
							variant="contained"
							onClick={() => {
								setModalEditable(true);
								setSelectedCosting(
									allMonthlyCostings.filter((row) => row.id === selectedCosting.id)[0]
								);
							}}
							sx={{ height: "40px" }}>
							Cancel Edit
						</Button>
					)}
					<IconButton
						sx={{ width: "50px", height: "50px", marginLeft: "auto" }}
						onClick={handleEditModalClose}>
						<ClearRounded sx={{ transform: "scale(1.3)" }} />
					</IconButton>
				</Box>
				<Box p={2}>
					<CostingInfoModal
						allMonthlyCostings={allMonthlyCostings}
						setAllMonthlyCostings={setAllMonthlyCostings}
						selectedCosting={selectedCosting}
						setSelectedCosting={setSelectedCosting}
						setEditModalOpen={setEditModalOpen}
						modalEditable={modalEditable}
						setModalEditable={setModalEditable}
					/>
				</Box>
			</Dialog>
		</Stack>
	);
};

export default OwnProductionCostingDashboard;
