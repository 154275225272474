import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { webDataDrawerElements } from "data/radhamaniPageData";
import { useNavigate, useOutletContext } from "react-router-dom";
import ContactUs from "./ContactUs/ContactUs";
import JoinUs from "./JoinUs/JoinUs";
import { KeyboardBackspaceRounded } from "@mui/icons-material";

const DashboardRWebData = () => {
	const [tab, setTab] = useOutletContext();
	const navigate = useNavigate();
	const tabComponents = [
		{
			...webDataDrawerElements.contact_us,
			component: <ContactUs />,
		},
		{
			...webDataDrawerElements.join_us,
			component: <JoinUs />,
		},
	];
	return (
		<Box sx={{ width: "100%" }}>
			<Button
				startIcon={<KeyboardBackspaceRounded />}
				variant="outlined"
				onClick={() => navigate('/explore')}
				sx={{ m: 2 }}>
				Back
			</Button>
			{tabComponents &&
				tabComponents.map((row) => (
					<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
						{row.component}
					</Box>
				))}
		</Box>
	);
};

export default DashboardRWebData;
