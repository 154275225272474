import Box from "@mui/material/Box";
import { grey, yellow } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
	attributepriorityTypes,
	barcodeTableColumns,
	designerAttributeObjName,
	designerTableColumns,
	groups,
	inputTypes,
	orderData,
	orderTableAttributeColumns,
	pimDrawerElements,
	pimParamName,
	priority,
	productColumns,
	productDetailsColumns,
	productDummyData,
	productImagesObjName,
	statusOptions,
	styleTableColumns,
} from "data/pimData";
import { useEffect, useState } from "react";
import DisplayInfoWithEditTab from "components/Pim/CommonComponents/DisplayInfoWithEditTab";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import BarcodeModal, { BarcodeModalWithCost } from "../../BarcodeModal/BarcodeModal";
import { DataGrid } from "@mui/x-data-grid";
import { AddRoad, DeleteRounded, EditRounded } from "@mui/icons-material";
import { PimState } from "context/PimContext";
import CreateColorModal from "../../CreateColorModal/CreateColorModal";
import { rootProtectedPath, services } from "data/authData";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import EditImageDataModal from "../../EditImageDataModal/EditImageDataModal";
import AddRounded from "@mui/icons-material/AddRounded";
import AddNewCosting from "components/OwnProductionCosting/OwnProductionCostingDashbord/AddNewCosting/AddNewCosting";
import AddNewImageModal from "../../AddNewImageModal/AddNewImageModal";
import ImageSlider from "components/Pim/CommonComponents/ImageSlider/ImageSlider";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const MerchandiserTab = ({ foreignKey }) => {
	const { setSnackBar, setSnackBarOpen, snackBar } = GlobalState();
	const { attributes, attributeValues, selectedProduct, setSelectedProduct } = PimState();

	useEffect(() => {
		const fetch = async () => {
			if (!selectedProduct) {
				// await axios.get("/").then((res) => setProductData(res.data));
			} else setProductData(selectedProduct);
		};
		fetch();
	}, [selectedProduct]);

	const navigate = useNavigate();

	const [productData, setProductData] = useState(selectedProduct);
	const [barcodes, setBarcodes] = useState([
		{ barcode: "8907279846372", size: "S", id: 0, icode: "RR123" },
		{ barcode: "8907279846372", size: "M", id: 1, icode: "RR123" },
		{ barcode: "8907279846372", size: "L", id: 2, icode: "RR123" },
	]);

	const [orders, setOrders] = useState([orderData]);
	const [selectedBarcode, setSelectedBarcode] = useState();

	const [activeGroup, setActiveGroup] = useState("");

	// modal var
	const [barcodeModal, setBarcodeModal] = useState(false);
	const [barcodeModal2, setBarcodeModal2] = useState(false);
	const [highPriorityDataEditModal, setHighPriorityDataEditModal] = useState(false);
	const [designerDataEditModal, setDesignerDataEditModal] = useState(false);
	const [mdmDataEditModal, setMdmDataEditModal] = useState(false);
	const [CreateColorModal, setCreateColorModal] = useState(false);
	const [imgInfoModal, setImgInfoModal] = useState(false);
	const [addNewImgModal, setAddNewImgModal] = useState(false);
	const [images, setImages] = useState([
		{ data_url: "/images/pimImages/ProductImages/1.jpg" },
		{ data_url: "/images/pimImages/ProductImages/2.jpg" },
		{ data_url: "/images/pimImages/ProductImages/3.jpg" },
	]);

	const handleHighpriorityDataEditClick = () => {
		setHighPriorityDataEditModal(true);
	};

	const handleBarcodeDeleteClick = (e, row) => {
		e.stopPropagation();
	};

	const imgWidth = "23vw";
	const imgHeight = "65vh";
	const orderTableColumns = [
		{
			field: "url",
			headerName: "Image",
			attribute_name: "",
			width: 120,
			renderCell: (params) => {
				let imgWidth = 43;
				let imgHeight = 50;
				return (
					<img
						style={{
							width: `${imgWidth}px`,
							height: `${imgHeight}px`,
							objectFit: "cover",
							objectPosition: "50% 0%",
							borderRadius: 2,
						}}
						src={params.row.url}
					/>
				);
			},
			...colStyle,
		},
		{
			field: "color",
			headerName: "Color",
			attribute_name: "",
			width: 120,
			...colStyle,
		},
		{
			field: "pallete",
			headerName: "Pallete",
			width: 120,
			...colStyle,
		},
		{
			field: "status",
			headerName: "Status",
			width: 120,
			...colStyle,
			renderCell: (params) => {
				return (
					<Box
						display="flex"
						alignItems="center"
						height="30px"
						columnGap={1}
						sx={{
							backgroundColor: statusOptions["drafted"]?.color || yellow[400],
							color: "white",
							padding: "5px",
							borderRadius: "5px",
						}}>
						{statusOptions["drafted"]?.icon} <Typography> {statusOptions["drafted"]?.name}</Typography>
					</Box>
				);
			},
		},

		// {
		// 	// field: "pallette",
		// 	headerName: "Delete",
		// 	width: 60,
		// 	...colStyle,
		// 	renderCell: (params) => {
		// 		return (
		// 			<IconButton onClick={(e) => handleBarcodeDeleteClick(e, params.row)}>
		// 				<DeleteRounded />
		// 			</IconButton>
		// 		);
		// 	},
		// },
	];
	const tablecolumns = [
		{
			field: "barcode",
			headerName: "Barcode",
			width: 180,
			...colStyle,
		},
		{
			field: "icode",
			headerName: "Icode",
			width: 150,
			...colStyle,
		},
		{
			field: "size",
			headerName: "Size",
			width: 160,
			...colStyle,
		},
		{
			// field: "pallette",
			headerName: "Delete",
			width: 60,
			...colStyle,
			renderCell: (params) => {
				return (
					<IconButton onClick={(e) => handleBarcodeDeleteClick(e, params.row)}>
						<DeleteRounded />
					</IconButton>
				);
			},
		},
	];

	const addBarcode = async (data) => {
		try {
			if (data) {
				data[foreignKey] = productData.id;
				if (selectedBarcode) {
					const res = await axios.put("/api/supplier/update_contact", data);
					if (res) {
						setSelectedBarcode(
							barcodes.map((row) => {
								if (data.id === row.id) return data;
								else return row;
							})
						);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Updated",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						return true;
					}
				} else {
					const res = await axios.post("/api/supplier/create_contact", data);
					if (res) {
						setBarcodes([...barcodes, res.data.result]);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Created",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						return true;
					}
				}
			}
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Add File",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};
	const handleBarcodeClick = (barcode) => {
		setSelectedBarcode(barcode);
		setBarcodeModal(true);
	};
	const handleSaveEditedData = (data) => {
		console.log("save fun ran");
	};

	const cerateOrder = (orderData) => {};
	const handleOrderClick = () => {
		navigate(`/${rootProtectedPath}/${services.pim.path}?${pimParamName}=${pimDrawerElements.order.key}`);
	};

	const handleArrowDownClick = (group) => {
		if (group === activeGroup) setActiveGroup("");
		else setActiveGroup(group);
	};

	const handleFileUpload = async (files, data) => {
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append("files", files[i].file);
		}
		formData.append("style_id", data.STYLE_ID);

		return await axios
			.post("/api/pim/image_upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				return "Error in file Upload";
			});
	};
	const updateMainImage = async (data) => {
		try {
			const res = await axios.put("/api/pim/change_main_image", data);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Main Image Changed Sucessfully",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSelectedProduct({ ...selectedProduct, IMAGE_URL: data.url });
			setImgInfoModal(false);
			return true;
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Error",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};
	const addNewImages = async (images, mainImgIndex) => {
		const imageUrls = await handleFileUpload(images, selectedProduct);

		const productImages = imageUrls.map((row, index) => {
			return { style_id: selectedProduct.STYLE_ID, is_main: false, url: row.publicUrl };
		});

		try {
			const res = await axios.post("/api/pim/bulk_create_pim_images", productImages);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Images created Successfully",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return true;
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable to Add Product",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};

	if (selectedProduct && productData)
		return (
			<Stack width="100%">
				<Box pt={3} pb={0} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
					<Box width="85%">
						<Typography variant="h6">{groups.main.name}</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Button variant="contained" onClick={handleHighpriorityDataEditClick}>
						Edit
					</Button>
					<Box width="100%" display="flex" boxSizing="border-box" justifyContent="space-between">
						<Box>
							{/* <img
								style={{
									width: `${imgWidth}px`,
									height: `${imgHeight}px`,
									objectFit: "cover",
									objectPosition: "50% 0%",
									borderRadius: 2,
									zIndex: 2,
								}}
								src={productData?.IMAGE_URL}
							/> */}
							<Box width={imgWidth} height={imgHeight}>
								<ImageSlider
									imgWidth={imgWidth}
									imgHeight={imgHeight}
									column="url"
									images={productData[productImagesObjName]}
								/>
							</Box>

							<Box p={1} display="flex" columnGap={2}>
								<IconButton
									startIcon={<EditRounded sx={{ color: "#fff" }} />}
									sx={{
										background: "#000",
										"&: hover": { background: "#00000083" },
										// width: 100,
									}}
									variant="contained"
									onClick={() => setImgInfoModal(true)}>
									<EditRounded sx={{ color: "white" }} />
								</IconButton>
								<IconButton
									sx={{
										background: "#000",
										"&: hover": { background: "#00000083" },
									}}
									variant="contained"
									onClick={() => setAddNewImgModal(true)}>
									<AddRounded sx={{ color: "#fff" }} />
								</IconButton>
							</Box>
						</Box>

						<Box
							width="68vw"
							p={2}
							boxSizing="border-box"
							// sx={{ boxShadow: `1px 1px 10px ${grey[300]}`, background: grey[100] }}
						>
							<DisplayInfoWithEditTab
								columnData={[...styleTableColumns, ...designerTableColumns.filter((row, i) => i < 16)]}
								// columnData={attributes[attributepriorityTypes.high.index]}
								data={{ ...productData, ...productData[designerAttributeObjName] }}
								setData={setProductData}
								modalOpen={highPriorityDataEditModal}
								setModalOpen={setHighPriorityDataEditModal}
								submitFunction={handleSaveEditedData}
								width={"32%"}
							/>
						</Box>
					</Box>
				</Box>

				<Box pt={5} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
					<Box width="85%">
						<Typography variant="h6">Colors</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Button
						variant="contained"
						onClick={() => {
							// setCreateColorModal(true);
						}}>
						Create
					</Button>
					<Box width="50%">
						<DataGrid
							rows={orders}
							columns={orderTableColumns}
							sx={{
								minHeight: 320,
								maxHeight: 350,
								border: "none",
								"& .MuiDataGrid-row": {
									// borderBottom: "1px solid lightgray",
									cursor: "pointer",
									border: "none",
								},
								"& .MuiDataGrid-columnHeader": {
									cursor: "pointer",
								},
								"& .MuiDataGrid-columnHeaders": {
									background: grey[300],
									// mb: 2,
									border: "none",
									borderRadius: 1,
									// padding: "10px 0px",
								},
							}}
							columnHeaderHeight={35}
							rowHeight={60}
							// showCellVerticalBorder
							// onRowClick={(params, e) => handleOrderClick(params.row)}
							initialState={{
								pagination: {
									paginationModel: { pageSize: 30 },
								},
							}}
							disableRowSelectionOnClick
							pageSizeOptions={[50, 100]}
						/>
					</Box>
				</Box>

				{/* designer Fields */}
				<Box pt={5} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
					<Box width="85%">
						<Typography pb={1} variant="h6">
							{groups.designer.name}
						</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Box>
						<Button variant="contained" onClick={() => setDesignerDataEditModal(true)}>
							Edit
						</Button>
						{/* <IconButton
							sx={{ ml: 2 }}
							size="large"
							onClick={() => handleArrowDownClick(groups.designer.key)}>
							{activeGroup === groups.designer.key ? (
								<KeyboardArrowUpRoundedIcon sx={{ transform: "scale(1.6)" }} />
							) : (
								<KeyboardArrowDownRoundedIcon sx={{ transform: "scale(1.6)" }} />
							)}
						</IconButton> */}
					</Box>
					{/* <Collapse in={activeGroup === groups.designer.key} width="100%" p={2}> */}
					<DisplayInfoWithEditTab
						columnData={designerTableColumns.filter((row, i) => i > 15)}
						// columnData={attributes[attributepriorityTypes.high.index]}
						data={productData[designerAttributeObjName]}
						setData={setProductData}
						modalOpen={designerDataEditModal}
						setModalOpen={setDesignerDataEditModal}
						submitFunction={handleSaveEditedData}
						width={"20%"}
					/>
					{/* </Collapse> */}
				</Box>

				{/* Orders */}

				<BarcodeModal
					modalOpen={barcodeModal}
					setModalOpen={setBarcodeModal}
					selected={selectedBarcode}
					setSelected={setSelectedBarcode}
					submitFunction={addBarcode}
					key={barcodeModal}
				/>
				<BarcodeModalWithCost
					modalOpen={barcodeModal2}
					setModalOpen={setBarcodeModal2}
					selected={selectedBarcode}
					setSelected={setSelectedBarcode}
					submitFunction={addBarcode}
					key={barcodeModal2}
				/>
				<CreateColorModal
					modalOpen={CreateColorModal}
					setModalOpen={setCreateColorModal}
					submitFunction={cerateOrder}
				/>
				<EditImageDataModal
					modalOpen={imgInfoModal}
					setModalOpen={setImgInfoModal}
					submitFunction={updateMainImage}
				/>
				<AddNewImageModal
					modalOpen={addNewImgModal}
					setModalOpen={setAddNewImgModal}
					submitFunction={addNewImages}
				/>
			</Stack>
		);
	else return <div></div>;
	return <></>;
};

export default MerchandiserTab;
