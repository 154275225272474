import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GlobalState } from "context/GlobalContext";
import AddNewUser from "./AddNewUser/AddNewUser";

import axios, { all } from "axios";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ClearRounded from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import UserInfoModal from "./UserInfoModal/UserInfoModal";
import { Chip } from "@mui/material";
import { grey } from "@mui/material/colors";

const userTablecolumns = [
	{ field: "name", headerName: "Name", width: 140, headerAlign: "center", align: "center" },
	{ field: "type", headerName: "Type", width: 100, headerAlign: "center", align: "center" },
	{ field: "employee_id", headerName: "Employee Id", width: 120, headerAlign: "center", align: "center" },
	{ field: "department", headerName: "Department", width: 120, headerAlign: "center", align: "center" },
	{ field: "email", headerName: "Email", width: 200, headerAlign: "center", align: "center" },
	{ field: "designation", headerName: "Designation", width: 120, headerAlign: "center", align: "center" },
	{ field: "mobile_no", headerName: "Mobile No", width: 110, headerAlign: "center", align: "center" },
	{
		headerName: "Roles",
		field: "role",
		headerAlign: "center",
		width: 275,
		renderCell: (params) => {
			const role = params.row.role;
			return (
				<Box display="flex" columnGap={1} p={1}>
					{Object.keys(role).map((row) => {
						return <Chip key={row} label={row} size="small" />;
					})}
				</Box>
			);
		},
	},
];

const Users = () => {
	const [allUsers, setAllUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState();

	// def value is true becoz for textfield disabled =true;
	const [modalEditable, setModalEditable] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [newUserModalOpen, setNewUserModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);

	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleNewUserModalClose = () => {
		setNewUserModalOpen(false);
	};
	const handleEditModalClose = () => {
		setModalEditable(true);
		setEditModalOpen(false);
	};
	const handleNewCostingModalClose = () => {
		setModalOpen(false);
	};
	// fetch users on load
	useEffect(() => {
		const fetch = async () => {
			try {
				await axios.get("/api/auth/users").then((res) => {
					setAllUsers(res.data);
					// console.log(res.data);
				});
			} catch (error) {
				console.log(error);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Network Error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		};
		fetch();
	}, []);

	const handleGridRowClick = (data) => {
		setEditModalOpen(true);
		setSelectedUser(data);
	};
	return (
		<Stack maxWidth="1200px" spacing={3} margin="auto">
			<Box display="flex" alignItems="center" pt={4} justifyContent="space-between">
				<Typography variant="h3">Users</Typography>
				<Button
					variant="contained"
					sx={{ height: "50px" }}
					startIcon={<AddRoundedIcon />}
					onClick={() => setNewUserModalOpen(true)}>
					New User
				</Button>
			</Box>
			{/* table */}
			<Stack width="100%">
				<DataGrid
					rows={allUsers}
					columns={userTablecolumns}
					columnHeaderHeight={40}
					sx={{
						// mb: 10,
						// background: grey[200],
						borderRadius: "8px",
						minHeight: 400,
						maxHeight: 480,
						"&.MuiDataGrid-root": {
							boxSizing: "none",
							boxShadow: `2px 2px 5px ${grey[200]}`,
						},
						// "& .MuiDataGrid-row": {
						// },
						"& .MuiDataGrid-row:first-child  ": {},
						"& .MuiDataGrid-cell": {
							padding: 0,
							borderBottom: "0px",
						},
						"& .MuiDataGrid-columnHeader": {
							padding: 0,
						},
						".MuiDataGrid-columnSeparator": {
							display: "none",
						},
						// "& .MuiDataGrid-cell:first-child ": {
						// 	padding: 0,
						// 	// background: grey[900],
						// },
					}}
					// rowHeight={36}
					// showCellVerticalBorder
					showColumnVerticalBorder
					onRowClick={(params) => {
						handleGridRowClick(params.row);
					}}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
					}}
					autoHeight
					disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Stack>
			{/* new User Modal */}
			<Dialog open={newUserModalOpen} onClose={handleNewUserModalClose} scroll="body" maxWidth="md" fullWidth>
				<Box display="flex" justifyContent="space-between" p="5px 10px" alignItems="center">
					<Box display="flex" alignItems="center">
						<DialogTitle>Add New User</DialogTitle>
					</Box>
					<IconButton sx={{ width: "50px", height: "50px" }} onClick={handleNewUserModalClose}>
						<ClearRounded sx={{ transform: "scale(1.3)" }} />
					</IconButton>
				</Box>
				<Box p={2}>
					<AddNewUser
						allUsers={allUsers}
						setAllUsers={setAllUsers}
						setNewUserModalOpen={setNewUserModalOpen}
					/>
				</Box>
			</Dialog>
			{/* edit Costing Modal */}
			<Dialog open={editModalOpen} onClose={handleEditModalClose} scroll="body" maxWidth="md" fullWidth>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box display="flex" alignItems="center">
						<DialogTitle>{selectedUser ? selectedUser.name : "Edit User"}</DialogTitle>
					</Box>

					<IconButton
						sx={{ width: "50px", height: "50px", marginLeft: "auto" }}
						onClick={handleEditModalClose}>
						<ClearRounded sx={{ transform: "scale(1.3)" }} />
					</IconButton>
				</Box>
				<Box pl={2} pr={2}>
					<UserInfoModal
						allUsers={allUsers}
						setAllUsers={setAllUsers}
						selectedUser={selectedUser}
						setSelectedUser={setSelectedUser}
						setEditModalOpen={setEditModalOpen}
						modalEditable={modalEditable}
						setModalEditable={setModalEditable}
					/>
				</Box>
			</Dialog>
		</Stack>
	);
};

export default Users;
