import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import { useNavigate, useOutletContext } from "react-router-dom";
import { foreignKeys, pimDrawerElements } from "data/pimData";
import AttributeMaster from "./AttributeMaster/AttributeMaster";
import StylesDashboard from "./StylesDashboard/StylesDashboard";
import ProductPage from "./ProductPage/ProductPage";
import PimProvider from "context/PimContext";
import CreateProduct from "./CreateProduct/CreateProduct";
import ColorBuyingSheet from "./BuyingPage/colorBuyingSheet/ColorBuyingSheet";
import BuyingPage from "./BuyingPage/BuyingSheet";
import ApproxCogsDashboard from "./ApproxCogsDashboard/ApproxCogsDashboard";
import TrimsDashboard from "./TrimsDashboard/TrimsDashboard";
import FabricDashboard from "./FabricDashboard/FabricDashboard";

const PimDashboard = () => {
	const [currentPath, service] = useOutletContext();
	console.log(currentPath, "currentPath");
	const navigate = useNavigate();
	{
		console.log("here");
	}

	const tabComponents = {
		[pimDrawerElements.attribute_master.key]: {
			...pimDrawerElements.attribute_master,
			component: <AttributeMaster />,
		},
		[pimDrawerElements.designer.types.styles.key]: {
			...pimDrawerElements.designer.types.styles,
			component: <StylesDashboard />,
		},
		[pimDrawerElements.buying.types.buying_sheet.key]: {
			...pimDrawerElements.buying.types.buying_sheet,
			component: <BuyingPage />,
		},
		[pimDrawerElements.buying.types.style_sheet.key]: {
			...pimDrawerElements.buying.types.style_sheet,
			component: <ColorBuyingSheet />,
		},
		[pimDrawerElements.sourcing.types.approx_cogs.key]: {
			...pimDrawerElements.sourcing.types.approx_cogs,
			component: <ApproxCogsDashboard />,
		},
		[pimDrawerElements.designer.types.trims_sheet.key]: {
			...pimDrawerElements.designer.types.trims_sheet,
			component: <TrimsDashboard />,
		},
		[pimDrawerElements.designer.types.fabric_sheet.key]: {
			...pimDrawerElements.designer.types.fabric_sheet,
			component: <FabricDashboard />,
		},
		// {
		// 	...pimDrawerElements.product_page,
		// 	component: <ProductPageOld foreignKey={foreignKeys.product} />,
		// },
		[pimDrawerElements.designer.types.product_page.key]: {
			...pimDrawerElements.designer.types.product_page,
			component: <ProductPage foreignKey={foreignKeys.product} />,
		},
		[pimDrawerElements.designer.types.create_product.key]: {
			...pimDrawerElements.designer.types.create_product,
			component: <CreateProduct foreignKey={foreignKeys.product} />,
		},
	};
	return (
		<Box sx={{ width: "100%", p: 1 }}>
			<Box display="flex" alignItems="center" columnGap={1}>
				<IconButton
					onClick={() => navigate(-1)}
					// sx={{ backgroundColor: grey[200], color: "#fff", "&:hover": { background: grey[500] } }}
				>
					<ArrowBackRoundedIcon />
				</IconButton>
				<Typography variant="h5">{service?.name}</Typography>
			</Box>
			<PimProvider>
				{/* {tabComponents &&
					tabComponents
						.filter((row) => row.key === currentPath)
						.map((row) => {
							console.log(row, "rowwww");
							return (
								<Box
									width="98%"
									margin="auto"
									key={row.key}
									// display={row.key === currentPath ? "block" : "none"}
								>
									{row.component}
								</Box>
							);
						})} */}

				{tabComponents && tabComponents[currentPath]?.component}
			</PimProvider>
		</Box>
	);
};

export default PimDashboard;
