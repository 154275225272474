export const AllMrpData = [
	{
		MRP: "Total",
		QTY_RECEIVED: 14028,
		OPTION_RECEIVED: 443,
		QTY_RECEIVED_CONTRIBUTION: 1,
		OPTION_RECEIVED_CONTRIBUTION: 1,
		QTY_SOLD: 10486,
		QTY_SOLD_CONTRIBUTION: 1,
		ST_PERCENT: 0.75,
		REALISED_VALUE: 25526962.3,
		REALISED_VALUE_CONTRIBUTION: 1,
		DISCOUNT_PERCENT: 0.1,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 1499,
		QTY_RECEIVED: 3000,
		OPTION_RECEIVED: 43,
		QTY_RECEIVED_CONTRIBUTION: 0.21385799828913601,
		OPTION_RECEIVED_CONTRIBUTION: 0.09706546275395034,
		QTY_SOLD: 2440,
		QTY_SOLD_CONTRIBUTION: 0.2326912073240511,
		ST_PERCENT: 0.8133333333333334,
		REALISED_VALUE: 3108925.9999999995,
		REALISED_VALUE_CONTRIBUTION: 0.12178989272060776,
		DISCOUNT_PERCENT: 0.15,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 1799,
		QTY_RECEIVED: 984,
		OPTION_RECEIVED: 20,
		QTY_RECEIVED_CONTRIBUTION: 0.07014542343883662,
		OPTION_RECEIVED_CONTRIBUTION: 0.045146726862302484,
		QTY_SOLD: 921,
		QTY_SOLD_CONTRIBUTION: 0.08783139423993896,
		ST_PERCENT: 0.9359756097560976,
		REALISED_VALUE: 1574035.05,
		REALISED_VALUE_CONTRIBUTION: 0.06166166704449592,
		DISCOUNT_PERCENT: 0.05,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 1999,
		QTY_RECEIVED: 696,
		OPTION_RECEIVED: 14,
		QTY_RECEIVED_CONTRIBUTION: 0.04961505560307956,
		OPTION_RECEIVED_CONTRIBUTION: 0.03160270880361174,
		QTY_SOLD: 638,
		QTY_SOLD_CONTRIBUTION: 0.06084302880030517,
		ST_PERCENT: 0.9166666666666666,
		REALISED_VALUE: 1160579.4200000002,
		REALISED_VALUE_CONTRIBUTION: 0.04546484639889956,
		DISCOUNT_PERCENT: 0.09,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 2199,
		QTY_RECEIVED: 720,
		OPTION_RECEIVED: 10,
		QTY_RECEIVED_CONTRIBUTION: 0.05132591958939264,
		OPTION_RECEIVED_CONTRIBUTION: 0.022573363431151242,
		QTY_SOLD: 564,
		QTY_SOLD_CONTRIBUTION: 0.053786000381460995,
		ST_PERCENT: 0.7833333333333333,
		REALISED_VALUE: 1066602.96,
		REALISED_VALUE_CONTRIBUTION: 0.04178338759876649,
		DISCOUNT_PERCENT: 0.14,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 2299,
		QTY_RECEIVED: 222,
		OPTION_RECEIVED: 43,
		QTY_RECEIVED_CONTRIBUTION: 0.015825491873396064,
		OPTION_RECEIVED_CONTRIBUTION: 0.09706546275395034,
		QTY_SOLD: 159,
		QTY_SOLD_CONTRIBUTION: 0.015163074575624642,
		ST_PERCENT: 0.7162162162162162,
		REALISED_VALUE: 314365.25999999995,
		REALISED_VALUE_CONTRIBUTION: 0.01231502817708944,
		DISCOUNT_PERCENT: 0.14,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 2499,
		QTY_RECEIVED: 636,
		OPTION_RECEIVED: 20,
		QTY_RECEIVED_CONTRIBUTION: 0.045337895637296836,
		OPTION_RECEIVED_CONTRIBUTION: 0.045146726862302484,
		QTY_SOLD: 453,
		QTY_SOLD_CONTRIBUTION: 0.04320045775319473,
		ST_PERCENT: 0.7122641509433962,
		REALISED_VALUE: 1086765.1199999999,
		REALISED_VALUE_CONTRIBUTION: 0.042573225408806266,
		DISCOUNT_PERCENT: 0.04,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 2699,
		QTY_RECEIVED: 216,
		OPTION_RECEIVED: 14,
		QTY_RECEIVED_CONTRIBUTION: 0.015397775876817793,
		OPTION_RECEIVED_CONTRIBUTION: 0.03160270880361174,
		QTY_SOLD: 164,
		QTY_SOLD_CONTRIBUTION: 0.01563990082014114,
		ST_PERCENT: 0.7592592592592593,
		REALISED_VALUE: 380666.95999999996,
		REALISED_VALUE_CONTRIBUTION: 0.014912348579760306,
		DISCOUNT_PERCENT: 0.14,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 2799,
		QTY_RECEIVED: 1746,
		OPTION_RECEIVED: 10,
		QTY_RECEIVED_CONTRIBUTION: 0.12446535500427716,
		OPTION_RECEIVED_CONTRIBUTION: 0.022573363431151242,
		QTY_SOLD: 1098,
		QTY_SOLD_CONTRIBUTION: 0.10471104329582301,
		ST_PERCENT: 0.6288659793814433,
		REALISED_VALUE: 2950369.92,
		REALISED_VALUE_CONTRIBUTION: 0.115578574737034,
		DISCOUNT_PERCENT: 0.04,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 2999,
		QTY_RECEIVED: 1428,
		OPTION_RECEIVED: 43,
		QTY_RECEIVED_CONTRIBUTION: 0.10179640718562874,
		OPTION_RECEIVED_CONTRIBUTION: 0.09706546275395034,
		QTY_SOLD: 1007,
		QTY_SOLD_CONTRIBUTION: 0.09603280564562273,
		ST_PERCENT: 0.7051820728291317,
		REALISED_VALUE: 2929393.21,
		REALISED_VALUE_CONTRIBUTION: 0.11475682752898489,
		DISCOUNT_PERCENT: 0.03,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 3199,
		QTY_RECEIVED: 132,
		OPTION_RECEIVED: 20,
		QTY_RECEIVED_CONTRIBUTION: 0.009409751924721984,
		OPTION_RECEIVED_CONTRIBUTION: 0.045146726862302484,
		QTY_SOLD: 102,
		QTY_SOLD_CONTRIBUTION: 0.009727255388136563,
		ST_PERCENT: 0.7727272727272727,
		REALISED_VALUE: 287142.24,
		REALISED_VALUE_CONTRIBUTION: 0.011248586362349896,
		DISCOUNT_PERCENT: 0.12,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 3299,
		QTY_RECEIVED: 630,
		OPTION_RECEIVED: 14,
		QTY_RECEIVED_CONTRIBUTION: 0.04491017964071856,
		OPTION_RECEIVED_CONTRIBUTION: 0.03160270880361174,
		QTY_SOLD: 557,
		QTY_SOLD_CONTRIBUTION: 0.0531184436391379,
		ST_PERCENT: 0.8841269841269841,
		REALISED_VALUE: 1598662.4100000001,
		REALISED_VALUE_CONTRIBUTION: 0.06262642578510016,
		DISCOUNT_PERCENT: 0.13,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 3499,
		QTY_RECEIVED: 1788,
		OPTION_RECEIVED: 10,
		QTY_RECEIVED_CONTRIBUTION: 0.12745936698032506,
		OPTION_RECEIVED_CONTRIBUTION: 0.022573363431151242,
		QTY_SOLD: 987,
		QTY_SOLD_CONTRIBUTION: 0.09412550066755675,
		ST_PERCENT: 0.552013422818792,
		REALISED_VALUE: 3211767.09,
		REALISED_VALUE_CONTRIBUTION: 0.1258186168904241,
		DISCOUNT_PERCENT: 0.07,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 3699,
		QTY_RECEIVED: 120,
		OPTION_RECEIVED: 43,
		QTY_RECEIVED_CONTRIBUTION: 0.00855431993156544,
		OPTION_RECEIVED_CONTRIBUTION: 0.09706546275395034,
		QTY_SOLD: 68,
		QTY_SOLD_CONTRIBUTION: 0.006484836925424375,
		ST_PERCENT: 0.5666666666666667,
		REALISED_VALUE: 221348.16,
		REALISED_VALUE_CONTRIBUTION: 0.008671151600361002,
		DISCOUNT_PERCENT: 0.12,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 3799,
		QTY_RECEIVED: 300,
		OPTION_RECEIVED: 20,
		QTY_RECEIVED_CONTRIBUTION: 0.0213857998289136,
		OPTION_RECEIVED_CONTRIBUTION: 0.045146726862302484,
		QTY_SOLD: 264,
		QTY_SOLD_CONTRIBUTION: 0.025176425710471104,
		ST_PERCENT: 0.88,
		REALISED_VALUE: 922701.12,
		REALISED_VALUE_CONTRIBUTION: 0.03614613870448658,
		DISCOUNT_PERCENT: 0.08,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 3999,
		QTY_RECEIVED: 516,
		OPTION_RECEIVED: 14,
		QTY_RECEIVED_CONTRIBUTION: 0.036783575705731396,
		OPTION_RECEIVED_CONTRIBUTION: 0.03160270880361174,
		QTY_SOLD: 494,
		QTY_SOLD_CONTRIBUTION: 0.047110432958230024,
		ST_PERCENT: 0.9573643410852714,
		REALISED_VALUE: 1679180.1,
		REALISED_VALUE_CONTRIBUTION: 0.06578064715518461,
		DISCOUNT_PERCENT: 0.15,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 4599,
		QTY_RECEIVED: 108,
		OPTION_RECEIVED: 10,
		QTY_RECEIVED_CONTRIBUTION: 0.007698887938408896,
		OPTION_RECEIVED_CONTRIBUTION: 0.022573363431151242,
		QTY_SOLD: 62,
		QTY_SOLD_CONTRIBUTION: 0.005912645432004577,
		ST_PERCENT: 0.5740740740740741,
		REALISED_VALUE: 273732.48,
		REALISED_VALUE_CONTRIBUTION: 0.010723268863056218,
		DISCOUNT_PERCENT: 0.04,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 4999,
		QTY_RECEIVED: 354,
		OPTION_RECEIVED: 43,
		QTY_RECEIVED_CONTRIBUTION: 0.02523524379811805,
		OPTION_RECEIVED_CONTRIBUTION: 0.09706546275395034,
		QTY_SOLD: 318,
		QTY_SOLD_CONTRIBUTION: 0.030326149151249284,
		ST_PERCENT: 0.8983050847457628,
		REALISED_VALUE: 1589682,
		REALISED_VALUE_CONTRIBUTION: 0.062274624818950734,
		DISCOUNT_PERCENT: 0,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 5299,
		QTY_RECEIVED: 24,
		OPTION_RECEIVED: 20,
		QTY_RECEIVED_CONTRIBUTION: 0.001710863986313088,
		OPTION_RECEIVED_CONTRIBUTION: 0.045146726862302484,
		QTY_SOLD: 12,
		QTY_SOLD_CONTRIBUTION: 0.0011443829868395957,
		ST_PERCENT: 0.5,
		REALISED_VALUE: 54685.68000000001,
		REALISED_VALUE_CONTRIBUTION: 0.0021422713504771385,
		DISCOUNT_PERCENT: 0.14,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 5499,
		QTY_RECEIVED: 60,
		OPTION_RECEIVED: 14,
		QTY_RECEIVED_CONTRIBUTION: 0.00427715996578272,
		OPTION_RECEIVED_CONTRIBUTION: 0.03160270880361174,
		QTY_SOLD: 4,
		QTY_SOLD_CONTRIBUTION: 0.00038146099561319857,
		ST_PERCENT: 0.06666666666666667,
		REALISED_VALUE: 18696.6,
		REALISED_VALUE_CONTRIBUTION: 0.000732425573410276,
		DISCOUNT_PERCENT: 0.15,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 5999,
		QTY_RECEIVED: 126,
		OPTION_RECEIVED: 10,
		QTY_RECEIVED_CONTRIBUTION: 0.008982035928143712,
		OPTION_RECEIVED_CONTRIBUTION: 0.022573363431151242,
		QTY_SOLD: 54,
		QTY_SOLD_CONTRIBUTION: 0.005149723440778181,
		ST_PERCENT: 0.42857142857142855,
		REALISED_VALUE: 307748.7,
		REALISED_VALUE_CONTRIBUTION: 0.012055829298576586,
		DISCOUNT_PERCENT: 0.05,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 6999,
		QTY_RECEIVED: 150,
		OPTION_RECEIVED: 5,
		QTY_RECEIVED_CONTRIBUTION: 0.0106928999144568,
		OPTION_RECEIVED_CONTRIBUTION: 0.011286681715575621,
		QTY_SOLD: 102,
		QTY_SOLD_CONTRIBUTION: 0.009727255388136563,
		ST_PERCENT: 0.68,
		REALISED_VALUE: 642508.2000000001,
		REALISED_VALUE_CONTRIBUTION: 0.025169786849256245,
		DISCOUNT_PERCENT: 0.1,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 8999,
		QTY_RECEIVED: 60,
		OPTION_RECEIVED: 2,
		QTY_RECEIVED_CONTRIBUTION: 0.00427715996578272,
		OPTION_RECEIVED_CONTRIBUTION: 0.004514672686230248,
		QTY_SOLD: 18,
		QTY_SOLD_CONTRIBUTION: 0.0017165744802593935,
		ST_PERCENT: 0.3,
		REALISED_VALUE: 147403.62,
		REALISED_VALUE_CONTRIBUTION: 0.005774428553921591,
		DISCOUNT_PERCENT: 0.09,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
	{
		MRP: 24999,
		QTY_RECEIVED: 12,
		OPTION_RECEIVED: 1,
		QTY_RECEIVED_CONTRIBUTION: 0.000855431993156544,
		OPTION_RECEIVED_CONTRIBUTION: 0.002257336343115124,
		QTY_SOLD: 0,
		QTY_SOLD_CONTRIBUTION: 0,
		ST_PERCENT: 0,
		REALISED_VALUE: 0,
		REALISED_VALUE_CONTRIBUTION: 0,
		DISCOUNT_PERCENT: 0.04,
		PLANNED_DISC_PERCENT: 0,
		PLANNED_CONTRIBUTION_QTY: 0,
	},
];
