import { ExpandLess } from "@mui/icons-material";
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import { AuthState } from "context/AuthContext";

import { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import { supplierUrlParamName } from "data/supplierPageData";

const Dashboard = ({ drawerList, searchParamName, navSpace }) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [drawerElements, setDrawerElements] = useState();

	const [tab, setTab] = useState("");

	const { user } = AuthState();

	const drawerWidth = 240;

	const setUrlParams = (value) => {
		let obj = { ...searchParams };
		setDrawerElements(
			Object.keys(drawerList)?.reduce((acc, row) => {
				if (drawerList[row].display) acc = [...acc, drawerList[row]];
				return acc;
			}, [])
		);

		obj[searchParamName] = value;
		setSearchParams((searchParams) => {
			searchParams.set(searchParamName, value);
			return searchParams;
		});
		setTab(value);
	};
	useEffect(() => {
		const param = searchParams.get(searchParamName);
		if (param) {
			setUrlParams(param);
		} else {
			let firstEle = drawerList ? drawerList[Object.keys(drawerList)[0]].key : "";
			setUrlParams(firstEle);
		}
	}, []);

	// cons;
	return (
		<Box width="100%">
			<Navbar />
			<Box display="flex">
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						"& .MuiDrawer-paper": {
							width: drawerWidth,
							boxSizing: "border-box",
						},
					}}
					variant="permanent"
					open={drawerOpen}
					onClose={() => setDrawerOpen(!drawerOpen)}>
					<List>
						<Box pt={10} />
						{drawerElements?.map((listItemData) => {
							// const listItemData = drawerList[listItemKey];
							return (
								<ListItem key={listItemData.key} disablePadding>
									<ListItemButton
										onClick={() => setUrlParams(listItemData?.key)}
										selected={tab === listItemData.key}>
										{/* <ListItemIcon>{index % 2 === 0 ? <Inbox /> : <MailIcon />}</ListItemIcon> */}
										<ListItemText
											primaryTypographyProps={{ sx: { fontSize: "18px" } }}
											primary={listItemData?.name}
										/>
									</ListItemButton>
								</ListItem>
							);
						})}
					</List>
				</Drawer>
				<Box width={`calc(100% - ${drawerWidth}px)`}>
					<Box pt={navSpace ? navSpace : 0} />
					{user && <Outlet context={[tab, setTab]} />}
				</Box>
			</Box>
		</Box>
	);
};

export default Dashboard;
