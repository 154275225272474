export const DataHubTabParamName = "tab";
export const DataHubDrawerElements = {
	RARE_RABBIT: {
		name: "Rare Rabbit",
		key: "Rare_Rabbit",
		display: true,
	},
	RAREISM: {
		name: "Rareism",
		key: "Rareism",
		display: true,
	},
	RAREONES: {
		name: "Rare Ones",
		key: "Rare_ones",
		display: true,
	},
	FINANCE: {
		name: "Finance",
		key: "Finance",
		display: true,
	},
	STORE_ADMIN: {
		name: "Store Admin",
		key: "STORE_ADMIN",
		display: true,
	},
	AGREEMENT: {
		name: "Agreement",
		key: "AGREEMENT",
		display: true,
	},
	HR: {
		name: "HR",
		key: "HR",
		display: true,
	},
	BOARD: {
		name: "RTPL Board Data",
		key: "BOARD_DATA",
		display: true,
	},
};
