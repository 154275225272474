const RareBoxLogo = () => {
    return ( <div>
        

<svg  viewBox="0 0 352 69" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.021 4.46729C23.6968 4.46729 28.6929 5.854 32.0054 8.62354C35.3179 11.397 36.9741 15.1978 36.9741 20.0298C36.9741 23.4985 36.1304 26.4048 34.4429 28.7485C32.7632 31.0845 30.4038 32.8345 27.3647 33.9985C24.3218 35.1665 20.7632 35.7485 16.6929 35.7485L37.8022 62.9517H30.5522L9.84912 35.7485H6.19287V62.9517H0.333496V4.46729H17.021ZM16.6929 30.0454C21.2007 30.0454 24.6929 29.2329 27.1616 27.6079C29.6304 25.9751 30.8647 23.4478 30.8647 20.0298C30.8647 17.0415 29.8062 14.6509 27.6929 12.8579C25.5757 11.0688 22.3999 10.1704 18.1616 10.1704H6.19287V30.0454H16.6929Z" fill="#010101"/>
<path d="M91.5406 62.9517L82.5875 43.7329H56.9156L47.9625 62.9517H41.8531L69.1343 4.46729H70.525L97.65 62.9517H91.5406ZM59.525 38.186H79.9781L69.7906 16.2798L59.525 38.186Z" fill="#010101"/>
<path d="M121.823 4.46729C128.498 4.46729 133.494 5.854 136.807 8.62354C140.119 11.397 141.776 15.1978 141.776 20.0298C141.776 23.4985 140.932 26.4048 139.244 28.7485C137.565 31.0845 135.205 32.8345 132.166 33.9985C129.123 35.1665 125.565 35.7485 121.494 35.7485L142.604 62.9517H135.354L114.651 35.7485H110.994V62.9517H105.135V4.46729H121.823ZM121.494 30.0454C126.002 30.0454 129.494 29.2329 131.963 27.6079C134.432 25.9751 135.666 23.4478 135.666 20.0298C135.666 17.0415 134.608 14.6509 132.494 12.8579C130.377 11.0688 127.201 10.1704 122.963 10.1704H110.994V30.0454H121.494Z" fill="#010101"/>
<path d="M153.342 4.46729H186.733V10.0923H159.202V28.5767H186.733V34.1235H159.202V57.2485H186.733V62.9517H153.342V4.46729Z" fill="#010101"/>
<path d="M209.283 4.46729C213.24 4.46729 216.564 5.08447 219.251 6.31104C221.947 7.52979 223.947 9.19775 225.251 11.311C226.552 13.4282 227.205 15.897 227.205 18.7173C227.205 21.3345 226.58 23.6978 225.33 25.811C224.08 27.9282 222.205 29.6665 219.705 31.0298C227.9 33.7915 232.001 38.8384 232.001 46.1704C232.001 49.1626 231.255 51.9321 229.767 54.4829C228.275 57.0376 226.087 59.0884 223.205 60.6392C220.33 62.1821 216.908 62.9517 212.939 62.9517H196.908V4.46729H209.283ZM206.673 28.9829C211.236 28.9829 214.775 28.1548 217.298 26.4985C219.83 24.8423 221.095 22.2485 221.095 18.7173C221.095 16.1157 220.158 14.0415 218.283 12.4985C216.408 10.9478 213.787 10.1704 210.423 10.1704H202.767V28.9829H206.673ZM211.814 57.2485C214.958 57.2485 217.587 56.7485 219.705 55.7485C221.818 54.7407 223.376 53.397 224.376 51.7173C225.384 50.0298 225.892 48.2095 225.892 46.2485C225.892 43.811 225.224 41.7251 223.892 39.9829C222.568 38.2446 220.63 36.9282 218.08 36.0298C215.525 35.1353 212.458 34.686 208.876 34.686H202.767V57.2485H211.814Z" fill="#010101"/>
<path d="M325.534 32.811L308.19 4.46729H315.034L329.034 27.2798L343.127 4.46729H349.893L332.456 32.811L351.034 62.9517H344.19L329.034 38.436L313.721 62.9517H306.877L325.534 32.811Z" fill="#010101"/>
<path d="M260.63 2.29459C253.686 4.03437 246.952 5.66542 245.585 5.99163C241.166 7.07899 243.27 10.6673 251.476 16.6478L259.157 22.0846L268.31 20.0186L277.463 17.9526L277.779 13.0595C278.305 6.31784 277.147 1.31596 275.043 0.228595C273.886 -0.423824 268.836 0.337331 260.63 2.29459Z" fill="black"/>
<path d="M281.461 1.85963C280.514 2.18584 280.093 4.79551 280.093 9.79739V17.0827L288.721 15.0167C293.455 13.9294 297.558 12.842 297.769 12.6245C298.084 12.1896 286.511 3.38194 283.776 1.96836C283.25 1.64215 282.092 1.64215 281.461 1.85963Z" fill="black"/>
<path d="M236.221 14.7993C235.484 16.8653 236.747 48.8338 237.694 50.3561C238.325 51.5522 247.058 50.6823 252.844 48.9425L256.632 47.8552L256.211 36.6553C255.895 30.5661 255.58 25.0205 255.369 24.3681C254.843 22.4108 241.271 13.0595 238.956 13.0595C237.799 13.0595 236.642 13.8207 236.221 14.7993Z" fill="black"/>
<path d="M287.984 18.2788L280.619 20.1274V24.803C280.725 27.304 280.83 33.2845 280.935 37.9601L281.145 46.5503L289.667 52.5308C294.717 56.1191 298.821 58.2939 299.768 57.9677C302.187 56.989 300.714 19.6924 298.189 17.6264C297.242 16.974 296.296 16.3216 295.98 16.4303C295.664 16.4303 292.087 17.3002 287.984 18.2788Z" fill="black"/>
<path d="M269.257 22.8458C259.157 25.6729 259.051 25.7817 259.051 37.0903C259.051 47.9639 258.525 47.529 267.784 45.2455C277.147 42.962 277.989 41.7659 277.989 30.2399C277.989 25.0205 277.673 20.671 277.253 20.7798C276.727 20.7798 273.255 21.7584 269.257 22.8458Z" fill="black"/>
<path d="M266.942 48.8338L259.577 50.5736L259.262 56.0104C258.841 63.0783 259.788 68.1889 261.682 68.95C262.523 69.2763 269.467 67.9714 277.147 66.0142C289.352 62.8608 291.14 62.2084 291.456 60.1424C291.772 58.2939 290.193 56.5541 284.197 52.3134C279.988 49.2687 276.095 46.8765 275.464 46.8765C274.833 46.9853 271.045 47.8552 266.942 48.8338Z" fill="black"/>
<path d="M245.374 54.1619C241.587 55.1405 238.536 56.4454 238.536 56.989C238.536 57.424 241.902 60.3599 246.111 63.4045C255.579 70.1462 256.947 69.6025 256.947 59.1638C256.947 51.1173 256.947 51.1173 245.374 54.1619Z" fill="black"/>
</svg>
</div>
 );
}
 
export default RareBoxLogo;