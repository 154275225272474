import { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Typography from "@mui/material/Typography";
import QueryRunnner from "./QueryRunnner/QueryRunnner";
import History from "./History/History";

//for tab section
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const tabStyle = { textTransform: "capitalize", fontSize: "16px", width: "170px" };

const UcVsGynesis = () => {
	const [tab, setTab] = useState(0);
	const [history, setHistory] = useState();

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<Stack maxWidth="1200px" margin="auto" spacing={2}>
			<Typography variant="h4" width="100%" pt={4}>
				UC vs Ginesys
			</Typography>
			<Box height="450px">
				<Tabs value={tab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tab sx={tabStyle} label="Run Query" {...a11yProps(0)} />
					<Tab sx={tabStyle} label="History" {...a11yProps(1)} />
				</Tabs>
				<Box display={tab === 0 ? "block" : "none "}>
					<QueryRunnner history={history} setHistory={setHistory} />
				</Box>
				<Box display={tab === 1 ? "block" : "none "}>
					<History />
				</Box>
			</Box>
		</Stack>
	);
};

export default UcVsGynesis;
