import axios from "axios";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Chip, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, List, ListItem } from "@mui/material";
import { GlobalState } from "../../../context/GlobalContext";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ListItemIcon from "@mui/material/ListItemIcon";
import moment from "moment";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import { StoreContext } from "../EboStore";

function EboForm({ data, activeStore, setActiveStore, setOpen, action, open }) {
	const initialState = {
		store_type: "Select",
		rr_code: "Select",
		rr_number: "",
		brand: "Select",
		store_name: "",
		ginesys_name: "",
		store_type_as_per_acc: "Select",
		name_of_franchise_company: "",
		unit_no: "",
		floor: 0,
		address: "",
		state: "",
		city: "",
		tier: "Select",
		pincode: 0,
		billing_name_as_per_agreement: "",
		billing_name_as_per_acc: "",
		mall_hs: "Select",
		zone: "Select",
		carpet_area: 0,
		chargable_area: 0,
		facade_in_ft: 0,
		deposit_paid_to_party: 0,
		security_deposit_as_per_agreement: 0,
		cam_deposit_as_per_agreement: 0,
		security_deposit_as_per_account: 0,
		cam_deposit_as_per_account: 0,
		store_opening_date: new Date().toJSON().slice(0, 10),
		rental_start_date: new Date().toJSON().slice(0, 10),
		financial_year: "",
		lease_term: "",
		start_date: new Date().toJSON().slice(0, 10),
		end_date: new Date().toJSON().slice(0, 10),
		lock_in: "",
		notice: "",
		rent_renewal_date: new Date().toJSON().slice(0, 10),
		revenue_share: "",
		rent_y2: "",
		rent_y1: "",
		rent_y3: "",
		rent_y4: "",
		rent_y5: "",
		rent_y6: "",
		rent_y7: "",
		rent_y8: "",
		rent_y9: "",
		rent_y10: "",
		rent_y11: "",
		rent_y12: "",
		rent_y13: "",
		rent_y14: "",
		rent_y15: "",
		cam_y1: "",
		cam_y2: "",
		cam_y3: "",
		cam_y4: "",
		cam_y5: "",
		cam_y6: "",
		cam_y7: "",
		cam_y8: "",
		cam_y9: "",
		cam_y10: "",
		cam_y11: "",
		cam_y12: "",
		cam_y13: "",
		cam_y14: "",
		cam_y15: "",
		billing_unit: "",
		billing_city: "",
		billing_state: "",
		billing_pincode: 0,
		billing_address: "",
		stock_delivery_unit: "",
		stock_delivery_city: "",
		stock_delivery_state: "",
		stock_delivery_pincode: 0,
		stock_delivery_address: "",
		marketing_charges_per_month: "",
		gift_vouchers_per_month: "",
		// capex: "",
		deposit_against_goods: 0,
		// base_stock_qty_ss: 0,
		// base_stock_qty_aw: 0,
		electricity_deposit_as_per_acc: 0,
		electricity_deposit_as_per_agreement: 0,
		board_resolution_number: "",
		board_resolution_date: new Date().toJSON().slice(0, 10),
		authorized_signatory: "",
		stamp_duty_and_registration: 0,
		gstin: "",
		agreement_registered: "Select",
		gst_registered: "Select",
		is_active: "Select",
		insurance_amount: 0,
		insurance_end_date: new Date().toJSON().slice(0, 10),
		agreement_expiry_date: new Date().toJSON().slice(0, 10),
		capex_insurance: 0,
		sellable_insurance: 0,
		fitout_period: 0,
		fitout_start_date: new Date().toJSON().slice(0, 10),
		fitout_end_date: new Date().toJSON().slice(0, 10),
		actual_capex: 0,
		projected_capex: 0,
		capex_sale_value: 0,
		site_code: "",
		mapping_code: "",
		rent_schedule: "Select",
		shop_est_date: new Date().toJSON().slice(0, 10),
		shop_est_liscense_no: "",
		eboStoreVmData: {},
		storeEmployeeData: {},
	};
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [files, setFiles] = useState([]);
	const [formData, setformData] = useState(initialState);
	const [loading, setLoading] = useState(false);
	// const { activeStore, setActiveStore, pending, setPending } = StoreContext()

	useEffect(() => {
		if (action === "UPDATE" && data) {
			const updateObj = {
				...data,
				store_opening_date: moment(data.store_opening_date).format("YYYY-MM-DD"),
				rental_start_date: moment(data.rental_start_date).format("YYYY-MM-DD"),
				start_date: moment(data.start_date).format("YYYY-MM-DD"),
				end_date: moment(data.end_date).format("YYYY-MM-DD"),
				board_resolution_date: moment(data.board_resolution_date).format("YYYY-MM-DD"),
				rent_renewal_date: moment(data.board_resolution_date).format("YYYY-MM-DD"),
				agreement_expiry_date: moment(data.board_resolution_date).format("YYYY-MM-DD"),
				insurance_end_date: moment(data.board_resolution_date).format("YYYY-MM-DD"),
				fitout_start_date: moment(data.fitout_start_date).format("YYYY-MM-DD"),
				fitout_end_date: moment(data.fitout_end_date).format("YYYY-MM-DD"),
				shop_est_date: moment(data.shop_est_date).format("YYYY-MM-DD"),
			};
			setformData(updateObj);
		} else {
			setformData(initialState);
		}
	}, [action, data]);

	const handleCloseModal = () => {
		setOpen(false);
	};

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	const options = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "RR",
			value: "RR",
		},
		{
			label: "RSM",
			value: "RSM",
		},
		{
			label: "THOR",
			value: "THOR",
		},
	];

	const brandOptions = {
		Select: "Select",
		RR: "RARE RABBIT",
		RSM: "RAREISM",
		THOR: "THOR",
	};

	const tierOptions = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "TIER1",
			value: "TIER1",
		},
		{
			label: "TIER2",
			value: "TIER2",
		},
		{
			label: "TIER3",
			value: "TIER3",
		},
	];

	const storeTypeOptions = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "COCO",
			value: "COCO",
		},
		{
			label: "COFO",
			value: "COFO",
		},
		{
			label: "FOFO",
			value: "FOFO",
		},
		{
			label: "FOCO",
			value: "FOCO",
		},
	];

	const MALL_HS_OPTIONS = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "Mall",
			value: "MALL",
		},
		{
			label: "High Street",
			value: "HIGH STREET",
		},
		{
			label: "Hotel",
			value: "HOTEL",
		},
		{
			label: "Airport",
			value: "AIRPORT",
		},
	];

	const AGREEMENT_REGISTERED_OPTIONS = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "Yes",
			value: "YES",
		},
		{
			label: "No",
			value: "NO",
		},
	];

	const GST_REGISTERED_OPTIONS = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "N/A",
			value: "N/A",
		},
		{
			label: "Registered",
			value: "REGISTERED",
		},
		{
			label: "To be registered",
			value: "TO BE REGISTERED",
		},
	];

	const IS_ACTIVE_OPTIONS = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "Pending",
			value: "Pending",
		},
		{
			label: "Active",
			value: "Active",
		},
		{
			label: "Closed",
			value: "Closed",
		},
		{
			label: "Inactive",
			value: "Inactive",
		},
	];

	const ZONE_OPTIONS = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "NORTH",
			value: "NORTH",
		},
		{
			label: "SOUTH",
			value: "SOUTH",
		},
		{
			label: "EAST",
			value: "EAST",
		},
		{
			label: "WEST",
			value: "WEST",
		},
		{
			label: "CENTRAL",
			value: "CENTRAL",
		},
	];

	const RENT_SCHEDULE_OPTIONS = [
		{
			label: "Select",
			value: "Select",
		},
		{
			label: "ADVANCE",
			value: "ADVANCE",
		},
		{
			label: "MONTH END",
			value: "MONTH_END",
		},
	];

	const handleBlur = async (pincode, field) => {
		await axios
			.get(`/api/store/get-state/${formData[pincode]}`)
			.then((res) => {
				const data = res.data;
				if (data[0].state && data[0].state.length > 0) {
					let obj = formData;
					obj[field] = data[0].state;
					setformData({ ...obj });
				} else {
					// Handle case where postal code is not found
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Postal code not found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			})
			.catch((error) => {
				// Handle error
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Error fetching pincode data",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	const handleFileUpload = async () => {
		let form = new FormData();
		files.forEach((file, i) => {
			form.append(`files`, file);
		});
		form.append("rr_code", formData.rr_code);
		form.append("rr_number", formData.rr_number);
		return await axios
			.post("/api/store/ebo-file-upload", form, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				return "Error in file Upload";
			});
	};

	const handleCopyDetails = () => {
		let updatedObj = formData;
		updatedObj["stock_delivery_unit"] = updatedObj["billing_unit"];
		updatedObj["stock_delivery_city"] = updatedObj["billing_city"];
		updatedObj["stock_delivery_state"] = updatedObj["billing_state"];
		updatedObj["stock_delivery_pincode"] = updatedObj["billing_pincode"];
		updatedObj["stock_delivery_address"] = updatedObj["billing_address"];
		setformData({ ...updatedObj });
	};

	// helper function for form validation of supplier details form
	function validateFormData(data) {
		// if (
		//   data.rr_code === "Select" ||
		//   data.store_type === "Select" ||
		//   data.store_type_as_per_acc === "Select" ||
		//   data.tier === "Select" ||
		//   data.mall_hs === "Select" ||
		//   data.agreement_registered === "Select" ||
		//   data.gst_registered === "Select"
		// ) {
		//   setSnackBarOpen(true);
		//   setSnackBar({
		//     ...snackBar,
		//     message: "Select all the dropdowns",
		//     severity: "warning",
		//     anchorOrigin: { horizontal: "center", vertical: "top" },
		//   });
		//   return false;
		// }

		// if (
		//   data.store_opening_date === "" ||
		//   data.rental_start_date === "" ||
		//   data.start_date === "" ||
		//   data.end_date === "" ||
		//   data.board_resolution_date === ""
		// ) {
		//   setSnackBarOpen(true);
		//   setSnackBar({
		//     ...snackBar,
		//     message: "Enter all date fields",
		//     severity: "warning",
		//     anchorOrigin: { horizontal: "center", vertical: "top" },
		//   });
		//   return false;
		// }
		if (data.rr_code === "Select") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Select RR / RSM / THOR field",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
		if (!Number(data.rr_number)) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Enter valid number for RR code field",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
		return true;
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (action === "UPDATE") {
			handleUpdate();
			return;
		}
		if (!validateFormData(formData)) {
			return;
		}
		const links = await handleFileUpload();
		setformData({ ...formData, ebo_files: JSON.stringify(links) });
		setformData({
			...formData,
			eboStoreVmData: {
				footware_allocated_area: 0,
				maximum_stock_holding_ss: 0,
				minimum_stock_holding: 0,
				backend_stock_quantity_aw: 0,
				backend_stock_quantity_ss: 0,
				frontend_stock: 0,
				maximum_stock_holding_aw: 0,
			},
		});
		setLoading(true);
		await axios
			.post("/api/store/ebo-store", {
				...formData,
				ebo_files: JSON.stringify(links),
			})
			.then((res) => {
				if (res.status === 200) {
					console.log(formData);
					const { createdData } = res.data;
					// setPending([...pending, createdData]);
					setActiveStore([...activeStore, createdData]);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "form saved successfully",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoading(false);
					setformData(initialState);
				} else if (res.status === 400) {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: res.error,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.response ? err.response.data.error : "Internal server error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
			});
	};

	async function handleUpdate() {
		if (!validateFormData(formData)) {
			return;
		}

		const links = files.length ? await handleFileUpload() : [];
		setformData({
			...formData,
			ebo_files: JSON.stringify([...JSON.parse(formData.ebo_files), ...links]),
		});

		const id = formData.id;
		setLoading(true);
		await axios
			.put(`/api/store/ebo-store/${id}`, {
				...formData,
				ebo_files: JSON.stringify([...JSON.parse(formData.ebo_files), ...links]),
			})
			.then((res) => {
				if (res.status === 200) {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "updated successfully",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					// setPending([...pending.map((x) => (x.id === id ? formData : x))]);
					setActiveStore([...activeStore.map((x) => (x.id === id ? formData : x))]);
					// modal(false);
					setLoading(false);
				} else {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: res.error,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoading(false);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
			});
	}

	// helper function to handle file uploads
	function handleFileChange(e) {
		const newFile = e.target.files[0];
		const newFileName = e.target.id + "-" + newFile.name;
		const modifiedFile = new File([newFile], newFileName, { type: newFile.type });
		setFiles([...files, modifiedFile]);
	}

	return (
		<Dialog fullWidth component="form" open={open} onSubmit={handleSubmit} maxWidth="lg" onClose={handleCloseModal}>
			<Box
				direction="row"
				width="100%"
				justifyContent="space-around"
				flexWrap="wrap"
				p={2}
				boxSizing={"border-box"}>
				<DialogTitle>
					{/* <Box align="left"> */}
					<Typography fontSize={26} align={"center"} pt={0}>
						{action === "UPDATE" ? "EBO Store Update" : "EBO Store Form"}
					</Typography>
					{/* </Box> */}
					{/* <Box align="right">
						{action === "update" ? (
							<Button sx={{ fontSize: 18 }} variant="contained" type="button">
								Approve
							</Button>
						) : (
							""
						)}
					</Box> */}
				</DialogTitle>
				<Box
					display="flex"
					flexWrap="wrap"
					pt={4}
					rowGap={3}
					className="vendorInputForm"
					columnGap={6}
					justifyContent="left">
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							RR / RSM / THOR <span>*</span>:
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.rr_code}
							onChange={(e) =>
								setformData({
									...formData,
									rr_code: e.target.value,
									brand: brandOptions[e.target.value],
								})
							}
							size="small">
							{options.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							RR Code<span>*</span>:
						</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rr_number}
							onChange={(e) => setformData({ ...formData, rr_number: e.target.value })}
							placeholder="Enter RR number for the rr code"
							required
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							Brand<span>*</span>:
						</InputLabel>
						<TextField disabled value={formData.brand} size="small"></TextField>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Reporting Name</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.store_name}
							onChange={(e) => setformData({ ...formData, store_name: e.target.value })}
							placeholder="Enter store name"
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Store Type:</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.store_type === "" ? "Select" : formData.store_type}
							onChange={(e) => setformData({ ...formData, store_type: e.target.value })}
							size="small">
							{storeTypeOptions.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Store Type As Per A/C:</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.store_type_as_per_acc === "" ? "Select" : formData.store_type_as_per_acc}
							onChange={(e) =>
								setformData({
									...formData,
									store_type_as_per_acc: e.target.value,
								})
							}
							size="small">
							{storeTypeOptions.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Name Of Franchise / Company:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.name_of_franchise_company}
							onChange={(e) =>
								setformData({
									...formData,
									name_of_franchise_company: e.target.value,
								})
							}
							placeholder="Enter franchise or company name"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Unit No</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.unit_no}
							onChange={(e) => setformData({ ...formData, unit_no: e.target.value })}
							placeholder="Enter unit no"
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Floor</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.floor !== 0 ? formData.floor : ""}
							onChange={(e) => setformData({ ...formData, floor: e.target.value })}
							placeholder="Enter floor"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Address</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.address}
							onChange={(e) => setformData({ ...formData, address: e.target.value })}
							placeholder="Enter Address"
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>State</InputLabel>
						<TextField
							disabled
							size="small"
							type="text"
							value={formData.state}
							onChange={(e) => setformData({ ...formData, state: e.target.value })}
							placeholder="Enter state"
							required
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>City</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.city}
							onChange={(e) => setformData({ ...formData, city: e.target.value })}
							placeholder="Enter city"
							required
						/>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							Tier <span>*</span>:
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.tier === "" ? "Select" : formData.tier}
							onChange={(e) => setformData({ ...formData, tier: e.target.value })}
							size="small">
							{tierOptions.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Pincode</InputLabel>
						<NumberTextField
							size="small"
							value={formData.pincode !== 0 ? formData.pincode : ""}
							onChange={(e) => setformData({ ...formData, pincode: e.target.value })}
							onBlur={() => handleBlur("pincode", "state")}
							placeholder="Enter pincode"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Billing Name As Per Agreement</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.billing_name_as_per_agreement}
							onChange={(e) =>
								setformData({
									...formData,
									billing_name_as_per_agreement: e.target.value,
								})
							}
							placeholder="Enter billing name as per agreement"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Billing Name As Per Accounts</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.billing_name_as_per_acc}
							onChange={(e) =>
								setformData({
									...formData,
									billing_name_as_per_acc: e.target.value,
								})
							}
							placeholder="Enter billing name as per accounts"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel htmlFor="companyType">
							Mall/HS<span>*</span>:
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.mall_hs === "" ? "Select" : formData.mall_hs}
							onChange={(e) => setformData({ ...formData, mall_hs: e.target.value })}
							size="small">
							{MALL_HS_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Zone</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.zone}
							onChange={(e) =>
								setformData({
									...formData,
									zone: e.target.value,
								})
							}
							size="small"
							displayEmpty>
							{ZONE_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Carpet Area</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={formData.carpet_area !== 0 ? formData.carpet_area : ""}
							onChange={(e) =>
								setformData({
									...formData,
									carpet_area: e.target.value,
								})
							}
							placeholder="Enter carpet area"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Chargable Area</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={formData.chargable_area !== 0 ? formData.chargable_area : ""}
							onChange={(e) =>
								setformData({
									...formData,
									chargable_area: e.target.value,
								})
							}
							placeholder="Enter chargable area"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Facade In Ft.</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={formData.facade_in_ft !== 0 ? formData.facade_in_ft : ""}
							onChange={(e) =>
								setformData({
									...formData,
									facade_in_ft: e.target.value,
								})
							}
							placeholder="Enter facade in feet"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Security deposit as per agreement</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={
								formData.security_deposit_as_per_agreement !== 0
									? formData.security_deposit_as_per_agreement
									: ""
							}
							onChange={(e) =>
								setformData({
									...formData,
									security_deposit_as_per_agreement: e.target.value,
								})
							}
							placeholder="Enter security deposit as per aggreement"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>CAM deposit as per aggreement</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={
								formData.cam_deposit_as_per_agreement !== 0 ? formData.cam_deposit_as_per_agreement : ""
							}
							onChange={(e) =>
								setformData({
									...formData,
									cam_deposit_as_per_agreement: e.target.value,
								})
							}
							placeholder="Enter CAM deposit as per aggreement"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Security deposit as per account</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={
								formData.security_deposit_as_per_account !== 0
									? formData.security_deposit_as_per_account
									: ""
							}
							onChange={(e) =>
								setformData({
									...formData,
									security_deposit_as_per_account: e.target.value,
								})
							}
							placeholder="Enter security deposit as per account"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>CAM deposit as per account</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={formData.cam_deposit_as_per_account !== 0 ? formData.cam_deposit_as_per_account : ""}
							onChange={(e) =>
								setformData({
									...formData,
									cam_deposit_as_per_account: e.target.value,
								})
							}
							placeholder="Enter CAM deposit as per account"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Store Opening Date</InputLabel>
						<TextField
							size="small"
							type="Date"
							value={formData.store_opening_date}
							onChange={(e) => setformData({ ...formData, store_opening_date: e.target.value })}
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Rental Start Date</InputLabel>
						<TextField
							size="small"
							type="Date"
							value={formData.rental_start_date}
							onChange={(e) => setformData({ ...formData, rental_start_date: e.target.value })}
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Financial Year</InputLabel>
						<TextField
							size="small"
							placeholder="Enter financial year"
							value={formData.financial_year}
							onChange={(e) => setformData({ ...formData, financial_year: e.target.value })}
						/>
					</Stack>

					<Stack sx={{ width: "100%" }} spacing={0}>
						<Divider>
							<Chip label={<Typography variant="h5">Fitout</Typography>} size="large" />
						</Divider>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Fitout period:</InputLabel>
						<NumberTextField
							size="small"
							type="number"
							value={formData.fitout_period}
							onChange={(e) => setformData({ ...formData, fitout_period: e.target.value })}
							placeholder="Enter fitoutout period"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Start date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={formData.fitout_start_date}
							onChange={(e) => setformData({ ...formData, fitout_start_date: e.target.value })}
							placeholder="Enter fitout start date"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>End date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={formData.fitout_end_date}
							onChange={(e) => setformData({ ...formData, fitout_end_date: e.target.value })}
							placeholder="Enter fitout end date"
						/>
					</Stack>

					<Stack sx={{ width: "100%" }} spacing={0}>
						<Divider>
							<Chip label={<Typography variant="h5">Term</Typography>} size="large" />
						</Divider>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Lease Term</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.lease_term}
							onChange={(e) => setformData({ ...formData, lease_term: e.target.value })}
							placeholder="Enter lease term"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Start Date:</InputLabel>
						<TextField
							size="small"
							type="Date"
							value={formData.start_date}
							onChange={(e) => setformData({ ...formData, start_date: e.target.value })}
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>End Date:</InputLabel>
						<TextField
							size="small"
							type="Date"
							value={formData.end_date}
							onChange={(e) => setformData({ ...formData, end_date: e.target.value })}
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Lock In</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.lock_in}
							onChange={(e) => setformData({ ...formData, lock_in: e.target.value })}
							placeholder="Enter lock in"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Notice</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.notice}
							onChange={(e) => setformData({ ...formData, notice: e.target.value })}
							placeholder="Enter Notice"
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Term Renewal Date:</InputLabel>
						<TextField
							size="small"
							type="Date"
							value={formData.rent_renewal_date}
							onChange={(e) => setformData({ ...formData, rent_renewal_date: e.target.value })}
						/>
					</Stack>

					<Stack sx={{ width: "100%" }} spacing={0}>
						<Divider>
							<Chip label={<Typography variant="h5">Rent</Typography>} size="large" />
						</Divider>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Rent schedule</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.rent_schedule === "" ? "Select" : formData.rent_schedule}
							onChange={(e) =>
								setformData({
									...formData,
									rent_schedule: e.target.value,
								})
							}
							size="small"
							displayEmpty>
							{RENT_SCHEDULE_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Electricity deposit as per account</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.electricity_deposit_as_per_acc}
							onChange={(e) =>
								setformData({ ...formData, electricity_deposit_as_per_acc: e.target.value })
							}
							placeholder="Enter electricity deposit as per account"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Electricity deposit as per agreement</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.electricity_deposit_as_per_agreement}
							onChange={(e) =>
								setformData({ ...formData, electricity_deposit_as_per_agreement: e.target.value })
							}
							placeholder="Enter electricity deposit as per agreement"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Revenue Share %</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.revenue_share}
							onChange={(e) => setformData({ ...formData, revenue_share: e.target.value })}
							placeholder="Enter revenue share percentage"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y1:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y1}
							onChange={(e) => setformData({ ...formData, rent_y1: e.target.value })}
							placeholder="Enter value for Y1"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y2:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y2}
							onChange={(e) => setformData({ ...formData, rent_y2: e.target.value })}
							placeholder="Enter value for Y2"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y3:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y3}
							onChange={(e) => setformData({ ...formData, rent_y3: e.target.value })}
							placeholder="Enter value for Y3"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y4:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y4}
							onChange={(e) => setformData({ ...formData, rent_y4: e.target.value })}
							placeholder="Enter value for Y4"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y5:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y5}
							onChange={(e) => setformData({ ...formData, rent_y5: e.target.value })}
							placeholder="Enter value for Y5"
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y6:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y6}
							onChange={(e) => setformData({ ...formData, rent_y6: e.target.value })}
							placeholder="Enter value for Y6"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y7:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y7}
							onChange={(e) => setformData({ ...formData, rent_y7: e.target.value })}
							placeholder="Enter value for Y7"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y8:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y8}
							onChange={(e) => setformData({ ...formData, rent_y8: e.target.value })}
							placeholder="Enter value for Y8"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y9:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y9}
							onChange={(e) => setformData({ ...formData, rent_y9: e.target.value })}
							placeholder="Enter value for Y9"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y10:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y10}
							onChange={(e) => setformData({ ...formData, rent_y10: e.target.value })}
							placeholder="Enter value for Y10"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y11:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y11}
							onChange={(e) => setformData({ ...formData, rent_y11: e.target.value })}
							placeholder="Enter value for Y11"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y12:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y12}
							onChange={(e) => setformData({ ...formData, rent_y12: e.target.value })}
							placeholder="Enter value for Y12"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y13:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y13}
							onChange={(e) => setformData({ ...formData, rent_y13: e.target.value })}
							placeholder="Enter value for Y13"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y14:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y14}
							onChange={(e) => setformData({ ...formData, rent_y14: e.target.value })}
							placeholder="Enter value for Y14"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y15:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.rent_y15}
							onChange={(e) => setformData({ ...formData, rent_y15: e.target.value })}
							placeholder="Enter value for Y15"
						/>
					</Stack>

					<Stack sx={{ width: "100%" }} spacing={0}>
						<Divider>
							<Chip label={<Typography variant="h5">CAM + HVAC</Typography>} size="large" />
						</Divider>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y1:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y1}
							onChange={(e) => setformData({ ...formData, cam_y1: e.target.value })}
							placeholder="Enter value for Y1"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y2:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y2}
							onChange={(e) => setformData({ ...formData, cam_y2: e.target.value })}
							placeholder="Enter value for Y2"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y3:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y3}
							onChange={(e) => setformData({ ...formData, cam_y3: e.target.value })}
							placeholder="Enter value for Y3"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y4:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y4}
							onChange={(e) => setformData({ ...formData, cam_y4: e.target.value })}
							placeholder="Enter value for Y4"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y5:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y5}
							onChange={(e) => setformData({ ...formData, cam_y5: e.target.value })}
							placeholder="Enter value for Y5"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y6:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y6}
							onChange={(e) => setformData({ ...formData, cam_y6: e.target.value })}
							placeholder="Enter value for Y6"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y7:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y7}
							onChange={(e) => setformData({ ...formData, cam_y7: e.target.value })}
							placeholder="Enter value for Y7"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y8:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y8}
							onChange={(e) => setformData({ ...formData, cam_y8: e.target.value })}
							placeholder="Enter value for Y8"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y9:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y9}
							onChange={(e) => setformData({ ...formData, cam_y9: e.target.value })}
							placeholder="Enter value for Y9"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y10:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y10}
							onChange={(e) => setformData({ ...formData, cam_y10: e.target.value })}
							placeholder="Enter value for Y10"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y11:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y11}
							onChange={(e) => setformData({ ...formData, cam_y11: e.target.value })}
							placeholder="Enter value for Y11"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y12:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y12}
							onChange={(e) => setformData({ ...formData, cam_y12: e.target.value })}
							placeholder="Enter value for Y12"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y13:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y13}
							onChange={(e) => setformData({ ...formData, cam_y13: e.target.value })}
							placeholder="Enter value for Y13"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y14:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y14}
							onChange={(e) => setformData({ ...formData, cam_y14: e.target.value })}
							placeholder="Enter value for Y14"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Y15:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.cam_y15}
							onChange={(e) => setformData({ ...formData, cam_y15: e.target.value })}
							placeholder="Enter value for Y15"
						/>
					</Stack>
					<Stack sx={{ width: "100%" }} spacing={0}>
						<Divider>
							<Chip label={<Typography variant="h5">Billing Address</Typography>} size="large" />
						</Divider>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Unit No:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.billing_unit}
							onChange={(e) => setformData({ ...formData, billing_unit: e.target.value })}
							placeholder="Enter billing address unit no"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>City:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.billing_city}
							onChange={(e) => setformData({ ...formData, billing_city: e.target.value })}
							placeholder="Enter billing address city"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>State:</InputLabel>
						<TextField
							disabled
							size="small"
							type="text"
							value={formData.billing_state}
							onChange={(e) => setformData({ ...formData, billing_state: e.target.value })}
							placeholder="Enter billing address state"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Pincode:</InputLabel>
						<NumberTextField
							// inputProps={{inputMode: "numeric"}}
							size="small"
							type="text"
							value={formData.billing_pincode !== 0 ? formData.billing_pincode : ""}
							onChange={(e) =>
								setformData({
									...formData,
									billing_pincode: e.target.value,
								})
							}
							onBlur={() => handleBlur("billing_pincode", "billing_state")}
							placeholder="Enter billing address pincode"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Adddress:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.billing_address}
							onChange={(e) => setformData({ ...formData, billing_address: e.target.value })}
							placeholder="Enter billing address"
						/>
					</Stack>

					<Stack sx={{ width: "100%" }} spacing={0}>
						<Divider>
							<Chip label={<Typography variant="h5">Stock Delivery Address</Typography>} size="large" />
						</Divider>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Unit No:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.stock_delivery_unit}
							onChange={(e) =>
								setformData({
									...formData,
									stock_delivery_unit: e.target.value,
								})
							}
							placeholder="Enter stock delivery address unit no"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>City:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.stock_delivery_city}
							onChange={(e) =>
								setformData({
									...formData,
									stock_delivery_city: e.target.value,
								})
							}
							placeholder="Enter stock delivery address city"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>State:</InputLabel>
						<TextField
							disabled
							size="small"
							type="text"
							value={formData.stock_delivery_state}
							onChange={(e) =>
								setformData({
									...formData,
									stock_delivery_state: e.target.value,
								})
							}
							placeholder="Enter stock delivery address state"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Pincode:</InputLabel>
						<NumberTextField
							size="small"
							// type="text"
							value={formData.stock_delivery_pincode !== 0 ? formData.stock_delivery_pincode : ""}
							onChange={(e) =>
								setformData({
									...formData,
									stock_delivery_pincode: e.target.value,
								})
							}
							onBlur={() => handleBlur("stock_delivery_pincode", "stock_delivery_state")}
							placeholder="Enter stock delivery address pincode"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Adddress:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.stock_delivery_address}
							onChange={(e) =>
								setformData({
									...formData,
									stock_delivery_address: e.target.value,
								})
							}
							placeholder="Enter stock delivery address"
						/>
					</Stack>
					<Stack sx={{ width: 180, my: 3 }}>
						<Button variant="contained" sx={{ marginLeft: "" }} onClick={handleCopyDetails}>
							Same as billing
						</Button>
					</Stack>
					<Stack sx={{ width: "100%" }} spacing={2} p={4}>
						<Divider></Divider>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Marketing Charges / Month</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.marketing_charges_per_month}
							onChange={(e) =>
								setformData({
									...formData,
									marketing_charges_per_month: e.target.value,
								})
							}
							placeholder="Enter maketing charges per month"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Gift Vouchers / Month</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.gift_vouchers_per_month}
							onChange={(e) =>
								setformData({
									...formData,
									gift_vouchers_per_month: e.target.value,
								})
							}
							placeholder="Enter gift vouchers per month"
						/>
					</Stack>
					{/* <Stack sx={formStack} spacing={0}>
						<InputLabel>CAPEX</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.capex}
							onChange={(e) => setformData({ ...formData, capex: e.target.value })}
							placeholder="Enter CAPEX"
						/>
					</Stack> */}
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Actual CAPEX</InputLabel>
						<NumberTextField
							size="small"
							type="number"
							value={formData.actual_capex}
							onChange={(e) => setformData({ ...formData, actual_capex: e.target.value })}
							placeholder="Enter actual CAPEX"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Projected CAPEX</InputLabel>
						<NumberTextField
							size="small"
							type="text"
							value={formData.projected_capex}
							onChange={(e) => setformData({ ...formData, projected_capex: e.target.value })}
							placeholder="Enter projected CAPEX"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>CAPEX sale value</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.capex_sale_value}
							onChange={(e) => setformData({ ...formData, capex_sale_value: e.target.value })}
							placeholder="Enter CAPEX sale value"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Deposit Against Goods</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={formData.deposit_against_goods !== 0 ? formData.deposit_against_goods : ""}
							onChange={(e) =>
								setformData({
									...formData,
									deposit_against_goods: e.target.value,
								})
							}
							placeholder="Enter deposit against goods"
						/>
					</Stack>

					{/* <Stack sx={formStack} spacing={0}>
						<InputLabel>Base Stocks qty-ss</InputLabel>
						<NumberTextField
							size="small"
							// type="text"
							value={formData.base_stock_qty_ss}
							onChange={(e) => setformData({ ...formData, base_stock_qty_ss: e.target.value })}
							placeholder="Enter base stocks qty-ss"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Base Stocks qty-aw</InputLabel>
						<NumberTextField
							size="small"
							// type="text"
							value={formData.base_stock_qty_aw}
							onChange={(e) => setformData({ ...formData, base_stock_qty_aw: e.target.value })}
							placeholder="Enter base stocks qty-aw"
						/>
					</Stack> */}
					<Stack sx={formStack} spacing={0}>
						<InputLabel>BR.NO</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.board_resolution_number}
							placeholder="Enter board resolution number"
							onChange={(e) =>
								setformData({
									...formData,
									board_resolution_number: e.target.value,
								})
							}
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Board Resolution Date</InputLabel>
						<TextField
							size="small"
							type="date"
							value={formData.board_resolution_date}
							onChange={(e) =>
								setformData({
									...formData,
									board_resolution_date: e.target.value,
								})
							}
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Authorized Signatory</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.authorized_signatory}
							onChange={(e) =>
								setformData({
									...formData,
									authorized_signatory: e.target.value,
								})
							}
							placeholder="Enter authorized signatory"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Stamp Duty & Registration </InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={
								formData.stamp_duty_and_registration !== 0 ? formData.stamp_duty_and_registration : ""
							}
							onChange={(e) =>
								setformData({
									...formData,
									stamp_duty_and_registration: e.target.value,
								})
							}
							placeholder="Enter Stamp Duty & Registration   "
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Insurance Amount</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={formData.insurance_amount}
							onChange={(e) => setformData({ ...formData, insurance_amount: e.target.value })}
							placeholder="Enter Insurance amount"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Insurance End Date</InputLabel>
						<TextField
							size="small"
							type="Date"
							value={formData.insurance_end_date}
							onChange={(e) => setformData({ ...formData, insurance_end_date: e.target.value })}
							placeholder="Enter Insurance end date"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Agreement Expiry Date</InputLabel>
						<TextField
							size="small"
							type="Date"
							value={formData.agreement_expiry_date}
							onChange={(e) =>
								setformData({
									...formData,
									agreement_expiry_date: e.target.value,
								})
							}
							placeholder="Enter agreement expiry date"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>CAPEX Insurance</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={formData.capex_insurance}
							onChange={(e) => setformData({ ...formData, capex_insurance: e.target.value })}
							placeholder="Enter capex insurance amount"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Sellable Insurance</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={formData.sellable_insurance}
							onChange={(e) => setformData({ ...formData, sellable_insurance: e.target.value })}
							placeholder="Enter sellable insurance amount "
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>GSTIN</InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.gstin}
							onChange={(e) => setformData({ ...formData, gstin: e.target.value })}
							placeholder="Enter GSTIN"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Agreement Registered</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.agreement_registered === "" ? "Select" : formData.agreement_registered}
							onChange={(e) =>
								setformData({
									...formData,
									agreement_registered: e.target.value,
								})
							}
							size="small"
							displayEmpty>
							{AGREEMENT_REGISTERED_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>GST Registered</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.gst_registered === "" ? "Select" : formData.gst_registered}
							onChange={(e) => setformData({ ...formData, gst_registered: e.target.value })}
							size="small">
							{GST_REGISTERED_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Shop & EST License Date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={formData.shop_est_date}
							onChange={(e) => setformData({ ...formData, shop_est_date: e.target.value })}
							placeholder="Enter Shop & EST date"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Shop & EST License No: </InputLabel>
						<TextField
							size="small"
							type="text"
							value={formData.shop_est_liscense_no}
							onChange={(e) => setformData({ ...formData, shop_est_liscense_no: e.target.value })}
							placeholder="Enter shop & est liscense no"
						/>
					</Stack>
					<Stack className="vendor-input" spacing={1} sx={formStack}>
						<InputLabel>Is Active:</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={formData.is_active === "" ? "Select" : formData.is_active}
							onChange={(e) => setformData({ ...formData, is_active: e.target.value })}
							size="small">
							{IS_ACTIVE_OPTIONS.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack className="vendor-input" spacing={1} sx={formStack}>
						<InputLabel>Upload LOI / Term Sheet:</InputLabel>
						<TextField
							id="LOI/TERM SHEET"
							size="small"
							type="file"
							accept=".pdf, .jpeg, .jpg, .png"
							onChange={handleFileChange}
						/>
					</Stack>

					<Stack className="vendor-input" spacing={1} sx={formStack}>
						<InputLabel>Upload Agreement:</InputLabel>
						<TextField
							id="AGREEMENT"
							size="small"
							type="file"
							accept=".pdf, .jpeg, .jpg, .png"
							onChange={handleFileChange}
						/>
					</Stack>

					<Stack className="vendor-input" spacing={1} sx={formStack}>
						<InputLabel>Upload Insurance:</InputLabel>
						<TextField
							id="INSURANCE"
							size="small"
							type="file"
							accept=".pdf, .jpeg, .jpg, .png"
							onChange={handleFileChange}
						/>
					</Stack>

					<Stack className="vendor-input" spacing={1} sx={formStack}>
						<InputLabel>Upload Shop & Est / Labour:</InputLabel>
						<TextField
							id="SHOP&EST-LABOUR"
							size="small"
							type="file"
							accept=".pdf, .jpeg, .jpg, .png"
							onChange={handleFileChange}
						/>
					</Stack>

					<Stack className="vendor-input" spacing={1} sx={formStack}>
						<InputLabel>Upload Trade:</InputLabel>
						<TextField
							id="TRADE"
							size="small"
							type="file"
							accept=".pdf, .jpeg, .jpg, .png"
							onChange={handleFileChange}
						/>
					</Stack>
					<Stack className="vendor-input" spacing={1} sx={formStack}>
						<InputLabel>Music License:</InputLabel>
						<TextField
							id="MUSIC-LICENSE"
							size="small"
							type="file"
							accept=".pdf, .jpeg, .jpg, .png"
							onChange={handleFileChange}
						/>
					</Stack>
					<Stack className="vendor-input" spacing={1} sx={formStack}>
						<InputLabel>Fire Safety:</InputLabel>
						<TextField
							id="FIRE-SAFETY"
							size="small"
							type="file"
							accept=".pdf, .jpeg, .jpg, .png"
							onChange={handleFileChange}
						/>
					</Stack>

					{action === "UPDATE" && formData.ebo_files?.length > 0 ? (
						<>
							{/* {Object.keys(formData.eboStoreVmData).map((key) => (
								<Stack sx={formStack} spacing={0} key={key}>
									<InputLabel>{key}</InputLabel>
									<TextField
										size="small"
										type="text"
										value={formData.eboStoreVmData[key]}
										onChange={(e) =>
											setformData({
												...formData,
												eboStoreVmData: {
													...formData.eboStoreVmData,
													[key]: e.target.value,
												},
											})
										}
										placeholder=""
									/>
								</Stack>
							))} */}
							<Stack className="vendor-input" spacing={1} sx={{ ...formStack, alignContent: "center" }}>
								<InputLabel>Available Files:</InputLabel>

								<Box
									sx={{
										width: "100%",
										maxWidth: 360,
										bgcolor: "background.paper",
									}}>
									<List>
										{JSON.parse(formData.ebo_files).map((file, index) => (
											<ListItem key={index}>
												<ListItemIcon>
													<FilePresentIcon />
												</ListItemIcon>
												<a href={file.publicUrl} download>
													{file.name}
												</a>
											</ListItem>
										))}
									</List>
								</Box>
							</Stack>
						</>
					) : (
						""
					)}
				</Box>
			</Box>
			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button
					autoFocus
					variant="contained"
					sx={{ mr: 1 }}
					type="submit"
					startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : ""}
					disabled={loading}>
					{loading ? "Saving" : "Save"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default EboForm;
