import { green, lightBlue, red, yellow } from "@mui/material/colors";
import { roundDecimalValueUptoTwo } from "../../utils/roundDecimalValue";
import { Box } from "@mui/material";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";

const GrowthPercentage = ({ number, sx, textStyle }) => {
	// fontsize must be in Integer
	const no = roundDecimalValueUptoTwo(number) ? roundDecimalValueUptoTwo(number) : 0;

	const style = { display: "flex", alignItems: "center", justifyContent: "center" };
	const fontStyle = { color: no > 0 ? green["A700"] : no === 0 ? red[600] : red["A700"], fontSize: "13px" };

	return (
		<Box sx={sx ? { ...style, ...sx } : style}>
			<span style={textStyle ? { ...fontStyle, ...textStyle } : fontStyle}>{Math.abs(no)}%</span>
			<ArrowRightAltRoundedIcon
				sx={{
					transform: "scale(1.3)",
					display: no === 0 ? "none" : "block",
					rotate: no > 0 ? "270deg" : "90deg",
					color: no > 0 ? green["A700"] : red["A700"],
					width: "15px",
					height: "15px",
				}}
			/>
		</Box>
	);
};
export default GrowthPercentage;
