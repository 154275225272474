import {
	Box,
	Button,
	Dialog,
	DialogActions,
	Divider,
	InputLabel,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import ImageUploadHandler from "components/Pim/CommonComponents/ImageUploadHandler/ImageUploadHandler";
import SelectInputField from "components/Pim/CommonComponents/InputComponents/SelectInputField";
import TextInputField from "components/Pim/CommonComponents/InputComponents/TextInputField";
import { GlobalState } from "context/GlobalContext";
import { PimState } from "context/PimContext";
import {
	attributeGroups,
	attributeValueAssociationName,
	colorImgAssociationObjName,
	colorsObjName,
	constraints,
	designerAttributeObjName,
	groups,
	inputTypes,
	isMainImg,
	pimDrawerElements,
	pimParamName,
	productImagesObjName,
	statusOptions,
} from "data/pimData";
import { useEffect, useState } from "react";

import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { AuthState } from "context/AuthContext";
import { green, grey, lightGreen, red, yellow } from "@mui/material/colors";
import { AddRounded, CheckCircleOutlineRounded } from "@mui/icons-material";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";
import ColorsSection from "./ColorsSection/ColorsSection";
import ImageSlider from "components/Pim/CommonComponents/ImageSlider/ImageSlider";
import AddNewImageModal from "components/Pim/PimDashboard/ProductPage/AddNewImageModal/AddNewImageModal";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import axios from "axios";
import { type } from "@testing-library/user-event/dist/type";
import { foreignKeys } from "data/pimData";
import { useNavigate } from "react-router-dom";

const RenderInputFields = ({ columns, setData, data, width }) => {
	return (
		<Box
			display="flex"
			width="calc(100%-280px)"
			flexWrap="wrap"
			p={1}
			alignItems="flex-start"
			columnGap="3%"
			rowGap="2px">
			{columns?.map((column) => {
				if (column.input_type === inputTypes.enter)
					return (
						<TextInputField
							sx={{
								width: "55%",
								p: 1,
								background: grey[200],
								fontWeight: 400,
								overflow: "hidden",
								boxSizing: "border-box",
							}}
							data={data}
							setData={setData}
							column={column}
							width={column.type.datatype.includes("long") ? "70%" : width}
							required={column.type[constraints.notNull]}
							type={column.type.datatype}
						/>
					);
				else {
					return (
						<SelectInputField
							sx={{ width: "55%", p: 1, background: grey[200], fontWeight: 400, overflow: "hidden" }}
							data={data}
							setData={setData}
							column={column}
							width={width}
							options={column?.[attributeValueAssociationName]}
							required={column.type[constraints.notNull]}
						/>
					);
				}
			})}
		</Box>
	);
};

const CreateProduct = () => {
	const { attributes, attributesWithValuesGroups, setSelectedStyle } = PimState();
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();
	const { user } = AuthState();
	const navigate = useNavigate();

	const status = "drafted";

	const [newStyle, setNewStyle] = useState(() => {
		let obj = {};
		attributesWithValuesGroups[attributeGroups.STYLE.key]?.map((column) => {
			if (column.input_type === inputTypes.select) {
				obj[column.key] = "select";
			}
		});
		return obj;
	});
	const [newStyleAttributes, setNewStyleAttributes] = useState(() => {
		let obj = {};
		attributesWithValuesGroups[attributeGroups.DESIGNER.key]?.map((column) => {
			if (column.input_type === inputTypes.select) {
				obj[column.key] = "select";
			}
		});
		return obj;
	});

	const [images, setImages] = useState([]);
	const [colors, setColors] = useState([]);

	// const handleCloseModal = () => {
	// 	setnewProduct();
	// 	setModalOpen(false);
	// };
	const handleFileUpload = async (styleImages, colors) => {
		const formData = new FormData();
		styleImages?.forEach((images) => {
			formData.append("styleImages", images.file);
		});
		colors?.forEach((images) => {
			formData.append("colorImages", images.file);
		});
		const res = await axios.post("/api/pim/upload_style_color_images", formData, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});

		return [res.data.styleImageUrls, res.data.colorImageUrls];
	};

	const handleFormSubmit = async (e) => {
		// e.preventDefault();
		setLoadingOpen(true);
		if (images.length === 0) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Add atleast one image",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);

			return;
		}
		if (colors.length === 0) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Add atleast one color",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);

			return;
		}
		const empArr = [];
		attributesWithValuesGroups[attributeGroups.STYLE.key]?.forEach((row) => {
			if (newStyle[row.key] === undefined || newStyle[row.key] === "select" || newStyle[row.key] === "") {
				empArr.push(row.key);
			}
		});
		if (empArr.length) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Enter values for ${empArr.map((row) => row)}`,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		try {
			const [styleImages, colorImages] = await handleFileUpload(images, colors);
			console.log(styleImages, colorImages);

			images.forEach((row, i) => {
				if (row[isMainImg] === true) styleImages[i][isMainImg] = true;
			});
			const filteredStyleAttr = Object.keys(newStyleAttributes).reduce((acc, row) => {
				if (newStyleAttributes[row] !== "select") acc[row] = newStyleAttributes[row];
				return acc;
			}, {});
			const style = {
				...newStyle,
				[designerAttributeObjName]: filteredStyleAttr,
				images: [
					styleImages.map((row) => {
						return { [isMainImg]: row[isMainImg], url: row.publicUrl };
					}),
					colorImages,
				],
				[colorsObjName]: colors.map((row, i) => {
					return {
						color: row.color,
						pallete: row.pallete,
						[colorImgAssociationObjName]: [{ url: colorImages[i].publicUrl }],
					};
				}),
			};
			console.log("stylesty", style);

			const res = await axios.post("/api/pim/create_style", style);
			console.log("res.data", res.data);
			setSelectedStyle(res.data);
			navigate(`?${pimParamName}=${pimDrawerElements.product_page.key}&style=${res.data.id}`);
			setLoadingOpen(false);
		} catch (error) {
			setLoadingOpen(false);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Internal Server Error",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	const imgWidth = "280px";
	const imgHeight = "360px";

	const [addImageModal, setAddImgModal] = useState(false);

	const handleAddImageClick = () => {
		setAddImgModal(true);
	};

	const handleSaveImageClick = async (newImages) => {
		setImages(newImages);
		return true;
	};

	return (
		<Box width="98%" p={2} boxSizing="border-box">
			{/* user with status */}
			<Box width="100%" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
				<Stack>
					<Box display="flex" columnGap={1}>
						<PersonRoundedIcon />
						<Typography ba variant="subtitle1">
							{user.name}
						</Typography>
					</Box>
				</Stack>
				<Box
					display="flex"
					alignItems="center"
					height="30px"
					columnGap={1}
					sx={{
						backgroundColor: statusOptions[status]?.color || yellow[400],
						color: "white",
						padding: "5px",
						borderRadius: "5px",
					}}>
					{statusOptions[status]?.icon} <Typography> {statusOptions[status]?.name}</Typography>
				</Box>
				<Divider variant="fullWidth" orientation="horizontal" flexItem sx={{ width: "100%", mt: 1 }} />
			</Box>
			<Tooltip title="Style ID">
				<Typography variant="h6">
					<span style={{ fontSize: "15px" }}>STYLE_ID:</span> RR001
				</Typography>
			</Tooltip>
			<Box display="flex" justifyContent="space-between">
				{/* left */}
				<Stack p={1}>
					<Box
						width={imgWidth}
						height={imgHeight}
						sx={{ border: `1px solid ${grey[200]}` }}
						display="flex"
						alignItems="center"
						justifyContent="center">
						{images.length === 0 ? (
							<Box>
								<Button size="large" startIcon={<AddRounded />} onClick={handleAddImageClick}>
									Add Images
								</Button>
							</Box>
						) : (
							<Box width={imgWidth} height={imgHeight} display="flex" flexWrap="wrap" rowGap={2}>
								<ImageSlider
									imgWidth={imgWidth}
									imgHeight={imgHeight}
									column="data_url"
									images={images}
								/>
								<Button
									sx={{ textTransform: "capitalize", mr: 2 }}
									onClick={handleAddImageClick}
									size="small"
									startIcon={<AddRounded />}
									variant="outlined">
									Add Images / Set Main Image
								</Button>
								{/* <Button sx={{ textTransform: "capitalize" }} size="small" variant="contained">
									Set Main Image
								</Button> */}
							</Box>
						)}
						{/* */}
					</Box>
				</Stack>
				{/* right */}
				<Box sx={{ width: "80%" }}>
					{/* styleTableColumns */}

					<RenderInputFields
						setData={setNewStyle}
						data={newStyle}
						columns={attributesWithValuesGroups[attributeGroups.STYLE.key]}
						width={"40%"}
					/>
					<RenderInputFields
						setData={setNewStyleAttributes}
						data={newStyleAttributes}
						columns={attributesWithValuesGroups[attributeGroups.DESIGNER.key]?.filter((row, i) => i < 8)}
						width={"40%"}
					/>
					<RenderInputFields
						setData={setNewStyleAttributes}
						data={newStyleAttributes}
						columns={attributesWithValuesGroups[attributeGroups.DESIGNER.key].filter(
							(row, i) => i > 7 && i < 12
						)}
						width={"40%"}
					/>
				</Box>
			</Box>
			<Stack direction="row" width="100%" rowGap={1} flexWrap="wrap" pt={2} justifyContent="space-between">
				<Box width="38%">
					<RenderInputFields
						setData={setNewStyleAttributes}
						data={newStyleAttributes}
						columns={attributesWithValuesGroups[attributeGroups.DESIGNER.key]?.filter(
							(row, i) => i > 11 && i < 16
						)}
						width={"90%"}
					/>
				</Box>
				<Box width="60%" border={`1px solid ${grey[200]}`} p={1} borderRadius={1}>
					<Typography variant="h6">Colors</Typography>
					<Divider orientation="horizontal" variant="fullWidth" />
					<ColorsSection colors={colors} setColors={setColors} />
				</Box>
			</Stack>

			{/* designer */}
			<Stack pt={4}>
				<Box width="100%">
					<Typography variant="h6" sx={{ background: grey[200], p: 1, borderRadius: 1 }}>
						Designer Details
					</Typography>
					{/* <Divider orientation="horizontal" variant="fullWidth" /> */}
					<RenderInputFields
						setData={setNewStyleAttributes}
						data={newStyleAttributes}
						columns={attributesWithValuesGroups[attributeGroups.DESIGNER.key]?.filter((row, i) => i > 15)}
						width={"30%"}
					/>
				</Box>
			</Stack>
			<Button variant="contained" onClick={handleFormSubmit}>
				Create Style
			</Button>
			<AddNewImageModal
				modalOpen={addImageModal}
				setModalOpen={setAddImgModal}
				submitFunction={handleSaveImageClick}
				defaultImages={images}
				key={addImageModal}
			/>
		</Box>
	);
};

export default CreateProduct;
