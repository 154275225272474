import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

import { useEffect, useState } from "react";
import AddRounded from "@mui/icons-material/AddRounded";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import SearchBar from "components/ReusableComponents/SearchBar";
import PublishIcon from "@mui/icons-material/Publish";
import {
	attributepriorityTypes,
	colorsObjName,
	designerAttributeObjName,
	groups,
	inputTypes,
	pimDrawerElements,
	pimParamName,
	productColumns,
	productDummyData,
	statusOptions,
	styleImagesObjName,
} from "data/pimData";
import { grey, yellow } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import CreateProduct from "../CreateProduct/CreateProduct";
import { PimState } from "context/PimContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Dialog, DialogActions, Switch, TablePagination, Tooltip } from "@mui/material";
import { CheckCircleOutlineRounded, ErrorOutlineRounded } from "@mui/icons-material";
import PublishModal from "./PublishModal/PublishModal";
import StyleFilter from "../../CommonComponents/StyleFilter/StyleFilter";
import { rootProtectedPath, services } from "data/authData";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const ProductBox = ({ data }) => {
	let imgWidth = 50;
	let imgHeight = 60;
	return (
		<Box width="100%" display="flex" alignItems="center">
			<img
				style={{
					width: `${imgWidth}px`,
					height: `${imgHeight}px`,
					objectFit: "cover",
					objectPosition: "50% 0%",
					borderRadius: 2,
				}}
				src={data[styleImagesObjName][0]?.url}
			/>
			<Stack pl={1} justifyContent="space-between" rowGap={1}>
				<Typography variant="body1" width="100%" fontSize={18} fontWeight={600}>
					{data.style_name}
				</Typography>
				{/* <Typography variant="body1" fontSize={12} fontWeight={500} pt={0}>
					{data.color}-{data.pallette}
				</Typography> */}
				<Box display="flex" columnGap={1} alignItems="center">
					<Chip label={data.category} size="small" />
					<Typography variant="body1" fontSize={14}>
						{data.sub_category}
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};

const publishStyleTablColumns = [
	{
		field: "image",
		headerName: "Product",
		width: 220,
		...colStyle,
		renderCell: (params) => {
			return <ProductBox data={params.row} />;
		},
	},
	// { field: "STYLE_ID", headerName: "Style Id", width: 100, ...colStyle },
	// {
	// 	// field: "pallette",
	// 	headerName: "Pallette",
	// 	width: 100,
	// 	...colStyle,
	// 	renderCell: (params) => {
	// 		return <PalleteBox data={params.row} />;
	// 	},
	// },

	{ field: "style_name", headerName: "STYLE", width: 120, ...colStyle },
	{ field: "category", headerName: "CATEGORY", width: 100, ...colStyle },
	{ field: "sub_category", headerName: "SUB-CATEGORY", width: 120, ...colStyle },
	{ field: "brand", headerName: "BRAND", width: 110, ...colStyle },
	{ field: "sub_brand", headerName: "SUB-BRAND", width: 110, ...colStyle },
	{ field: "season", headerName: "SEASON", width: 100, ...colStyle },
	{ field: "division", headerName: "DIVISION", width: 100, ...colStyle },
	{ field: "season", headerName: "SEASON", width: 110, ...colStyle },
	{ field: "created_by", headerName: "CREATED BY", width: 150, ...colStyle },

	{
		field: "status",
		headerName: "STATUS",
		width: 120,
		renderCell: ({ row }) => {
			return (
				<Box
					display="flex"
					alignItems="center"
					height={"22px"}
					// columnGap={1}
					sx={{
						backgroundColor: statusOptions[row.status]?.color || yellow[400],
						color: "white",
						padding: "6px",
						borderRadius: "5px",
					}}>
					{statusOptions[row.status]?.icon}{" "}
					<Typography pl="3px"> {statusOptions[row.status]?.name}</Typography>
				</Box>
			);
		},
	},
];

const defaultFilterData = { division: "APPARELS" };

const StylesDashboard = () => {
	const { setSelectedStyle } = PimState();
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();
	const [styles, setStyles] = useState();
	const [pendingCount, setPendingCount] = useState(0);
	const [displayImg, setDisplayImg] = useState(false);
	const [selectedStyleToPublish, setelectedStyleToPublish] = useState();
	const [searchActive, setSearchActive] = useState(false);
	const [publishModal, setPublishModal] = useState(false);
	const styleTablColumns = [
		{
			field: "image",
			headerName: "Product",
			width: 220,
			...colStyle,
			renderCell: (params) => {
				return <ProductBox data={params.row} />;
			},
		},
		{ field: "style_name", headerName: "STYLE", width: 90, ...colStyle },
		{ field: "category", headerName: "CATEGORY", width: 90, ...colStyle },
		{ field: "sub_category", headerName: "SUB-CATEGORY", width: 100, ...colStyle },
		{ field: "brand", headerName: "BRAND", width: 110, ...colStyle },
		{ field: "sub_brand", headerName: "SUB-BRAND", width: 80, ...colStyle },
		{ field: "season", headerName: "SEASON", width: 80, ...colStyle },
		// { field: "division", headerName: "DIVISION", width: 100, ...colStyle },
		{ field: "created_by", headerName: "CREATED BY", width: 150, ...colStyle },
		{
			field: "published_by",
			headerName: "PUBLISHED BY",
			width: 150,
			...colStyle,
			valueGetter: ({ row }) => {
				if (row?.is_published) return row.published_by;
				else return "Not Published";
			},
		},
		{
			field: "color_count",
			headerName: "COLORS",
			width: 140,
			...colStyle,
			renderCell: ({ row }) => {
				const published = typeof row?.published_count === "number" ? row.published_count : 0;
				const rejected = typeof row?.rejected_count === "number" ? row.rejected_count : 0;
				const remaining = typeof row?.created_colors === "number" ? row.created_colors : 0;
				return (
					<Box display="flex" columnGap={2}>
						<Tooltip title="Remaining">
							<Box display="flex" columnGap="4px">
								<InfoRoundedIcon fontSize="small" color="warning" />{" "}
								<span style={{ fontSize: "14px" }}>{remaining}</span>
							</Box>
						</Tooltip>
						<Box display="flex" columnGap="4px">
							<Tooltip title="Published">
								<Box display="flex" columnGap="4px">
									<CheckCircleRoundedIcon fontSize="small" color="success" />{" "}
									<span style={{ fontSize: "14px" }}>{published}</span>
								</Box>
							</Tooltip>
							<Tooltip title="Rejected">
								<Box display="flex" columnGap="4px">
									<CancelRoundedIcon fontSize="small" color="error" />{" "}
									<span style={{ fontSize: "14px" }}>{rejected}</span>
								</Box>
							</Tooltip>
						</Box>
					</Box>
				);
			},
		},

		{
			field: "stage",
			headerName: "Stage",
			width: 140,
			renderCell: ({ row }) => {
				return (
					<Box
						display="flex"
						alignItems="center"
						height={"22px"}
						// columnGap={1}
						sx={{
							backgroundColor: statusOptions["CREATED"]?.color,
							color: "white",
							padding: "6px",
							borderRadius: "5px",
						}}>
						{/* {statusOptions[row.status]?.icon} */}
						<Typography pl="3px"> {row.stage}</Typography>
					</Box>
				);
			},
		},
		{
			field: "is_published",
			headerName: "Publish",
			width: 120,
			renderCell: ({ row }) => {
				if (row.is_published !== true)
					return (
						<Button
							onClick={(e) => handlePublishClick(e, row)}
							variant="contained"
							size="small"
							startIcon={<PublishIcon />}>
							Publish
						</Button>
					);
				else
					return (
						<Box
							display="flex"
							alignItems="center"
							height={"22px"}
							// columnGap={1}
							sx={{
								backgroundColor: statusOptions[row.status]?.color || yellow[400],
								color: "white",
								padding: "6px",
								borderRadius: "5px",
							}}>
							{statusOptions[row.status]?.icon}{" "}
							<Typography pl="3px"> {statusOptions[row.status]?.name}</Typography>
						</Box>
					);
			},
		},
	];

	const fetch = async (filter, limit) => {
		setLoadingOpen(true);
		let body = { limit };
		body["limit"] = limit === false ? false : true;
		if (filter) body["filter"] = filter;
		try {
			await axios.post("/api/pim/get_styles", body).then((res) => {
				setStyles(res.data);
				let count = 0;
				res.data.forEach((row) => {
					if (!row.is_published) count++;
				});
				setPendingCount(count);
			});
			setLoadingOpen(false);
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Server Error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};
	useEffect(() => {
		fetch();
	}, []);

	const navigate = useNavigate();

	const [filter, setFilter] = useState(defaultFilterData);

	const handleImageSwitchChange = (checked) => {
		setDisplayImg(checked);
	};

	const handleStyleSelect = async (data) => {
		// console.log("selected prod", productDummyData);
		// setSelectedProduct({ ...productDummyData, id: 0 });
		setLoadingOpen(true);
		try {
			await axios.get(`/api/pim/get_style_by_id/${data.id}`).then((res) => {
				console.log("styledata", res.data);
				setSelectedStyle(res.data);
				navigate(
					`/${rootProtectedPath}/${services.pim.key}/${pimDrawerElements.designer.types.product_page.key}?style=${data.id}`
				);
				setLoadingOpen(false);
			});
		} catch (error) {
			setLoadingOpen(false);
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Unable to load style data `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	const handlePublishClick = (e, styleData) => {
		e.stopPropagation();
		setPublishModal(true);
		setelectedStyleToPublish(styleData);
	};
	const onFinalSubmit = (styleData) => {
		setelectedStyleToPublish(styleData);
		const map = {};
		styleData[colorsObjName].forEach((row, i) => {
			map[row.id] = i;
		});
		setStyles(
			styles.map((style) => {
				if (style.id === styleData.id) {
					const colors = style[colorsObjName].map((row) => {
						if (map[row.id] >= 0) return styleData[colorsObjName][map[row.id]];
						else return row;
					});
					return { ...styleData, [colorsObjName]: colors };
				} else return style;
			})
		);
	};
	const filterStyleFunction = () => {
		fetch(filter, false);
	};
	// const handleSearch = async (value) => {
	// 	try {
	// 		const res = await axios.get(`/api/pim/search_style?query=${value}`);
	// 		setStyles(res.data);
	// 		let count = 0;
	// 		res.data.forEach((row) => {
	// 			if (!row.is_published) count++;
	// 		});
	// 		setPendingCount(count);
	// 	} catch (error) {
	// 		console.log(error);
	// 		setSnackBarOpen(true);
	// 		setSnackBar({
	// 			...snackBar,
	// 			message: `Server Error `,
	// 			severity: "error",
	// 			anchorOrigin: { horizontal: "center", vertical: "top" },
	// 		});
	// 		setLoadingOpen(false);
	// 	}
	// };
	const handleSearch = async (value) => {
		setLoadingOpen(true);

		try {
			const res = await axios.get(`/api/pim/search_style?query=${value}`);
			setStyles(res.data);
			let count = 0;
			res.data.forEach((row) => {
				if (!row.is_published) count++;
			});
			setPendingCount(count);
			setLoadingOpen(false);
			setSearchActive(true);
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Server Error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};
	const clearSearch = () => {
		fetch();
		setSearchActive(false);
	};
	return (
		<Stack width="100%" spacing={1} pt={1}>
			<StyleFilter filter={filter} setFilter={setFilter} submitFunction={filterStyleFunction} />

			<SearchBar
				placeholder="Search Style"
				submitFunction={handleSearch}
				clearFunction={clearSearch}
				displayClearBtn={searchActive}
			/>
			<Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
				<Box display="flex">
					<Typography variant="h6">Images</Typography>
					<Switch checked={displayImg} onChange={(e) => handleImageSwitchChange(e.target.checked)} />
				</Box>
				<Typography variant="	h6" pr={2}>
					Pending : <span style={{ color: "red" }}>{pendingCount}</span>
				</Typography>
			</Stack>

			<Stack width="100%" height={400}>
				<DataGrid
					rows={styles ? styles : []}
					key={displayImg}
					columns={styleTablColumns}
					sx={{
						minHeight: 350,
						maxHeight: 430,
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							background: grey[300],
							mb: 2,
							border: "none",
							borderRadius: 1,
							// padding: "10px 0px",
						},
					}}
					columnHeaderHeight={35}
					rowHeight={displayImg ? 70 : 40}
					// showCellVerticalBorder
					onRowClick={(params, e) => handleStyleSelect(params.row)}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
						columns: {
							columnVisibilityModel: {
								image: displayImg,
								category: !displayImg,
								sub_category: !displayImg,
								style_name: !displayImg,
							},
						},
					}}
					// loading={loading}
					disableRowSelectionOnClick
					// checkboxSelection
					// isRowSelectable={(params) => params.row.is_published === false}
					pageSizeOptions={[10, 50]}
					// onRowSelectionModelChange={(newSelectionModel) => {
					// 	console.log("dfsd", newSelectionModel);
					// 	setSelectedStyleIds(newSelectionModel);
					// 	let obj = newSelectionModel?.reduce((acc, row) => {
					// 		acc[row] = true;
					// 		return acc;
					// 	}, {});

					// 	setSelectedStyles(styles.filter((row) => obj[row["id"]] === true));
					// }}
				/>
			</Stack>

			<PublishModal
				modal={publishModal}
				setModal={setPublishModal}
				styleData={selectedStyleToPublish}
				submitFunction={onFinalSubmit}
			/>
		</Stack>
	);
};

export default StylesDashboard;
