import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const StatusButtons = ({
	handleReject,
	disableEdit,
	handleEdit,
	approved,
	handleApproval,
	editButton,
	buttonName,
	remark,
	otherAccess,
}) => {
	const apporoveColor = "#A5DD9B";
	const rejectColor = "#D37676";
	return (
		<Stack direction="row">
			{disableEdit ? (
				approved || remark !== "" ? (
					""
				) : otherAccess ? (
					<Button
						color="success"
						variant="contained"
						sx={{ margin: "0 5px", backgroundColor: apporoveColor }}
						onClick={handleApproval}>
						Approve
					</Button>
				) : (
					""
				)
			) : (
				""
			)}
			{disableEdit ? (
				approved || remark !== "" ? (
					""
				) : otherAccess ? (
					<Button
						color="error"
						variant="contained"
						sx={{ margin: "0 5px", backgroundColor: rejectColor }}
						onClick={handleReject}>
						Reject
					</Button>
				) : (
					""
				)
			) : (
				""
			)}
			{approved ? (
				<Typography sx={{ color: "green" }} variant="h6">
					Approved
				</Typography>
			) : remark !== "" ? (
				<Typography sx={{ color: "red" }} variant="h6">
					Rejected
				</Typography>
			) : disableEdit ? (
				""
			) : (
				<Typography sx={{ color: "black" }} variant="h6">
					Pending
				</Typography>
			)}
			{disableEdit ? (
				""
			) : !editButton || approved ? (
				""
			) : (
				<Button variant="contained" sx={{ margin: "0 5px" }} onClick={handleEdit}>
					{buttonName}
				</Button>
			)}
		</Stack>
	);
};

export default StatusButtons;
