import Box from "@mui/material/Box";
import { useOutletContext } from "react-router-dom";
import DataHubRR from "./DataHubRareRabbit/DataHubRR";
import { DataHubDrawerElements } from "data/DataHubData";
import DataHubRSM from "./DataHubRSM/DataHubRSM";
import { useEffect, useState } from "react";
import { AuthState } from "context/AuthContext";
import { admin, departments } from "data/authData";
import DataHubRO from "./DataHubRareOnes/DataHubRO";
import DataHubFinance from "./DataHubFinance/DatahubFinance";
import DataHubStore from "./DataHubStore/DataHubStore";
import DataHubAgreement from "./DataHubAgreement/DataHubAgreement";
import DataHubBoardData from "./DataHubBoardData/DataHubBoardData";

const DataHub = () => {
	const [tab, setTab] = useOutletContext();
	const [tabComponents, setTabComponents] = useState([]);
	const { user } = AuthState();
	const defaultTabComponents = [
		{
			...DataHubDrawerElements.RARE_RABBIT,
			component: <DataHubRR />,
		},
		{
			...DataHubDrawerElements.RAREISM,
			component: <DataHubRSM />,
		},
		{
			...DataHubDrawerElements.RAREONES,
			component: <DataHubRO />,
		},
		{
			...DataHubDrawerElements.FINANCE,
			component: <DataHubFinance />,
		},
		{
			...DataHubDrawerElements.STORE_ADMIN,
			component: <DataHubStore />,
		},
		{
			...DataHubDrawerElements.AGREEMENT,
			component: <DataHubAgreement />,
		},
		{
			...DataHubDrawerElements.BOARD,
			component: <DataHubBoardData />,
		},
	];

	const setTabComponentsList = () => {
		console.log(user);
		if (user.role) {
			let access = [];
			const role = user.role;
			for (let i in user.role) {
				if (i === "RARE_RABBIT" || i === "RAREISM" || i === "RAREONES") {
					access.push(i);
				}
			}
			if (role[admin] || role[departments.buying_planning.key]) {
				setTabComponents(defaultTabComponents);
				return;
			} else if (access.length > 1) {
				setTabComponents(
					defaultTabComponents.filter(
						(row) =>
							row.key === DataHubDrawerElements[`${access[0]}`].key ||
							row.key === DataHubDrawerElements[`${access[1]}`].key
					)
				);
			} else if (user.role.RARE_RABBIT) {
				setTabComponents(
					defaultTabComponents.filter((row) => row.key === DataHubDrawerElements.RARE_RABBIT.key)
				);
			} else if (user.role.RAREISM) {
				setTabComponents(defaultTabComponents.filter((row) => row.key === DataHubDrawerElements.RAREISM.key));
			} else if (user.role.RAREONES) {
				setTabComponents(defaultTabComponents.filter((row) => row.key === DataHubDrawerElements.RAREONES.key));
			} else if (user.role.FINANCE) {
				setTabComponents(defaultTabComponents.filter((row) => row.key === DataHubDrawerElements.FINANCE.key));
			} else if (user.role.STORE_ADMIN) {
				setTabComponents(
					defaultTabComponents.filter((row) => row.key === DataHubDrawerElements.STORE_ADMIN.key)
				);
			} else if (user.role.AGREEMENT_ADMIN) {
				setTabComponents(defaultTabComponents.filter((row) => row.key === DataHubDrawerElements.AGREEMENT.key));
			} else if (user.role.BOARD) {
				setTabComponents(defaultTabComponents.filter((row) => row.key === DataHubDrawerElements.BOARD.key));
			}
		}
	};

	useEffect(() => {
		setTabComponentsList();
	}, []);

	return (
		<Box sx={{ width: "100%" }}>
			{tabComponents &&
				tabComponents.map((row) => (
					<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
						{row.component}
					</Box>
				))}
		</Box>
	);
};

export default DataHub;
