import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthState } from "context/AuthContext";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { grey } from "@mui/material/colors";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import StoreIcon from "@mui/icons-material/Store";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PaymentsIcon from "@mui/icons-material/Payments";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import CategoryIcon from "@mui/icons-material/Category";
import DatasetIcon from "@mui/icons-material/Dataset";
import { Task } from "@mui/icons-material";
import { admin, services } from "data/authData";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import CodeIcon from "@mui/icons-material/Code";
import { pimDrawerElements } from "data/pimData";

const serviceBox = {
	display: "flex",
	p: 2,
	alignItems: "center",
	columnGap: "20px",
	border: "1px solid var(--grayBgColor)",
	borderRadius: 2,
	margin: "5px 0px",
	sx: { "&:hover": { boxShadow: 1 }, cursor: "pointer" },
};

const allServices = {
	DESIGNER: {
		name: "Designer",
		values: [
			{
				name: "Moodboard",
				link: `/explore/${services.users.path}`,
				icon: <GroupAddRoundedIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				name: "Styles",
				link: `/explore/${services.radhamani_web_data.path}`,
				icon: <GroupAddRoundedIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				name: "create Style",
				link: `/explore/${services.radhamani_web_data.path}`,
				icon: <GroupAddRoundedIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
		],
	},
	TECH: {
		name: "MDM",
		values: [
			{
				...services.supplier_dashboard,
				link: `/explore/${services.supplier_dashboard.path}`,
				icon: <AddBusinessRoundedIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.ebo_stores,
				link: `/explore/${services.ebo_stores.path}`,
				icon: <StoreIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.pim,
				link: `/explore/${services.pim.path}`,
				icon: <CategoryIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.mbo_stores,
				link: `/explore/${services.mbo_stores.path}`,
				icon: <StoreIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.item_master,
				link: `/explore/${services.item_master.path}`,
				icon: <DatasetIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
		],
	},
	FINANCE_ACCOUNTS: {
		name: "Finance & Accounts",
		values: [
			{
				...services.production_costing,
				link: `/explore/${services.production_costing.path}`,
				icon: <PaymentsIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.uc_vs_ginesys,
				link: `/explore/${services.uc_vs_ginesys.path}`,
				icon: <CompareArrowsIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.ebo_payments,
				link: `/explore/${services.ebo_payments.path}`,
				icon: <Task sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.pos_reco,
				link: `/explore/${services.pos_reco.path}`,
				icon: <PaymentsIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
		],
	},
	BUYING_PLANNING: {
		name: "Buying & Planning",
		values: [
			{
				...services.ecom_reports,
				link: `/explore/${services.ecom_reports.path}`,
				icon: <AssessmentIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.business_plan,
				link: `/explore/${services.business_plan.path}`,
				icon: <InsightsRoundedIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.data_hub,
				link: `/explore/${services.data_hub.path}`,
				icon: <FolderCopyIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
			{
				...services.custom_queries,
				link: `/explore/${services.custom_queries.path}`,
				icon: <CodeIcon sx={{ transform: "scale(1.5)", m: 3, borderRadius: 2 }} />,
			},
		],
	},
};

const PimExplore = () => {
	const navigate = useNavigate();
	const { user } = AuthState();

	const [accessableServices, setAccessableServices] = useState();

	const setServices = () => {
		if (user.role) {
			const role = user.role;
			// if (role[admin]) {
			let arr = [];
			Object.keys(allServices).forEach((row) => arr.push(allServices[row]));
			return arr;
			// } else {
			// 	const arr = [];
			// 	Object.keys(allServices).forEach((key) => {
			// 		if (role[key]) {
			// 			arr.push(allServices[key]);
			// 			return;
			// 		}
			// 		let filterdValues = [];
			// 		allServices[key].values.forEach((service) => {
			// 			if (role[service.key]) filterdValues.push(service);
			// 		});
			// 		if (filterdValues.length > 0) arr.push({ ...allServices[key], values: filterdValues });
			// 	});

			// 	return arr;
			// }
		}
	};

	useEffect(() => {
		if (user) {
			const services = setServices();
			setAccessableServices(services);
		}
	}, [user]);

	const handleServiceClick = (path) => {
		navigate(`${window.location.pathname}/${path}`);
	};

	return (
		<Box maxWidth="1200px" margin="auto">
			<Box display="flex" columnGap="20px" alignItems="center">
				<CategoryIcon fontSize="large" />
				<Typography sx={{ width: "100%", margin: "auto" }} pt={2} pb={2} variant="h4">
					PIM
				</Typography>
			</Box>
			{/* services list */}
			<Stack width={{ xs: "90%", sm: "100%" }} margin="auto" direction="row" flexWrap="wrap">
				{pimDrawerElements &&
					Object.keys(pimDrawerElements).map((key) => {
						const service = pimDrawerElements[key];
						return (
							<Box display="flex" flexWrap="wrap" key={service.name} columnGap={2}>
								<Typography
									sx={{ width: "100%", margin: "auto" }}
									pt={2}
									variant="subtitle1"
									color={grey[400]}>
									{service.name}
								</Typography>
								{service?.types &&
									Object.keys(service.types)
										.reduce((acc, row) => {
											if (service.types[row].display) acc.push(service.types[row]);
											return acc;
										}, [])
										.map((key) => {
											const row = key;
											return (
												<Box
													key={row.name}
													onClick={() => handleServiceClick(row.key)}
													{...serviceBox}>
													{row.icon}
													<Typography variant="h6">{row.name}</Typography>
												</Box>
											);
										})}
								{!service?.types && (
									<Box
										key={service.name}
										onClick={() => handleServiceClick(service.key)}
										{...serviceBox}>
										{service.icon}
										<Typography variant="h6">{service.name}</Typography>
									</Box>
								)}
							</Box>
						);
					})}

				{/*<Box onClick={() => navigate("/explore/own_prod_cost")} {...serviceBox}>
					<PaymentsIcon
						sx={{
							transform: "scale(1.5)",

							m: 3,
							// border: "1px solid var(--grayBgColor)",
							borderRadius: 2,
						}}
					/>
					<Typography variant="h6">Own Production Costing</Typography>
				</Box> */}
			</Stack>
		</Box>
	);
};

export default PimExplore;
