import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { GlobalState } from "../../../../context/GlobalContext";
import { DialogActions, MenuItem, Select, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { paymentModeOptions, paymentTermColumns, supplierIdParamName } from "data/supplierPageData";
import { rootProtectedPath, services } from "data/authData";

function SupplierInfoModal({
	modalEditable,
	selectedSupplier,
	setSelectedSupplier,
	allSuppliers,
	setAllSuppliers,
	setModalOpen,
	setModalEditable,
}) {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [companyData, setCompanyData] = useState(selectedSupplier);
	const navigate = useNavigate();
	const date = new Date();

	const updateSupplier = async (apibody) => {
		await axios
			.put(`/api/supplier/update/${companyData.id}`, apibody)
			.then((res) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Supplier updated",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setAllSuppliers([
					{ ...companyData, ...apibody },
					...allSuppliers.filter((row) => row.id !== selectedSupplier.id),
				]);
				setModalEditable(true);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable to update supplier details ",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		let apibody = {};
		if (companyData.payment_term === "select" || companyData.supplier_type === "select") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Select both Type and Payment Term ",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}

		paymentTermColumns &&
			Object.keys(paymentTermColumns).forEach((col) => {
				apibody[col] = companyData[col];
			});
		apibody["is_active"] = companyData.is_active;
		if (e.target.id === "approve") apibody["is_approved"] = true;
		await updateSupplier(apibody);
	};

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const handleFullDataClick = () => {
		navigate(
			`/${rootProtectedPath}/${services.supplier_dashboard.path}/supplier_info?${supplierIdParamName}=${companyData.id}`
		);
	};
	const handleApproveClick = async () => {
		if (companyData?.is_approved === false) await updateSupplier({ is_approved: true });
		else {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Supplier is Already Approved ",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	return (
		<Box component="form" onSubmit={onFormSubmit} sx={{ maxWidth: "1100px", margin: "auto" }}>
			{/*  */}
			<Box direction="row" width="100%" justifyContent="space-around" flexWrap="wrap">
				<Box display="flex" flexWrap="wrap" pt={2} rowGap={2} columnGap={4}>
					<Stack className="vendor-input" sx={formStack} spacing={1}>
						<InputLabel>
							Supplier Type<span>*</span>:
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={companyData && companyData.supplier_type ? companyData.supplier_type : "select"}
							disabled={modalEditable}
							onChange={(e) => setCompanyData({ ...companyData, supplier_type: e.target.value })}
							size="small"
							required>
							<MenuItem value="select">Select</MenuItem>
							<MenuItem value="FOB">FOB</MenuItem>
							<MenuItem value="JOB_WORKER">JOB WORKER</MenuItem>
							<MenuItem value="FABRIC">FABRIC</MenuItem>
							<MenuItem value="TRIMS">TRIMS</MenuItem>
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={1}>
						<InputLabel>
							Payment Term<span>*</span>:
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={companyData && companyData.payment_term ? companyData.payment_term : "select"}
							disabled={modalEditable}
							onChange={(e) => setCompanyData({ ...companyData, payment_term: e.target.value })}
							size="small"
							required>
							<MenuItem value="select">Select</MenuItem>
							<MenuItem value="90_DAYS_OC">90 Days OC</MenuItem>
							<MenuItem value="60_DAYS_OC">60 Days OC</MenuItem>
							<MenuItem value="45_DAYS_OC">45 Days OC</MenuItem>
							<MenuItem value="30_DAYS_OC">30 Days OC</MenuItem>
							{/* <MenuItem value="LC">LC</MenuItem> */}
							<MenuItem value="ADVANCE_PAYMENT">ADVANCE_PAYMENT</MenuItem>
						</Select>
						{/* <TextField
							size="small"
							type="number"
							disabled={modalEditable}
							value={companyData?.payment_term}
							onChange={(e) => setCompanyData({ ...companyData, payment_term: e.target.value })}
							placeholder="Enter Jobworker Term"
							required
						/> */}
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={1}>
						<InputLabel>
							Payment Mode<span>*</span>:
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={
								companyData && companyData[paymentTermColumns.payment_mode.key]
									? companyData[paymentTermColumns.payment_mode.key]
									: "select"
							}
							disabled={modalEditable}
							onChange={(e) =>
								setCompanyData({
									...companyData,
									[paymentTermColumns.payment_mode.key]: e.target.value,
								})
							}
							size="small"
							required>
							<MenuItem value="select">Select</MenuItem>
							{Object.keys(paymentModeOptions)?.map((row) => {
								const data = paymentModeOptions[row];
								return <MenuItem value={data.key}>{data.name}</MenuItem>;
							})}
						</Select>
					</Stack>
					{/* {companyData && companyData[paymentTermColumns.payment_mode.key] === "NEFT" ? (
						<Stack className="vendor-input" sx={formStack} spacing={1}>
							<InputLabel>UTR Number:</InputLabel>
							<TextField
								size="small"
								type="number"
								disabled={modalEditable}
								value={companyData?.utr_no}
								onChange={(e) => setCompanyData({ ...companyData, utr_no: e.target.value })}
								placeholder="Enter UTR Number"
								required
								helperText="UTR No of transaction done for verification"
							/>
						</Stack>
					) : (
						<></>
					)} */}
					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							Supplier Status <span>*</span>:
						</InputLabel>
						<Box display="flex" alignItems="center">
							<Switch
								disabled={modalEditable}
								checked={companyData.is_active}
								onChange={(e) => setCompanyData({ ...companyData, is_active: !companyData.is_active })}
								inputProps={{ "aria-label": "controlled" }}
							/>
							<p>{companyData.is_active ? "Active" : "InActive"}</p>
						</Box>
					</Stack>
				</Box>
			</Box>

			<DialogActions sx={{ p: 3 }}>
				{modalEditable ? (
					<Button variant="outlined" onClick={() => setModalEditable(false)} sx={{ height: "40px" }}>
						Edit
					</Button>
				) : (
					<Button
						variant="outlined"
						onClick={() => {
							setModalEditable(true);
							setCompanyData(selectedSupplier);
						}}
						sx={{ height: "40px" }}>
						Cancel Edit
					</Button>
				)}
				{/* {companyData &&
					!companyData?.is_approved &&
					(modalEditable ? (
						<Button
							variant="contained"
							color="success"
							onClick={() => handleApproveClick(false)}
							sx={{ height: "40px" }}>
							Approve
						</Button>
					) : (
						<Button variant="contained" id="approve" type="submit" color="success" sx={{ height: "40px" }}>
							Save and Approve
						</Button>
					))} */}
				{!modalEditable ? (
					<Button variant="contained" type="submit">
						Save Changes
					</Button>
				) : (
					<Button variant="contained" onClick={handleFullDataClick}>
						View Full Data
					</Button>
				)}
			</DialogActions>
		</Box>
	);
}

export default SupplierInfoModal;
