import styled from "@emotion/styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";

export const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 3,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: "white",
		...theme.applyStyles("dark", {
			backgroundColor: "white",
		}),
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: "primary",
		...theme.applyStyles("dark", {
			backgroundColor: "primary",
		}),
	},
}));
