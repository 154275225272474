import { DownloadRounded } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, IconButton, Stack, Typography } from "@mui/material";
import axios from "axios";
import {
	agreementData,
	companyBasicDetailColumns,
	companyRegistredAddressDetailColumns,
	industryTypeValues,
	supplierAgreementTypes,
} from "data/supplierPageData";
import FileSaver from "file-saver";
import { useState, useEffect } from "react";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { GlobalState } from "../../../../context/GlobalContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
import StatusButtons from "components/SupplierPage/reusableComp/statusButton";
import moment from "moment";

const Agreements = ({ companyData, disableEdit, handleReject, updateSupplierStore, setCompanyData }) => {
	let [emptyColms, setEmptyColms] = useState([]);
	let [loading, setLoading] = useState(true);
	const [open, setOpen] = React.useState(false);
	const [file, setFile] = useState(null);
	const [uploadedFileData, setUploadedFileData] = useState([]);
	const [selectedFileType, setSelectedFileType] = useState();
	const { snackBar, snackBarOpen, setSnackBarOpen, setLoadingOpen, loginUser, setSnackBar } = GlobalState();

	const largeScaleCompanyAgreementUrl = "";
	const agreementDownloadFunctions = {
		[supplierAgreementTypes.vendor_agreement.key]: async () => {
			let apiBody = {};
			let arr = [];
			setLoading(true);
			setLoadingOpen(true);
			Object.keys(agreementData).forEach((col) => (apiBody[col] = companyData[col]));
			Object.keys(companyRegistredAddressDetailColumns).forEach((col) => {
				apiBody[col] = companyData[col];
				if (companyData[col] == "" || companyData[col] === 0)
					arr.push(companyRegistredAddressDetailColumns[col].name);
			});

			if (arr.length === 0) {
				try {
					let columns = {
						registred_office_address: "registred_office_address",
						registred_office_state: "registred_office_state",
						registred_office_city: "registred_office_city",
						registred_office_pin_code: "registred_office_pin_code",
						company_name: "company_name",
					};
					let body = Object.keys(columns).reduce((acc, row) => {
						return { ...acc, [row]: companyData[row] };
					}, {});
					body["date"] = moment().format("YYYY-MM-DD");
					setEmptyColms([]);

					await axios.post(`/api/supplier/genearte_agreement`, body, { responseType: "blob" }).then((res) => {
						FileSaver(res.data, "vendorAgreement");
						setLoadingOpen(false);
					});
				} catch (error) {
					console.log(error);
					setLoadingOpen(false);
				}
				setLoading(false);
			} else {
				setEmptyColms(arr);
				setLoading(false);
				setLoadingOpen(false);
			}
		},
		[supplierAgreementTypes.decleration_agreemnt.key]: () => {
			window.location.href =
				"https://storage.googleapis.com/rarebox_files/SupplierAgreementUploadFormats/DECLARATION_OF_MSME_STATUS.pdf";
		},
	};
	useEffect(() => {
		setUploadedFileData(companyData.supplierAgreement);
	}, [companyData]);

	const handleClickOpen = (file_type) => {
		setOpen(true);
		setSelectedFileType(file_type);
	};

	const handleClose = () => {
		setOpen(false);
		setFile(null);
	};

	const handleDownloadClick = async () => {
		let apiBody = {};
		let arr = [];
		setLoading(true);
		setLoadingOpen(true);
		Object.keys(agreementData).forEach((col) => (apiBody[col] = companyData[col]));
		Object.keys(companyRegistredAddressDetailColumns).forEach((col) => {
			apiBody[col] = companyData[col];
			if (companyData[col] == "" || companyData[col] === 0)
				arr.push(companyRegistredAddressDetailColumns[col].name);
		});

		if (arr.length === 0) {
			try {
				let columns = {
					registred_office_address: "registred_office_address",
					registred_office_state: "registred_office_state",
					registred_office_city: "registred_office_city",
					registred_office_pin_code: "registred_office_pin_code",
					company_name: "company_name",
				};
				let body = Object.keys(columns).reduce((acc, row) => {
					return { ...acc, [row]: companyData[row] };
				}, {});
				body["date"] = moment().format("YYYY-MM-DD");
				setEmptyColms([]);

				await axios.post(`/api/supplier/genearte_agreement`, body, { responseType: "blob" }).then((res) => {
					FileSaver(res.data, "vendorAgreement");
					setLoadingOpen(false);
				});
			} catch (error) {
				console.log(error);
				setLoadingOpen(false);
			}
			setLoading(false);
		} else {
			setEmptyColms(arr);
			setLoading(false);
			setLoadingOpen(false);
		}
	};
	const handleLargeScaleCompanyAgreementDownloadClick = async () => {
		window.location.href = largeScaleCompanyAgreementUrl;
	};

	function generatePuclicUrl(fileName, download) {
		const url = `https://storage.googleapis.com/rarebox_files/Supplier_Agreement_Files/${fileName}`;
		if (download) window.location.href = url;
		// return url;
	}

	const handleAgreementUploadToDB = async (newFileName, url) => {
		let body = {};
		const isAgreementPresent = uploadedFileData?.filter((row) => row.type === selectedFileType);
		if (isAgreementPresent?.length > 0) {
			body = {
				...isAgreementPresent[0],
				name: newFileName,
				url,
			};
		} else {
			body = {
				name: newFileName,
				type: selectedFileType,
				url,
				supplier_id: loginUser.id,
			};
		}
		setLoadingOpen(true);
		try {
			await axios.post("/api/supplier/agreement_to_sql", body).then((res) => {
				const newAllFiles = body?.id
					? uploadedFileData?.map((row) => {
							if (row.id === body.id) return body;
							else return row;
					  })
					: [...uploadedFileData, res.data];
				setUploadedFileData(newAllFiles);
				setCompanyData({ ...companyData, supplierAgreement: newAllFiles });

				setSnackBarOpen(true);
				setSnackBar({ ...snackBar, message: "Successfully uploaded file", severity: "success" });
			});
		} catch (err) {
			console.log("err", err);
			setSnackBarOpen(true);
			setSnackBar({ ...snackBar, message: "Unable to upload file ", severity: "error" });
		}
		setLoadingOpen(false);
		setFile(null);
		setSelectedFileType("");
	};

	// handle resume upload htmlFor join us form
	const handleFileUpload = async () => {
		let form = new FormData();
		form.append("files", file);
		form.append("mobile_no", loginUser.mobile_no);
		form.append("name", loginUser.name);
		setLoadingOpen(true);
		handleClose();
		try {
			await axios
				.post("/api/supplier/upload_supplier_agreement", form, {
					headers: { "Content-Type": "multipart/form-data" },
				})
				.then((res) => {
					handleAgreementUploadToDB(res.data[0].name, res.data[0].publicUrl);
				});
		} catch (err) {
			setSnackBarOpen(true);
			setSnackBar({ ...snackBar, message: "Unable to upload file in bucket", severity: "error" });
			setLoadingOpen(false);
		}
	};

	const handleFileChange = (e) => {
		const newFile = e.target.files[0];
		setFile(newFile);
	};

	const handleRejectedFactoryButton = (type) => {
		const rejected = companyData?.supplierApproval.filter((data) => data.type === type)[0];
		handleReject(rejected);
	};

	const logeduser = loginUser.role?.FINANCE_VIEWER ? true : false;
	const approved = companyData?.supplierApproval.filter((data) => data.type === "vendor_agreement")[0].approved;
	return (
		<Box>
			<Stack direction="row" width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
				<Box flexGrow={1}>
					<Typography variant="h4">Agreements</Typography>
					<Divider orientation="horizontal" variant="fullWidth" />
				</Box>
				<Stack direction="row">
					<StatusButtons
						handleReject={() => handleRejectedFactoryButton("vendor_agreement")}
						handleApproval={() => updateSupplierStore("vendor_agreement")}
						disableEdit={disableEdit}
						handleEdit={() => {}}
						editButton={false}
						otherAccess={!logeduser}
						buttonName="Edit"
						approved={approved}
						remark={
							companyData?.supplierApproval.filter((data) => data.type === "vendor_agreement")[0].remark
						}
					/>
				</Stack>
			</Stack>

			{/* vendor Agreement */}

			{companyData &&
				uploadedFileData &&
				Object.keys(supplierAgreementTypes)?.map((file_key) => {
					let agreementData = supplierAgreementTypes[file_key];

					let arr = uploadedFileData?.filter((fileData) => fileData.type === file_key);
					const fileData = arr?.length ? arr[0] : null;
					console.log("fileData", fileData, uploadedFileData);
					return (
						<Stack
							border={`1px solid ${grey[300]}`}
							sx={{ margin: "10px 0px" }}
							borderRadius={2}
							p={1}
							spacing={4}
							display={
								typeof agreementData.display === "function" &&
								agreementData.display(
									companyData ? companyData[companyBasicDetailColumns.industry_type.key] : ""
								)
									? "block"
									: !agreementData.display
									? "block "
									: "none"
							}>
							<Typography variant="h6" width="100%">
								{agreementData.name}
							</Typography>
							{!disableEdit && (
								<Stack spacing={1}>
									<Box
										flexWrap={"wrap"}
										display="flex"
										width="100%"
										columnGap={5}
										rowGap={1}
										alignItems="center">
										<Typography width="maxContent">Download {agreementData.name}</Typography>
										<Button
											disabled={
												approved //||
												// companyData[companyBasicDetailColumns.industry_type.key] ===
												// 	industryTypeValues.large_scale
											}
											startIcon={<DownloadRounded />}
											variant="contained"
											sx={{ width: 150 }}
											onClick={
												agreementDownloadFunctions && agreementDownloadFunctions[file_key]
													? () => {
															agreementDownloadFunctions[file_key]();
													  }
													: () => {}
											}>
											{"Download"}
										</Button>
										{/* <Button
								disabled={true}
								startIcon={<DownloadRounded />}
								variant="contained"
								sx={{ width: 180 }}
								onClick={handleDownloadClick}>
								{"Download"}
							</Button> */}
										<Stack>
											{emptyColms.length > 0 && (
												<Typography variant="body1" fontSize={16} width="100%" color="error">
													fill these details to download :
													{emptyColms?.map((row) => `${row} `)}
												</Typography>
											)}
										</Stack>
									</Box>
								</Stack>
							)}
							{!disableEdit && (
								<Stack spacing={1}>
									<Box display="flex" width="100%" columnGap={5} alignItems="center">
										Please upload the signed agreement file.
										<Button
											variant="outlined"
											disabled={approved}
											onClick={() => handleClickOpen(file_key)}>
											Upload file
										</Button>
									</Box>
								</Stack>
							)}
							{fileData ? (
								<Box>
									<TableContainer sx={{ width: "650px" }} component={Paper}>
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>File Name</TableCell>
													<TableCell>Last Update</TableCell>
													<TableCell align="right">Download</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow
													key={fileData?.name}
													sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
													<TableCell component="th" scope="row">
														{fileData?.name}
													</TableCell>
													<TableCell component="th" scope="row">
														{moment(fileData?.updatedAt).format("DD-MM-YYYY")}
													</TableCell>
													<TableCell align="right">
														<IconButton
															sx={{ cursor: "pointer" }}
															onClick={() => generatePuclicUrl(fileData?.name, true)}>
															<DownloadRounded />
														</IconButton>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
							) : (
								disableEdit && (
									<Typography
										variant="h6"
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}
										color={"red"}
										width="100%">
										Signed agreement is not uploaded
									</Typography>
								)
							)}
						</Stack>
					);
				})}

			<React.Fragment>
				<Dialog
					// sx={{ width: "300px" }}
					maxWidth="sm"
					open={open}
					onClose={handleClose}
					aria-labelledby="responsive-dialog-title">
					<DialogTitle id="responsive-dialog-title">{"Upload File"}</DialogTitle>
					<DialogContent>
						<input type="file" required id="file" onChange={handleFileChange} />
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" autoFocus onClick={handleClose}>
							Cancel
						</Button>
						<Button disabled={file === null} variant="contained" onClick={handleFileUpload} autoFocus>
							Upload
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		</Box>
	);
};

export default Agreements;
