import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { Chip, DialogActions, MenuItem, Select, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { admin, custom, departments, externalTypes, services, userTypes } from "data/authData";

const AddRole = ({ submitFunction, options }) => {
	const [roleToAdd, setRoleToAdd] = useState("select");

	const handleSelectChange = (value) => {
		// setRoleToAdd(value);
		submitFunction(value);
	};
	return (
		<Box sx={{ display: "flex", columnGap: 2 }}>
			<Select
				MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
				value={roleToAdd}
				sx={{ width: 250 }}
				onChange={(e) => handleSelectChange(e.target.value)}
				size="small"
				required>
				<MenuItem value="select">Select</MenuItem>
				{options &&
					Object.keys(options).map((role) => {
						const roleData = options[role];
						return (
							<MenuItem key={roleData.key} value={roleData.key}>
								{roleData.name}
							</MenuItem>
						);
					})}
			</Select>
		</Box>
	);
};

function UserInfoModal({ allUsers, setAllUsers, setNewUserModalOpen }) {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [user, setUser] = useState({ type: userTypes.internal });
	const [roles, setRoles] = useState({});
	const [month, setMonth] = useState(dayjs());

	const onFormSubmit = async (e) => {
		e.preventDefault();
		if (!Object.keys(roles).length) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Add atlest one role",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		const apibody = {
			...user,
			role: roles,
			password:
				user.type && user.type === userTypes.external
					? user.name.split(" ").join("_") + "_" + user.mobile_no
					: user.employee_id + "_" + user.mobile_no,
		};
		if (user.type === userTypes.external) apibody["other_data"] = { root_path: Object.keys(roles)[0] };
		await axios
			.post(`/api/auth/signup`, apibody)
			.then((res) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "User Added",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setAllUsers([res.data.user, ...allUsers]);
				// setSelectedUser();
				setNewUserModalOpen(false);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message:
						err.response && err.response.data ? err.response.data.error : "Unable to update User Details ",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	const handleRoleDelete = (roleToDelete) => {
		return () => {
			let obj = { ...roles };
			delete obj[roleToDelete];
			setRoles({ ...obj });
		};
	};
	const handleAddAdminFunction = () => {
		const obj = roles;
		obj[admin] = true;
		setRoles({ ...obj });
	};

	const handleAddNewRole = (roleToAdd) => {
		if (roles[roleToAdd]) {
			setSnackBarOpen(true);
			setSnackBar({ ...snackBar, message: "Role already Exists", severity: "error" });
		} else {
			let obj = roles;
			obj[roleToAdd] = true;
			setRoles({ ...obj });
		}
	};

	return (
		<Box component="form" onSubmit={onFormSubmit} sx={{ maxWidth: "1100px", margin: "auto" }}>
			{/* form */}
			<Box direction="row" width="100%" justifyContent="space-around" flexWrap="wrap">
				<Box
					display="flex"
					flexWrap="wrap"
					rowGap={2}
					className="vendorInputForm"
					justifyContent="space-between">
					<Stack sx={formStack} spacing={1}>
						<InputLabel>User Type:</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={user?.type}
							sx={{ width: 250 }}
							onChange={(e) => setUser({ ...user, type: e.target.value })}
							size="small"
							required>
							<MenuItem value={userTypes.internal}>INTERNAL</MenuItem>
							<MenuItem value={userTypes.external}>EXTERNAL</MenuItem>
						</Select>
					</Stack>
					{user.type !== "EXTERNAL" && (
						<Stack sx={formStack} spacing={1}>
							<InputLabel>Employee ID:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={user?.employee_id}
								onChange={(e) => setUser({ ...user, employee_id: e.target.value })}
								placeholder="Enter employee_id"
								required
							/>
						</Stack>
					)}

					<Stack sx={formStack} spacing={1}>
						<InputLabel>Name:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={user?.name}
							onChange={(e) => setUser({ ...user, name: e.target.value })}
							placeholder="Enter name"
							required
						/>
					</Stack>
					{user.type !== userTypes.external && (
						<Stack sx={formStack} spacing={1}>
							<InputLabel>Department:</InputLabel>
							<TextField
								size="small"
								type="text"
								value={user?.department}
								onChange={(e) => setUser({ ...user, department: e.target.value })}
								placeholder="Enter department"
								required
							/>
						</Stack>
					)}
					{user.type !== userTypes.external && (
						<Stack sx={formStack} spacing={1}>
							<InputLabel>Designation</InputLabel>
							<TextField
								size="small"
								type="text"
								value={user?.designation}
								onChange={(e) => setUser({ ...user, designation: e.target.value })}
								placeholder="Enter designation"
								required
							/>
						</Stack>
					)}
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Mobile No</InputLabel>
						<TextField
							size="small"
							type="text"
							value={user?.mobile_no}
							onChange={(e) => setUser({ ...user, mobile_no: e.target.value })}
							placeholder="Enter designation"
							required
						/>
					</Stack>

					<Stack sx={formStack} spacing={1}>
						<InputLabel>Email:</InputLabel>
						<TextField
							size="small"
							type="email"
							value={user?.email}
							onChange={(e) => setUser({ ...user, email: e.target.value })}
							placeholder="Enter email"
							required
						/>
					</Stack>

					<Stack width="95%">
						<Typography variant="h6" width="100%">
							User Access
						</Typography>
						<Box key={roles} display="flex" p={1} columnGap={2}>
							{roles &&
								Object.keys(roles).map((role) => {
									return <Chip key={role} label={role} onDelete={handleRoleDelete(role)} />;
								})}
						</Box>
						{user.type !== userTypes.external ? (
							<Stack direction="row" flexWrap="wrap" justifyContent="space-between" rowGap={1}>
								<Box width="30%">
									<Typography variant="subtitle1">Department Level</Typography>
									<AddRole submitFunction={handleAddNewRole} options={departments} />
								</Box>
								<Box width="30%">
									<Typography variant="subtitle1">Service Level</Typography>

									<AddRole submitFunction={handleAddNewRole} options={services} />
								</Box>
								<Box width="30%">
									<Typography variant="subtitle1">Custom Tags</Typography>

									<AddRole submitFunction={handleAddNewRole} options={custom} />
								</Box>
								<Box width="30%">
									<Button variant="outlined" onClick={handleAddAdminFunction}>
										Add Admin
									</Button>
								</Box>
							</Stack>
						) : (
							<Box width="30%">
								<Typography variant="subtitle1">Custom Tags</Typography>

								<AddRole submitFunction={handleAddNewRole} options={externalTypes} />
							</Box>
						)}

						<Typography variant="subtitle1" pt={5}>
							Password Format :{" "}
							{user.type === userTypes.external ? "userName_phoneNO" : "employeeId_PhoneNO"}
						</Typography>
					</Stack>
				</Box>
			</Box>
			{/* form Actions */}
			<DialogActions sx={{ p: 3 }}>
				<Button variant="contained" type="submit">
					Add User
				</Button>
			</DialogActions>
		</Box>
	);
}

export default UserInfoModal;
