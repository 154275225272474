import styled from "@emotion/styled";
import { AddRounded, DeleteRounded, SetMeal } from "@mui/icons-material";
import { Box, Button, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import { PimState } from "context/PimContext";
import moment from "moment";
import { useEffect, useState } from "react";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});
const contactFormStack = {
	width: { xs: "80%", sm: "40%", md: "30%" },
};

const costWidth = 80;
const createdDateWidth = 120;
const CostItem = ({ arr, setArr, currentCost }) => {
	const [cost, setCost] = useState(currentCost);

	useEffect(() => {
		if (arr.length > 0)
			setArr([
				...arr.map((row) => {
					if (row.cogs === currentCost.cogs) {
						return cost;
					} else return row;
				}),
			]);
	}, [cost]);

	const handleDeleteColorClick = () => {
		setArr(arr.filter((row) => row.cogs !== currentCost.cogs));
	};

	return (
		<Box display="flex" flexWrap="wrap" columnGap={2} width="60%">
			<Stack sx={{ width: costWidth }} spacing={0}>
				{/* <InputLabel>COGS:</InputLabel> */}
				{/* <TextField
					value={cost.cogs}
					onChange={(e) => setCost({ ...cost, cogs: parseFloat(e.target.value) })}
					MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
					size="small"
					required
					disabled={true}
				/> */}
				<Typography variant="h6">{cost.cogs}</Typography>
			</Stack>
			<Stack sx={{ width: createdDateWidth }} spacing={0}>
				{/* <InputLabel>CREATED DATE:</InputLabel> */}
				<Typography variant="h6">{cost.created_date}</Typography>
			</Stack>
			<Box>
				<IconButton variant="contained" onClick={handleDeleteColorClick}>
					<DeleteRounded />
				</IconButton>
			</Box>
		</Box>
	);
};

const CogsSection = ({ allCogs, setAllCogs }) => {
	const defNewCogs = { cogs: 0, created_date: moment().format("DD/MM/YYYY") };
	const [newCogs, setNewCogs] = useState(defNewCogs);
	const { attributeValues } = PimState();
	const { setSnackBarOpen, setSnackBar, snackBar } = GlobalState();

	const handleAddColorClick = (obj) => {
		if (obj.cogs == 0) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Enter Cogs greater than 0",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		const isPresent = allCogs.filter((row) => row.cogs === newCogs.cogs);
		if (isPresent.length > 0) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Cogs is already present",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setAllCogs([...allCogs, newCogs]);
		setNewCogs(defNewCogs);
	};

	return (
		<Box width="100%">
			<Stack spacing={1}>
				{allCogs.length > 0 && (
					<Box display="flex" flexWrap="wrap" columnGap={2} width="60%" pt={2}>
						<Stack sx={{ width: costWidth }} spacing={0}>
							<Typography variant="subtitle1" fontSize={14} color={grey[400]}>
								COST
							</Typography>
						</Stack>
						<Stack sx={{ width: createdDateWidth }} spacing={0} color={grey[400]}>
							<Typography variant="subtitle1" fontSize={14}>
								CREATED DATE
							</Typography>
						</Stack>
					</Box>
				)}
				{allCogs?.map((obj) => {
					return <CostItem arr={allCogs} setArr={setAllCogs} currentCost={obj} />;
				})}
			</Stack>

			<Box display="flex" columnGap={2} pt={3}>
				<TextField
					value={newCogs.cogs}
					onChange={(e) => setNewCogs({ ...newCogs, cogs: parseFloat(e.target.value) })}
					size="small"
					sx={{ width: 200 }}
					required
				/>

				<Button variant="contained" onClick={() => handleAddColorClick(newCogs)}>
					Add Cost
				</Button>
			</Box>
		</Box>
	);
};

export default CogsSection;
