import {
	Box,
	Breadcrumbs,
	Button,
	Divider,
	ListItem,
	Typography,
	Link,
	CircularProgress,
	Avatar,
	List,
	IconButton,
	InputBase,
} from "@mui/material";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { GlobalState } from "context/GlobalContext";
import FolderIcon from "@mui/icons-material/Folder";
import ArticleIcon from "@mui/icons-material/Article";

import React, { useEffect, useState } from "react";
import { CloudUpload } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { AuthState } from "context/AuthContext";
import Paper from "@mui/material/Paper";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

export const CommonFolderUI = ({ commonFolders, handleFolderClick, isFolder, search }) => {
	console.log("commonFolders", commonFolders);
	let filterData =
		commonFolders.length > 0 &&
		commonFolders.filter(
			(folder) => folder.name !== "" && folder?.name.toLowerCase().includes(search.toLowerCase())
		);
	return (
		<div>
			<Box
				width={"100%"}
				display="flex"
				flexWrap="wrap"
				// pt={3}
				justifyContent={"flex-start"}
				// justifyItems={"center"}
				sx={{ margin: "10px 0px" }}
				rowGap={3}
				className="vendorInputForm"
				columnGap={3}>
				{commonFolders.length > 0 &&
					filterData.map((folder) => (
						<List>
							<Paper
								sx={{
									minWidth: "100px",
									height: "50px",
									display: "flex",
									padding: "0px 5px",
									cursor: "pointer",
								}}
								onClick={() => handleFolderClick(folder)}
								elevation={3}>
								<ListItem
									disablePadding
									sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
									<Avatar sx={{ width: "30px", height: "30px", minWidth: "0px", margin: "7px" }}>
										{folder.isFolder ? <FolderIcon /> : <ArticleIcon />}
									</Avatar>
									{folder.name}
								</ListItem>
							</Paper>
						</List>
					))}
			</Box>
		</div>
	);
};

function DataHubStoreView() {
	const [folders, setFolders] = useState([]);
	const [breadCrumb, setBreadCrumb] = useState(["HOME"]);
	const [loading, setLoading] = useState(false);
	const [fileUpload, setFileUpload] = useState(false);
	const [files, setFiles] = useState({});
	const [isFolder, setIsFolder] = useState(true);
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const [search, setSearch] = useState("");
	const navigate = useNavigate();
	const { user } = AuthState();

	const getFolders = async () => {
		setLoading(true);
		setLoadingOpen(true);
		await axios
			.get(`/api/ebo_store_dh/get-user-contents`)
			.then((res) => {
				console.log(res.data);
				if (res.status === 200) {
					const foldersList = res.data;
					setFolders([...foldersList]);
					setLoading(false);
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err?.response?.data?.message ? err.response.data.message : err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
				setLoadingOpen(false);
			});
	};

	useEffect(() => {
		getFolders();
	}, []);

	const getFolderContent = async (folderName) => {
		setLoading(true);
		setLoadingOpen(true);
		// console.log("breadCrumb", breadCrumb);
		let newArr = [];
		newArr = [...breadCrumb];

		// newArr.shift();
		if (!newArr.includes(folderName)) newArr.push(folderName);
		// let param = newArr.join("/") + "/";
		// param = param + folderName + "/";

		// console.log("PARAM", param);
		await axios
			.post(`/api/ebo_store_dh/get-files-store`, { newArr })
			.then((res) => {
				if (res.status === 200) {
					const { folders } = res.data;
					console.log("folders", folders);
					setFolders([...folders]);
					setLoading(false);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
			});
		setLoadingOpen(false);
	};
	const handleBreadCrumb = (value) => {
		let newArr = breadCrumb;
		newArr.splice(breadCrumb.indexOf(value) + 1, breadCrumb.length);
		setBreadCrumb([...newArr]);
		value === "HOME" ? getFolders() : getFolderContent(value);
	};

	const handleFileUpload = async (files) => {
		let form = new FormData();
		form.append("file", files);
		form.append("folderName", JSON.stringify(breadCrumb));
		setFiles({});
		setFileUpload(true);
		setLoadingOpen(true);
		console.log("modifiedFile1");
		await axios
			.post("/api/ebo_store_dh/upload-files", form, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((res) => {
				console.log(res.data);
				let { name, publicUrl } = res.data;
				if (name && publicUrl) {
					setFileUpload(false);
				}
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "File uploaded successfully",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			})
			.catch((err) => {
				setFileUpload(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				return "Error in file Upload";
			});
		getFolderContent(breadCrumb[breadCrumb.length - 1]);
		setLoadingOpen(false);
	};

	// helper function to handle file uploads
	async function handleFileChange(e) {
		const formattedDateTime = moment().format("DD-MM-YYYY HH:mm:ss");
		const newFile = e.target.files[0];
		const extension = newFile?.name.split(".").pop();
		let newName = newFile?.name.substring(0, newFile?.name.lastIndexOf("."));
		console.log("new Name", newName);
		newName = newName + "-" + formattedDateTime + "." + extension;
		const modifiedFile = new File([newFile], newName, { type: newFile.type });
		await handleFileUpload(modifiedFile);
	}

	function generatePuclicUrl(fileName) {
		let newArr = breadCrumb;
		newArr.shift();
		newArr.push(fileName);
		console.log("NEWARR DOWNLOAD", newArr);
		const url = `https://storage.googleapis.com/ebo_stores/` + newArr.join("/");
		console.log("url", url);
		window.open(url, "_blank");
	}

	const handleFolderClick = (folder) => {
		setBreadCrumb([...breadCrumb, folder.name]);
		getFolderContent(folder.name);
		// console.log("folder.isFile", folder.name);
		if (!folder.isFolder) {
			generatePuclicUrl(folder.name);
		}
	};
	console.log(breadCrumb);
	return (
		<Box>
			<Box
				direction="row"
				width="100%"
				justifyContent="space-around"
				flexWrap="wrap"
				maxWidth="1200px"
				margin={"auto"}>
				<Stack spacing={0} margin="auto" direction={"row"} mt={3}>
					<Box width="100%">
						<Box display={"flex"} justifyContent={"space-between"}>
							<Typography variant="h4" pt={0}>
								STORES
							</Typography>

							<Button
								component="label"
								role={undefined}
								variant="contained"
								tabIndex={-1}
								sx={{ display: breadCrumb.length === 4 ? "" : "none" }}
								// disabled={fileUpload || breadCrumb.length === 1 || disableUpload}
								startIcon={fileUpload ? <CircularProgress color="inherit" /> : <CloudUpload />}>
								{fileUpload ? "Uploading" : "Upload file"}
								<VisuallyHiddenInput
									type="file"
									onClick={(e) => (e.target.value = null)}
									onChange={handleFileChange}
								/>
							</Button>
						</Box>
						{/* <Divider sx={{ width: "100%" }}></Divider> */}
						<Box display={"flex"} justifyContent={"space-between"}>
							<Breadcrumbs aria-label="breadcrumb" sx={{ mt: 3 }}>
								{breadCrumb.map((value) => (
									<Link
										key={value}
										underline="hover"
										color="inherit"
										sx={{ fontSize: 20, cursor: "pointer" }}
										onClick={() => handleBreadCrumb(value)}>
										{value}
									</Link>
								))}
							</Breadcrumbs>
							<Box mt={2} display={"flex"}>
								<Paper
									component="form"
									sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 300 }}
									elevation={1}>
									<IconButton type="button" disabled sx={{ p: "10px" }} aria-label="search">
										<SearchIcon />
									</IconButton>
									<InputBase
										sx={{ ml: 1, flex: 1 }}
										placeholder="Type to search"
										inputProps={{ "aria-label": "Search Products" }}
										value={search}
										onChange={(e) => setSearch(e.target.value)}
										required
									/>
								</Paper>
							</Box>
						</Box>
						<Divider sx={{ width: "100%", marginTop: "30px" }}></Divider>
					</Box>
				</Stack>
				<CommonFolderUI
					commonFolders={folders}
					handleFolderClick={(folder) => handleFolderClick(folder)}
					isFolder={isFolder}
					search={search}
				/>
			</Box>
		</Box>
	);
}

export default DataHubStoreView;
