export const COL_NAMES = [
	"Month",
	"Store Name",
	"City",
	"Qty.",
	"MRP Sales",
	"Discount",
	"Discount %",
	"Realised Value",
	"Margin on Realised",
	"Margin Value",
	"Receivable",
	"Billing GST",
	"Total Receivable",
	"Received through Card",
	"Balance Receivable",
	"Invoice Value",
	"DN/CN",
];

export const COL_KEYS = [
	"MONTH",
	"STORE_NAME",
	"CITY",
	"BILL_QUANTITY",
	"MRP_VALUE",
	"TOTAL_DISCOUNT",
	"DISCOUNT_PERCENT",
	"REALISED_VALUE",
	"MARGIN_RATE_PERCENT",
	"MARGIN_VALUE",
	"RECEIVABLE",
	"BILLING_GST",
	"TOTAL_RECEIVABLE",
	"RECEIVABLE_THROUGH_CARD",
	"BALANCE_RECEIVABLE",
	"INVOICE_VALUE",
	"DN_CN",
];
