import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import grey from "@mui/material/colors/grey";
import { roundDecimalValueUptoTwo } from "utils/roundDecimalValue";
import { Button, IconButton } from "@mui/material";
import { DownloadRounded } from "@mui/icons-material";
import moment from "moment";

const DisplayTable = ({ rows, onRowClick, downloadUrlColumn, columns }) => {
	const cellStyle = {
		// width: "30%",
		// borderRight: tableBorder,
		textTransform: "uppercase",
		width: "100px",
	};

	if (rows[0]) {
		return (
			<TableContainer sx={{ borderRadius: "4px" }}>
				<Table size="small">
					<TableHead>
						<TableRow>
							{Object.keys(columns).map((key) => (
								<TableCell key={key} sx={cellStyle} component="th" scope="row">
									{columns[key].name}
								</TableCell>
							))}
							{downloadUrlColumn && (
								<TableCell component="th" download sx={cellStyle} scope="row">
									Download
								</TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows &&
							rows.map((row, index) => (
								<TableRow
									onClick={() => {
										if (typeof onRowClick === "function") onRowClick(row);
									}}
									key={index}
									sx={{
										"&:last-child td, &:last-child th": { borderBottom: 0 },
										// "&:first-child td, &:first-child th": {}
									}}>
									{Object.keys(columns).map((key) => (
										<TableCell component="th" scope="row" key={key} sx={cellStyle}>
											{columns[key].isDate
												? moment(row[key]).format("DD-MM-YYYY")
												: Number(row[key]) === row[key] && row[key] % 1 !== 0
												? roundDecimalValueUptoTwo(row[key])
												: row[key]}
										</TableCell>
									))}
									{downloadUrlColumn && (
										<TableCell component="th" download scope="row" sx={cellStyle}>
											<IconButton component="a" href={row[downloadUrlColumn]} download>
												<DownloadRounded />
											</IconButton>
										</TableCell>
									)}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	} else return <div>Failed to Display Table</div>;
};

export default DisplayTable;
