import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	Stack,
	InputLabel,
	Button,
	TextField,
	CircularProgress,
	Typography,
	Chip,
	Tooltip,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import { isStartDateGraterThanEndDate } from "utils/isStartDateGreaterThanEndDate";
import axios from "axios";
import moment from "moment";
import Filter from "./Filter/Filter";
import { InLakhsDisplay } from "components/ReusableComponents/InLakhsDisplay";
import InfoModal from "./InfoModal/InfoModal";

const valueColumnName = {
	gin_value: "SALE",
	collection: "COLLECTION",
	difference: "DIFF",
};

const stickyColumn = {
	position: "sticky",
	left: 0,
	background: "inherit",
	boxShadow: 1,
	// borderRight: "2px solid black",
	// zIndex: "9999 !important",
};

const storeLevelTotalColName = "Store Total";
const posStoreCollDataSQl = "pos_store_Data_sql";
const posBasicDetailsMapping = {
	store_name: { name: "STORE NAME", key: "NAME", sqlCol: "store_name" },
	store_short_name: { name: "STORE NAME", key: "SHRTNAME", sqlCol: "store_short_name" },
	date: { name: "DATE", key: "BILL_DATE", sqlCol: "date" },
	store_code: { name: "STORE CODE", key: "STORE_CODE", sqlCol: "store_code" },
};
const posStoreStageColumns = {
	reason: { name: "Reason", key: "reason" },
	status: { name: "Status", key: "status" },
	published: { name: "Published", key: "is_published" },
	published_by: { name: "Published", key: "published_by" },
	published_date: { name: "Published", key: "published_date" },
};
const posValuecolumnMapping = {
	card_gin_val: { name: valueColumnName.gin_value, key: "POS_CARD_VALUE_SALE_VALUE" },
	card_collection: { name: valueColumnName.collection, key: "CARD_COLLECTION_AMT" },
	card_difference: { name: valueColumnName.difference, key: "CARD_DIFF" },
	upi_gin_val: { name: valueColumnName.gin_value, key: "POS_UPI_VALUE_SALE_VALUE" },
	upi_collection: { name: valueColumnName.collection, key: "UPI_COLLECTION_AMT" },
	upi_difference: { name: valueColumnName.difference, key: "UPI_DIFF" },
	amex_gin_val: { name: valueColumnName.gin_value, key: "POS_AMEX" },
	amex_collection: { name: valueColumnName.collection, key: "AMEX_COLLECTION_AMT" },
	amex_difference: { name: valueColumnName.difference, key: "AMEX_DIFF" },
	// cash_gin_val: { name: valueColumnName.gin_value, key: "amex_gin_val" },
	// cash_collection: { name: valueColumnName.collection, key: "amex_collection" },
	// cash_difference: { name: valueColumnName.difference, key: "amex_collection" },
	total_gin_val: { name: valueColumnName.gin_value, key: "TOTAL_POS_AMT" },
	total_collection: { name: valueColumnName.collection, key: "TOTAL_COLLECTION_AMT" },
	total_difference: { name: valueColumnName.difference, key: "TOTAL_DIFFERENCE" },
};
const notSetteled = "NOT_SETTLED";
const settled = "SETTLED";
const defFilterColumns = {
	status: {
		name: "Status",
		key: posStoreStageColumns.status.key,
		options: [
			// {
			// 	name: "Settled More",
			// 	key: "setteled_more",
			// },
			{
				label: "Status(ALL)",
				key: "select",
			},
			{
				label: "Settled",
				key: settled,
			},
			{
				label: "Not Settled",
				key: notSetteled,
			},
		],
	},
	store_short_name: {
		name: "Stores",
		key: "store_short_name",
		width: 200,
		options: [],
	},
};
const totalColumnColor = grey[200];
const generateCellStyle = (bt, br, bb, bl, color) => {
	let cellStyle = {
		borderRight: `${br ? br : 1}px solid ${color ? color : grey[700]}`, // Border on the right side
		borderBottom: `${bb ? bb : 1}px solid ${color ? color : grey[700]}`, // Border on the bottom side
		borderTop: `${bt >= 0 ? bt : 1}px solid ${color ? color : grey[700]}`, // Border on the bottom side
		borderLeft: `${bl >= 0 ? bl : 1}px solid ${color ? color : grey[700]}`, // Border on the bottom side
		padding: "5px 5px",
		// boxSizing: "border-box",
	};
	// if (colMaster && col && colMaster[col].name === valueColumnName.difference)
	// 	cellStyle.borderRight = `2px solid ${grey[600]}`;
	return cellStyle;
};

const PaymentTable = () => {
	// Example data

	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [tableRows, setTableRows] = useState();
	const [filter, setFilter] = useState({ status: "select", store_short_name: "select" });
	const [storeDateCount, setStoreDateCount] = useState({});
	const [columns, setColumns] = useState(defFilterColumns);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedStoreRow, setSelectedStoreRow] = useState(false);
	const [allShortNames, setAllShortNames] = useState(false);
	const [masterBQ, setMasterBQ] = useState({});
	const [download, setDownload] = useState(false);
	// const [totalColData, setTotlaColData] = useState(false);

	// useEffect(() => {
	// 	const fetch = async () => {
	// 		let obj = {};
	// 		await axios
	// 			.get(`/api/pos_reco/get_store_master_bq`)
	// 			.then((res) => {
	// 				console.log("USEEFFECT RES", res.data);
	// 				for (let i of res.data) {
	// 					// console.log(i);
	// 					// let obj = {};
	// 					obj[i["SHRTNAME"]] = {
	// 						NEW_SHRTNAME: i["NEW_SHRTNAME"],
	// 						DATE: i["STORE_CLOSURE_DATE"],
	// 						NEW_NAME: i["NEW_NAME"],
	// 					};
	// 					// console.log(i["SHRTNAME"], i["NEW_SHRTNAME"]);
	// 				}
	// 				setMasterBQ(obj);
	// 				console.log("MASTER_ARRAY", obj);
	// 			})
	// 			.catch((err) => console.log("ERROR", err));
	// 	};
	// 	fetch();
	// }, []);

	// const mapBQMastertoResult = (bqarr) => {
	// 	let newBQArr = [];
	// 	console.log("masterBQ in for", masterBQ);
	// 	for (let i of bqarr) {
	// 		if (
	// 			masterBQ &&
	// 			masterBQ[i["SHRTNAME"]] &&
	// 			masterBQ[i["SHRTNAME"]]["NEW_SHRTNAME"] &&
	// 			masterBQ[i["SHRTNAME"]]["NEW_NAME"]
	// 		) {
	// 			let j = { ...i };
	// 			// console.log("CHIN TAPAK DUM DUM", i);
	// 			const date1 = i["BILL_DATE"];
	// 			const date2 = masterBQ[i["SHRTNAME"]]["STORE_CLOSURE_DATE"];
	// 			if (j["ISUPDATED"]) {
	// 				i["NAME"] = masterBQ[j["SHRTNAME"]]["NEW_NAME"];
	// 				i["SHRTNAME"] = masterBQ[j["SHRTNAME"]]["NEW_SHRTNAME"];
	// 				if (i["SHRTNAME"] == "RR-VASHI" || i["SHRTNAME"] == "D-SHIVERA-INORBIT VASHI")
	// 					console.log("CHIN TAPAK DUM DUM", i);
	// 			}
	// 		}
	// 		newBQArr.push(i);
	// 	}
	// 	console.log("newBQArr", newBQArr);
	// 	return newBQArr;
	// };

	const defDbNoColumns = {
		...Object.keys(posValuecolumnMapping).reduce((acc, row) => {
			acc[posValuecolumnMapping[row].key] = 0.0;
			return acc;
		}, {}),
	};
	const calculateTotalRowValues = (rows) => {
		let columns = defDbNoColumns;
		rows?.forEach((row) => {
			if (row[posBasicDetailsMapping.date.key] !== storeLevelTotalColName)
				Object.keys(columns).forEach((column) => {
					if (!isNaN(parseFloat(row[column]))) columns[column] = columns[column] + parseFloat(row[column]);
				});
		});
		console.log("columns", columns);
		return columns;
	};

	const calculatelAndAddStoreTotalColumns = (stores) => {
		let columns = { ...defDbNoColumns };

		return stores
			?.map((row, i, arr) => {
				// adding row data to store Totla
				// console.log("row", row);

				Object.keys(columns).forEach((column) => {
					if (!isNaN(parseFloat(row[column]))) columns[column] = columns[column] + parseFloat(row[column]);
				});
				// if(i < arr.length && )
				if (i < arr.length - 1) {
					if (
						row[posBasicDetailsMapping.store_code.key] !== arr[i + 1][posBasicDetailsMapping.store_code.key]
					) {
						let totalData = { ...columns };
						columns = { ...defDbNoColumns };
						return [
							row,
							{
								...totalData,
								[posBasicDetailsMapping.store_code.key]: row[posBasicDetailsMapping.store_code.key],
								[posBasicDetailsMapping.store_name.key]: row[posBasicDetailsMapping.store_name.key],
								[posBasicDetailsMapping.store_short_name.key]:
									row[posBasicDetailsMapping.store_short_name.key],
								[posBasicDetailsMapping.date.key]: storeLevelTotalColName,
							},
						];
					} else {
						return [row];
					}
				} else {
					let totalData = { ...columns };
					columns = { ...defDbNoColumns };
					return [
						row,
						{
							...totalData,
							[posBasicDetailsMapping.store_code.key]: row[posBasicDetailsMapping.store_code.key],
							[posBasicDetailsMapping.store_name.key]: row[posBasicDetailsMapping.store_name.key],
							[posBasicDetailsMapping.store_short_name.key]:
								row[posBasicDetailsMapping.store_short_name.key],
							[posBasicDetailsMapping.date.key]: storeLevelTotalColName,
						},
					];
				}
			})
			.flat();
	};

	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);
			return;
		}

		try {
			const bqStoreDataP = new Promise(async (resolve, reject) => {
				await axios
					.get(`/api/pos_reco/total_report?startDate=${startDate}&endDate=${endDate}`)
					.then((res) => {
						const updatedData = [
							...res.data,
							// ...res.data.map((row) => {
							// 	return {
							// 		...row,
							// 		[posBasicDetailsMapping.date.key]: "2024-07-10",
							// 	};
							// }),
						]
							.filter((row) => {
								if (row[posBasicDetailsMapping.store_code.key]) return row;
							})
							.sort((a, b) =>
								a[posBasicDetailsMapping.store_code.key].localeCompare(
									b[posBasicDetailsMapping.store_code.key]
								)
							);
						resolve(updatedData);
					})
					.catch((err) => reject(err));
			});
			const posStoreDailyCollP = new Promise(async (resolve, reject) => {
				await axios
					.get(`/api/pos_reco/store_daily_coll_data?startDate=${startDate}&endDate=${endDate}`)
					.then((res) => {
						resolve(res.data);
					})
					.catch((err) => reject(err));
			});

			const [bqDataArr, sqlDataArr] = await Promise.all([bqStoreDataP, posStoreDailyCollP]);
			// const newBqArr = mapBQMastertoResult(bqDataArr);
			console.log(sqlDataArr, "safds");
			const posDailySaleObj = await sqlDataArr.reduce((acc, row, i) => {
				console.log("INSIDE REDUCE", acc, row);
				acc[
					`${row[posBasicDetailsMapping.store_code.sqlCol]}_${moment(
						row[posBasicDetailsMapping.date.sqlCol]
					).format("YYYY-MM-DD")}`
				] = i;
				return acc;
			}, {});

			console.log("posDailySaleObj", posDailySaleObj);
			// join bqDataArr and sqlDataArr
			let updatedData = bqDataArr?.map((row) => {
				let sqlArrIndex =
					posDailySaleObj[
						`${row[posBasicDetailsMapping.store_code.key]}_${row[posBasicDetailsMapping.date.key]}`
					];
				if (sqlArrIndex >= 0) return { ...row, [posStoreCollDataSQl]: sqlDataArr[sqlArrIndex] };
				else {
					let isSetteled =
						Number(row[posValuecolumnMapping.total_difference.key]) === 0 ||
						(!row[posValuecolumnMapping.total_collection.key] && !row[posValuecolumnMapping.total_gin_val]);
					return {
						...row,
						[posStoreCollDataSQl]: {
							[posStoreStageColumns.status.key]: isSetteled ? settled : notSetteled,
							[posBasicDetailsMapping.store_name.sqlCol]: row[posBasicDetailsMapping.store_name.key],
							[posBasicDetailsMapping.date.sqlCol]: row[posBasicDetailsMapping.date.key],
							[posBasicDetailsMapping.store_short_name.sqlCol]:
								row[posBasicDetailsMapping.store_short_name.key],
						},
					};
				}
			});

			// add store totla rows
			const updatedDataWithStoreTotal = calculatelAndAddStoreTotalColumns(updatedData);

			// calculate each store count
			let map = {};
			updatedDataWithStoreTotal.forEach((row) => {
				if (map[row[posBasicDetailsMapping.store_name.key]]) map[row[posBasicDetailsMapping.store_code.key]]++;
				else map[row[posBasicDetailsMapping.store_code.key]] = 1;
			});
			console.log("updated", updatedDataWithStoreTotal, map);

			setResult({
				result: updatedData,
				startDate: startDate,
				endDate: endDate,
				storeDateCount: map,
				total: calculateTotalRowValues(updatedDataWithStoreTotal),
			});
			setTableRows(updatedDataWithStoreTotal);
			setAllShortNames([
				...new Set([...updatedData.map((row) => row[posBasicDetailsMapping.store_short_name.key])]),
			]);
			console.log(
				[...new Set([...updatedData.map((row) => row[posBasicDetailsMapping.store_short_name.key])])],
				"set"
			);
			setLoading(false);
			setStartDate(new Date());
			setEndDate(new Date());
			// if (checkMisMatch(res.data)) setIsMismatch(true);
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};
	const rows = [
		{
			storeName: "UB CITY – RR001",
			date: "01/04/2024",
			card: { ginV: "", coll: "", diff: "" },
			upi: { ginV: "5000", coll: "", diff: "" },
			amex: { ginV: "", coll: "", diff: "" },
			cash: { ginV: "", coll: "", diff: "" },
			total: { ginV: "", coll: "", diff: "" },
			status: "",
			reason: "",
		},
		{
			storeName: "UB CITY – RR001",
			date: "01/04/2024",
			card: { ginV: "", coll: "", diff: "" },
			upi: { ginV: "5000", coll: "", diff: "" },
			amex: { ginV: "", coll: "", diff: "" },
			cash: { ginV: "", coll: "", diff: "" },
			total: { ginV: "", coll: "", diff: "" },
			status: "",
			reason: "",
		},
		{
			storeName: "UB CITY – RR001",
			date: "01/04/2024",
			card: { ginV: "", coll: "", diff: "" },
			upi: { ginV: "5000", coll: "", diff: "" },
			amex: { ginV: "", coll: "", diff: "" },
			cash: { ginV: "", coll: "", diff: "" },
			total: { ginV: "", coll: "", diff: "" },
			status: "",
			reason: "",
		},
		{
			storeName: "UB CITY – RR001",
			date: "01/04/2024",
			card: { ginV: "", coll: "", diff: "" },
			upi: { ginV: "5000", coll: "", diff: "" },
			amex: { ginV: "", coll: "", diff: "" },
			cash: { ginV: "", coll: "", diff: "" },
			total: { ginV: "", coll: "", diff: "" },
			status: "",
			reason: "",
		},
		{
			storeName: "UB CITY – RR001",
			date: "01/04/2024",
			card: { ginV: "", coll: "", diff: "" },
			upi: { ginV: "5000", coll: "", diff: "" },
			amex: { ginV: "", coll: "", diff: "" },
			cash: { ginV: "", coll: "", diff: "" },
			total: { ginV: "", coll: "", diff: "" },
			status: "",
			reason: "",
		},
		{
			storeName: "UB CITY – RR001",
			date: "01/04/2024",
			card: { ginV: "", coll: "", diff: "" },
			upi: { ginV: "5000", coll: "", diff: "" },
			amex: { ginV: "", coll: "", diff: "" },
			cash: { ginV: "", coll: "", diff: "" },
			total: { ginV: "", coll: "", diff: "" },
			status: "",
			reason: "",
		},
	];
	const filterClick = () => {
		const filterArr = result?.result.filter((row) => {
			if (filter?.status !== "select" && filter?.store_short_name !== "select")
				return (
					filter?.status === row[posStoreCollDataSQl][defFilterColumns.status.key] &&
					filter?.store_short_name === row[posStoreCollDataSQl][defFilterColumns.store_short_name.key]
				);
			if (filter?.status !== "select" || filter?.store_short_name !== "select")
				return (
					filter?.status === row[posStoreCollDataSQl][defFilterColumns.status.key] ||
					filter?.store_short_name === row[posStoreCollDataSQl][defFilterColumns.store_short_name.key]
				);
			if (filter?.status === "select" && filter?.store_short_name === "select") {
				return true;
			}
		});
		console.log(filterArr);
		const arrWithStoreTotal = calculatelAndAddStoreTotalColumns(filterArr);
		setTableRows(arrWithStoreTotal);
		setResult({ ...result, total: calculateTotalRowValues(arrWithStoreTotal) });
		setFilter({ status: "select", store_short_name: "select" });
	};

	const onStoreRowSelect = (row) => {
		if (
			row &&
			row[posBasicDetailsMapping.date.key] !== storeLevelTotalColName &&
			!row[posStoreCollDataSQl][posStoreStageColumns.published.key]
		) {
			setSelectedStoreRow(row);
			setModalOpen(true);
		}
	};

	const handleSaveClick = async (updatedSqlData, publish) => {
		let rowSqlData = updatedSqlData;
		setLoadingOpen(true);
		if (publish) {
			const sqlTableCollValues = {};
			Object.keys(posValuecolumnMapping).forEach((row) => {
				sqlTableCollValues[row] = selectedStoreRow[posValuecolumnMapping[row].key];
			});

			rowSqlData = {
				...updatedSqlData,
				...sqlTableCollValues,
				[posStoreStageColumns.published.key]: true,
				[posStoreStageColumns.published_date.key]: moment().format("YYYY-MM-DD"),
			};
			console.log("inPublish", rowSqlData);
		}
		try {
			await axios.post("/api/pos_reco/store_daily_coll_data", rowSqlData).then((res) => {
				console.log(res.data, "res");
				let updatedStoreRow = { ...selectedStoreRow, [posStoreCollDataSQl]: res.data };
				setTableRows(
					tableRows.map((store) => {
						if (
							store[posBasicDetailsMapping.store_name.key] ===
								selectedStoreRow[posBasicDetailsMapping.store_name.key] &&
							store[posBasicDetailsMapping.date.key] === selectedStoreRow[posBasicDetailsMapping.date.key]
						)
							return updatedStoreRow;
						else return store;
					})
				);
				setResult({
					...result,
					result: result?.result?.map((store) => {
						if (
							store[posBasicDetailsMapping.store_name.key] ===
								selectedStoreRow[posBasicDetailsMapping.store_name.key] &&
							store[posBasicDetailsMapping.date.key] === selectedStoreRow[posBasicDetailsMapping.date.key]
						)
							return updatedStoreRow;
						else return store;
					}),
				});
				setModalOpen(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Store Updated `,
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Unable to save. Server error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};

	const handleDownload = async () => {
		setDownload(true);
		await axios
			.get(`/api/ebo_store_dh/get-contents`)
			.then((res) => {
				if (res.status === 200) {
					const foldersList = res.data;
					// setFolders([...foldersList]);
					setLoading(false);
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoading(false);
				setLoadingOpen(false);
			});
	};

	return (
		<Box width="100%">
			{/* query run */}
			<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
				<Typography variant="h5">GENERATE REPORTS</Typography>
				<Box display="flex" columnGap={2} alignItems={"flex-end"}>
					<Stack spacing={1}>
						<InputLabel>Select Start Date</InputLabel>
						<TextField
							size="small"
							type="date"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							required
							placeholder="compliance certificate issue date"
						/>
					</Stack>
					<Stack spacing={1}>
						<InputLabel>Select End Date</InputLabel>
						<TextField
							size="small"
							type="date"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							required
							placeholder="compliance certificate issue date"
						/>
					</Stack>
					<Stack spacing={1}>
						<Button
							variant="contained"
							sx={{ width: "150px" }}
							type="submit"
							disabled={loading}
							startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
							{loading ? "Loading..." : "Run"}
						</Button>
					</Stack>
					<Stack>
						<Button
							variant="contained"
							sx={{ width: "150px" }}
							onClick={(e) => handleDownload()}
							disabled={true}
							startIcon={download && <CircularProgress color="inherit" size="20px" />}>
							{download ? "Loading..." : "DOWNLOAD"}
						</Button>
					</Stack>
				</Box>

				<Typography color="red" variant="subtitle1">
					{" "}
					{error ? error : ""}
				</Typography>
			</Stack>

			{/* <Box display="flex" alignItems="center" flexWrap="wrap" p={2} columnGap={2}>
				<Typography variant="h6">Filter</Typography>
				<Stack spacing={1}>
					<InputLabel>Select Start Date</InputLabel>
					<TextField
						size="small"
						type="text"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder=""
					/>
				</Stack>
			</Box> */}
			{result && (
				<>
					<Box
						display="flex"
						justifyContent="space-around"
						// sx={{ background: grey[200], p: 0, borderRadius: 2 }}
						alignItems="center">
						<Typography variant="h6" width="500px">
							{" "}
							RESULT ({moment(result?.startDate).format("DD MMM YYYY")} -
							{moment(result?.endDate).format("DD MMM YYYY")})
						</Typography>
						<Filter
							filter={filter}
							setFilter={setFilter}
							submitFunction={filterClick}
							columns={columns}
							allShortNames={allShortNames}
						/>
					</Box>

					<TableContainer component={Paper} sx={{ height: 400 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell
										rowSpan={2}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 150,
											width: "10%",

											...generateCellStyle(2, 1, 2, 2),
											...stickyColumn,
											background: "white",
											zIndex: 1000,
										}}>
										STORE NAME
									</TableCell>
									<TableCell
										rowSpan={2}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 100,
											width: "10%",
											...generateCellStyle(2, 1, 2, 0),
										}}>
										DATE
									</TableCell>
									<TableCell
										colSpan={3}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 180,
											width: "15%",
											...generateCellStyle(2, 0, 0, 2),
										}}>
										CARD
									</TableCell>
									<TableCell
										colSpan={3}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 180,
											width: "15%",
											...generateCellStyle(2, 0, 0, 2),
										}}>
										UPI
									</TableCell>
									<TableCell
										colSpan={3}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 180,
											width: "15%",
											...generateCellStyle(2, 0, 0, 2),
										}}>
										AMEX
									</TableCell>
									{/* <TableCell
								colSpan={3}
								align="center"
								sx={{
									fontWeight: "bold",
									minWidth: 180,
									width: "15%",
									...generateCellStyle(0, 2, 0, 0),
								}}>
								CASH
							</TableCell> */}
									<TableCell
										colSpan={3}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 180,
											width: "15%",
											...generateCellStyle(2, 0, 1, 2),
										}}>
										TOTAL
									</TableCell>
									<TableCell
										rowSpan={2}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 120,
											width: "12%",
											...generateCellStyle(2, 0, 2, 2),
										}}>
										REASON
									</TableCell>
									<TableCell
										rowSpan={2}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 100,
											width: "8%",
											...generateCellStyle(2, 2, 2, 0),
										}}>
										STATUS
									</TableCell>
									<TableCell
										rowSpan={2}
										align="center"
										sx={{
											fontWeight: "bold",
											minWidth: 100,
											width: "8%",
											...generateCellStyle(2, 2, 2, 0),
										}}>
										PUBLISHED
									</TableCell>
								</TableRow>
								<TableRow>
									{Object.keys(posValuecolumnMapping)?.map((col, index) => {
										let bl = 0;
										let br = 0;
										if (posValuecolumnMapping[col].name === valueColumnName.gin_value) bl = 2;
										// else if (posValuecolumnMapping[col].name === valueColumnName.difference) br = 2;
										return (
											<TableCell
												key={index}
												align="center"
												sx={{
													fontWeight: 600,
													fontSize: "12px",
													top: 36,
													minWidth: 60,
													width: "5%",
													...generateCellStyle(0, 1, 2, bl),
												}}>
												{posValuecolumnMapping[col].name}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{/* total row */}
								<TableRow sx={{ background: grey[200], height: "60px", boxShadow: 3 }}>
									{/* store columns */}
									<TableCell
										align="center"
										sx={{
											width: "10%",
											...generateCellStyle(0, 0, 0, 2, totalColumnColor),
											fontWeight: "bold",
											...stickyColumn,
											fontSize: 16,
										}}>
										{/* {total && total[posBasicDetailsMapping.store_name.key]
									? total[posBasicDetailsMapping.store_name.key]
									: ""} */}
										TOTAL
									</TableCell>

									<TableCell
										align="center"
										sx={{
											width: "10%",
											fontWeight: "bold",
											fontSize: 16,
											...generateCellStyle(0, 0, 1, 0, totalColumnColor),
										}}>
										{/* {total && total[posBasicDetailsMapping.date.key]
									? total[posBasicDetailsMapping.date.key]
									: ""} */}
										--
									</TableCell>
									{/* value Columns */}
									{Object.keys(posValuecolumnMapping)?.map((col, index) => {
										let val =
											result?.total && result?.total[posValuecolumnMapping[col].key]
												? result?.total[posValuecolumnMapping[col].key]
												: 0;

										let bl = 0;
										let br = 0;
										if (posValuecolumnMapping[col].name === valueColumnName.gin_value) bl = 2;
										else if (posValuecolumnMapping[col].name === valueColumnName.difference) br = 2;
										return (
											<TableCell
												align="center"
												sx={{
													width: "10%",
													...generateCellStyle(0, 1, 0, bl, totalColumnColor),
													fontWeight: 600,
													fontSize: 16,
													color:
														posValuecolumnMapping[col].name ===
															valueColumnName.difference && val !== 0
															? red[600]
															: "inherit",
												}}>
												<InLakhsDisplay number={+val} />
											</TableCell>
										);
									})}
									{/* sql table Columns */}
									<TableCell
										align="center"
										sx={{ width: "8%", ...generateCellStyle(0, 0, 0, 2, totalColumnColor) }}>
										{result?.total?.status}
									</TableCell>
									<TableCell
										align="center"
										sx={{ width: "12%", ...generateCellStyle(0, 2, 0, 0, totalColumnColor) }}>
										{result?.total?.reason}
									</TableCell>
									<TableCell
										align="center"
										sx={{ width: "12%", ...generateCellStyle(0, 2, 0, 0, totalColumnColor) }}>
										{/* {result?.total?.reason} */}
									</TableCell>
								</TableRow>
								{tableRows?.map((row, index) => {
									let prevObj = index > 0 ? result?.result[index - 1] : null;
									let rowSpan = 1;
									let displayStoreCell = true;

									rowSpan =
										result?.storeDateCount &&
										result?.storeDateCount[row[posBasicDetailsMapping.store_name.key]] &&
										result?.storeDateCount[row[posBasicDetailsMapping.store_name.key]] >= 0
											? result?.storeDateCount[row[posBasicDetailsMapping.store_name.key]]
											: 1;
									rowSpan = 1;

									// if (
									// 	prevObj &&
									// 	prevObj[posBasicDetailsMapping.store_name.key] !==
									// 		row[posBasicDetailsMapping.store_name.key]
									// )
									// 	displayStoreCell = true;
									// else if (index === 0) displayStoreCell = true;
									// console.log(rowSpan, row[posBasicDetailsMapping.store_name.key], result?.storeDateCount);

									return (
										<React.Fragment key={index}>
											<TableRow
												component="div"
												onClick={() => onStoreRowSelect(row)}
												sx={{
													background:
														row[posBasicDetailsMapping.date.key] === storeLevelTotalColName
															? grey[400]
															: row &&
															  row[posStoreCollDataSQl][
																	posStoreStageColumns.published.key
															  ]
															? grey[100]
															: "white",
													"&:hover": {
														background:
															row[posBasicDetailsMapping.date.key] ===
															storeLevelTotalColName
																? grey[400]
																: grey[200],
													},
												}}>
												{/* store columns */}
												{displayStoreCell && (
													<TableCell
														rowSpan={rowSpan}
														align="center"
														sx={{
															width: "10%",
															...generateCellStyle(0, 0, 0, 0),
															...stickyColumn,
														}}>
														{row && row[posBasicDetailsMapping.store_short_name.key]
															? row[posBasicDetailsMapping.store_short_name.key]
															: row[posBasicDetailsMapping.store_name.key]}
													</TableCell>
												)}
												<TableCell
													align="center"
													sx={{ width: "10%", ...generateCellStyle(0, 0, 1, 0) }}>
													{row && row[posBasicDetailsMapping.date.key]
														? row[posBasicDetailsMapping.date.key]
														: ""}
												</TableCell>

												{/* value Columns */}
												{Object.keys(posValuecolumnMapping)?.map((col, index) => {
													let val =
														row && row[posValuecolumnMapping[col].key]
															? row[posValuecolumnMapping[col].key]
															: 0;

													let bl = 0;
													let br = 0;
													if (posValuecolumnMapping[col].name === valueColumnName.gin_value)
														bl = 2;
													else if (
														posValuecolumnMapping[col].name === valueColumnName.difference
													)
														br = 2;
													return (
														<TableCell
															align="center"
															sx={{
																width: "10%",
																...generateCellStyle(0, 1, 0, bl),
																color:
																	posValuecolumnMapping[col].name ===
																		valueColumnName.difference && val !== 0
																		? red[700]
																		: "inherit",
																fontWeight: 600,
															}}>
															{val}
														</TableCell>
													);
												})}

												{/* sql table Columns */}
												<TableCell
													align="center"
													sx={{ width: "8%", ...generateCellStyle(0, 0, 0, 2) }}>
													{row[posStoreCollDataSQl]?.reason}
												</TableCell>
												<TableCell
													align="center"
													sx={{ width: "12%", ...generateCellStyle(0, 2, 0, 0) }}>
													{row[posStoreCollDataSQl]?.status && (
														<Chip
															size="small"
															color={
																row[posStoreCollDataSQl]?.status === settled
																	? "success"
																	: "error"
															}
															label={row[posStoreCollDataSQl]?.status}
														/>
													)}
													{/* {row[posStoreCollDataSQl]?.status} */}
												</TableCell>
												<TableCell
													align="center"
													sx={{ width: "12%", ...generateCellStyle(0, 2, 0, 0) }}>
													{row[posBasicDetailsMapping.date.key] !==
														storeLevelTotalColName && (
														<Chip
															size="small"
															color={
																row[posStoreCollDataSQl]?.is_published === true
																	? "success"
																	: "info"
															}
															label={
																row[posStoreCollDataSQl]?.is_published === true
																	? "PUBLISHED"
																	: "PENDING"
															}
														/>
													)}
													{/* {row[posStoreCollDataSQl]?.status} */}
												</TableCell>
											</TableRow>

											{/* {row.dates.slice(1).map((date, dateIndex) => (
								<TableRow key={dateIndex}>
									<TableCell colSpan={17} align="center">
										{date}
									</TableCell>
								</TableRow>2
							))} */}
										</React.Fragment>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
			{selectedStoreRow && (
				<InfoModal
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
					columns={{ ...posStoreStageColumns, ...defFilterColumns }}
					sqlStoreData={selectedStoreRow[posStoreCollDataSQl]}
					submitFunction={handleSaveClick}
				/>
			)}
		</Box>
	);
};

export default PaymentTable;
