import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#212121",
		},
		secondary: {
			main: "#0277bd",
		},
		grayBgColor: {
			main: "#f5f5f5",
		},
	},
	typography: {
		fontFamily: `"Poppins" sans-serif`,
	},
	components: {
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: 3,
				},
			},
		},
	},
});
