import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import grey from "@mui/material/colors/grey";
import { roundDecimalValueUptoTwo } from "../../utils/roundDecimalValue";

const DisplayTable = ({ rows, disableFirstRow, onRowClick }) => {
	const tableBorder = disableFirstRow ? `1px solid ${grey[400]}` : `1px solid ${grey[300]}`;

	const cellStyle = {
		// width: "30%",
		borderRight: tableBorder,
		textTransform: "uppercase",
	};
	console.log(rows[0]);
	if (rows[0])
		return (
			<TableContainer sx={{ border: tableBorder, borderRadius: "4px" }}>
				<Table>
					<TableHead>
						<TableRow>
							{Object.keys(rows[0]).map((key) => (
								<TableCell key={key} sx={cellStyle} component="th" scope="row">
									{key}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows &&
							rows.map((row, index) => (
								<TableRow
									onClick={() => {
										if (typeof onRowClick === "function") onRowClick(row);
									}}
									key={index}
									sx={{
										"&:last-child td, &:last-child th": { borderBottom: 0 },
										"&:first-child td, &:first-child th": !disableFirstRow
											? {
													background: grey[200],
													fontWeight: "bold",
											  }
											: {},
									}}>
									{Object.keys(row).map((key) => (
										<TableCell component="th" scope="row" key={key} sx={cellStyle}>
											{Number(row[key]) === row[key] && row[key] % 1 !== 0
												? roundDecimalValueUptoTwo(row[key])
												: row[key]}
										</TableCell>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	else return <div>Failed to Display Table</div>;
};

export default DisplayTable;
