import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { colors } from "@mui/material";
import { green, grey } from "@mui/material/colors";

const SelectInputField = ({ data, setData, column, width, options, sx }) => {
	const sxData = sx ? sx : {};
	return (
		<Stack sx={{ width }} spacing={"3px"} height={"40px"} direction={"row"}>
			<InputLabel sx={{ width: "38%", p: 1, background: grey[600], overflow: "hidden", color: grey[100] }}>
				{column?.name}
			</InputLabel>
			<Select
				sx={{ width: "55%", p: 1, background: grey[200], fontWeight: 400, overflow: "hidden" }}
				size="small"
				fullWidth
				variant="standard"
				placeholder={`Enter ${column?.name}`}
				MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
				value={data && data[column.key] ? data[column.key] : "select"}
				onChange={(e) => setData({ ...data, [column.key]: e.target.value })}>
				<MenuItem value={"select"}>
					<span style={{ color: grey[400] }}>Select</span>{" "}
				</MenuItem>
				{options?.map((row) => {
					return (
						<MenuItem key={row.value} value={row.key}>
							{row.value}
						</MenuItem>
					);
				})}
			</Select>
		</Stack>
	);
};

export default SelectInputField;
