import { CloudUpload, UploadFile } from "@mui/icons-material";
import { Button, InputLabel, Stack, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { GlobalState } from "context/GlobalContext";
import { useState } from "react";
import { pythonVmInstance } from "utils/AxiosInstances/RareboxPythonInstance";

const InputFileld = ({ width, column, data, setData, sx }) => {
	const handleFileChange = (e) => {
		const newFile = e.target.files[0];
		const newFileName = e.target.id + "-" + newFile.name;
		const modifiedFile = new File([newFile], newFileName, { type: newFile.type });
		setData([...data, modifiedFile]);
	};
	return (
		<Stack sx={{ width }} spacing={1}>
			<InputLabel>{column?.name}</InputLabel>

			<TextField
				sx={sx ? sx : {}}
				size="small"
				type={"file"}
				id={column?.id}
				// value={data && data[column.key] ? data[column.key] : ""}
				onChange={(e) => handleFileChange(e)}
				// required={required ? required : false}
				fullWidth
				ccept=".csv"
			/>
		</Stack>
	);
};

const fileTypes = {
	MYNTRA_FORWARD_SETTLED_FILE: {
		name: "MYNTRA FORWARD SETTLED FILE",
		key: "MYNTRA_FORWARD_SETTLED_FILE",
		id: "FORWARD",
	},
	MYNTRA_REVERSE_SETTLED_FILE: {
		name: "MYNTRA REVERSE SETTLED FILE",
		key: "MYNTRA_REVERSE_SETTLED_FILE",
		id: "REVERSE",
	},
	CUSTOM_FILE: { name: "CUSTOM FILE", key: "CUSTOM_FILE", id: "CUSTOM" },
	MYNTRA_SPF_FILE: { name: "MYNTRA SPF FILE", key: "MYNTRA_SPF_FILE", id: "SPF" },
	NON_ORDER_SETTLED_FILE: { name: "NON ORDER SETTLED FILE", key: "NON_ORDER_SETTLED_FILE", id: "NON_ORDER" },
};

const MyntraFileUpload = () => {
	const [files, setFiles] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const onFormSubmit = (e) => {
		e.preventDefault();
		setLoadingOpen(true);
		let form = new FormData();
		if (files.length === 0) {
			setLoadingOpen(false);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Please upload some files and try again",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		files.forEach((file) => {
			form.append("files[]", file);
		});
		pythonVmInstance
			.post("/reco/upload", form, { headers: { "Content-Type": "multipart/form-data" } })
			.then((res) => {
				setLoadingOpen(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Uploaded the files successfully",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				document.getElementById("FORWARD").value = "";
				document.getElementById("REVERSE").value = "";
				document.getElementById("CUSTOM").value = "";
				document.getElementById("SPF").value = "";
				document.getElementById("NON_ORDER").value = "";
				setFiles([]);
			})
			.catch((err) => {
				console.log("uploaded", err);
				const message = err?.response?.data?.message;
				setLoadingOpen(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: message ? message : "Unable to uploaded the files",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	return (
		<Stack maxWidth="1200px" margin="auto" spacing={2} component="form" onSubmit={onFormSubmit}>
			<Typography variant="h5" width="100%" pt={2} pb={2}>
				UPLOAD FILES
			</Typography>
			<Box rowGap={2} display="flex" columnGap="3%" flexWrap="wrap">
				{Object.keys(fileTypes).map((row) => {
					return <InputFileld data={files} setData={setFiles} column={fileTypes[row]} required width="30%" />;
				})}
			</Box>
			<Button size="large" startIcon={<CloudUpload />} variant="contained" type="submit" sx={{ width: "200px", alignSelf: "center" }}>
				Upload
			</Button>
		</Stack>
	);
};

export default MyntraFileUpload;
