import { COL_KEYS, COL_NAMES } from "data/excelTemplateData";
import ExcelJS from "exceljs";

const saveExcelFile = (buffer, fileName) => {
	// Create a Blob from the buffer
	const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

	// Create a download link
	const downloadLink = document.createElement("a");
	downloadLink.href = window.URL.createObjectURL(blob);
	downloadLink.download = fileName;

	// Trigger download
	downloadLink.click();
};

export const modifyExcel = async (data, name) => {
	console.log("data", data);
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet("Sheet1");

	// Merge cell E to U
	worksheet.mergeCells("E3:U3");
	// Get the merged cell
	const mergedCell = worksheet.getCell("E3");
	// align center
	mergedCell.alignment = { vertical: "middle", horizontal: "center" };
	// Write text in the center of the merged cell
	mergedCell.value = `${name} - Payment Working`;
	// Set font properties: increase font size and make it bold
	mergedCell.font = {
		size: 16, // Font size
		bold: true, // Bold
	};
	mergedCell.fill = {
		type: "pattern",
		pattern: "solid",
		fgColor: { argb: "B8CCE4" },
	};
	// Set border properties for the merged cell
	mergedCell.border = {
		top: { style: "thick", color: { argb: "000000" } }, // Thin black border at the top
		left: { style: "thick", color: { argb: "000000" } }, // Thin black border at the left
		bottom: { style: "thick", color: { argb: "000000" } }, // Thin black border at the bottom
		right: { style: "thick", color: { argb: "000000" } }, // Thin black border at the right
	};

	worksheet.mergeCells("E4:U4");
	// Get the merged cell
	const mergedCell2 = worksheet.getCell("E4");
	// align center
	mergedCell2.alignment = { vertical: "middle", horizontal: "center" };
	// Write text in the center of the merged cell
	mergedCell2.value = `Month ${data[0].MONTH} 2024`;
	// Set font properties: increase font size and make it bold
	mergedCell2.font = {
		size: 12, // Font size
		bold: true, // Bold
	};

	// Set border properties for the merged cell
	mergedCell2.border = {
		top: { style: "thick", color: { argb: "000000" } }, // Thin black border at the top
		left: { style: "thick", color: { argb: "000000" } }, // Thin black border at the left
		bottom: { style: "thick", color: { argb: "000000" } }, // Thin black border at the bottom
		right: { style: "thick", color: { argb: "000000" } }, // Thin black border at the right
	};

	// Write text in cells from column E to column U and in row 5
	for (let col = 5; col <= 21; col++) {
		// Columns E to U (5 to 21)
		const cell = worksheet.getCell(5, col); // Row 5, current column
		cell.value = `${COL_NAMES[col - 5]}`; // Text to write in the cell
		cell.alignment = { wrapText: true, vertical: "middle", horizontal: "center" }; // Wrap text and center align
		cell.font = { bold: true, size: 6 }; // Make text bold
		cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "EBF1DE" } }; // Yellow background color
		cell.border = {
			top: { style: "thick", color: { argb: "000000" } }, // Thin black border at the top
			left: { style: "thick", color: { argb: "000000" } }, // Thin black border at the left
			bottom: { style: "thick", color: { argb: "000000" } }, // Thin black border at the bottom
			right: { style: "thick", color: { argb: "000000" } }, // Thin black border at the right
		};
	}

	let rowIndex;
	// Insert rows and write data from array of objects to cells from E7 to U7
	for (rowIndex = 0; rowIndex < data.length; rowIndex++) {
		for (let col = 5; col <= 21; col++) {
			// Columns E to U (5 to 21)

			const cell = worksheet.getCell(7 + rowIndex, col);
			if (col >= 8 && col != 11 && col != 13) {
				cell.value =
					parseFloat(`${data[rowIndex][COL_KEYS[col - 5]]}`) === null ||
					isNaN(parseFloat(`${data[rowIndex][COL_KEYS[col - 5]]}`)) ||
					`${data[rowIndex][COL_KEYS[col - 5]]}` === null
						? "-"
						: parseFloat(`${data[rowIndex][COL_KEYS[col - 5]]}`).toFixed(2);
			} else {
				cell.value = `${data[rowIndex][COL_KEYS[col - 5]]}`; // Text to write in the cell
			}

			cell.alignment = { wrapText: true, vertical: "middle", horizontal: "center" }; // Wrap text and center align
			cell.font = { bold: true, size: 6 }; // Make text bold
			cell.border = {
				top: { style: "thick", color: { argb: "000000" } }, // Thin black border at the top
				left: { style: "thick", color: { argb: "000000" } }, // Thin black border at the left
				bottom: { style: "thick", color: { argb: "000000" } }, // Thin black border at the bottom
				right: { style: "thick", color: { argb: "000000" } }, // Thin black border at the right
			};
		}
	}
	// Total section.
	worksheet.mergeCells(`E${rowIndex + 8}:U${rowIndex + 8}`);
	// Get the merged cell
	const mergedCell3 = worksheet.getCell(`E${rowIndex + 8}`);
	// align center
	mergedCell3.alignment = { vertical: "middle", horizontal: "center" };

	// Write text in cells from column E to column U and in row 5
	for (let col = 5; col <= 21; col++) {
		// Columns E to U (5 to 21)
		const cell = worksheet.getCell(rowIndex + 7, col); // Row 5, current column
		if (col === 5 || col === 7 || col === 13 || col === 11 || col === 18 || col === 19) {
			cell.value = "";
		} else if (col === 6) {
			cell.value = "Total";
		} else {
			cell.value = data.reduce((acc, curr) => {
				if (!isNaN(parseFloat(curr[COL_KEYS[col - 5]]))) {
					return acc + parseFloat(curr[COL_KEYS[col - 5]]);
				}
			}, 0.0);
		}
		cell.alignment = { wrapText: true, vertical: "middle", horizontal: "center" }; // Wrap text and center align
		cell.font = { bold: true, size: 6 }; // Make text bold
		cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "DAEEF3" } }; // Yellow background color
		cell.border = {
			top: { style: "thick", color: { argb: "000000" } }, // Thin black border at the top
			left: { style: "thick", color: { argb: "000000" } }, // Thin black border at the left
			bottom: { style: "thick", color: { argb: "000000" } }, // Thin black border at the bottom
			right: { style: "thick", color: { argb: "000000" } }, // Thin black border at the right
		};
	}

	// Total section.
	worksheet.mergeCells(`E${rowIndex + 9}:U${rowIndex + 9}`);
	// Get the merged cell
	const mergedCell4 = worksheet.getCell(`E${rowIndex + 9}`);
	// align center
	mergedCell4.alignment = { vertical: "middle", horizontal: "center" };

	mergedCell4.fill = {
		type: "pattern",
		pattern: "solid",
		fgColor: { argb: "B8CCE4" },
	};
	// Set border properties for the merged cell
	mergedCell4.border = {
		top: { style: "thick", color: { argb: "000000" } }, // Thin black border at the top
		left: { style: "thick", color: { argb: "000000" } }, // Thin black border at the left
		bottom: { style: "thick", color: { argb: "000000" } }, // Thin black border at the bottom
		right: { style: "thick", color: { argb: "000000" } }, // Thin black border at the right
	};
	mergedCell4.value =
		"   Created By                                               Approved By                                                       Approved By";

	worksheet.mergeCells(`E${rowIndex + 10}:U${rowIndex + 10}`);
	// Get the merged cell
	const mergedCell5 = worksheet.getCell(`E${rowIndex + 10}`);
	// align center
	mergedCell5.alignment = { vertical: "middle", horizontal: "center" };

	mergedCell5.fill = {
		type: "pattern",
		pattern: "solid",
		fgColor: { argb: "B8CCE4" },
	};
	// Set border properties for the merged cell
	mergedCell5.border = {
		top: { style: "thick", color: { argb: "000000" } }, // Thin black border at the top
		left: { style: "thick", color: { argb: "000000" } }, // Thin black border at the left
		bottom: { style: "thick", color: { argb: "000000" } }, // Thin black border at the bottom
		right: { style: "thick", color: { argb: "000000" } }, // Thin black border at the right
	};
	mergedCell5.value =
		"Varun Mishra                                          Bhavish Kumar                                                        Lavish Soni";

	// Generate Excel file as a Buffer
	const buffer = await workbook.xlsx.writeBuffer();

	// Save the Excel file
	saveExcelFile(buffer, `${name}-summary.xlsx`);
};
