import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useNavigate, useOutletContext } from "react-router-dom";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { itemMasterDrawerElements } from "data/itemMasterData";
import WithCOGS from "./WithCOGS/WithCOGS";

const ItemMasterDashboard = () => {
	const [tab, setTab] = useOutletContext();
	const navigate = useNavigate();
	const tabComponents = [
		{
			...itemMasterDrawerElements.without_cogs,
			component: <WithCOGS cost_rate={false} />,
		},
		{
			...itemMasterDrawerElements.with_cogs,
			component: <WithCOGS cost_rate={true} />,
		},
	];
	return (
		<Box sx={{ width: "100%" }}>
			<Button
				startIcon={<KeyboardBackspaceRounded />}
				variant="outlined"
				onClick={() => navigate(-1)}
				sx={{ m: 2 }}>
				Back
			</Button>
			{tabComponents &&
				tabComponents.map((row) => (
					<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
						{row.component}
					</Box>
				))}
		</Box>
	);
};

export default ItemMasterDashboard;
