import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import CodeIcon from "@mui/icons-material/Code";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import axios from "axios";
import DisplayTable from "../../ReusableComponents/DisplayTable";
import moment from "moment";
import dayjs from "dayjs";
import { GlobalState } from "../../../context/GlobalContext";
import FileSaver from "file-saver";
import { Divider, MenuItem, Select } from "@mui/material";
import DisplayDataGrid from "components/ReusableComponents/DisplayDataGrid";

const isStartDateGraterThanEndDate = (startDateText, endDateText) => {
	const startDate = dayjs(startDateText);
	const endDate = dayjs(endDateText);

	const startday = startDate.get("D");
	const startYear = startDate.get("year");
	const startMonth = startDate.get("M");

	const endDay = endDate.get("D");
	const endYear = endDate.get("year");
	const endMonth = endDate.get("M");

	if (startYear > endYear) return true;

	if (startYear === endYear && startMonth > endMonth) return true;
	if (startYear === endYear && startMonth === endMonth && startday > endDay) {
		return true;
	}

	// returns true if start date >end date
	return false;
};

const Sale = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading1, setDownloading1] = useState(false);
	const [downloading2, setDownloading2] = useState(false);

	const AddTotalColumns = (arr) => {
		let total_UC_ORDERS = 0;
		let total_GIN_ORDERS = 0;
		let total_UC_QTY = 0;
		let total_GIN_QTY = 0;
		let total_UC_Value = 0;
		let total_GIN_Value = 0;
		let total_ORDER_DIFF = 0;
		let total_Value_DIFF = 0;
		let total_QTY_DIFF = 0;

		const addTotalCounts = arr.map((row) => {
			const ORDER_DIFF = +row.UC_ORDERS - +row.GIN_ORDERS;
			const QTY_DIFF = +row.UC_QTY - +row.GIN_QTY;
			const Value_DIFF = +row.UC_Value - +row.GIN_Value;

			total_UC_ORDERS = total_UC_ORDERS + +row.UC_ORDERS;
			total_GIN_ORDERS = total_GIN_ORDERS + +row.GIN_ORDERS;
			total_UC_QTY = total_UC_QTY + +row.UC_QTY;
			total_GIN_QTY = total_GIN_QTY + +row.GIN_QTY;
			total_UC_Value = total_UC_Value + +row.UC_Value;
			total_GIN_Value = total_GIN_Value + +row.GIN_Value;
			total_ORDER_DIFF = total_ORDER_DIFF + ORDER_DIFF;
			total_Value_DIFF = total_Value_DIFF + Value_DIFF;
			total_QTY_DIFF = total_QTY_DIFF + QTY_DIFF;

			return {
				CHANNEL: row.CHANNEL,
				UC_ORDERS: row.UC_ORDERS,
				GIN_ORDERS: row.GIN_ORDERS,
				ORDER_DIFF,
				UC_QTY: row.UC_QTY,
				GIN_QTY: row.GIN_QTY,
				QTY_DIFF,
				UC_Value: row.UC_Value,
				GIN_Value: row.GIN_Value,
				Value_DIFF,
			};
		});
		const summary = {
			CHANNEL: "TOTAL",
			UC_ORDERS: total_UC_ORDERS,
			GIN_ORDERS: total_GIN_ORDERS,
			ORDER_DIFF: total_ORDER_DIFF,
			UC_QTY: total_UC_QTY,
			GIN_QTY: total_GIN_QTY,
			QTY_DIFF: total_QTY_DIFF,
			UC_Value: total_UC_Value,
			GIN_Value: total_GIN_Value,
			Value_DIFF: total_Value_DIFF,
		};

		return [summary, ...addTotalCounts];
	};

	const checkMisMatch = (records) => {
		let isMismatch = false;
		records.forEach((row) => {
			if (row.UC_QTY !== row.GIN_QTY) {
				isMismatch = true;
			}
		});
		return isMismatch;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/query_run/uc_vs_gynesis_sale?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					const updatedData = res.data;

					setResult({ result: updatedData, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					// if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleMisMatchClick = async (file) => {
		file === 1 ? setDownloading1(true) : setDownloading2(true);
		try {
			await axios({
				url: `/api/query_run/download_UcVsGin_sale_diff?startDate=${result.startDate}&endDate=${result.endDate}&file=${file}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(
					url,
					file === 1
						? `raise_to_ginesys_${result.startDate}_to_${result.endDate}.xlsx`
						: `raise_to_uc_${result.startDate}_to_${result.endDate}.xlsx`
				);
				file === 1 ? setDownloading1(false) : setDownloading2(false);
			});
		} catch (error) {
			setIsMismatch(false);
			file === 1 ? setDownloading1(false) : setDownloading2(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6">Compare Sales</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Select Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : <CodeIcon />}>
				{loading ? "Loading..." : "Run Query"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							Raise To Ginesys From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={() => handleMisMatchClick(1)}
							// disabled={isMismatch ? false : true}
							startIcon={
								downloading1 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Download
						</Button>
					</Box>
					{result.result.raiseToGinesysData.length ? (
						<DisplayDataGrid
							rows={result.result.raiseToGinesysData}
							columns={Object.keys(result.result.raiseToGinesysData[0])}
						/>
					) : (
						<Typography color="red" variant="subtitle1">
							No records to display
						</Typography>
					)}

					<Box display="flex" pt={5} pb={2}>
						<Typography p="10px  5px" pr={2}>
							Raise To UC From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={() => handleMisMatchClick(2)}
							// disabled={isMismatch ? false : true}
							startIcon={
								downloading2 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Download
						</Button>
					</Box>
					{result.result.raiseToUcData.length ? (
						<DisplayDataGrid
							rows={result.result.raiseToUcData}
							columns={Object.keys(result.result.raiseToUcData[0])}
						/>
					) : (
						<Typography color="red" variant="subtitle1">
							No records to display
						</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};
const Return = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading, setDownloading] = useState(false);

	const AddTotalColumns = (arr) => {
		let total_UC_ORDERS = 0;
		let total_GIN_ORDERS = 0;
		let total_UC_QTY = 0;
		let total_GIN_QTY = 0;
		let total_UC_Value = 0;
		let total_GIN_Value = 0;
		let total_ORDER_DIFF = 0;
		let total_Value_DIFF = 0;
		let total_QTY_DIFF = 0;

		const addTotalCounts = arr.map((row) => {
			const ORDER_DIFF = +row.Return_Orders - +row.GIN_ORDERS;
			const QTY_DIFF = +row.Return_Qty - Math.abs(row.GIN_QTY);
			const Value_DIFF = +row.Return_Value - Math.abs(row.GIN_Value);

			total_UC_ORDERS = total_UC_ORDERS + +row.Return_Orders;
			total_GIN_ORDERS = total_GIN_ORDERS + +row.GIN_ORDERS;
			total_UC_QTY = total_UC_QTY + +row.Return_Qty;
			total_GIN_QTY = total_GIN_QTY + Math.abs(row.GIN_QTY);
			total_UC_Value = total_UC_Value + +row.Return_Value;
			total_GIN_Value = total_GIN_Value + Math.abs(row.GIN_Value);
			total_ORDER_DIFF = total_ORDER_DIFF + ORDER_DIFF;
			total_Value_DIFF = total_Value_DIFF + Value_DIFF;
			total_QTY_DIFF = total_QTY_DIFF + QTY_DIFF;

			return {
				CHANNEL: row.CHANNEL,
				Return_Orders: row.Return_Orders,
				GIN_ORDERS: row.GIN_ORDERS,
				ORDER_DIFF,
				Return_Qty: row.Return_Qty,
				GIN_QTY: Math.abs(row.GIN_QTY),
				QTY_DIFF,
				Return_Value: row.Return_Value,
				GIN_Value: Math.abs(row.GIN_Value),
				Value_DIFF,
			};
		});
		const summary = {
			CHANNEL: "TOTAL",
			UC_ORDERS: total_UC_ORDERS,
			GIN_ORDERS: total_GIN_ORDERS,
			ORDER_DIFF: total_ORDER_DIFF,
			UC_QTY: total_UC_QTY,
			GIN_QTY: total_GIN_QTY,
			QTY_DIFF: total_QTY_DIFF,
			UC_Value: total_UC_Value,
			GIN_Value: total_GIN_Value,
			Value_DIFF: total_Value_DIFF,
		};

		return [summary, ...addTotalCounts];
	};
	const checkMisMatch = (records) => {
		let isMismatch = false;
		records.forEach((row) => {
			if (row.UC_QTY !== row.GIN_QTY) {
				isMismatch = true;
			}
		});
		return isMismatch;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (result) setResult();
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than end date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/query_run/uc_vs_gynesis_return?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					// const updatedData = res.data;
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			if (error.response.data) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleMisMatchClick = async () => {
		setDownloading(true);
		try {
			await axios({
				url: `/api/query_run/download_UcVsGin_return_diff?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `ucVsGin_return_mismatch_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};
	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6">Compare Returns</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Select Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : <CodeIcon />}>
				{loading ? "Loading..." : "Run Query"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>
			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleMisMatchClick}
							disabled={result.result.length ? false : true}
							startIcon={
								downloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Download
						</Button>
					</Box>
					{result.result.length ? (
						<DisplayDataGrid rows={result.result} columns={Object.keys(result.result[0])} />
					) : (
						<Typography color="red" variant="subtitle1">
							No records to display
						</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};
const Ledger = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading, setDownloading] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = true;
		// records.forEach((row) => {
		// 	if (row.UC_QTY !== row.GIN_QTY) {
		// 		isMismatch = true;
		// 	}
		// });
		return isMismatch;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (result) setResult();
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than end date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/query_run/uc_vs_gynesis_ledger_cod?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					// const updatedData = AddTotalColumns(res.data);
					const { dataUc, dataGinesys } = res.data;

					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);

					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			if (error.response.data) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleMisMatchClick = async () => {
		setDownloading(true);
		try {
			await axios({
				url: `/api/query_run/download_UcVsGin_ledger_cod?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `ucVsGin_ledger_cod_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};
	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6">Ledger</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Select Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : <CodeIcon />}>
				{loading ? "Loading..." : "Run Query"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleMisMatchClick}
							disabled={result.result.length ? false : true}
							startIcon={
								downloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Download
						</Button>
					</Box>
					<Stack direction={"row"} columnGap={2} maxHeight={"660px"}>
						{result.result.length ? (
							<DisplayDataGrid rows={result.result} columns={Object.keys(result.result[0])} />
						) : (
							<Typography color="red" variant="subtitle1">
								No records to display
							</Typography>
						)}
					</Stack>
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

// payment reco component.
const Payment = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [cod, setCod] = useState(0);
	const [downloading, setDownloading] = useState(false);

	const AddTotalColumns = (arr) => {
		let total_amount = 0;
		let amount_paid = 0;
		let pending_amount = 0;

		const addTotalCounts = arr.map((row) => {
			total_amount += row.SELLING_PRICE;
			pending_amount = total_amount;
			return {
				SHIPPING_PROVIDER: row.SHIPPING_PROVIDER,
				TOTAL_AMOUNT: row.SELLING_PRICE,
				AMOUNT_PAID: amount_paid,
				AMOUNT_PENDING: pending_amount,
			};
		});
		const summary = {
			SHIPPING_PROVIDER: "ALL",
			TOTAL_AMOUNT: total_amount,
			AMOUNT_PAID: amount_paid,
			AMOUNT_PENDING: pending_amount,
		};

		return [summary, ...addTotalCounts];
	};

	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (result) setResult();
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than end date");
			setLoading(false);

			return;
		}

		if (cod === 0) {
			try {
				await axios
					.get(`/api/query_run/payment_reco_cod?&startDate=${startDate}&endDate=${endDate}`)
					.then((res) => {
						const updatedData = res.data;
						// const updatedData = res.data;
						setResult({ result: updatedData, startDate, endDate });
						setLoading(false);
					});
			} catch (error) {
				if (error.response.data) {
					const errData = error.response.data;
					setError(errData.error);
				} else setError("Error Occured !!!");
				setLoading(false);
			}
		} else {
			try {
				await axios
					.get(`/api/query_run/payment_reco_prepaid?&startDate=${startDate}&endDate=${endDate}`)
					.then((res) => {
						const updatedData = res.data;

						// const updatedData = res.data;
						setResult({ result: updatedData, startDate, endDate });
						setLoading(false);
					});
			} catch (error) {
				if (error.response.data) {
					const errData = error.response.data;
					setError(errData.error);
				} else setError("Error Occured !!!");
				setLoading(false);
			}
		}
	};

	const handleDownload = async () => {
		setDownloading(true);
		if (cod === 0) {
			try {
				await axios({
					url: `/api/query_run/payment_reco_cod_download?startDate=${startDate}&endDate=${endDate}&cod=${cod}`,
					method: "GET",
					responseType: "blob",
				}).then((res) => {
					const url = URL.createObjectURL(res.data);
					FileSaver.saveAs(url, `payment_reco_cod_${new Date().toJSON().slice(0, 10)}.xlsx`);
					setDownloading(false);
				});
			} catch (error) {
				if (error.response)
					if (error.response.status === 404)
						setSnackBar({
							...snackBar,
							message: "No records were found",
							severity: "error",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
					else
						setSnackBar({
							...snackBar,
							message: "Internal Server Error",
							severity: "error",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
				else
					setSnackBar({
						...snackBar,
						message: "Error :Download Failed",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				setSnackBarOpen(true);
			}
		} else {
			try {
				await axios({
					url: `/api/query_run/payment_reco_prepaid_download?startDate=${startDate}&endDate=${endDate}&cod=${cod}`,
					method: "GET",
					responseType: "blob",
				}).then((res) => {
					const url = URL.createObjectURL(res.data);
					FileSaver.saveAs(url, `payment_reco_prepaid_${startDate}_to_${endDate}.xlsx`);
					setDownloading(false);
				});
			} catch (error) {
				if (error.response)
					if (error.response.status === 404)
						setSnackBar({
							...snackBar,
							message: "No records were found",
							severity: "error",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
					else
						setSnackBar({
							...snackBar,
							message: "Internal Server Error",
							severity: "error",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
				else
					setSnackBar({
						...snackBar,
						message: "Error :Download Failed",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				setSnackBarOpen(true);
			}
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6">Payment Reco</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Select Start Date</InputLabel>
					<TextField
						disabled={cod === 0 ? true : false}
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select End Date</InputLabel>
					<TextField
						disabled={cod === 0 ? true : false}
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select COD</InputLabel>
					<Select value={cod} onChange={(e) => setCod(e.target.value)} size="small">
						<MenuItem value={0}>COD</MenuItem>
						<MenuItem value={1}>Prepaid</MenuItem>
					</Select>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : <CodeIcon />}>
				{loading ? "Loading..." : "Run Query"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							{cod === 0
								? `From December 2023 till date`
								: `From ${moment(result.startDate).format("DD-MM-YYYY")} to${" "}
							${moment(result.endDate).format("DD-MM-YYYY")}`}
						</Typography>
						<Button
							disabled={result.result.length === 0}
							onClick={handleDownload}
							startIcon={
								downloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Download
						</Button>
					</Box>
					{result.result.length ? (
						<DisplayDataGrid rows={result.result} columns={Object.keys(result.result[0])} />
					) : (
						<></>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

const QueryRunnner = ({ history, setHistory }) => {
	const [selected, setSelected] = useState(0);

	const queryTypes = [
		{
			key: 0,
			name: "Sale Reco",
			component: <Sale history={history} setHistory={setHistory} />,
		},
		{
			key: 1,
			name: "Return Reco",
			component: <Return history={history} setHistory={setHistory} />,
		},
		{
			key: 2,
			name: "Ledger",
			component: <Ledger history={history} setHistory={setHistory} />,
		},
		{
			key: 3,
			name: "Payment Reco",
			component: <Payment history={history} setHistory={setHistory} />,
		},
	];

	const handleQuerySelect = (key) => {
		setSelected(key);
	};
	return (
		<Box display="flex" height="100%">
			<Box height="100%" width={170} sx={{ borderRight: 1, borderColor: "divider" }}>
				{queryTypes &&
					queryTypes.map((row) => {
						return (
							<ListItemButton
								onClick={() => handleQuerySelect(row.key)}
								sx={{ padding: "2px", margin: "5px", borderRadius: "4px" }}
								selected={row.key === selected}
								key={row.key}>
								<ListItemText sx={{ padding: "2px 15px", fontSize: "13px" }} primary={row.name} />
							</ListItemButton>
						);
					})}
			</Box>

			{queryTypes &&
				queryTypes.map((row) => {
					return (
						<Box
							width="80%"
							height="100%"
							p={2}
							key={row.key}
							display={row.key === selected ? "block" : "none"}>
							{row.component}
						</Box>
					);
				})}
		</Box>
	);
};

export default QueryRunnner;
