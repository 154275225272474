import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { grey } from "@mui/material/colors";
import NumberTextField from "components/ReusableComponents/NumberTextField";

const TextInputField = ({ data, setData, column, width, required, type, sx, disabled }) => {
	console.log(disabled);
	return (
		<Stack sx={{ width }} spacing={"3px"} height={"40px"} direction="row">
			<InputLabel sx={{ width: "38%", p: 1, background: grey[600], overflow: "hidden", color: grey[100] }}>
				{column?.name}
			</InputLabel>
			{column.type.datatype === "number" ? (
				<NumberTextField
					variant="standard"
					sx={sx}
					disableHelperText
					disabled={disabled}
					onChange={(e) => setData({ ...data, [column.key]: e.target.value })}
					value={data && data[column.key] ? data[column.key] : 0}
				/>
			) : (
				<TextField
					variant="standard"
					sx={sx}
					size="large"
					type={"text"}
					value={data && data[column.key] ? data[column.key] : " "}
					onChange={(e) =>
						setData({
							...data,
							[column.key]: e.target.value,
						})
					}
					disabled={disabled}
					placeholder={`Enter ${column?.key}`}
					required={required ? required : false}
					fullWidth
				/>
			)}
		</Stack>
	);
};

export default TextInputField;
