import { CloudUpload, UploadFile } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	InputLabel,
	List,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { GlobalState } from "context/GlobalContext";
import { useState } from "react";
import { pythonVmInstance } from "utils/AxiosInstances/RareboxPythonInstance";

const InputFileld = ({ width, column, required, data, setData, sx, message }) => {
	const handleFileChange = (e) => {
		const newFile = e.target.files[0];
		if (newFile) {
			const newFileName = column.id + "-" + newFile?.name;
			const modifiedFile = new File([newFile], newFileName, { type: newFile.type });
			setData({ ...data, [column.id]: modifiedFile });
		}
	};
	return (
		<Stack sx={{ width }} spacing={1}>
			<InputLabel>{column?.name}</InputLabel>

			<TextField
				sx={sx ? sx : {}}
				size="small"
				type={"file"}
				id={column?.id}
				onChange={(e) => handleFileChange(e)}
				fullWidth
			/>
		</Stack>
	);
};

const fileTypes = {
	GINESYS_POS_REPORT: { name: "GINESYS POS REPORT", key: "GINESYS_POS_REPORT", id: "POS" },
	PHONE_PE_REPORT: { name: "PHONE PE - REPORT", key: "PHONE_PE_REPORT", id: "PHONEPE" },
	HDFC_BANK_STATEMENT: { name: "HDFC BANK STATEMENT", key: "HDFC_BANK_STATEMENT", id: "HDFC" },
	UPI_REFUND_FILE: { name: "UPI REFUND FILE", key: "UPI_REFUND_FILE", id: "REFUND" },
	MPR_REPORT_HDFC: { name: "MPR REPORT - HDFC", key: "MPR_REPORT_HDFC", id: "MPR" },
	AMERICAN_EXPRESS_REPORT: { name: "AMERICAN EXPRESS - REPORT", key: "AMERICAN_EXPRESS_REPORT", id: "AMEX" },
	MPR_REPORT_PINE_LAB: { name: "MPR REPORT - PINE LAB", key: "MPR_REPORT_PINE_LAB", id: "PINELAB" },
	CMS: { name: "CMS FILE", key: "CMS_FILE", id: "CMS" },
	WRITER: { name: "WRITER FILE", key: "WRITER", id: "WRITER" },
};

const FileUpload = () => {
	const [files, setFiles] = useState([]);
	const [open, setOpen] = useState(false);
	const [errors, setErrors] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();

	const handleClose = (event, reason) => {
		setErrors([]);
		setOpen(false);
	};

	const onFormSubmit = (e) => {
		e.preventDefault();

		let form = new FormData();
		console.log(files);
		if (files.length === 0) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Please upload some files and try again",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setLoadingOpen(true);
		Object.keys(files).forEach((file, i) => {
			form.append(`files[]`, files[file]);
		});
		pythonVmInstance
			.post("/cash-card-upload", form, { headers: { "Content-Type": "multipart/form-data" } })
			.then((res) => {
				console.log(res.data);
				setErrors(res.data);
				setLoadingOpen(false);

				setOpen(true);

				document.getElementById("POS").value = "";
				document.getElementById("PHONEPE").value = "";
				document.getElementById("AMEX").value = "";

				document.getElementById("HDFC").value = "";
				document.getElementById("REFUND").value = "";
				document.getElementById("MPR").value = "";

				document.getElementById("PINELAB").value = "";
				document.getElementById("CMS").value = "";
				document.getElementById("WRITER").value = "";

				setFiles([]);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Something went wrong",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};

	return (
		<Stack maxWidth="1200px" margin="auto" spacing={2} component="form" onSubmit={onFormSubmit}>
			<Typography variant="h5" width="100%" pt={2} pb={2}>
				UPLOAD FILES
			</Typography>
			<Box rowGap={2} display="flex" columnGap="3%" flexWrap="wrap">
				{Object.keys(fileTypes).map((row) => {
					return <InputFileld data={files} setData={setFiles} column={fileTypes[row]} required width="30%" />;
				})}
			</Box>
			<Button
				size="large"
				startIcon={<CloudUpload />}
				variant="contained"
				type="submit"
				sx={{ width: "200px", alignSelf: "center" }}>
				Upload
			</Button>
			<Dialog maxWidth="lg" open={open}>
				<DialogTitle>FILE UPLOAD RESULTS</DialogTitle>
				<DialogContent>
					{errors.length
						? errors?.map((err) => (
								<List>
									<DialogContentText color={err?.error ? "red" : "green"}>
										{err.filename} : {err.status}
									</DialogContentText>
								</List>
						  ))
						: ""}
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} variant="outlined">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};

export default FileUpload;
