import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { attributeGroups, attributeValueAssociationName, inputTypes, styleTableColumns } from "data/pimData";
import { PimState } from "context/PimContext";
import { AddRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Autocomplete, TextField, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState } from "react";

const Filter = ({ filter, setFilter, submitFunction, columns, allShortNames }) => {
	// const [columns, setColumns] = useState(columns);
	// console.log(allShortNames, "allShortNames");
	const handleFilterClick = () => {
		if (typeof submitFunction === "function") submitFunction();
	};
	const handleSelectChange = (val, col) => {
		console.log(val, col);
		setFilter(() => {
			// if (val === "select") delete filter[col];
			// else
			filter[col] = val;

			return { ...filter };
		});
	};

	const getShortName = () => {
		const result = allShortNames
			.filter((value) => value !== null)
			.map((value) => ({
				label: value,
				key: value,
			}));
		console.log("allShort Obj", result);
		// result.push();
		return [{ label: "Stores(ALL)", key: "select" }, ...result];
	};
	return (
		<Box
			display="flex"
			width="85%"
			columnGap={1}
			alignItems="center"
			justifyContent="flex-end"
			flexWrap="wrap"
			sx={{ columnGap: "2%", rowGap: 2 }}
			// sx={{ background: "var(--grayBgColor)" }}
			p={1}
			borderRadius={1}>
			{/* <Typography variant="h6" color={"grey"} width="100%">
					Filter
				</Typography> */}
			{Object.keys(columns)?.map((col) => {
				const row = columns[col];
				// console.log("row", row);
				return (
					<Autocomplete
						disablePortal
						disableClearable
						size="small"
						id="combo-box-demo"
						options={col == "status" ? row.options : getShortName()}
						sx={{ minWidth: row.width ? row.width : 160 }}
						onChange={(e, newVal) => handleSelectChange(newVal.key, row.key)}
						renderInput={(params) => <TextField {...params} label={row.name} />}
					/>
				);
			})}
			<Box>
				<Button
					variant="contained"
					sx={{ height: "42px" }}
					startIcon={<FilterAltIcon />}
					onClick={() => handleFilterClick()}>
					Filter
				</Button>
			</Box>
		</Box>
	);
};

export default Filter;
