import Navbar from "./Navbar";
import { categories } from "../../../data/categories";
import { Outlet } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { useState } from "react";
import { AuthState } from "context/AuthContext";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CategoryDrawer from "../../Category/CategoryDrawer";
const DefaultLayout = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const { user } = AuthState();

	return (
		<div style={{ width: "100%" }}>
			<Navbar setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
			<Box height={60} />
			<Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(!drawerOpen)} sx={{ width: "50px" }}>
				<Box width="100%">
					<IconButton
						size="small"
						sx={{ float: "right" }}
						width="50px"
						onClick={() => setDrawerOpen(!drawerOpen)}>
						<CloseIcon />
					</IconButton>
				</Box>
				{categories && categories.map((row) => <CategoryDrawer key={row.key} data={row} />)}
			</Drawer>
			{user && <Outlet />}
		</div>
	);
};

export default DefaultLayout;
