import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import { PimState } from "context/PimContext";
import { constraints, inputTypes } from "data/pimData";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import TextInputField from "./InputComponents/TextInputField";
import SelectInputField from "./InputComponents/SelectInputField";

const DisplayInfoWithEditTab = ({ columnData, data, modalOpen, setModalOpen, submitFunction, width }) => {
	const [updatedData, setUpdatedData] = useState(data);

	const { attributeValues } = PimState();
	const handleCloseModal = () => {
		setUpdatedData(data);
		setModalOpen(false);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		let obj = {};
		// columnData?.forEach((col) => (obj[col.name] = updatedData[col.name]));
		if (typeof submitFunction === "function") submitFunction(obj, setModalOpen, columnData);
	};
	return (
		<Box width="100%" display="flex" flexWrap="wrap" columnGap="1%">
			{columnData?.map((row) => {
				// const isDate = labelData[colName]?.isDate;
				return (
					<Stack
						width={width ? width : "30%"}
						spacing={0}
						key={row?.name}
						overflow="hidden"
						direction="row"
						border={`1px solid white`}
						boxSizing="border-box">
						<Typography
							variant="subtitle2"
							color={grey[200]}
							sx={{ width: "38%", p: 1, background: grey[600], overflow: "hidden" }}>
							{row?.name}
						</Typography>
						<Typography
							variant="subtitle2"
							sx={{ width: "55%", p: 1, background: grey[200], fontWeight: 400, overflow: "hidden" }}>
							{data && data[row?.key] !== undefined ? data[row?.key] : ""}
						</Typography>
					</Stack>
				);
			})}
			<Dialog
				fullWidth
				maxWidth="lg"
				open={modalOpen}
				onClose={handleCloseModal}
				component="form"
				onSubmit={handleFormSubmit}>
				<Box width="98%" p={2} boxSizing="border-box">
					<Stack width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Stack direction="row" width="100%" flexWrap="wrap" columnGap="3%" rowGap={1}>
							<Typography textAlign="center" variant="h6" width="100%">
								Edit Details
							</Typography>
							{columnData?.map((column) => {
								if (column.input_type === inputTypes.enter)
									return (
										<TextInputField
											sx={{
												width: "55%",
												p: 1,
												background: grey[200],
												fontWeight: 400,
												overflow: "hidden",
											}}
											data={updatedData}
											setData={setUpdatedData}
											column={column}
											width={width ? width : "100%"}
											required={column?.type[constraints.notNull]}
											type={column.type.datatype}
										/>
									);
								else {
									return (
										<SelectInputField
											sx={{
												width: "55%",
												p: 1,
												background: grey[200],
												fontWeight: 400,
												overflow: "hidden",
											}}
											data={updatedData}
											setData={setUpdatedData}
											column={column}
											width={width ? width : "100%"}
											options={column?.attributeMasterValues}
											required={column?.type[constraints.notNull]}
										/>
									);
								}
							})}
						</Stack>
					</Stack>
				</Box>
				<DialogActions>
					<Button variant="outlined" onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DisplayInfoWithEditTab;
