import { Box, Chip, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { GlobalState } from "context/GlobalContext";
import { final_col, required_col } from "data/myntraRecoData";
import React, { useState } from "react";
import { DownloadRounded } from "@mui/icons-material";
import FileSaver from "file-saver";
import { MyntraRecoState } from "context/MyntraReco";
import StartDateEndDateCopm from "./dateComponent";
import { pythonVmInstance } from "utils/AxiosInstances/RareboxPythonInstance";

export default function MyntraSummary() {
	const [selectedData, setSelectedData] = useState([]);
	const [totalCount, setTotalCount] = useState(1);
	const [page, setPage] = useState(0);
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const { dashboardData, setDashboardData, startDate, setStartDate, endDate, setEndDate } = MyntraRecoState();
	const [dropdownValue, setDropdownValue] = useState("All");

	function transformString(str) {
		return str
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	}
	let myntraTableCol = [];

	required_col.forEach((col) => {
		const colum = {
			field: col,
			headerName: transformString(col),
			align: "center",
			headerAlign: "center",
			width: 180,
		};
		myntraTableCol.push(colum);
	});
	const colum = {
		field: "status",
		headerName: "status",
		align: "center",
		headerAlign: "center",
		width: 180,
		renderCell: ({ row }) => {
			return (
				<div>
					<Chip color={row.status === "closed" ? "success" : "error"} label={row.status.toUpperCase()} />
				</div>
			);
		},
	};
	myntraTableCol.push(colum);

	const applyToFixed = (data, decimalPlaces) => {
		return data.map((row) => {
			let newRow = { ...row };
			for (let key in newRow) {
				if (typeof newRow[key] === "number") {
					newRow[key] = newRow[key].toFixed(decimalPlaces);
				}
			}
			return newRow;
		});
	};

	const replaceNullValues = (data) => {
		return data.map((item) => {
			return Object.fromEntries(Object.entries(item).map(([key, value]) => [key, value === null ? "-" : value]));
		});
	};

	const handaleValues = (data1, data2, operation) => {
		if (data1 === null && data2 === null) {
			return null;
		} else if (operation === "sub") {
			return (data1 - data2).toFixed(2);
		} else if (operation === "add") {
			return (data1 + data2).toFixed(2);
		} else if (operation === "equal") {
			return data1 === data2;
		}
	};

	const handleDifferenceAdd = (data) => {
		return data.map((items) => {
			return {
				...items,
				tcs_amt_diff: handaleValues(items.tcs_amount, items.calculated_tcs_amt, "sub"),
				tds_amt_diff: handaleValues(items.tds_amount, items.calculated_tds_amt, "sub"),
				commission_percentage_diff: handaleValues(items.commission_percentage, items.calculated_commission_percentage, "sub"),
				platform_fees_diff: handaleValues(items.platform_fees, items.calculated_platform_fee, "sub"),
				forward_shipping_fee_diff: handaleValues(items.forward_shipping_fee, items.calculated_shepping_fee, "sub"),
				fixed_fee_diff: handaleValues(items.fixed_fee, items.calculated_fixed_fee, "sub"),
				payment_gateway_fee_diff: handaleValues(items.payment_gateway_fee, items.calculated_paymentgatway_fee, "sub"),
				total_tax_on_logistics_diff: handaleValues(items.total_tax_on_logistics, items.calculated_total_tax_on_logistics, "sub"),
				forward_logistics_deduction_diff: handaleValues(
					items.forward_total_logistics_deduction,
					items.calculated_total_logistic_deduction,
					"sub"
				),
				shipment_zone_classification_diff: handaleValues(items.shipment_zone_classification, items.calculated_zonal, "equal"),
				total_settled_amt_diff: handaleValues(items.total_settled_amt_in_month, items.calculated_total_expected_settlement_amt, "sub"),
				reverse_shipping_fee_diff: handaleValues(items.reverse_shipping_fee, items.calculated_reverse_shepping_fee, "sub"),
				reverse_total_tax_on_logistics_diff: handaleValues(
					items.reverse_total_tax_on_logistics,
					items.calculated_reverse_tax_on_logistics,
					"sub"
				),
				reverse_shipment_zone_classification_diff: handaleValues(
					items.reverse_shipment_zone_classification,
					items.calculated_reverse_zonal,
					"equal"
				),
				reverse_total_logistics_deduction_diff: handaleValues(
					items.reverse_total_logistics_deduction,
					items.calculated_total_reverse_logistics,
					"add"
				),
				total_reverse_settled_amt_diff: handaleValues(items.total_reverse_settled_amt_in_month, items.total_calculated_reverse_amt, "sub"),
			};
		});
	};
	const cleanJsonString = (jsonString) => {
		return jsonString.replace(/NaN/g, "null");
	};
	const handleGetResult = async (page, page_size) => {
		setLoadingOpen(true);

		await pythonVmInstance
			.get(`/reco/summary`, {
				params: {
					startDate,
					endDate,
					page,
					page_size,
					type: dropdownValue,
				},
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				// const cleanedJsonString = cleanJsonString(res.data);
				// let data = JSON.parse(cleanedJsonString);
				let data = res.data.rows;
				if (data.length === 0) {
					setSelectedData([]);
					setSnackBarOpen(true);
					setLoadingOpen(false);
					setSnackBar({
						...snackBar,
						message: "No data to display",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					return;
				}
				let totalRowCount = res.data.totalRowCount;
				const flattenedData = data.flat();
				let finalData = handleDifferenceAdd(data);
				setDashboardData(finalData);
				setTotalCount(totalRowCount);
				const cleanedData = replaceNullValues(finalData);
				setSelectedData(cleanedData);
				setLoadingOpen(false);
			})

			.catch((err) => {
				console.log("flattenedData12", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable to Load Data",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		setLoadingOpen(false);
	};

	const handleDownload = async () => {
		try {
			// setDownloading(true);
			setLoadingOpen(true);
			await pythonVmInstance({
				url: `/reco/download`,
				params: {
					startDate,
					endDate,
					type: dropdownValue,
				},
				headers: {
					"Content-Type": "application/json",
				},
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `${startDate}_${endDate}_${dropdownValue}_myntra_reco.csv`);
				// setDownloading(false);
				setLoadingOpen(false);
			});
		} catch (error) {
			setSnackBarOpen(true);
			setLoadingOpen(false);
			setSnackBar({
				...snackBar,
				message: "Failed to download file",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	const handlePageChange = (newpage) => {
		setPage(newpage + 1);
		handleGetResult(newpage + 1, 100);
	};
	const transformedRows = applyToFixed(selectedData, 2);
	return (
		<div>
			<Typography variant="h5" width="100%" pt={2} pb={2}>
				SUMMARY
			</Typography>
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<StartDateEndDateCopm
					setStartDate={(e) => setStartDate(e)}
					startDate={startDate}
					endDate={endDate}
					setEndDate={(e) => setEndDate(e)}
					handleGetResult={handleGetResult}
					disabled={startDate.length === 0 || endDate.length === 0 ? true : false}
					dropdownList={["All", "Closed", "Open"]}
					dropdownValue={dropdownValue}
					setDropdownValue={(e) => setDropdownValue(e)}
				/>
				<Box pt={2} sx={{ display: "flex", alignItems: "center" }}>
					<Button
						disabled={startDate.length === 0 || endDate.length === 0 ? true : false}
						startIcon={<DownloadRounded />}
						variant="contained"
						sx={{ width: 150, height: "40px" }}
						onClick={handleDownload}>
						{"Download"}
					</Button>
				</Box>
			</div>
			{selectedData.length > 0 && (
				<Stack width="100%">
					<DataGrid
						// key={selectedData}
						getRowId={(row) => row.Order_No + row.Date}
						rows={transformedRows}
						columns={myntraTableCol}
						sx={{
							maxHeight: 430,
							"& .MuiDataGrid-row": {
								cursor: "pointer",
							},
							"& .MuiDataGrid-cell": {
								padding: "10px",
							},
						}}
						showColumnVerticalBorder
						disableRowSelectionOnClick
						pagination
						pageSize={100}
						pageSizeOptions={[100]}
						rowCount={totalCount}
						paginationMode="server"
						onPaginationModelChange={(params) => handlePageChange(params.page)}
						page={page}
					/>
				</Stack>
			)}
		</div>
	);
}
