import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useEffect, useState } from "react";
import { Box, Button, Chip, CircularProgress, Grid, InputLabel, Paper, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import List from "@mui/material/List";
import Modal from "@mui/material/Modal";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AttributeForm from "./AttributeForm/AttributeForm";
import { Edit } from "@mui/icons-material";
import SearchBar from "components/ReusableComponents/SearchBar";

// Styled component for resizable box
const ResizableBox = styled(Paper)(({ theme, width }) => ({
	width: width,
	height: "100%", // Set the height as per your requirement
	overflowY: "scroll",
	resize: "horizontal",
	backgroundColor: theme.palette.primary.grey,
}));

const ResizableDivider = styled(Divider)({
	cursor: "ew-resize",
	width: "4px", // Set the width of the divider as per your requirement
});

const AttributeMaster = () => {
	const [box1Width, setBox1Width] = useState("20%");
	const [box2Width, setBox2Width] = useState("80%");
	const [attributeList, setAttributeList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isAttributeLoading, setIsAttributeLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [attributeDetails, setAttributeDetails] = useState({});
	const [attributeName, setAttributeName] = useState();
	const [action, setAction] = useState("CREATE");
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [selectedAttribute, setAttribute] = useState("");
	const [search, setSearch] = useState("");

	useEffect(() => {
		(async function fetchData() {
			setIsLoading(true);
			await axios
				.get("/api/pim/get_attribute_list")
				.then((res) => {
					if (res.status === 200 && res.data) {
						setAttributeList(res.data);
					}
				})
				.catch((err) => {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: err.message,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				});
			setIsLoading(false);
		})();
	}, []);

	const handleResize = (event) => {
		const box1NewWidth = `${event.clientX}px`;
		const box2NewWidth = `${window.innerWidth - event.clientX}px`;
		if (event.clientX < 400 && event.clientX > 150) {
			setBox1Width(box1NewWidth);
			setBox2Width(box2NewWidth);
		}
	};

	const handleRemoveEventListener = () => {
		window.removeEventListener("mousemove", handleResize);
		window.removeEventListener("mouseup", handleRemoveEventListener);
	};

	const handleAttributeClick = async (attribute) => {
		setAttribute(attribute.key);
		setIsAttributeLoading(true);
		setAttributeName(attribute.name);
		await axios
			.get(`/api/pim/get_attribute_detail/${attribute.key}`)
			.then((res) => {
				if (res.status === 200 && res.data) {
					console.log(res.data);
					setAttributeDetails(res.data);
					setIsAttributeLoading(false);
				}
			})
			.catch((err) => {
				setIsAttributeLoading(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	function handleSearchSubmit() {
		if (search) {
			let newArr = [];
			let regex = new RegExp(search, "i");
			attributeList.forEach((attribute) => {
				console.log(search);
				console.log(regex.test(attribute.name));
				if (regex.test(attribute.name)) {
					newArr.push(attribute);
				}
			});
			if (newArr.length) {
				setAttributeList(newArr);
			}
		}
	}

	return (
		<Stack sx={{ display: "flex", flexDirection: "row", height: "79vh", width: "100%" }}>
			<ResizableBox width={box1Width}>
				<Box mt={2} display={"flex"} p={2} flexWrap={"wrap"}>
					<InputLabel>{"Search"}</InputLabel>
					<SearchBar
						value={search}
						setValue={setSearch}
						placeholder={"Enter attribute to search"}
						submitFunction={handleSearchSubmit}
						sx={{
							p: "2px",
						}}
					/>{" "}
				</Box>
				{!isLoading ? (
					<List height={400} width={360} itemSize={46} itemCount={200} overscanCount={5}>
						{attributeList.map((attribute, index) => (
							<ListItem key={index} component="div" disablePadding>
								<ListItemButton
									onClick={() => handleAttributeClick(attribute)}
									selected={selectedAttribute === attribute.key}>
									<ListItemText
										primary={`${attribute.name}`}
										// onClick={() => handleAttributeClick(attribute)}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				) : (
					<Grid container spacing={0} my={1} mx={3}>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton variant="text" width="80%" height={"40px"} />
						</Grid>

						{/* Add more grid items as needed */}
					</Grid>
				)}
			</ResizableBox>
			<ResizableDivider
				onMouseDown={(event) => {
					window.addEventListener("mousemove", handleResize);
					window.addEventListener("mouseup", handleRemoveEventListener);
				}}
			/>
			<ResizableBox width={box2Width}>
				<Stack display="flex" alignItems="end" justifyContent="space-between" m={4}>
					<Button
						variant="contained"
						sx={{ height: "50px" }}
						startIcon={<AddRoundedIcon />}
						onClick={() => {
							setOpen(true);
							setAction("CREATE");
						}}>
						CREATE NEW ATTRIBUTE
					</Button>
				</Stack>
				<Stack display="flex" alignItems="center" justifyContent="space-between">
					<Modal
						open={open}
						onClose={() => setOpen(!open)}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
						sx={{ minHeight: "100%" }}
						disableEnforceFocus>
						<Stack
							sx={{
								width: 1040,
								bgcolor: "background.paper",
								p: 4,
								borderRadius: 2,
								maxHeight: 450,
								overflow: "auto",
								margin: "auto",
								my: 8,
							}}>
							<AttributeForm
								action={action}
								attributeDetails={attributeDetails}
								attributeName={attributeName}
								attributeList={attributeList}
								setAttributeList={setAttributeList}
							/>
						</Stack>
					</Modal>
				</Stack>
				<Box>
					{!isAttributeLoading ? (
						<Stack>
							<Divider sx={{ m: 2 }} textAlign="center">
								{attributeName} DETAILS
							</Divider>
							{attributeDetails && attributeName ? (
								<Box display={"flex"} justifyContent={"end"}>
									<Button
										variant="contained"
										sx={{ height: 40, mx: 4 }}
										endIcon={<Edit />}
										onClick={() => {
											setAction("UPDATE");

											setOpen(true);
										}}>
										Edit
									</Button>
								</Box>
							) : (
								""
							)}

							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									flexWrap: "wrap",
									textTransform: "uppercase",
									rowGap: 1,
									columnGap: 1,
									m: 2,
								}}>
								{Object.keys(attributeDetails).map((attr, index) =>
									attr !== "type" &&
									attr !== "value" &&
									attr !== "data" &&
									attr !== "attributeMasterValues" &&
									attr !== "toAdd" ? (
										<ListItem key={index} sx={{ width: "22%", display: "flex", flexWrap: "wrap" }}>
											<ListItemText primary={attr} secondary={`${attributeDetails[attr]}`} />
										</ListItem>
									) : (
										""
									)
								)}
							</Box>
							{attributeDetails.value ? (
								<Typography mx={4} my={3}>
									Values
								</Typography>
							) : (
								""
							)}
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									flexWrap: "wrap",
									rowGap: 1,
									columnGap: 1,
									mx: 3,
									pb: 2,

									textTransform: "uppercase",
								}}>
								{attributeDetails?.value
									? attributeDetails?.value.map((val, index) => <Chip label={val} key={index}></Chip>)
									: ""}
							</Box>
						</Stack>
					) : (
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress />
						</Box>
					)}
				</Box>
			</ResizableBox>
		</Stack>
	);
};

export default AttributeMaster;
