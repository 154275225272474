import React, { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

const BusinessPlanningContext = createContext();

const BusinessPlanningProvider = ({ children }) => {
	const [allBp, setAllBp] = useState();
	const [selectedBp, setSelectedBp] = useState();
	const [selectedStore, setSelectedStore] = useState();
	const [selectedCategory, setSelectedCategory] = useState();
	const [selectedSubCategory, setSelectedSubCategory] = useState();

	return (
		<BusinessPlanningContext.Provider
			value={{
				allBp,
				setAllBp,
				selectedBp,
				setSelectedBp,
				selectedStore,
				setSelectedStore,
				selectedCategory,
				setSelectedCategory,
				selectedSubCategory,
				setSelectedSubCategory,
			}}>
			<Outlet />
		</BusinessPlanningContext.Provider>
	);
};

export const BusinessPlanState = () => {
	return useContext(BusinessPlanningContext);
};

export default BusinessPlanningProvider;
