import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { grey } from "@mui/material/colors";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
	{
		label: "San Francisco – Oakland Bay Bridge, United States",
		imgPath: "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
	},
	{
		label: "Bird",
		imgPath: "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
	},
	{
		label: "Bali, Indonesia",
		imgPath: "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
	},
	{
		label: "Goč, Serbia",
		imgPath: "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
	},
];

const ImageSlider = ({ imgWidth, imgHeight, column, images }) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = images.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};
	return (
		<Box width="100%" height="100%" position="relative">
			<AutoPlaySwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={activeStep}
				onChangeIndex={handleStepChange}
				interval={20000}
				enableMouseEvents>
				{images?.map((step, index) => (
					<div key={step.label} style={{ height: "100%", width: "100%" }}>
						{Math.abs(activeStep - index) <= 2 ? (
							<Box
								component="img"
								sx={{
									height: imgHeight,
									width: imgWidth,
									overflow: "hidden",
									display: "block",
									objectFit: "cover",
								}}
								src={step[column]}
								alt={"img"}
							/>
						) : null}
					</div>
				))}
			</AutoPlaySwipeableViews>
			<MobileStepper
				sx={{
					position: "absolute",
					bottom: 0,
					left: 0,
					width: "94%",
					color: "white",
					textShadow: "1px 1px 1px grey",
					background: "#0000",
				}}
				variant="text"
				steps={maxSteps}
				position="static"
				activeStep={activeStep}
				nextButton={
					<Button
						endIcon={<KeyboardArrowRight />}
						size="small"
						onClick={handleNext}
						disabled={activeStep === maxSteps - 1}
						variant="contained">
						Next
					</Button>
				}
				backButton={
					<Button
						startIcon={<KeyboardArrowLeft />}
						size="small"
						onClick={handleBack}
						disabled={activeStep === 0}
						variant="contained">
						Back
					</Button>
				}
			/>
		</Box>
	);
};

export default ImageSlider;
