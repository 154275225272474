import Navbar from "./Navbar";
import { categories } from "../../../data/categories";
import { Outlet } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CategoryDrawer from "../../Category/CategoryDrawer";
const DefaultOpenLayout = () => {
	return (
		<div style={{ width: "100%" }}>
			<Navbar />
			<Outlet />
		</div>
	);
};

export default DefaultOpenLayout;
