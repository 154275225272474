import { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Typography from "@mui/material/Typography";
import Queries from "./Queries";

//for tab section
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const tabStyle = { textTransform: "capitalize", fontSize: "16px", width: "170px" };

const CustomQueries = () => {
	const [tab, setTab] = useState(0);
	const [history, setHistory] = useState();

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<Stack maxWidth="1200px" margin="auto" spacing={2}>
			<Typography variant="h4" width="100%" pt={4}>
				Custom Queries
			</Typography>
			<Box height="450px">
				<Tabs
					value={tab}
					onChange={handleTabChange}
					aria-label="basic tabs example"
					sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tab sx={tabStyle} label="Run Query" {...a11yProps(0)} />
				</Tabs>
				<Box display={tab === 0 ? "block" : "none "}>
					<Queries history={history} setHistory={setHistory} />
				</Box>
			</Box>
		</Stack>
	);
};

export default CustomQueries;
