import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GlobalState } from "context/GlobalContext";
import AddNewFactory from "./AddNewFactory/AddNewFactory";

import axios from "axios";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ClearRounded from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FactoryInfoModal from "./FactoryInfoModal/FactoryInfoModal";
import moment from "moment";
import EditFactoryDetails from "../FactoryDetails/EditFactoryDetails/EditFactoryDetails";
import {
	EditCompanyDetailsModalTypes,
	factoryUrlParamName,
	foreignKeys,
	supplierDrawerElements,
	supplierUrlParamName,
} from "data/supplierPageData";
import { useNavigate, useSearchParams } from "react-router-dom";

const factoryTablecolumns = [
	{
		field: "name",
		headerName: "Name",
		align: "center",
		headerAlign: "center",
		width: 180,
	},
	{
		field: "factory_liscence_no",
		headerName: "Factory Liscence No",
		align: "center",
		headerAlign: "center",
		width: 180,
	},
	{
		field: "factory_liscence_expiry_date",
		headerName: "Liscence Expiry Date",
		align: "center",
		headerAlign: "center",
		width: 180,
		renderCell: ({ row }) => {
			return <div>{moment(row.factory_liscence_expiry_date).format("DD-MM-YYYY")}</div>;
		},
	},
	{ field: "factory_city", headerAlign: "center", align: "center", headerName: "City", width: 140 },
	{ field: "factory_state", headerAlign: "center", align: "center", headerName: "State", width: 130 },
	{
		field: "factory_gst_no",
		headerAlign: "center",
		align: "center",
		headerName: "GST No",
		width: 130,
	},
	{
		field: "factory_pincode",
		headerAlign: "center",
		align: "center",
		headerName: "Factory Pincode",
		width: 130,
	},
];

const AllFactories = ({ factories, setFactories, selectedFactory, setSelectedFactory, companyData, setTab }) => {
	// def value is true becoz for textfield disabled =true;
	const [modalOpen, setModalOpen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleGridRowClick = async (data) => {
		try {
			const factory = await axios.get(`/api/supplier/factory?id=${data.id}`);
			setSelectedFactory({ ...factory.data.result });
			setSearchParams((params) => {
				params.set(supplierUrlParamName, supplierDrawerElements.factory.key);
				params.set(factoryUrlParamName, data.id);
				return params;
			});
			setTab(supplierDrawerElements.factory.key);
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Get factory details",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	const createFactory = async (body, setModalOpen) => {
		const obj = {};
		obj[foreignKeys.supplier] = companyData.id;
		try {
			let res = await axios.post(`/api/supplier/create_factory`, { ...body, ...obj });
			setSelectedFactory({ ...selectedFactory, ...body });
			setFactories([...factories, res.data.result]);
			setSearchParams(supplierUrlParamName, supplierDrawerElements.factory.key);
			setModalOpen(false);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Crated",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Update Details",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	return (
		<Stack maxWidth="1100px" spacing={3} margin="auto">
			<Box display="flex" alignItems="center" pt={4} justifyContent="space-between">
				<Typography variant="h4">All Factories</Typography>
				<Button
					variant="contained"
					sx={{ height: "50px" }}
					startIcon={<AddRoundedIcon />}
					onClick={() => setModalOpen(true)}>
					New Factory
				</Button>
			</Box>
			{/* table */}
			<Stack width="100%">
				<DataGrid
					key={factories}
					rows={factories}
					columns={factoryTablecolumns}
					sx={{
						minHeight: 430,
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-cell": {
							padding: "10px",
						},
					}}
					showColumnVerticalBorder
					onRowClick={(params) => {
						handleGridRowClick(params.row);
					}}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
					}}
					autoHeight
					disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Stack>
			{/* new Costing Modal */}
			{/* <Dialog open={modalOpen} onClose={handleNewCostingModalClose} scroll="body" maxWidth="lg" fullWidth>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<DialogTitle variant="h4">Add Factory</DialogTitle>
					<IconButton sx={{ width: "50px", height: "50px" }} onClick={handleNewCostingModalClose}>
						<ClearRounded sx={{ transform: "scale(1.3)" }} />
					</IconButton>
				</Box>
				<Box p={2}>
					<AddNewFactory factories={factories} setFactories={setFactories} setModalOpen={setModalOpen} />
				</Box>
			</Dialog> */}
			{/* edit Costing Modal */}
			{/* <Dialog open={editModalOpen} onClose={handleEditModalClose} scroll="body" maxWidth="lg" fullWidth>
				<Box display="flex" justifyContent="space-between" p="5px 10px" alignItems="center">
					<Box display="flex" alignItems="center">
						<DialogTitle>Edit Costing</DialogTitle>
					</Box>
					{modalEditable ? (
						<Button variant="contained" onClick={() => setModalEditable(false)} sx={{ height: "40px" }}>
							Edit
						</Button>
					) : (
						<Button
							variant="contained"
							onClick={() => {
								setModalEditable(true);
								setSelectedCosting(factories.filter((row) => row.id === selectedCosting.id)[0]);
							}}
							sx={{ height: "40px" }}>
							Cancel Edit
						</Button>
					)}
					<IconButton
						sx={{ width: "50px", height: "50px", marginLeft: "auto" }}
						onClick={handleEditModalClose}>
						<ClearRounded sx={{ transform: "scale(1.3)" }} />
					</IconButton>
				</Box>
				<Box p={2}>
					<FactoryInfoModal
						factories={factories}
						setFactories={setFactories}
						selectedCosting={selectedCosting}
						setSelectedCosting={setSelectedCosting}
						setEditModalOpen={setEditModalOpen}
						modalEditable={modalEditable}
						setModalEditable={setModalEditable}
					/>
				</Box>
			</Dialog> */}
			<EditFactoryDetails
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				basicDetails={selectedFactory}
				setBasicDetails={setSelectedFactory}
				type={EditCompanyDetailsModalTypes.onbarding}
				submitFunction={createFactory}
			/>
		</Stack>
	);
};

export default AllFactories;
