import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import {
	companyBasicDetailDummyValues,
	foreignKeys,
	supplierDrawerElements,
	supplierIdParamName,
} from "data/supplierPageData";
import CompanyDetails from "components/SupplierPage/SupplierUser/CompanyDetails/CompanyDetails";
import AllFactories from "components/SupplierPage/SupplierUser/AllFactories/AllFactories";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import FactoryDetails from "components/SupplierPage/SupplierUser/FactoryDetails/FactoryDetails";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import Agreements from "components/SupplierPage/SupplierUser/Agreemens/Agreements";
import SupplierStatus, { suppliers_atrr } from "components/SupplierPage/SupplierUser/SupplierStatus/supplierStatus";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import Stack from "@mui/material/Stack";

function SupplierUser() {
	const [tab, setTab] = useOutletContext();
	const [companyData, setCompanyData] = useState();
	const [factories, setFactories] = useState();
	const [selectedFactory, setSelectedFactory] = useState();
	const [isOnboarded, setIsOnboarded] = useState(true);

	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [open, setOpen] = useState(false);
	const [approveOpen, setApproveOpen] = useState(false);
	const [rejectedData, setRejectedData] = useState({});
	const [allApproved, setAllApproved] = useState(true);
	const [selectedType, setSelectedType] = useState("");
	const [CompyOrFact, setCompyOrFact] = useState("");
	const date = new Date();
	useEffect(() => {
		console.log("approve ran------");
		handleAllApprovedCount();
	}, [companyData]);
	const handleAllApprovedCount = () => {
		let totalCount = 0;
		let approvedCount = 0;
		companyData &&
			companyData?.supplierApproval.forEach((data) => {
				totalCount += 1;
				if (data.approved) {
					approvedCount += 1;
				}
			});
		factories &&
			factories?.forEach((fact) => {
				fact?.factoryApproval.forEach((data) => {
					totalCount += 1;
					if (data.approved) {
						approvedCount += 1;
					}
				});
			});
		if (approvedCount !== 0 && totalCount === approvedCount) {
			setAllApproved(false);
		}
	};
	const handleClickOpen = (data) => {
		setRejectedData(data);
		setOpen(true);
	};
	const handleApprovalOpen = (type, mainType) => {
		setSelectedType(type);
		setCompyOrFact(mainType);
		setApproveOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleSubmit = () => {
		let updatedData = [];
		let selectedApproval = "";
		if (rejectedData?.factory_id === null) {
			companyData?.supplierApproval.forEach((data) => {
				if (data.type === rejectedData.type) {
					const updated = rejectedData;
					updatedData.push(updated);
					selectedApproval = updated;
				} else {
					updatedData.push(data);
				}
			});
		} else {
			selectedFactory?.factoryApproval.forEach((data) => {
				if (data.type === rejectedData.type) {
					const updated = rejectedData;
					updatedData.push(updated);
					selectedApproval = updated;
				} else {
					updatedData.push(data);
				}
			});
		}
		updateSupplier(selectedApproval, updatedData, rejectCallback);
	};
	const rejectCallback = (updatedData) => {
		if (rejectedData?.factory_id !== null) {
			setSelectedFactory({ ...selectedFactory, factoryApproval: updatedData });
			const updated = factories.filter((data) => data.id !== selectedFactory.id);
			setFactories([...updated, { ...selectedFactory, factoryApproval: updatedData }]);
		} else {
			setCompanyData({ ...companyData, supplierApproval: updatedData });
		}
		setOpen(false);
	};

	const handleTextArea = (e) => {
		const value = e.target.value;
		setRejectedData({ ...rejectedData, remark: value, updated_by: loginUser.email });
	};

	const updateSupplierStore = (type) => {
		let updatedData = [];
		let selectedApproval = "";
		companyData?.supplierApproval.forEach((data) => {
			if (data.type === type) {
				const updated = {
					...data,
					approved: true,
					updated_by: loginUser.email,
					approved_date: moment(date).format("YYYY-MM-DD"),
				};
				updatedData.push(updated);
				selectedApproval = updated;
			} else {
				updatedData.push(data);
			}
		});
		updateSupplier(selectedApproval, updatedData, supplierCallback);
	};
	const supplierCallback = (updatedData) => {
		setCompanyData({ ...companyData, supplierApproval: updatedData });
	};
	const factoryCallback = (updatedData) => {
		setSelectedFactory({ ...selectedFactory, factoryApproval: updatedData });
		const updated = factories.filter((data) => data.id !== selectedFactory.id);
		setFactories([...updated, { ...selectedFactory, factoryApproval: updatedData }]);
	};
	const updateFactoryStore = (type) => {
		let updatedData = [];
		let selectedApproval = "";
		selectedFactory?.factoryApproval.forEach((data) => {
			if (data.type === type) {
				const updated = { ...data, approved: true };
				updatedData.push(updated);
				selectedApproval = updated;
			} else {
				updatedData.push(data);
			}
		});
		updateSupplier(selectedApproval, updatedData, factoryCallback);
	};

	const updateSupplier = async (apibody, updatedData, callbackFn) => {
		setLoadingOpen(true);
		await axios
			.put(`/api/supplier/approval_update/${apibody.id}`, apibody)
			.then((res) => {
				setSnackBarOpen(true);
				callbackFn(updatedData);
				setSnackBar({
					...snackBar,
					message: `${CompyOrFact === "Company" ? "Supplier" : "Factory"} updated`,
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Unable to update ${CompyOrFact === "Company" ? "Supplier" : "Factory"} details `,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		setLoadingOpen(false);
	};

	const updateSupplierApi = async (apibody) => {
		setLoadingOpen(true);
		await axios
			.put(`/api/supplier/update/${companyData.id}`, apibody)
			.then((res) => {
				setSnackBarOpen(true);
				setCompanyData({ ...companyData, is_approved: true });
				setSnackBar({
					...snackBar,
					message: "Supplier updated",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				// setAllSuppliers([
				// 	{ ...companyData, ...apibody },
				// 	...allSuppliers.filter((row) => row.id !== selectedSupplier.id),
				// ]);
				// setModalEditable(true);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable to update supplier details ",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		setLoadingOpen(false);
	};

	const handleAllApprovedButton = async () => {
		if (companyData?.is_approved === false) await updateSupplierApi({ is_approved: true });
		else {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Supplier is Already Approved ",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	const handleApprove = () => {
		setApproveOpen(false);
		if (CompyOrFact === "Company") {
			updateSupplierStore(selectedType);
		} else if (CompyOrFact === "Factory") {
			updateFactoryStore(selectedType);
		}
	};

	useEffect(() => {
		const fetch = async () => {
			// console.log("fetch");
			const id = searchParams.get(supplierIdParamName);
			if (id) {
				setLoadingOpen(true);
				await axios
					.get(`/api/supplier/get_by_id?${supplierIdParamName}=${id}`)
					.then((res) => {
						let data = res.data;
						setCompanyData(data.result);
						setFactories(data.result.supplierFactories);
						// console.log(data.result);
						// console.log(data);
					})

					.catch((err) => {
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Unable to Load Supplier Data",
							severity: "error",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
					});
				setLoadingOpen(false);
			} else {
				navigate("/explore/suppliers");
			}
		};
		fetch();
	}, []);
	const tabComponents = [
		{
			...supplierDrawerElements.company_info,
			component: (
				<CompanyDetails
					pageData={companyData}
					setPageData={setCompanyData}
					isOnboarded={isOnboarded}
					setIsOnboarded={setIsOnboarded}
					foreignKey={foreignKeys.supplier}
					disableEdit={true}
					handleReject={(data) => handleClickOpen(data)}
					updateSupplierStore={(type) => handleApprovalOpen(type, "Company")}
				/>
			),
		},
		{
			...supplierDrawerElements.all_factories,
			component: (
				<AllFactories
					factories={factories}
					setFactories={setFactories}
					selectedFactory={selectedFactory}
					setSelectedFactory={setSelectedFactory}
					companyData={companyData}
					setTab={setTab}
				/>
			),
		},
		{
			...supplierDrawerElements.factory,
			component: (
				<FactoryDetails
					key={selectedFactory}
					foreignKey={foreignKeys.factory}
					pageData={selectedFactory}
					setPageData={setSelectedFactory}
					companyData={companyData}
					setTab={setTab}
					disableEdit={true}
					handleReject={(data) => handleClickOpen(data)}
					updateSupplierStore={(type) => handleApprovalOpen(type, "Factory")}
				/>
			),
		},
		{
			...supplierDrawerElements.agreements,
			component: (
				<Agreements
					foreignKey={foreignKeys.factory}
					setPageData={setSelectedFactory}
					companyData={companyData}
					setTab={setTab}
					disableEdit={true}
					handleReject={(data) => handleClickOpen(data)}
					updateSupplierStore={(type) => handleApprovalOpen(type, "Company")}
					setCompanyData={(data) => setCompanyData(data)}
				/>
			),
		},
		{
			...supplierDrawerElements.status,
			component: (
				<SupplierStatus
					foreignKey={foreignKeys.factory}
					allFactories={factories}
					setPageData={setSelectedFactory}
					companyData={companyData}
					setTab={setTab}
				/>
			),
		},
	];

	const logeduser = loginUser.role?.FINANCE_VIEWER ? true : false;

	return (
		<Box sx={{ width: "100%" }}>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<Button
					startIcon={<KeyboardBackspaceRoundedIcon />}
					variant="outlined"
					onClick={() => navigate("/explore/supplier_dashboard")}
					sx={{ m: 2 }}>
					Back
				</Button>
				{companyData?.is_approved ? (
					<Typography sx={{ color: "green", display: "flex", alignItems: "center" }} variant="h6">
						All Approved
					</Typography>
				) : (
					<Button
						variant="contained"
						disabled={allApproved || logeduser}
						onClick={() => handleAllApprovedButton()}
						sx={{ m: 2 }}>
						FINAL APPROVE
					</Button>
				)}
			</Stack>
			{companyData &&
				tabComponents &&
				tabComponents.map((row) => (
					<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
						{row.component}
					</Box>
				))}
			{/* <Modal
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				open={modalOpen}
				onClose={handleModalClose}>
				<Box
					width="300px"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{ background: "white", borderRadius: 4, p: 4, height: "100px" }}>
					{setSuccessful ? (
						<Stack alignItems="center" spacing={2}>
							<Typography variant="h6">{message}</Typography>
							<Button variant="outlined" onClick={handleModalClose}>
								Close
							</Button>
						</Stack>
					) : (
						<CircularProgress />
					)}
				</Box>
			</Modal> */}
			<React.Fragment>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{"Remark*"}</DialogTitle>
					<DialogContent>
						<textarea
							style={{ width: "500px", height: "100px", resize: "none" }}
							onChange={(e) => handleTextArea(e)}
						/>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={handleClose}>
							Close
						</Button>
						<Button
							disabled={rejectedData?.remark === "" && !rejectedData?.approved}
							variant="contained"
							onClick={handleSubmit}
							autoFocus>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
			<React.Fragment>
				<Dialog
					open={approveOpen}
					onClose={() => setApproveOpen(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					{/* <DialogTitle id="alert-dialog-title">{}</DialogTitle> */}
					<DialogContent>
						{`Are you sure you want to approve the ${CompyOrFact} ${suppliers_atrr[selectedType]}?`}
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={() => setApproveOpen(false)}>
							No
						</Button>
						<Button variant="contained" onClick={handleApprove}>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		</Box>
	);
}

export default SupplierUser;
