import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GlobalState } from "context/GlobalContext";

import axios from "axios";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ClearRounded from "@mui/icons-material/ClearRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditModal from "./EditModal/EditModal";
import { inputTypes } from "data/pimData";

const inputColumns = {
	ginesys_store_name: {
		name: "Ginesys Store Name",
		key: "NAME",
		group: "EBO_STORE_POS",
		type: { notNull: true, datatype: "string", unique: true },
		input_type: inputTypes.autoGenerate,
		display: true,
	},
	short_name: {
		name: "Store Short Name",
		key: "SHRTNAME",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.autoGenerate,
		display: true,
	},
	store_code: {
		name: "Store Code",
		key: "STORE_CODE",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.autoGenerate,
		display: true,
	},
	upi_id_1: {
		name: "UPI Id 1",
		key: "UPI_ID_1",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	upi_id_2: {
		name: "UPI Id 2",
		key: "UPI_ID_2",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	hdfc_card_tid_1: {
		name: "HDFC Card TID 1",
		key: "HDFC_CARD_TID_1",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	hdfc_card_tid_2: {
		name: "HDFC Card TID 2",
		key: "HDFC_CARD_TID_2",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	amex_id: {
		name: "Amex Id",
		key: "AMEX_ID",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	pinelab_id_1: {
		name: "Pinelab Id 1",
		key: "PINELAB_ID_1",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	pinelab_id_2: {
		name: "Pinelab Id 2",
		key: "PINELAB_ID_2",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	pinelab_id_3: {
		name: "Pinelab Id 3",
		key: "PINELAB_ID_3",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	pinelab_id_4: {
		name: "Pinelab Id 4",
		key: "PINELAB_ID_4",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	writer_cash_pickup: {
		name: "Writer Cash Pickup",
		key: "Writer Cash Pickup",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	phonepe_id: {
		name: "Phonepe Id",
		key: "PHONEPE_ID",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	store_closure_date: {
		name: "Close Date",
		key: "STORE_CLOSURE_DATE",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	new_short_name: {
		name: "New Short Name",
		key: "NEW_SHRTNAME",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
	new_name: {
		name: "New name",
		key: "NEW_NAME",
		group: "EBO_STORE_POS",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: inputTypes.enter,
		display: true,
	},
};

const tableColumns = [
	...Object.keys(inputColumns).map((col) => {
		return {
			field: inputColumns[col].key,
			headerName: inputColumns[col].name,
			width: 200,
			headerAlign: "center",
			align: "center",
		};
	}),
	// { field: "short_name", headerName: "SHORT NAME", width: 200, headerAlign: "center", align: "center" },
	// { field: "upi_id_1", headerName: "UPI ID 1", width: 140, headerAlign: "center", align: "center" },
	// { field: "upi_id_2", headerName: "UPI ID 2", width: 140, headerAlign: "center", align: "center" },
	// { field: "hdfc_card_tid_1", headerName: "HDFC CARD TID 1", width: 130, headerAlign: "center", align: "center" },
	// { field: "hdfc_card_tid_2", headerName: "HDFC CARD TID 2", width: 130, headerAlign: "center", align: "center" },
	// { field: "amex_id", headerName: "AMEX ID", width: 130, headerAlign: "center", align: "center" },
	// { field: "pinelab_id_1", headerName: "PINELAB ID 1", width: 130, headerAlign: "center", align: "center" },
	// { field: "pinelab_id_2", headerName: "PINELAB ID 2", width: 130, headerAlign: "center", align: "center" },
	// { field: "pinelab_id_3", headerName: "PINELAB ID 3", width: 130, headerAlign: "center", align: "center" },
	// { field: "pinelab_id_4", headerName: "PINELAB ID 4", width: 130, headerAlign: "center", align: "center" },
	// {
	// 	field: "writer_cash_pickup",
	// 	headerName: "WRITER CASH PICKUP",
	// 	width: 130,
	// 	headerAlign: "center",
	// 	align: "center",
	// },
	// {
	// 	field: "phonepe_id",
	// 	headerName: "Phonepe Id",
	// 	width: 130,
	// 	headerAlign: "center",
	// 	align: "center",
	// },
];

const StoreTidMaster = () => {
	const [allStores, setAllStores] = useState([]);
	const [selectedStore, setSelectedStore] = useState();

	// def value is true becoz for textfield disabled =true;
	const [modalEditable, setModalEditable] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);

	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleEditModalClose = () => {
		setModalEditable(true);
		setEditModalOpen(false);
	};
	const handleModalClose = () => {
		setModalOpen(false);
	};
	// console.log(selectedStore, allStores);
	const submitFunction = () => {};
	// fetch costings on load
	useEffect(() => {
		const fetch = async () => {
			try {
				await axios.get("/api/pos_reco/get_store_master_bq").then((res) => {
					console.log(res.data);
					setAllStores(res.data);
				});
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Network Error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		};
		fetch();
	}, []);

	const handleGridRowClick = (data) => {
		setModalOpen(true);
		setSelectedStore(data);
	};
	return (
		<Stack maxWidth="1100px" spacing={3} margin="auto">
			<Box display="flex" alignItems="center" pt={4} justifyContent="space-between">
				<Typography variant="h4">Store POS Master</Typography>
				{/* <Button
					variant="contained"
					sx={{ height: "50px" }}
					startIcon={<AddRoundedIcon />}
					onClick={() => setModalOpen(true)}>
					New Costing
				</Button> */}
			</Box>
			{/* table */}
			<Stack width="100%">
				<DataGrid
					key={allStores}
					rows={allStores}
					columns={tableColumns}
					sx={{
						minHeight: 400,
						maxHeight: 430,
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-cell": {
							padding: "10px",
						},
					}}
					// rowHeight={40}
					// showCellVerticalBorder
					showColumnVerticalBorder
					onRowClick={(params) => {
						handleGridRowClick(params.row);
					}}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
					}}
					getRowId={(row) => row[inputColumns.store_code.key]}
					autoHeight
					disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Stack>

			{/* edit Store Data */}
			<EditModal
				columns={inputColumns}
				allStores={allStores}
				setAllStores={setAllStores}
				selectedStore={selectedStore}
				setSelectedStore={setSelectedStore}
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				submitFunction={submitFunction}
			/>
		</Stack>
	);
};

export default StoreTidMaster;
