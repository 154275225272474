import {
	AutoAwesomeMotion,
	CheckCircleOutlineRounded,
	CurrencyRupee,
	DangerousRounded,
	Dataset,
	DescriptionRounded,
	DryCleaning,
	Inventory,
	NoteAddRounded,
	TextSnippet,
} from "@mui/icons-material";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { blue, grey, lightGreen, red, yellow } from "@mui/material/colors";

export const pimStyleStages = {
	DESIGNER: { name: "Designer", key: "DESIGNER" },
	COGS: { name: "COGS", key: "COGS" },
	FABRIC: { name: "Trims", key: "FABRIC" },
	COMPLETED: { name: "Completed", key: "COMPLETED" },
};
export const pimColorStages = {
	DESIGNER: { name: "Designer", key: "DESIGNER" },
	BUYING: { name: "Buying", key: "BUYING" },
	TRIMS: { name: "Trims", key: "TRIMS" },
	COMPLETED: { name: "Completed", key: "COMPLETED" },
};

export const pimDrawerElements = {
	designer: {
		name: "Designer",
		key: "designer",
		display: true,
		icon: <DesignServicesIcon />,
		types: {
			create_product: {
				name: "Create Style",
				key: "create_product",
				display: true,
				icon: <LibraryAddIcon />,
			},
			product_page: {
				name: "Product Page",
				key: "product_page",
				display: false,
				icon: <LibraryAddIcon />,
			},
			styles: {
				name: "Styles",
				key: "styles",
				display: true,
				icon: <AutoAwesomeMotion />,
			},
			trims_sheet: {
				name: "Trims Sheet",
				key: "trims_sheet",
				display: true,
				icon: <TextSnippet />,
			},
			fabric_sheet: {
				name: "Fabric Sheet",
				key: "fabric_sheet",
				display: true,
				icon: <DryCleaning />,
			},
		},
	},
	buying: {
		name: "Buying",
		key: "buying",
		display: true,
		icon: <LocalMallIcon />,
		types: {
			buying_sheet: {
				name: "Buying Sheet",
				key: "buying_sheet",
				display: true,
				icon: <Inventory />,
			},
			style_sheet: {
				name: "Style Sheet",
				key: "style_sheet",
				display: false,
				icon: <Inventory />,
			},
		},
	},
	sourcing: {
		name: "Sourcing",
		key: "sourcing",
		display: true,
		icon: <NoteAddRounded />,
		types: {
			approx_cogs: {
				name: "COGS Sheet(Approx.)",
				key: "approx_cogs",
				display: true,
				icon: <CurrencyRupee />,
			},
		},
	},
	attribute_master: {
		name: "Attribute Master",
		key: "attribute_master",
		display: true,
		icon: <Dataset />,
	},
};

export const productDepartments = {
	designer: {
		name: "Designer",
		key: "designer",
		display: true,
	},
	// mdm: {
	// 	name: "MDM",
	// 	key: "MDM",
	// 	display: true,
	// },
	buying: {
		name: "Buying",
		key: "buying",
		display: true,
	},
	// merchandiser: {
	// 	name: "Merchandiser",
	// 	key: "merchandiser",
	// 	display: true,
	// },
	// sourcing: {
	// 	name: "Sourcing",
	// 	key: "sourcing",
	// 	display: true,
	// },
	trims_fabric: {
		name: "Fabric and Trims",
		key: "trims_fabric",
		display: true,
	},
	// fabric: {
	// 	name: "Fabric",
	// 	key: "fabric",
	// 	display: true,
	// },
};
export const pimParamName = "tab";
export const attributeValueAssociationName = "attributeMasterValues";
export const foreignKeys = { style: "style_id", color: "product_id" };
export const pimAttrValAssociationObj = "attributeMasterValues";
export const colorImgAssociationObjName = "colorImages";

export const groups = {
	main: { name: "Main fields ", key: "MAIN" },
	channelBuyer: { name: "Channel Buyer", key: "CHANNEL_BUYER" },
	buyingSourcing: { name: "Buying & Sourcing", key: "BUYING_SOURCING" },
	designer: { name: "Designer Fields", key: "DESIGNER" },
	mdm: { name: "MDM Fields", key: "MDM" },
};
export const attributeGroups = {
	STYLE: { name: "Main fields ", key: "STYLE" },
	DESIGNER: { name: "Designer Fields", key: "DESIGNER" },
	COLOR: { name: "Designer Fields", key: "COLOR" },
	MDM: { name: "MDM Fields", key: "MDM" },
};

export const priority = { high: "HIGH", low: "LOW" };
export const inputTypes = { enter: "ENTER", select: "SELECT", autoGenerate: "AUTO_GENERATE" };

export const attributepriorityTypes = {
	high: { name: "High", key: "HIGH", index: 0 }, // index of the data in arr

	low: { name: "Low", key: "LOW", index: 1 },
};
export const productDetailsColumns = {
	color: { name: "Color" },
	style: { name: "Style" },
	pallette: { name: "Pallette" },
	division: { name: "Division" },
	category: { name: "Category" },
	sub_category: { name: "SOLID" },
	brand: { name: "Brand" },
	sub_brand: { name: "Sub-Brand" },
	season: { name: "Season" },
	occassion: { name: "Occassion" },
	mrp: { name: "MRP" },
	section: { name: "Section" },
};
export const barcodeTableColumns = {
	barcode: { name: "Barcode" },
	size: { name: "Size" },
};
export const sizeTypes = {
	xs: { name: "XS", key: "xs" },
	s: { name: "S", key: "s" },
	m: { name: "M", key: "m" },
	l: { name: "L", key: "l" },
	xl: { name: "XL", key: "xl" },
	xxl: { name: "XXL", key: "xxl" },
	xxxl: { name: "XXXL", key: "xxxl" },
	xxxxl: { name: "XXXXL", key: "xxxxl" },
};

export const orderTableAttributeColumns = {
	channel: "CHANNEL",
	season: "SEASON",
	elpp: "ELPP",
	production_type: "PRODUCTION_TYPE",
};
export const groupsColumnRows = {
	[groups.channelBuyer.key]: [
		{
			key: "buying_qty",
			name: "Buying Qty",
			input_type: inputTypes.enter,
		},
		{
			key: "buying_channel",
			name: "Channel",
			input_type: inputTypes.select,
			attribute_name: orderTableAttributeColumns.channel,
		},
		{
			key: "buying_season",
			name: "Season",
			input_type: inputTypes.select,

			attribute_name: orderTableAttributeColumns.season,
		},
		{
			key: "final_mrp",
			name: "Final MRP",
			input_type: inputTypes.enter,
		},
		{
			key: "multiple",
			name: "Multiple",
			input_type: inputTypes.enter,
			disabled: true,
		},
		{
			key: "elpp",
			name: "ELPP",
			input_type: inputTypes.select,

			attribute_name: orderTableAttributeColumns.elpp,
		},
	],
	[groups.buyingSourcing.key]: [
		{
			key: "production_type",
			name: "Production Type",
			attribute_name: orderTableAttributeColumns.production_type,
		},
		{
			key: "party_name",
			name: "Party Name",
			attribute_name: orderTableAttributeColumns.production_type,
		},
		{
			key: "net_weight",
			name: "Net Weight",
			attribute_name: orderTableAttributeColumns.production_type,
		},
		{
			key: "demand_delivery_date",
			name: "Demand Delivery Date",
		},
		{
			key: "cost_rate",
			name: "COGS",
		},
	],
};

export const orderData = {
	id: 0,
	color: "WHITE",
	pallete: "PRIMARY",
	status: "drafted",
	url: "/images/pimImages/ProductImages/melton1.png",
};
const datatypes = { string: "varchar(255)", number: "integer", float: "FLOAT", date: "DATETIME", json: "JSON" };
export const constraints = { notNull: "notNull", unique: "unique" };

export const styleTableColumns = [
	{
		name: "Style Name",
		key: "style_name",
		type: { notNull: true, datatype: "string", unique: false },
		input_type: "ENTER",
		group: "MAIN",
		data: {},
		sort_no: 0,
	},
	{
		name: "Season",
		key: "season",
		type: { notNull: true, datatype: "string", unique: false },
		input_type: "Select",
		data: {},
		group: "MAIN",
		sort_no: 1,
	},
	{
		name: "Brand",
		key: "brand",
		group: "MAIN",
		type: { notNull: true, datatype: "string", unique: false },
		input_type: "Select",
		data: {},
		sort_no: 2,
	},
	{
		name: "Sub Brand",
		key: "sub_brand",
		group: "MAIN",
		type: { notNull: true, datatype: "string", unique: false },
		input_type: "Select",
		data: {},
		sort_no: 3,
	},
	{
		name: "Category",
		key: "category",
		group: "MAIN",
		type: { notNull: true, datatype: "string", unique: false },
		input_type: "Select",
		data: {},
		sort_no: 4,
	},
	{
		name: "Sub Category",
		key: "sub_category",
		group: "MAIN",
		type: { notNull: true, datatype: "string", unique: false },
		input_type: "Select",
		data: {},
		sort_no: 5,
	},
];
export const colorTableColumns = [
	{
		name: "Color",
		key: "color",
		type: { notNull: true, datatype: "string", unique: false },
		input_type: "SELECT",
		group: "COLOR",
		data: {},
		sort_no: 0,
	},
	{
		name: "Pallete",
		key: "pallete",
		type: { notNull: true, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		group: "COLOR",
		sort_no: 1,
	},
];
export const designerTableColumns = [
	{
		name: "Block/Fit Type",
		key: "style_name",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 1,
	},
	{
		name: "Theme",
		key: "THEME",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 2,
	},
	{
		name: "Occasion",
		key: "OCCASION",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 3,
	},
	{
		name: "Fabric Type",
		key: "FABRIC_TYPE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 4,
	},
	{
		name: "Blend",
		key: "BLEND",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 5,
	},
	{
		name: "Stock Fabric",
		key: "STOCK_FABRIC",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		group: "DESIGNER",
		sort_no: 6,
	},
	{
		name: "Sort",
		key: "SORT",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		group: "DESIGNER",
		data: {},
		sort_no: 7,
	},
	{
		name: "Fabric Vendor",
		key: "FABRIC_VENDOR",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		group: "DESIGNER",
		data: {},
		sort_no: 8,
	},
	{
		name: "Fashionablity",
		key: "FASHIONABILITY",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		group: "DESIGNER",
		data: {},
		sort_no: 9,
	},
	{
		name: "PR COM",
		key: "PR_COM",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		group: "DESIGNER",
		data: {},
		sort_no: 10,
	},
	{
		name: "Order Type",
		key: "ORDER_TYPE",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		group: "DESIGNER",
		data: {},
		sort_no: 11,
	},
	{
		name: "SUG STORE Grade",
		key: "SUG_STORE_GRADE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 12,
	},
	{
		name: "Client Type",
		key: "CLIENT_TYPE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 13,
	},
	{
		name: "No of Pocket",
		key: "NO_OF_POCKET",
		group: "DESIGNER",
		type: { notNull: false, datatype: "number", unique: false },
		input_type: "ENTER",
		data: {},
		sort_no: 14,
	},
	{
		name: "CO-ORD SET",
		key: "CO_ORD_SET",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 15,
	},
	{
		name: "CO-ORD STyle Name",
		key: "CO_ORD_STYLE_NAME",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "PRINT TYPE",
		key: "PRINT_TYPE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "FIT TYPE",
		key: "FIT_TYPE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "LINING",
		key: "LINING",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "CONCEPT DETAIL",
		key: "CONCEPT_DETAIL",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "ELPP",
		key: "ELPP",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "WEAVE",
		key: "WEAVE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "Fabric Form",
		key: "FABRIC_FORM",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "NECK",
		key: "NECK",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "CLOSURE",
		key: "CLOSURE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "GMT LENGTH",
		key: "GMT_LENGTH",
		group: "DESIGNER",
		type: { notNull: false, datatype: "number", unique: false },
		input_type: "ENTER",
		data: {},
		sort_no: 16,
	},
	{
		name: "SLEEVE",
		key: "SLEEVE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "SLEEVE LENGTH",
		key: "SLEEVE_LENGTH",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "NO. OF POCKETS",
		key: "NO_OF_POCKETS",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "Title",
		key: "TITLE",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string_long", unique: false },
		input_type: "ENTER",
		data: {},
		sort_no: 16,
	},
	{
		name: "RODUCT DESCRIPTION FOR D2C",
		key: "RODUCT_DES_FOR_D2C",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string_long", unique: false },
		input_type: "ENTER",
		data: {},
		sort_no: 16,
	},
	{
		name: "VM INSTRUCTION",
		key: "VM_INSTRUCTION",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
	{
		name: "SPECIAL TAG KEYWORDS",
		key: "SPECIAL_TAG_KEYWORDS",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string_long", unique: false },
		input_type: "ENTER",
		data: {},
		sort_no: 16,
	},
	{
		name: "Collar",
		key: "COLLAR",
		group: "DESIGNER",
		type: { notNull: false, datatype: "string", unique: false },
		input_type: "SELECT",
		data: {},
		sort_no: 16,
	},
];
export const statusOptions = {
	CREATED: { name: "Created", color: blue[300], key: "CREATED", icon: <EditNoteIcon /> },
	DRAFTED: { name: "Drafted", color: grey[300], key: "DRAFTED", icon: <DescriptionRounded /> },
	APPROVED: { name: "Approved", color: lightGreen["A700"], key: "APPROVED", icon: <CheckCircleOutlineRounded /> },
	PUBLISHED: { name: "Published", color: lightGreen["A700"], key: "PUBLISHED", icon: <CheckCircleOutlineRounded /> },

	REJECTED: { name: "Rejected", color: red[600], key: "REJECTED", icon: <DangerousRounded /> },
};
// association names
export const designerAttributeObjName = "attributeData";
export const productImagesObjName = "styleImages";
export const mainProductImageObjName = "mainProductImage";
export const colorsObjName = "styleColor";
export const styleImagesObjName = "styleImages";
export const colorsChannelObjName = "colorChannels";
export const colorChannelBuyingObj = "colorChannelLevBuying";
export const sizeLevelBuyingObj = "channelSizeLevBuying";
export const colorStyleAssocObj = "colorStyle";
export const buyingColorAssocObj = "buyingColor";

// STYLE tABLE DUMMY DATA
export const productDummyData = {
	id: 0,
	style_id: "1230",
	style_name: "MELTON",
	season: "AW-23",
	brand: "RARE RABBIT",
	sub_brand: "RR",
	category: "SHIRT",
	sub_category: "SOLID",
	division: "APPARELS",
	status: "drafted",
	[designerAttributeObjName]: {
		BLOCK_FIT_TYPE: "COTTON",
		THEME: "BASIC",
		OCCASION: "CORE",
		FABRIC_TYPE: "",
		BLEND: "",
		STOCK_FABRIC: "",
		SORT: "",
		FABRIC_VENDOR: "RADHAMANI TEXTILES PVT. LTD : SP001",
		FASHIONABILITY: "CORE",
		PR_COM: "YES",
		ORDER_TYPE: "",
		SUG_STORE_GRADE: "",
		CLIENT_TYPE: "",
		NO_OF_POCKET: 0,
		CO_ORD_SET: "NO",
		CO_ORD_STYLE_NAME: "",
		PRINT_TYPE: "",
		FIT_TYPE: "REGULAR",
		LINING: "",
		CONCEPT_DETAIL: "",
		ELPP: "",
		WEAVE: "",
		FABRIC_FORM: "",
		NECK: "",
		CLOSURE: "",
		GMT_LENGTH: "",
		SLEEVE: "",
		SLEEVE_LENGTH: "FULL SELLVE",
		TITLE: "",
		PRODUCT_DES_FOR_D2C: "",
		VM_INSTRUCTION: "",
		SPECIAL_TAG_KEYWORDS: "",
		COLLAR: "",
	},
	// [mainProductImageObjName]: {
	// 	style_id: "1230",
	// 	url: "/images/pimImages/ProductImages/melton1.png",
	// 	is_main: true,
	// 	metaData: {},
	// },
	[productImagesObjName]: [
		{
			style_id: "1230",
			url: "/images/pimImages/ProductImages/melton1.png",
			is_main: true,
			metaData: {},
		},
		{
			style_id: "1230",
			url: "/images/pimImages/ProductImages/melton2.png",
			is_main: false,
			metaData: {},
		},
		{
			style_id: "1230",
			url: "/images/pimImages/ProductImages/melton3.png",
			is_main: false,
			metaData: {},
		},
		{
			style_id: "1230",
			url: "/images/pimImages/ProductImages/melton4.png",
			is_main: false,
			metaData: {},
		},
	],
	[colorsObjName]: [
		{
			url: "images/pimImages/ProductImages/1.jpg",
			color: "WHITE",
			pallete: "PRIMARY",
			total_qty: 0,
			id: 0,
			[colorsChannelObjName]: [],
		},
	],
};

const channels = ["MBO", "EBO", "LFS", "ECOM"];
const sizes = ["S", "M", "L", "XL", "XXL", "XXXL"];
const allChannelaWithSizes = [];
let no = new Date().valueOf();
channels.forEach((channel) => {
	let obj = { channel: channel };
	sizes.forEach((size, index) => {
		obj = { ...obj, size: size, total_qty: 0, id: no++ };
		allChannelaWithSizes.push(obj);
	});
});
// console.log("allChannelaWithSizes", allChannelaWithSizes);

// export const colorProductDummyData = [
// 	{
// 		url: "/images/pimImages/ProductImages/melton1.png",
// 		color: "WHITE",
// 		pallete: "PRIMARY",
// 		total_qty: 0,
// 		id: 0,
// 		mbo:0,
// 		ebo:0,
// 		lfs:0,
// 		ecom: 0,
// 		[colorsSizeObjName]: [...allChannelaWithSizes],
// 		[productStyle]: {
// 			id: 0,
// 			style_id: "1230",
// 			style_name: "MELTON",
// 			season: "AW-23",
// 			brand: "RARE RABBIT",
// 			sub_brand: "RR",
// 			category: "SHIRT",
// 			sub_category: "SOLID",
// 			division: "APPARELS",
// 			status: "drafted",
// 		}
// 	},
// 	{
// 		url: "/images/pimImages/ProductImages/melton1.png",
// 		color: "GRAY",
// 		pallete: "PRIMARY",
// 		total_qty: 0,
// 		id: 1,
// 		mbo:0,
// 		ebo:0,
// 		lfs:0,
// 		ecom: 0,
// 		[colorsSizeObjName]: [...allChannelaWithSizes],
// 		[productStyle]: {
// 			id: 0,
// 			style_id: "1231",
// 			style_name: "MELTON",
// 			season: "AW-23",
// 			brand: "RARE RABBIT",
// 			sub_brand: "RR",
// 			category: "SHIRT",
// 			sub_category: "SOLID",
// 			division: "APPARELS",
// 			status: "drafted",
// 		}
// 	},
// ]

export const colorProductDummyData = [
	{
		id: 0,
		total_qty: 0,
		[colorChannelBuyingObj]: [
			{
				channel: "EBO",
				total_qty: 0,
				blr_warehouse: 0,
				ggn_warehouse: 0,
				created_by: "adarshh@houseofrare.com",
				updated_by: "adarshh@houseofrare.com",
				id: 0,
				published: false,
				[sizeLevelBuyingObj]: [
					{
						id: 0,
						total_qty: 0,
						percentage: 0,
						size: "S",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
					{
						id: 1,
						total_qty: 0,
						percentage: 0,
						size: "M",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
					{
						id: 2,
						total_qty: 0,
						percentage: 0,
						size: "L",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
					{
						id: 3,
						total_qty: 0,
						percentage: 0,
						size: "XL",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
					{
						id: 4,
						total_qty: 0,
						percentage: 0,
						size: "XXL",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
					{
						id: 5,
						total_qty: 0,
						percentage: 0,
						size: "XXXL",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
				],
			},
			{
				channel: "MBO",
				total_qty: 0,
				blr_warehouse: 0,
				ggn_warehouse: 0,
				created_by: "adarshh@houseofrare.com",
				updated_by: "adarshh@houseofrare.com",
				id: 1,
				published: false,
				[sizeLevelBuyingObj]: [
					{
						id: 0,
						total_qty: 0,
						percentage: 0,
						size: "S",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
					{
						id: 1,
						total_qty: 0,
						percentage: 0,
						size: "M",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
				],
			},
			{
				channel: "LFS",
				total_qty: 0,
				blr_warehouse: 0,
				ggn_warehouse: 0,
				created_by: "adarshh@houseofrare.com",
				updated_by: "adarshh@houseofrare.com",
				id: 2,
				published: false,
				[sizeLevelBuyingObj]: [
					{
						id: 0,
						total_qty: 0,
						percentage: 0,
						size: "S",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
					{
						id: 1,
						total_qty: 0,
						percentage: 0,
						size: "M",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
				],
			},
			{
				channel: "ECOM",
				total_qty: 0,
				blr_warehouse: 0,
				ggn_warehouse: 0,
				created_by: "adarshh@houseofrare.com",
				updated_by: "adarshh@houseofrare.com",
				id: 3,
				published: false,
				[sizeLevelBuyingObj]: [
					{
						id: 0,
						total_qty: 0,
						percentage: 0,
						size: "S",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
					{
						id: 1,
						total_qty: 0,
						percentage: 0,
						size: "M",
						size_metadata_id: 0,
						channel_level_buying_id: 0,
					},
				],
			},
		],
		[buyingColorAssocObj]: {
			id: 0,
			color: "white",
			pallete: "Primary",
			[colorStyleAssocObj]: {
				id: "1230",
				style_name: "MELTON",
				season: "AW-23",
				brand: "RARE RABBIT",
				sub_brand: "RR",
				category: "SHIRT",
				sub_category: "SOLID",
				division: "APPARELS",
				status: "DRAFTED",
			},
			[colorImgAssociationObjName]: [{ url: "/images/pimImages/product_img.png" }],
		},
	},
];

// createProduct
export const isMainImg = "is_main";

export const fabricTableColumns = {
	type: { name: "Type", key: "type" },
	blend: { name: "Blend", key: "blend" },
	source_category: { name: "Source Category", key: "source_category" },
	fabric_category: { name: "Fabric Category", key: "fabric_category" },
	weave: { name: "Weave", key: "weave" },
	construction: { name: "Count Construction", key: "construction" },
	width: { name: "Width", key: "width" },
};
export const trimsGroupTableColumns = {
	category: { name: "Category", key: "category", type: inputTypes.select },
	description: { name: "Description", key: "description", type: inputTypes.select },
	color_level: { name: "Color Wise", key: "color_level", type: inputTypes.enter },
	size_level: { name: "Size Wise", key: "size_level", type: inputTypes.enter },
	consumption_unit: { name: "Consumption Unit", key: "consumption_unit", type: inputTypes.enter },
	consumption: { name: "Consumption", key: "consumption", type: inputTypes.enter },
	garment_qty: { name: "Garment Qty", key: "garment_qty", type: inputTypes.enter },
	wastage_percent: { name: "Wastage %", key: "wastage_percent", type: inputTypes.enter },
};
export const buyingTableData = {
	sheet_name: { name: "Sheet Name", key: "sheet_name" },
	season: { name: "Season", key: "season" },
	total_styles: { name: "Total Style", key: "total_styles" },
	total_qty: { name: "Total Qty", key: "total_qty" },
	created_by: { name: "Created By", key: "created_by" },
	published_by: { name: "Published By", key: "published_by" },
	is_published: { name: "Publish", key: "is_published" },
};

export const buyingSheetdata = [
	{
		id: 0,
		[buyingTableData.sheet_name.key]: "1AB",
		[buyingTableData.season.key]: "AS-24",
		[buyingTableData.total_styles.key]: 24,
		[buyingTableData.total_qty.key]: 0,
		[buyingTableData.created_by.key]: "amit@thor.com",
		[buyingTableData.published_by.key]: "adarsh@thor.com",
		[buyingTableData.is_published.key]: false,
	},
	{
		id: 1,
		[buyingTableData.sheet_name.key]: "2AB",
		[buyingTableData.season.key]: "AS-24",
		[buyingTableData.total_styles.key]: 14,
		[buyingTableData.total_qty.key]: 0,
		[buyingTableData.created_by.key]: "adarsh@thor.com",
		[buyingTableData.published_by.key]: "kewalh@thor.com",
		[buyingTableData.is_published.key]: true,
	},
	{
		id: 2,
		[buyingTableData.sheet_name.key]: "1AAB",
		[buyingTableData.season.key]: "AW-24",
		[buyingTableData.total_styles.key]: 4,
		[buyingTableData.total_qty.key]: 0,
		[buyingTableData.created_by.key]: "adarsh@thor.com",
		[buyingTableData.published_by.key]: "kewalh@thor.com",
		[buyingTableData.is_published.key]: true,
	},
	{
		id: 3,
		[buyingTableData.sheet_name.key]: "1ABB",
		[buyingTableData.season.key]: "SS-24",
		[buyingTableData.total_styles.key]: 134,
		[buyingTableData.total_qty.key]: 0,
		[buyingTableData.created_by.key]: "adarsh@thor.com",
		[buyingTableData.published_by.key]: "kewalh@thor.com",
		[buyingTableData.is_published.key]: true,
	},
];
export const trimsColumns = {
	category: { name: "Category", key: "category", type: inputTypes.select },
	description: { name: "Description", key: "description", type: inputTypes.select },
	color_level: { name: "Color Wise", key: "color_level", type: inputTypes.enter },
	size_level: { name: "Size Wise", key: "size_level", type: inputTypes.enter },
	consumption_unit: { name: "Consumption Unit", key: "consumption_unit", type: inputTypes.enter },
	consumption: { name: "Consumption", key: "consumption", type: inputTypes.enter },
	garment_qty: { name: "Garment Qty", key: "garment_qty", type: inputTypes.enter },
	wastage_percent: { name: "Wastage %", key: "wastage_percent", type: inputTypes.enter },
};

export const pimDescCatAssociationObj = "CategorySubCategories";
