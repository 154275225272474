import { grey, yellow } from "@mui/material/colors";
import { PimState } from "context/PimContext";
import { buyingTableData, statusOptions, trimsGroupTableColumns } from "data/pimData";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateBuyingSheet from "./CreateBuyingSheet/CreateBuyingSheet";
import PublishIcon from "@mui/icons-material/Publish";
import SearchIcon from "@mui/icons-material/Search";

const { Stack, Button, Box, Typography, Paper, IconButton, InputBase } = require("@mui/material");
const { DataGrid } = require("@mui/x-data-grid");

const ColorBuyingSheet = () => {
	const { selectedStyle, setSelectedStyle, buyingSheet, setBuyingSheet } = PimState();
	const [openCreateBuying, setOpenCreateBuying] = useState(false);
	const [search, setSearch] = useState("");
	const navigate = useNavigate();
	const colStyle = {
		headerAlign: "center",
		align: "center",
	};
	const tablecolumns = [
		{
			field: "sheet_name",
			headerName: "Sheet Name",
			width: 220,
			...colStyle,
			// renderCell: (params) => {
			// 	return <BuyingBox data={params.row} />;
			// },
		},
		{
			field: "season",
			headerName: "Season",
			width: 90,
			...colStyle,
			// renderCell: ({ row }) => {
			// 	return <RenderColorColumns column="color" data={row} />;
			// },
		},
		{
			field: "total_styles",
			headerName: "Total Style",
			width: 90,
			...colStyle,
			// renderCell: ({ row }) => {
			// 	return <RenderColorColumns column="pallete" data={row} />;
			// },
		},
		{
			field: "total_qty",
			headerName: "Total Qty",
			width: 120,
			...colStyle,
			// renderCell: ({ row }) => {
			// 	return <RenderStyleColumns column="style_name" data={row} />;
			// },
		},
		{ field: "created_by", headerName: "CREATED BY", width: 150, ...colStyle },
		{
			field: "published_by",
			headerName: "PUBLISHED BY",
			width: 150,
			...colStyle,
			valueGetter: ({ row }) => {
				if (row.is_published) return row.published_by;
				else return "Not Published";
			},
		},
		{
			field: "is_published",
			headerName: "Publish",
			width: 120,
			renderCell: ({ row }) => {
				return (
					<Box
						display="flex"
						alignItems="center"
						height={"22px"}
						// columnGap={1}
						sx={{
							backgroundColor: statusOptions.PUBLISHED?.color || yellow[400],
							color: "white",
							padding: "6px",
							borderRadius: "5px",
						}}>
						<Typography pl="3px">
							{statusOptions.PUBLISHED?.icon}
							{statusOptions.PUBLISHED.name}
						</Typography>
					</Box>
				);
			},
		},
	];

	const handleBuyingSheet = (row) => {
		setSelectedStyle(row);
		navigate("/explore/pim/style_sheet");
	};
	let filterData = buyingSheet.filter((row) => row?.sheet_name.toLowerCase().includes(search.toLowerCase()));
	console.log("selectedStyle", selectedStyle);
	return (
		<Stack width="100%" spacing={1} pt={1}>
			<Stack display="flex" flexDirection="row" justifyContent="space-between">
				<Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 300 }} elevation={1}>
					<IconButton type="button" disabled sx={{ p: "10px" }} aria-label="search">
						<SearchIcon />
					</IconButton>
					<InputBase
						sx={{ ml: 1, flex: 1 }}
						placeholder="Search by Buyinf Sheet"
						inputProps={{ "aria-label": "Search Products" }}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						required
					/>
				</Paper>
				<Button variant="contained" size="large" sx={{ height: "50px", width: "250px" }} onClick={() => setOpenCreateBuying(true)}>
					Create Buying Sheet
				</Button>
			</Stack>
			<Stack width="100%" height={400} pt={2}>
				<DataGrid
					rows={filterData}
					columns={tablecolumns}
					// key={displayImg}
					sx={{
						minHeight: 350,
						maxHeight: 430,
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							// cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							background: grey[300],
							mb: 2,
							border: "none",
							borderRadius: 1,
							// padding: "10px 0px",
						},
					}}
					columnHeaderHeight={35}
					onProcessRowUpdateError={(error) => console.log(error)}
					// showCellVerticalBorder
					onRowClick={(params, e) => handleBuyingSheet(params.row)}
					// onRowClick={(params, e) => setChannel(params.row)}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
					}}
					// loading={loading}
					disableRowSelectionOnClick
					pageSizeOptions={[10, 50]}
				/>
			</Stack>
			{openCreateBuying && <CreateBuyingSheet setOpenCreateBuying={() => setOpenCreateBuying(false)} />}
		</Stack>
	);
};

export default ColorBuyingSheet;
