import Box from "@mui/material/Box";
import { grey, yellow } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
	attributeGroups,
	colorImgAssociationObjName,
	colorsObjName,
	designerAttributeObjName,
	groups,
	orderData,
	pimDrawerElements,
	pimParamName,
	productImagesObjName,
	statusOptions,
} from "data/pimData";
import { useEffect, useState } from "react";
import DisplayInfoWithEditTab from "components/Pim/CommonComponents/DisplayInfoWithEditTab";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import BarcodeModal, { BarcodeModalWithCost } from "../../BarcodeModal/BarcodeModal";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteRounded, EditRounded } from "@mui/icons-material";
import { PimState } from "context/PimContext";
import { rootProtectedPath, services } from "data/authData";
import { useNavigate, useSearchParams } from "react-router-dom";

import EditImageDataModal from "../../EditImageDataModal/EditImageDataModal";
import AddRounded from "@mui/icons-material/AddRounded";
import AddNewImageModal from "../../AddNewImageModal/AddNewImageModal";
import ImageSlider from "components/Pim/CommonComponents/ImageSlider/ImageSlider";
import CreateColorModal from "../../CreateColorModal/CreateColorModal";
import moment from "moment";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const DesignerTab = ({ foreignKey }) => {
	const { setSnackBar, setSnackBarOpen, snackBar } = GlobalState();
	const { selectedStyle, setSelectedStyle, attributeWithValues, attributesWithValuesGroups } = PimState();
	let [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		console.log("selectedStyle", selectedStyle);
		const fetch = async () => {
			if (!selectedStyle) {
				// if(){
				// }
				// await axios.get("/").then((res) => setStyleData(res.data));
			} else setStyleData(selectedStyle);
		};
		fetch();
	}, [selectedStyle]);

	const navigate = useNavigate();

	const [styleData, setStyleData] = useState(selectedStyle);
	const [barcodes, setBarcodes] = useState([
		{ barcode: "8907279846372", size: "S", id: 0, icode: "RR123" },
		{ barcode: "8907279846372", size: "M", id: 1, icode: "RR123" },
		{ barcode: "8907279846372", size: "L", id: 2, icode: "RR123" },
	]);

	const [orders, setOrders] = useState([orderData]);
	const [selectedBarcode, setSelectedBarcode] = useState();

	const [activeGroup, setActiveGroup] = useState("");

	// modal var
	const [barcodeModal, setBarcodeModal] = useState(false);
	const [barcodeModal2, setBarcodeModal2] = useState(false);
	const [mainFieldsEditModal, setmainFieldsEditModal] = useState(false);
	const [designerDataEditModal, setDesignerDataEditModal] = useState(false);
	const [mdmDataEditModal, setMdmDataEditModal] = useState(false);
	const [createOrderModal, setCreateOrderModal] = useState(false);
	const [imgInfoModal, setImgInfoModal] = useState(false);
	const [addNewImgModal, setAddNewImgModal] = useState(false);
	// const [images, setImages] = useState([
	// 	{ data_url: "/images/pimImages/ProductImages/1.jpg" },
	// 	{ data_url: "/images/pimImages/ProductImages/2.jpg" },
	// 	{ data_url: "/images/pimImages/ProductImages/3.jpg" },
	// ]);

	const handleMainFieldsEditClick = () => {
		setmainFieldsEditModal(true);
	};

	const imgWidth = "23vw";
	const imgHeight = "65vh";
	const colorTableColumns = [
		{
			field: "url",
			headerName: "Image",
			attribute_name: "",
			width: 120,
			renderCell: (params) => {
				let imgWidth = 43;
				let imgHeight = 50;
				return (
					<img
						style={{
							width: `${imgWidth}px`,
							height: `${imgHeight}px`,
							objectFit: "cover",
							objectPosition: "50% 0%",
							borderRadius: 2,
						}}
						src={params.row[colorImgAssociationObjName][0]?.url}
					/>
				);
			},
			...colStyle,
		},
		{
			field: "color",
			headerName: "Color",
			attribute_name: "",
			width: 120,
			...colStyle,
		},
		{
			field: "pallete",
			headerName: "Pallete",
			width: 120,
			...colStyle,
		},
		{
			field: "color_fashionability",
			headerName: "Fashionability",
			width: 120,
			...colStyle,
		},
		{
			field: "created_by",
			headerName: "Created By",
			width: 120,
			...colStyle,
		},
		{
			field: "published_by",
			headerName: "Published By",
			width: 120,
			...colStyle,
		},
		{
			field: "published_date",
			headerName: "Published Date",
			width: 120,
			valueGetter: (value) => {
				// Convert the date value
				return moment(value).format("DD/MM/YYYY");
			},
			...colStyle,
		},
		{
			field: "status",
			headerName: "Status",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return (
					<Box
						display="flex"
						alignItems="center"
						height={"22px"}
						// columnGap={1}
						sx={{
							backgroundColor: statusOptions[row.status]?.color || yellow[400],
							color: "white",
							padding: "6px",
							borderRadius: "5px",
						}}>
						{statusOptions[row.status]?.icon}{" "}
						<Typography pl="3px"> {statusOptions[row.status]?.name}</Typography>
					</Box>
				);
			},
		},

		// {
		// 	// field: "pallette",
		// 	headerName: "Delete",
		// 	width: 60,
		// 	...colStyle,
		// 	renderCell: (params) => {
		// 		return (
		// 			<IconButton onClick={(e) => handleBarcodeDeleteClick(e, params.row)}>
		// 				<DeleteRounded />
		// 			</IconButton>
		// 		);
		// 	},
		// },
	];

	const addBarcode = async (data) => {
		try {
			if (data) {
				data[foreignKey] = styleData.id;
				if (selectedBarcode) {
					const res = await axios.put("/api/supplier/update_contact", data);
					if (res) {
						setSelectedBarcode(
							barcodes.map((row) => {
								if (data.id === row.id) return data;
								else return row;
							})
						);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Updated",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						return true;
					}
				} else {
					const res = await axios.post("/api/supplier/create_contact", data);
					if (res) {
						setBarcodes([...barcodes, res.data.result]);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "Created",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						return true;
					}
				}
			}
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable To Add File",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};

	const handleSaveMainFieldsEditedData = async (data, setModalOpen, columns) => {
		let styleObj = {};
		let styleAttributeData = {};
		columns?.map((row) => {
			console.log(row.key);

			if (data[row.key]) {
				if (row.group === attributeGroups.STYLE.key) styleObj[row.key] = data[row.key];
				if (row.group === attributeGroups.DESIGNER.key) {
					console.log(row.key);
					styleAttributeData[row.key] = data[row.key];
				}
			}
		});
		console.log(styleAttributeData);
		try {
			const p1 = new Promise(async (resolve, reject) => {
				await axios
					.put("/api/pim/update_style", { ...styleObj, id: selectedStyle.id })
					.then((res) => resolve(true))
					.catch((err) => reject(false));
			});
			const p2 = new Promise(async (resolve, reject) => {
				await axios
					.put("/api/pim/update_style_attribute", {
						...styleAttributeData,
						id: selectedStyle[designerAttributeObjName]?.id,
					})
					.then((res) => resolve(true))
					.catch((err) => reject(false));
			});

			const res = await Promise.all([p1, p2]);
			if (res) {
				setSelectedStyle({
					...selectedStyle,
					...styleObj,
					[designerAttributeObjName]: { ...selectedStyle[designerAttributeObjName], ...styleAttributeData },
				});
				setModalOpen(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Updated",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Save Failed",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	const cerateOrder = (orderData) => {};
	const handleSaveEditedData = (orderData) => {};

	const handleFileUpload = async (files, data) => {
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append("files", files[i].file);
		}
		formData.append("style_id", data.STYLE_ID);

		return await axios
			.post("/api/pim/image_upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				return "Error in file Upload";
			});
	};
	const updateMainImage = async (data) => {
		try {
			const res = await axios.put("/api/pim/change_main_image", data);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Main Image Changed Sucessfully",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSelectedStyle({ ...selectedStyle, IMAGE_URL: data.url });
			setImgInfoModal(false);
			return true;
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Error",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};
	const addNewImages = async (images, mainImgIndex) => {
		const imageUrls = await handleFileUpload(images, selectedStyle);

		const productImages = imageUrls.map((row, index) => {
			return { style_id: selectedStyle.id, is_main: false, url: row.publicUrl };
		});

		try {
			const res = await axios.post("/api/pim/bulk_create_pim_images", productImages);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Images created Successfully",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return true;
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Unable to Add Product",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return false;
		}
	};

	if (selectedStyle && styleData)
		return (
			<Stack width="100%">
				<Box pt={3} pb={0} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
					<Box width="85%">
						<Typography variant="h6">{groups.main.name}</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Button variant="contained" onClick={handleMainFieldsEditClick}>
						Edit
					</Button>
					<Box width="100%" display="flex" boxSizing="border-box" justifyContent="space-between">
						<Box>
							{/* <img
								style={{
									width: `${imgWidth}px`,
									height: `${imgHeight}px`,
									objectFit: "cover",
									objectPosition: "50% 0%",
									borderRadius: 2,
									zIndex: 2,
								}}
								src={styleData?.IMAGE_URL}
							/> */}
							<Box width={imgWidth} height={imgHeight}>
								<ImageSlider
									imgWidth={imgWidth}
									imgHeight={imgHeight}
									column="url"
									images={styleData[productImagesObjName]}
								/>
							</Box>

							<Box p={1} display="flex" columnGap={2}>
								<IconButton
									startIcon={<EditRounded sx={{ color: "#fff" }} />}
									sx={{
										background: "#000",
										"&: hover": { background: "#00000083" },
										// width: 100,
									}}
									variant="contained"
									onClick={() => setImgInfoModal(true)}>
									<EditRounded sx={{ color: "white" }} />
								</IconButton>
								<IconButton
									sx={{
										background: "#000",
										"&: hover": { background: "#00000083" },
									}}
									variant="contained"
									onClick={() => setAddNewImgModal(true)}>
									<AddRounded sx={{ color: "#fff" }} />
								</IconButton>
							</Box>
						</Box>

						<Box
							width="68vw"
							p={2}
							boxSizing="border-box"
							// sx={{ boxShadow: `1px 1px 10px ${grey[300]}`, background: grey[100] }}
						>
							<DisplayInfoWithEditTab
								columnData={[
									...attributesWithValuesGroups[attributeGroups.STYLE.key],
									...attributesWithValuesGroups[attributeGroups.DESIGNER.key].filter(
										(row, i) => i < 16
									),
								]}
								// columnData={attributes[attributepriorityTypes.high.index]}
								data={{ ...styleData, ...styleData[designerAttributeObjName] }}
								setData={setStyleData}
								modalOpen={mainFieldsEditModal}
								setModalOpen={setmainFieldsEditModal}
								submitFunction={handleSaveMainFieldsEditedData}
								width={"30%"}
							/>
						</Box>
					</Box>
				</Box>

				<Box pt={5} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
					<Box width="85%">
						<Typography variant="h6">Colors</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Button
						variant="contained"
						onClick={() => {
							setCreateOrderModal(true);
						}}>
						Create
					</Button>
					<Box width="90%">
						<DataGrid
							rows={styleData && styleData[colorsObjName] ? styleData[colorsObjName] : []}
							columns={colorTableColumns}
							sx={{
								minHeight: 320,
								maxHeight: 350,
								border: "none",
								"& .MuiDataGrid-row": {
									// borderBottom: "1px solid lightgray",
									cursor: "pointer",
									border: "none",
								},
								"& .MuiDataGrid-columnHeader": {
									cursor: "pointer",
								},
								"& .MuiDataGrid-columnHeaders": {
									background: grey[300],
									// mb: 2,
									border: "none",
									borderRadius: 1,
									// padding: "10px 0px",
								},
							}}
							columnHeaderHeight={35}
							rowHeight={60}
							// showCellVerticalBorder
							// onRowClick={(params, e) => handleOrderClick(params.row)}
							initialState={{
								pagination: {
									paginationModel: { pageSize: 30 },
								},
							}}
							disableRowSelectionOnClick
							pageSizeOptions={[50, 100]}
						/>
					</Box>
				</Box>

				{/* designer Fields */}
				<Box pt={5} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
					<Box width="85%">
						<Typography pb={1} variant="h6">
							{groups.designer.name}
						</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Box>
						<Button variant="contained" onClick={() => setDesignerDataEditModal(true)}>
							Edit
						</Button>
						{/* <IconButton
							sx={{ ml: 2 }}
							size="large"
							onClick={() => handleArrowDownClick(groups.designer.key)}>
							{activeGroup === groups.designer.key ? (
								<KeyboardArrowUpRoundedIcon sx={{ transform: "scale(1.6)" }} />
							) : (
								<KeyboardArrowDownRoundedIcon sx={{ transform: "scale(1.6)" }} />
							)}
						</IconButton> */}
					</Box>
					{/* <Collapse in={activeGroup === groups.designer.key} width="100%" p={2}> */}
					<DisplayInfoWithEditTab
						columnData={attributesWithValuesGroups[attributeGroups.DESIGNER.key].filter((row, i) => i > 15)}
						// columnData={attributes[attributepriorityTypes.high.index]}
						data={styleData[designerAttributeObjName]}
						setData={setStyleData}
						modalOpen={designerDataEditModal}
						setModalOpen={setDesignerDataEditModal}
						submitFunction={handleSaveEditedData}
						width={"30%"}
					/>
					{/* </Collapse> */}
				</Box>

				{/* Orders */}

				<BarcodeModal
					modalOpen={barcodeModal}
					setModalOpen={setBarcodeModal}
					selected={selectedBarcode}
					setSelected={setSelectedBarcode}
					submitFunction={addBarcode}
					key={barcodeModal}
				/>
				<BarcodeModalWithCost
					modalOpen={barcodeModal2}
					setModalOpen={setBarcodeModal2}
					selected={selectedBarcode}
					setSelected={setSelectedBarcode}
					submitFunction={addBarcode}
					key={barcodeModal2}
				/>
				<CreateColorModal
					selectedStyle={selectedStyle}
					modalOpen={createOrderModal}
					setModalOpen={setCreateOrderModal}
					submitFunction={cerateOrder}
				/>
				<EditImageDataModal
					modalOpen={imgInfoModal}
					setModalOpen={setImgInfoModal}
					submitFunction={updateMainImage}
				/>
				<AddNewImageModal
					modalOpen={addNewImgModal}
					setModalOpen={setAddNewImgModal}
					submitFunction={addNewImages}
				/>
			</Stack>
		);
	else return <div></div>;
};

export default DesignerTab;
