import {
	DialogActions,
	InputLabel,
	Stack,
	TextField,
	Dialog,
	DialogTitle,
	Select,
	MenuItem,
	Autocomplete,
	CircularProgress,
	Card,
	CardContent,
} from "@mui/material";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// const columns = [
// 	{
// 		field: "store-code",
// 		headerName: "STORE-CODE",
// 		width: 100,
// 		headerClassName: "super-app-theme--header",
// 		valueGetter: (params) => `${params.row.rr_code || ""}${params.row.rr_number || ""}`,
// 	},
// 	{
// 		field: "brand",
// 		headerName: "BRAND",
// 		headerClassName: "super-app-theme--header",
// 		width: 150,
// 	},
// 	{
// 		field: "store_name",
// 		headerName: "STORE NAME",
// 		headerClassName: "super-app-theme--header",
// 		width: 130,
// 	},
// 	{
// 		field: "name_of_franchise_company",
// 		headerName: "FRANCHISE NAME",
// 		headerClassName: "super-app-theme--header",
// 		width: 180,
// 	},
// 	{
// 		field: "address",
// 		headerClassName: "super-app-theme--header",
// 		headerName: "ADDRESS",
// 		width: 220,
// 	},
// 	{
// 		field: "state",
// 		headerClassName: "super-app-theme--header",
// 		headerName: "STATE",
// 		type: "string",
// 		width: 130,
// 	},
// 	{
// 		field: "city",
// 		headerClassName: "super-app-theme--header",
// 		headerName: "CITY",
// 		type: "string",
// 		width: 130,
// 	},
// 	{
// 		field: "pincode",
// 		headerClassName: "super-app-theme--header",
// 		headerName: "PINCODE",
// 		width: 160,
// 	},
// ];

// function NsmScreen({ isLoading }) {
// 	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
// 	const [open, setOpen] = useState(false);
// 	const [value, setValue] = useState({});
// 	const [downloading, setDownloading] = useState(false);
// 	const { activeStore, setActiveStore, pending, setPending } = StoreContext();

// 	const getRowClassName = (params) => {
// 		// Conditionally apply a custom class to the row based on a condition
// 		return params.row.is_active ? "" : "highlighted-row";
// 	};

// 	return (
// 		<>
// 			{activeStore.length ? (
// 				<Stack width="100%" height={400} pt={3} my={1}>
// 					<DataGrid
// 						// autoHeight
// 						getRowClassName={getRowClassName}
// 						sx={{
// 							"& .super-app-theme--header": {
// 								backgroundColor: grey[300],
// 							},
// 							minHeight: "65vh",
// 							cursor: "pointer",
// 						}}
// 						rows={activeStore}
// 						columns={columns}
// 						initialState={{
// 							pagination: {
// 								paginationModel: { page: 0, pageSize: 5 },
// 							},
// 						}}
// 						pageSizeOptions={[5, 10]}
// 						disableRowSelectionOnClick
// 						onRowClick={(params) => {
// 							setValue(params.row);
// 							setOpen(!open);
// 						}}
// 					/>
// 					<Modal
// 						open={open}
// 						onClose={() => setOpen(!open)}
// 						aria-labelledby="modal-modal-title"
// 						aria-describedby="modal-modal-description"
// 						disableEnforceFocus>
// 						<EmployeeDetail
// 							data={value}
// 							activeStore={activeStore}
// 							setActiveStore={setActiveStore}
// 							setOpen={setOpen}
// 						/>
// 					</Modal>
// 				</Stack>
// 			) : isLoading ? (
// 				<Grid container spacing={1} mt={2}>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"100px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 				</Grid>
// 			) : (
// 				<Stack align={"center"} margin={5}>
// 					No Store Records Found
// 				</Stack>
// 			)}
// 		</>
// 	);
// }

// export default NsmScreen;

function EmployeeDetail({ data, activeStore, setActiveStore, setOpen, open }) {
	// let mappedUsers = {};
	const initialState = {
		type: "SELECT",
	};
	// const [mappedUsers, setMappedUsers] = useState({});
	const [empData, setEmpData] = useState(initialState);
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState("");
	const [addEmp, setAddEmp] = useState(false);
	const [loading, setLoading] = useState(false);
	const [storeEmployee, setStoreEmployee] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	const EMPLOYEE_TYPES = [
		{
			key: "SELECT",
			value: "SELECT",
		},
		{ key: "NSM", value: "NSM" },
		{ key: "RSM", value: "RSM" },
		{ key: "ASM", value: "ASM" },
		{ key: "Store Manager", value: "STORE_MANAGER" },
		{ key: "Business Head", value: "BH" },
	];

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 750,
		bgcolor: "background.paper",
		borderRadius: 3,
		boxShadow: "0 1px 2px rgba(0, 0, 0, 0.4)",
		p: 4,
		overflow: "auto",
		maxHeight: 400,
	};

	const FIELDS = [
		{ key: "type", value: "Employee type" },
		{ key: "user_id", value: "User" },
	];

	useEffect(() => {
		axios
			.get("/api/store/users")
			.then((res) => {
				setUsers(res.data);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		fetchStoreEmployeeDetail(data.id);
	}, [data]);

	const handleCloseModal = () => {
		setOpen(false);
	};

	const addUserId = (value) => {
		for (let user of users) {
			if (user.name === value) {
				console.log("HIT FOR USER ID");
				setEmpData({ ...empData, user_id: user.id });
			}
		}
		// setempData({ ...empData, user_id: mappedUsers[e.target.value] });
	};

	const handleUpdate = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (empData.type === "SELECT") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "select employee type",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoading(false);
			return;
		}
		let x = users.filter((u) => u.name == user);

		console.log(x);
		let obj = { ...empData, site_code: data.rr_code + data.rr_number, user_id: x[0].id };
		const { id } = data;
		await axios
			.put(`/api/store/update-storeEmployee-details/${id}`, obj)
			.then((res) => {
				if (res.status === 200) {
					const { employee } = res.data;
					setActiveStore([...activeStore.map((x) => (x.id === id ? data : x))]);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "updated successfully",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoading(false);
					setOpen(false);
				} else if (res.status === 201) {
					setLoading(false);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "created successfully",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoading(false);
					setOpen(false);
				} else {
					setLoading(false);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: res.error,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			})
			.catch((err) => {
				setLoading(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	const fetchStoreEmployeeDetail = async (storeId) => {
		await axios
			.get(`/api/store/users/${storeId}`)
			.then((res) => {
				if (res.status === 200) {
					const employee = res.data;

					setStoreEmployee(
						employee.map((emp) => {
							return { ...emp.storeEmployeeUser, type: emp.type };
						})
					);
				} else {
					setLoading(false);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: res.error,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			})
			.catch((err) => {
				setLoading(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	return (
		<Dialog open={open} maxWidth="md" onClose={handleCloseModal} component="form" onSubmit={handleUpdate} fullWidth>
			<DialogTitle>
				<Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
					<Typography fontSize={26}>
						Employee Details For Store {data.rr_code}
						{data.rr_number}.
					</Typography>
					<Stack>
						<Button variant="contained" onClick={() => setAddEmp(!addEmp)}>
							{addEmp ? "Hide" : "Assign Employee"}
						</Button>
					</Stack>
				</Box>
			</DialogTitle>
			{addEmp ? (
				<>
					<Box width="95%" margin="auto" pt={2} display="flex" flexWrap="wrap" columnGap={2} rowGap={2}>
						{FIELDS.map(({ key, value }) => (
							<Stack sx={formStack} spacing={0} key={key}>
								{key === "type" ? (
									<Stack spacing={0} key={key}>
										<InputLabel>
											{value} <span>*</span>:
										</InputLabel>
										<Select
											MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
											value={empData.type}
											onChange={(e) => setEmpData({ ...empData, type: e.target.value })}
											size="small">
											{EMPLOYEE_TYPES.map(({ key, value }) => (
												<MenuItem key={key} value={value}>
													{key}
												</MenuItem>
											))}
										</Select>
									</Stack>
								) : (
									""
								)}
								{key === "user_id" ? (
									<Stack spacing={0} key={key}>
										<InputLabel>
											{value} <span>*</span>:
										</InputLabel>
										<Autocomplete
											id="combo-box-demo"
											options={users.map((user) => user.name)}
											onChange={(event, value) => setUser(value)}
											sx={{ width: 300 }}
											size="small"
											componentsProps={{
												popper: {
													modifiers: [
														{
															name: "flip",
															enabled: false,
														},
													],
												},
											}}
											renderInput={(params) => (
												<TextField {...params} placeholder="Select User name" required />
											)}
										/>
									</Stack>
								) : (
									""
								)}
							</Stack>
						))}
					</Box>
					<DialogActions>
						<Button variant="outlined" onClick={handleCloseModal}>
							Cancel
						</Button>
						<Button
							autoFocus
							variant="contained"
							sx={{ mr: 1 }}
							type="submit"
							startIcon={loading ? <CircularProgress color="inherit" size="25px" /> : ""}
							disabled={loading}>
							{loading ? "Saving" : "Save"}
						</Button>
					</DialogActions>
				</>
			) : (
				""
			)}
			<Box display={"flex"} flexDirection={"row"} p={3} m={2} flexWrap={"wrap"} rowGap={3} columnGap={"5%"}>
				{storeEmployee.length
					? storeEmployee.map((emp) => (
							<Card
								key={emp.email}
								variant={"outlined"}
								sx={{ width: "29%", display: emp.email ? "block" : "none" }}>
								<CardContent>
									<Typography variant="h6">{emp.type}</Typography>
									<Typography>{emp.name}</Typography>
									<Typography>{emp.email}</Typography>
									<Typography>{emp.mobile_no}</Typography>
								</CardContent>
							</Card>
					  ))
					: ""}
			</Box>
		</Dialog>
	);
}

export default EmployeeDetail;
