import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const CategoryDrawer = ({ data }) => {
	return (
		<List
			sx={{
				bgcolor: "background.paper",
				borderBottom: "1px solid var(--grayBgColor)",
				padding: "0px",
				width: "280px",
			}}
			minHeight="200px">
			<SubCategory data={data} depth={0} />
		</List>
	);
};

export default CategoryDrawer;

const SubCategory = ({ data, depth }) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	return (
		<Box pl={depth === 0 ? 0 : 1}>
			{data.isGroup ? (
				<>
					<ListItemButton disableRipple onClick={() => setOpen(!open)} sx={{ padding: "0px" }}>
						<ListItemText
							primary={data.name}
							disableTypography
							sx={{ padding: "2px 15px", fontSize: 18 - depth }}
						/>
						{open ? <ExpandLess sx={{ pr: 1 }} /> : <ExpandMore sx={{ pr: 1 }} />}
					</ListItemButton>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List component="div" disablePadding sx={{ bgcolor: "#f5f5f5" }} p={0}>
							{data.items.map((subCategory) => (
								<SubCategory key={subCategory.key} data={subCategory} depth={2 + depth} />
							))}
						</List>
					</Collapse>
				</>
			) : (
				<ListItemButton onClick={() => navigate(`/explore/${data.key}`)} sx={{ padding: "0px 15px" }}>
					<ListItemText sx={{ fontSize: 18 - depth }} primary={data.name} disableTypography />
				</ListItemButton>
			)}
		</Box>
	);
};

// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { Stack, Button } from "@mui/material";
// import Link from "@mui/material/Link";
// import { useNavigate } from "react-router-dom";

// const Category = ({ data }) => {
// 	return (
// 		<Box
// 			width={{ xs: "90%", sm: "40%", md: "28%" }}
// 			minHeight="200px"
// 			boxShadow={1}
// 			borderRadius={2}
// 			padding="20px"
// 			mb={2}>
// 			<SubCategory data={data} depth={0} />
// 		</Box>
// 	);
// };

// export default Category;

// const SubCategory = ({ data, depth }) => {
// 	const navigate = useNavigate();
// 	return (
// 		<Box pl={depth === 0 ? 0 : 1}>
// 			{data.isGroup ? (
// 				<Stack spacing="2px">
// 					<Typography variant="h6" sx={{ fontSize: depth === 0 ? 24 : 20 - depth }}>
// 						{data.name}
// 					</Typography>

// 					{data.items.map((subCategory) => (
// 						<SubCategory key={subCategory.key} data={subCategory} depth={3 + depth} />
// 					))}
// 				</Stack>
// 			) : (
// 				<Typography
// 					underline="hover"
// 					color="secondary"
// 					p={0}
// 					onClick={() => navigate(`/explore/${data.key}`)}
// 					sx={{
// 						fontSize: 20 - depth,
// 						cursor: "pointer",
// 						textTransform: "capitalize",
// 						"&:hover": { textDecoration: "underline" },
// 					}}>
// 					{data.name}
// 				</Typography>
// 			)}
// 		</Box>
// 	);
// };
