import { Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";

const DisplayDatagrid = ({ onRowClick, rows, columns, rowHeight }) => {
	return (
		<Box width="100%">
			<DataGrid
				rows={rows ? rows : []}
				columns={columns}
				sx={{
					minHeight: 350,
					// border: "none",
					"& .MuiDataGrid-row": {
						// borderBottom: "1px solid lightgray",
						cursor: "pointer",
						// border: "none",
					},
					"& .MuiDataGrid-columnHeader": {
						cursor: "pointer",
					},
					"& .MuiDataGrid-columnHeaders": {
						background: grey[100],
						mb: 2,
						// border: "none",
						borderRadius: 1,
						// padding: "10px 0px",
					},
				}}
				columnHeaderHeight={35}
				rowHeight={rowHeight ? rowHeight : 40}
				// showCellVerticalBorder
				onRowClick={(params, e) => {
					if (typeof onRowClick === "function") onRowClick(params.row, e);
				}}
				initialState={{
					pagination: {
						paginationModel: { pageSize: 10 },
					},
				}}
				disableRowSelectionOnClick
				pageSizeOptions={[10, 50]}
			/>
		</Box>
	);
};

export default DisplayDatagrid;
