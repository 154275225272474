import { useEffect, useState } from "react";
import { Box, Button, Chip, Dialog, DialogActions, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { CloseRounded, DeleteRounded } from "@mui/icons-material";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import StyleFilter from "components/Pim/CommonComponents/StyleFilter/StyleFilter";
import { colorsObjName, productImagesObjName, styleImagesObjName } from "data/pimData";
import { PimState } from "context/PimContext";

const defaultFilterData = { division: "APPARELS" };

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const ProductBox = ({ data }) => {
	let imgWidth = 50;
	let imgHeight = 60;
	return (
		<Box width="100%" display="flex" alignItems="center">
			<img
				style={{
					width: `${imgWidth}px`,
					height: `${imgHeight}px`,
					objectFit: "cover",
					objectPosition: "50% 0%",
					borderRadius: 2,
				}}
				src={data[productImagesObjName][0]?.url}
			/>
			<Stack pl={1} justifyContent="space-between" rowGap={1}>
				<Typography variant="body1" width="100%" fontSize={18} fontWeight={600}>
					{data.style_name}
				</Typography>
				{/* <Typography variant="body1" fontSize={12} fontWeight={500} pt={0}>
					{data.color}-{data.pallette}
				</Typography> */}
				<Box display="flex" columnGap={1} alignItems="center">
					<Chip label={data.category} size="small" />
					<Typography variant="body1" fontSize={14}>
						{data.sub_category}
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};

const CreateBuying = ({ sheetName, setSheetName, season, setSeason }) => {
	const [seasons, setSeasons] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();

	useEffect(() => {
		getSeasons();
	}, []);
	const getSeasons = async () => {
		setLoadingOpen(true);
		await axios
			.get(`/api/pim/get_attribute_detail/season`)
			.then((res) => {
				if (res.status === 200 && res.data) {
					console.log("res.data", res.data);
					setSeasons(res.data?.value);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		setLoadingOpen(false);
	};
	return (
		<>
			<Typography variant="body1" width="100%" fontSize={20} fontWeight={600} sx={{ margin: "15px 0 0 5px" }}>
				<span style={{ marginLeft: "10px" }}>Create Buying Sheet</span>
			</Typography>
			<Stack display="flex" flexDirection="row" row="2" columnGap={2} sx={{ margin: "20px 0px 20px 10px" }}>
				<Box>
					<InputLabel>Name*</InputLabel>
					<TextField
						required
						size="small"
						value={sheetName}
						onChange={(e) => {
							setSheetName(e.target.value);
						}}
						placeholder="Enter Buying Sheet name"
					/>
				</Box>
				<Box sx={{ width: "50%" }}>
					<InputLabel>Season*</InputLabel>
					<Select
						size="small"
						sx={{ width: "70%" }}
						value={season}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						onChange={(e) => setSeason(e.target.value)}>
						<MenuItem value={"select"}>Select</MenuItem>
						{seasons?.map((row) => {
							return (
								<MenuItem key={row} value={row}>
									{row}
								</MenuItem>
							);
						})}
					</Select>
				</Box>
			</Stack>
		</>
	);
};

const BuyingStyleSheet = ({ season, selectedStyle, setSelectedStyle }) => {
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();
	const [filter, setFilter] = useState({ ...defaultFilterData, season: season });

	const styleTablColumns = [
		{
			field: "image",
			headerName: "Product",
			width: 220,
			...colStyle,
			renderCell: (params) => {
				return <ProductBox data={params.row} />;
			},
		},
		{ field: "style_name", headerName: "STYLE", width: 90, ...colStyle },
		{ field: "category", headerName: "CATEGORY", width: 90, ...colStyle },
		{ field: "sub_category", headerName: "SUB-CATEGORY", width: 100, ...colStyle },
		{ field: "brand", headerName: "BRAND", width: 110, ...colStyle },
		{ field: "sub_brand", headerName: "SUB-BRAND", width: 80, ...colStyle },
		{ field: "season", headerName: "SEASON", width: 80, ...colStyle },
		{
			field: "action",
			headerName: "Action",
			attribute_name: "",
			width: 85,
			...colStyle,
			renderCell: (params) => {
				return (
					<IconButton>
						<DeleteRounded onClick={(e) => deleteStyles(params.row, e)} />
					</IconButton>
				);
			},
		},
	];

	const filterStyleFunction = () => {
		getSelectedSeasonsStyle(filter, false);
	};

	const deleteStyles = (row, e) => {
		e.stopPropagation();
		let deletedStyle = selectedStyle.filter((style) => row.id !== style.id);
		setSelectedStyle([...deletedStyle]);
	};

	const getSelectedSeasonsStyle = async (filter, limit) => {
		setLoadingOpen(true);
		let body = { limit };
		body["limit"] = false;
		body["filter"] = filter;
		try {
			await axios.post("/api/pim/get_styles", body).then((res) => {
				console.log("sjhdgahsgdv", res.data);
				setSelectedStyle(res.data);
			});
			setLoadingOpen(false);
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Server Error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};
	return (
		<>
			<Typography variant="body1" width="100%" fontSize={20} fontWeight={600} sx={{ margin: "15px 0 0 5px" }}>
				<span style={{ marginLeft: "10px" }}>Select Styles</span>
			</Typography>
			<StyleFilter filter={filter} setFilter={setFilter} submitFunction={filterStyleFunction} disableData={["season"]} />
			<Stack display="flex" flexDirection="row" row="1" columnGap={2} sx={{ margin: "20px 0px 0px 10px" }}>
				<DataGrid
					rows={selectedStyle}
					columns={styleTablColumns}
					height={0}
					sx={{
						minHeight: 220,
						maxHeight: 300,
						height: "67%",
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							// cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							background: grey[300],
							// mb: 2,
							border: "none",
							borderRadius: 1,
							// padding: "10px 0px",
						},
					}}
					columnHeaderHeight={35}
					rowHeight={60}
					disableRowSelectionOnClick
					// pageSizeOptions={[50, 100]}
					// hideFooter={true}
					// getCellParams={getCellParams}
				/>
			</Stack>
		</>
	);
};
const CreateBuyingSheet = ({ setOpenCreateBuying }) => {
	const { buyingSheet, setBuyingSheet } = PimState();
	const [modalOpen, setModalOpen] = useState(true);
	const [screen, SetScreen] = useState(0);
	const [sheetName, setSheetName] = useState("");
	const [season, setSeason] = useState("select");
	const [selectedStyle, setSelectedStyle] = useState([]);

	const handleColseDialog = () => {
		setOpenCreateBuying();
		setModalOpen(false);
	};

	const handleCreateBuyingSheet = () => {
		let allColors = [];
		selectedStyle.forEach((style) => {
			allColors.push(style[colorsObjName]);
		});
		const data = {
			id: 4,
			sheet_name: sheetName,
			season: season,
			total_styles: selectedStyle.length,
			total_qty: 0,
			created_by: "amit@thor.com",
			published_by: "",
			is_published: false,
			[colorsObjName]: allColors,
		};
		setBuyingSheet([...buyingSheet, data]);
		setModalOpen(false);
	};
	let displayScreen = (
		<CreateBuying sheetName={sheetName} setSheetName={(value) => setSheetName(value)} season={season} setSeason={(value) => setSeason(value)} />
	);
	if (screen === 1) {
		displayScreen = <BuyingStyleSheet season={season} selectedStyle={selectedStyle} setSelectedStyle={(styles) => setSelectedStyle(styles)} />;
	}

	let butonDisable = true;
	if (screen === 0 && sheetName !== "" && season !== "select") {
		butonDisable = false;
	} else if (screen === 1 && selectedStyle.length > 0) {
		butonDisable = false;
	}
	return (
		<Dialog
			maxWidth={screen === 0 ? "xs" : "md"}
			PaperProps={{ style: { padding: "15px" } }}
			fullWidth
			className="Channel_dialog"
			open={modalOpen}
			// onClose={() => setModalOpen(false)}
		>
			<IconButton
				aria-label="close"
				onClick={() => {
					handleColseDialog();
				}}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}>
				<CloseRounded />
			</IconButton>
			<Stack>{displayScreen}</Stack>
			<DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
				<Stack direction="row">
					<Button
						variant="outlined"
						autoFocus
						onClick={() => {
							handleColseDialog();
						}}>
						Cancel
					</Button>
					{screen === 1 && (
						<Button
							// disabled={}
							sx={{ margin: "0 0 0 7px" }}
							variant="contained"
							autoFocus
							onClick={() => SetScreen(0)}>
							Back
						</Button>
					)}
					<Button
						disabled={butonDisable}
						sx={{ margin: "0 0 0 7px" }}
						variant="contained"
						onClick={screen === 0 ? () => SetScreen(1) : handleCreateBuyingSheet}>
						{screen === 0 ? "Next" : "Create"}
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};

export default CreateBuyingSheet;
