import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import { DownloadDoneRounded, DownloadRounded } from "@mui/icons-material";
import FileSaver from "file-saver";
import { grey } from "@mui/material/colors";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';

const tablecolumns = [
	{ field: "name", headerName: "Name", width: 160, headerAlign: "center", align: "center" },
	{ field: "email", headerName: "Email", width: 200, headerAlign: "center", align: "center" },
	{ field: "contact", headerName: "Contact", width: 200, headerAlign: "center", align: "center" },
	{ field: "query", headerName: "Query", width: 165, headerAlign: "center", align: "center" },
	{ field: "message", headerName: "Message", width: 200, headerAlign: "center", align: "center" },
];
const datas = [{name: 'Name', key: 'name'}, {name: 'Email', key: 'email'}, {name: 'Contact', key: 'contact'},{name: 'Query', key: 'query'},{name: 'Other Title', key: 'others'}, {name: 'Region', key: 'region'}, {name: 'Message', key: 'message'}]

const ContactUs = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [open, setOpen] = React.useState(false);
	const [selectedRow, setSelectedRow] = useState({})

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

	useEffect(() => {
		// Reference to your Firebase database
		const fetch = async () => {
			try {
				const res = await axios.get("/api/website/contact_us");
				setData(res.data.result);
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Network Error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		};
		fetch();
	}, []);
	const handleDownloadClick = async () => {
		try {
			await axios.get("/api/website/download_contactus", {responseType: "blob"}).then((res) => {
				console.log(res);
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, "ContactUs.xlsx");
			});
		}catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Failed to download file",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
		
	};

	const handleRowClick = (params) => {
		setSelectedRow(params.row)
		handleClickOpen()
	}
	const DisplayDetailes = (key, value) => {
		return (
			<Typography width={'100%'} sx={{ margin: '10px 0'}} >
				<span>
					{key}
				</span>
				:
				<span style={{marginLeft: '5px', fontWeight: '500'}}>
					{value}
				</span>
			</Typography>
		)
	}
	return (
		<Stack maxWidth="1200px" spacing={3} margin="auto">
			<Box display="flex" justifyContent="space-between" width="100%">
				<Typography variant="h4" width="100%">
					Contact US
				</Typography>
				<Button variant="contained" startIcon={<DownloadRounded />} onClick={handleDownloadClick}>
					Download
				</Button>
			</Box>

			{/* table */}
			<Box sx={{ width: "100%" }}>
				<DataGrid
					rows={data}
					columns={tablecolumns}
					columnHeaderHeight={40}
					sx={{
						// minHeight: 430,
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
						// mb: 10,
						// background: grey[200],
						borderRadius: "8px",
						minHeight: 400,
						maxHeight: 480,
						"&.MuiDataGrid-root": {
							boxSizing: "none",
							boxShadow: `2px 2px 5px ${grey[200]}`,
						},
						// "& .MuiDataGrid-row": {
						// },
						"& .MuiDataGrid-row:first-child  ": {},
						"& .MuiDataGrid-cell": {
							padding: 0,
							borderBottom: "0px",
						},
						"& .MuiDataGrid-columnHeader": {
							padding: 0,
						},
						".MuiDataGrid-columnSeparator": {
							display: "none",
						},
						// "& .MuiDataGrid-cell:first-child ": {
						// 	padding: 0,
						// 	// background: grey[900],
						// },
					}}
					// rowHeight={36}
					// showCellVerticalBorder
					showColumnVerticalBorder
					onRowClick={(params) => {
						handleRowClick(params)
					}}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
					}}
					autoHeight
					disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Box>
			<React.Fragment>
				<Dialog
					fullWidth
					open={open}
					onClose={handleClose}
					maxWidth="xs"
				>
					<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
						Detailes
					</DialogTitle>
					<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent dividers>
						{datas.map((val) =>
						{return selectedRow[val.key] !== '' && DisplayDetailes(val.name, selectedRow[val.key])}
						)}
					</DialogContent>
				</Dialog>
			</React.Fragment>

		</Stack>
	);
};

export default ContactUs;
