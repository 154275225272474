export const getDatesofSeason = (season, year) => {
	let startDate, endDate;
	if (season === "AW") {
		startDate = `${year}-8-16`;
		endDate = `${year + 1}-2-14`;
	} else {
		startDate = `${year}-2-15`;
		endDate = `${year}-8-15`;
	}
	return { startDate, endDate };
};
