import React, { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
	const [snackBar, setSnackBar] = useState({
		message: "userdetails dont match",
		severity: "error",
		anchorOrigin: { horizontal: "center", vertical: "top" },
	});

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [loginUser, setLoginUser] = useState();
	const [rootPath, setRootPath] = useState();
	const [Loadingopen, setLoadingOpen] = useState(false);

	return (
		<GlobalContext.Provider
			value={{
				snackBar,
				setSnackBar,
				snackBarOpen,
				setSnackBarOpen,
				setLoginUser,
				loginUser,
				rootPath,
				setRootPath,
				Loadingopen,
				setLoadingOpen,
			}}>
			{children}
		</GlobalContext.Provider>
	);
};

export const GlobalState = () => {
	return useContext(GlobalContext);
};

export default GlobalProvider;
