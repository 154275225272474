import { Box, ImageList, ImageListItem, ImageListItemBar, IconButton, Button, Typography, Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import ImageUploading from "react-images-uploading";
import { DeleteRounded } from "@mui/icons-material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const ImageUploadHandler = ({ images, setImages, setMainImageIndex, mainImageIndex, disableButtons }) => {
	const maxNumber = 5;
	const onChange = (imageList, addUpdateIndex) => {
		// data for submit
		console.log(imageList, addUpdateIndex);
		setImages(imageList);
		console.log(images);
	};
	let imgWidth = 200;
	let imgHeight = 250;

	const updateFilename = (fileData, nameCol) => {
		const newName = `${fileData.name?.split(" ").join("_")}_${nameCol ? nameCol : ""}_${new Date().valueOf()}`;
		return new File([fileData], newName, {
			type: fileData.type,
			lastModified: fileData.lastModified,
		});
	};

	const handleSelectMainImgClick = (mainIndex) => {
		console.log(mainIndex);
		if (mainImageIndex !== mainIndex) {
			setMainImageIndex(mainIndex);
			setImages(
				images.map((row, i) => {
					if (i === mainIndex) return { ...row, file: updateFilename(row.file, "__main__"), is_main: true };
					else return row;
				})
			);
		}
	};

	return (
		<Stack spacing={2}>
			<Typography width="100%" textAlign="center" variant="h6">
				Add Images
			</Typography>
			<ImageUploading
				multiple
				value={images}
				onChange={onChange}
				maxNumber={maxNumber}
				dataURLKey="data_url"
				acceptType={["jpg", "png"]}>
				{({
					imageList,
					onImageUpload,
					onImageRemoveAll,
					onImageUpdate,
					onImageRemove,
					isDragging,
					dragProps,
				}) => (
					// write your building UI
					<Stack spacing={1} alignItems="center">
						<Box>
							<Button
								role={undefined}
								disabled={images.length > 4}
								variant="outlined"
								tabIndex={-1}
								sx={{ color: isDragging ? "red" : null, p: 3 }}
								onClick={onImageUpload}
								{...dragProps}
								startIcon={<CloudUploadIcon />}>
								Click or Drop here
							</Button>
						</Box>
						<Box width="80%" display="flex" flexWrap="wrap" columnGap={2}>
							{images.map((image, index) => (
								<Box key={index} sx={{ position: "relative" }}>
									<img
										style={{
											width: `${imgWidth}px`,
											height: `${imgHeight}px`,
											objectFit: "cover",
											objectPosition: "50% 0%",
											borderRadius: 2,
											zIndex: 2,
										}}
										src={image.data_url}
									/>
									<IconButton
										disabled={mainImageIndex === index}
										sx={{
											position: "absolute",
											left: "70%",
											top: "78%",
											zIndex: 10,
											background: "#00000038",
											"&: hover": { background: "#00000083" },
											display: disableButtons === true ? "none" : "block",
										}}
										onClick={() => onImageRemove(index)}>
										<DeleteRounded sx={{ color: "#fff" }} />
									</IconButton>
									<Box
										sx={{
											position: "absolute",
											left: "2%",
											top: "78%",
											zIndex: 11,
											display: disableButtons === true ? "none" : "block",
										}}>
										<Button
											variant="contained"
											startIcon={mainImageIndex === index && <CheckRoundedIcon color="inherit" />}
											color={mainImageIndex === index ? "success" : "primary"}
											// disabled={index === mainImageIndex}

											onClick={() => handleSelectMainImgClick(index)}>
											{mainImageIndex === index ? " Main" : "Set Main"}
										</Button>
									</Box>

									{/* <IconButton
										sx={{
											position: "absolute",
											left: "58%",
											top: "78%",
											zIndex: 10,
											background: "#00000038",
											"&: hover": { background: "#00000083" },
										}}
										onClick={() => onImageRemove(index)}>
										<DeleteRounded sx={{ color: "#fff" }} />
									</IconButton> */}
								</Box>
							))}
						</Box>

						<Box width="100%" display={imageList.length >= 2 ? "flex" : "none"} justifyContent="center">
							<Button
								variant="outlined"
								onClick={onImageRemoveAll}
								startIcon={<DeleteRounded />}
								sx={{ margin: "auto" }}>
								Delete All
							</Button>
						</Box>
					</Stack>
				)}
			</ImageUploading>
		</Stack>
	);
};
export default ImageUploadHandler;
