import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import GlobalProvider from "./context/GlobalContext";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<BrowserRouter>
		<GlobalProvider>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</GlobalProvider>
	</BrowserRouter>
	// </React.StrictMode>
);
