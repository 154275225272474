import React, { createContext, useContext, useState } from "react";

const MyntraRecoContext = createContext();

const MyntraRecoProvider = ({ children }) => {
	const [dashboardData, setDashboardData] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	return (
		<MyntraRecoContext.Provider
			value={{
				dashboardData,
				setDashboardData,
				startDate,
				setStartDate,
				endDate,
				setEndDate,
			}}>
			{children}
		</MyntraRecoContext.Provider>
	);
};

export const MyntraRecoState = () => {
	return useContext(MyntraRecoContext);
};

export default MyntraRecoProvider;
