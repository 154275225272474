import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SearchBar from "components/ReusableComponents/SearchBar";
import {
	buyingColorAssocObj,
	colorStyleAssocObj,
	colorImgAssociationObjName,
	statusOptions,
	designerAttributeObjName,
} from "data/pimData";
import { grey } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import { PimState } from "context/PimContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StyleFilter from "../../CommonComponents/StyleFilter/StyleFilter";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const BuyingBox = ({ data }) => {
	let imgWidth = 50;
	let imgHeight = 60;
	let selectedColor = data[buyingColorAssocObj];
	let colorStyle = selectedColor ? selectedColor[colorStyleAssocObj] : {};
	let imageUrl = selectedColor
		? selectedColor[colorImgAssociationObjName]
			? selectedColor[colorImgAssociationObjName][0].url
			: ""
		: "";

	return (
		<Box width="100%" display="flex" alignItems="center">
			<img
				style={{
					width: `${imgWidth}px`,
					height: `${imgHeight}px`,
					objectFit: "cover",
					objectPosition: "50% 0%",
					borderRadius: 2,
				}}
				src={imageUrl}
			/>
			<Stack pl={1} justifyContent="space-between" rowGap={1}>
				<Typography variant="body1" width="100%" fontSize={18} fontWeight={600}>
					{colorStyle?.style_name}
				</Typography>
				<Box display="flex" columnGap={1} alignItems="center">
					<Chip label={selectedColor?.color} size="small" />
					<Typography variant="body1" fontSize={14}>
						{selectedColor?.pallete}
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};
const RenderStyleColumns = ({ data, column }) => {
	let selectedColor = data[buyingColorAssocObj];
	let colorStyle = selectedColor ? selectedColor[colorStyleAssocObj] : {};

	return <Typography variant="subtitle2">{colorStyle[column] ? colorStyle[column] : ""}</Typography>;
};
const RenderColorColumns = ({ data, column }) => {
	let selectedColor = data[buyingColorAssocObj];

	return <Typography variant="subtitle2">{selectedColor[column]}</Typography>;
};
const RenderDesignerAttributeColumns = ({ data, column }) => {
	let selectedAttr = data[designerAttributeObjName];

	return <Typography variant="subtitle2">{selectedAttr[column]}</Typography>;
};

const defaultFilterData = { division: "APPARELS" };
const ApproxCogsDashboard = () => {
	const {} = PimState();
	const { setLoadingOpen } = GlobalState();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [totalBuying, setTotalBuying] = useState([]);
	const navigate = useNavigate();
	const [filter, setFilter] = useState(defaultFilterData);

	const [searchItem, setSearchItem] = useState("");
	const [selectedRows, setSelectedRows] = useState("");
	const [displayImg, setDisplayImg] = useState(false);

	const [pendingCount, setPendingCount] = useState(0);
	const [searchActive, setSearchActive] = useState(false);

	const fetch = async (filter, limit) => {
		setLoadingOpen(true);
		let body = { limit };
		body["limit"] = limit === false ? false : true;
		if (filter) body["filter"] = filter;
		try {
			await axios.post("/api/pim/get_style_cogs", body).then((res) => {
				setTotalBuying(res.data);
				let count = 0;
				res.data.forEach((row) => {
					if (!row.is_published) count++;
				});
				setPendingCount(count);
			});
			setLoadingOpen(false);
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Server Error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};

	const handleSearch = async (value) => {
		setLoadingOpen(true);

		try {
			const res = await axios.get(`/api/pim/get_style_cogs?query=${value}`);
			setTotalBuying(res.data);
			let count = 0;
			res.data.forEach((row) => {
				if (!row.is_published) count++;
			});
			setPendingCount(count);
			setLoadingOpen(false);
			setSearchActive(true);
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Server Error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};
	const clearSearch = () => {
		fetch();
		setSearchActive(false);
	};

	useEffect(() => {
		fetch();
	}, []);
	const filterStyleFunction = () => {
		fetch(filter, false);
	};

	const handleImageSwitchChange = (checked) => {
		setDisplayImg(checked);
	};

	const tablecolumns = [
		{
			field: "image",
			headerName: "Product",
			width: 220,
			...colStyle,
			renderCell: (params) => {
				return <BuyingBox data={params.row} />;
			},
		},
		{
			field: "style_name",
			headerName: "STYLE",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderStyleColumns column="style_name" data={row} />;
			},
		},
		{
			field: "season",
			headerName: "Season",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderStyleColumns column="season" data={row} />;
			},
		},
		{
			field: "designer_mrp",
			headerName: "MRP(Designer)",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderStyleColumns data={row} column="designer_mrp" />;
			},
		},
		{
			field: "cogs",
			headerName: "COGS(Approx)",
			width: 140,
			...colStyle,
			editable: true,
			renderCell: ({ row }) => {
				return <Typography variant="subtitle1">{499}</Typography>;
			},
		},

		{
			field: "stage",
			headerName: "Stage",
			width: 140,
			renderCell: ({ row }) => {
				return (
					<Box
						display="flex"
						alignItems="center"
						height={"22px"}
						// columnGap={1}
						sx={{
							backgroundColor: statusOptions["CREATED"]?.color,
							color: "white",
							padding: "6px",
							borderRadius: "5px",
						}}>
						{/* {statusOptions[row.status]?.icon} */}
						<Typography pl="3px">
							{" "}
							<RenderStyleColumns data={row} column="stage" />
						</Typography>
					</Box>
				);
			},
		},
		,
		{
			field: "action",
			headerName: "Action",
			attribute_name: "",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return (
					<Button variant="contained" size="small">
						Publish
					</Button>
				);
			},
		},
		,
	];

	return (
		<Stack width="100%" spacing={1} pt={1}>
			<StyleFilter filter={filter} setFilter={setFilter} submitFunction={filterStyleFunction} />

			<SearchBar
				placeholder="Search Style"
				submitFunction={handleSearch}
				clearFunction={clearSearch}
				displayClearBtn={searchActive}
			/>

			<Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
				<Box display="flex" flexGrow={1}>
					<Typography variant="h6">Images</Typography>
					<Switch checked={displayImg} onChange={(e) => handleImageSwitchChange(e.target.checked)} />
				</Box>
				<Typography variant="h6" pr={2}>
					Pending : <span style={{ color: "red" }}>{pendingCount}</span>
				</Typography>
				<Button variant="contained" disabled={!selectedRows.length}>
					Publish
				</Button>
			</Stack>

			<Stack width="100%" height={400}>
				<DataGrid
					rows={totalBuying}
					columns={tablecolumns}
					key={displayImg}
					sx={{
						minHeight: 350,
						maxHeight: 430,
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							// cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							background: grey[300],
							mb: 2,
							border: "none",
							borderRadius: 1,
							// padding: "10px 0px",
						},
					}}
					columnHeaderHeight={35}
					rowHeight={displayImg ? 70 : 50}
					//   processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={(error) => console.log(error)}
					// showCellVerticalBorder
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
						columns: {
							columnVisibilityModel: {
								image: displayImg,
								color: !displayImg,
								pallete: !displayImg,
								style_name: !displayImg,
							},
						},
					}}
					// loading={loading}
					disableRowSelectionOnClick
					checkboxSelection
					isRowSelectable={(params) => params.row.is_published === false}
					pageSizeOptions={[10, 50]}
					onRowSelectionModelChange={(newSelectionModel) => {
						console.log("dfsd", newSelectionModel);
						let obj = newSelectionModel?.reduce((acc, row) => {
							acc[row] = true;
							return acc;
						}, {});

						setSelectedRows(totalBuying.filter((row) => obj[row["id"]] === true));
					}}
				/>
			</Stack>
		</Stack>
	);
};

export default ApproxCogsDashboard;
