import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { eventWrapper } from "@testing-library/user-event/dist/utils";
import { ClearRounded } from "@mui/icons-material";

const SearchBar = ({ placeholder, submitFunction, displayClearBtn, clearFunction }) => {
	const [search, setSearch] = useState("");
	const handleSubmitClick = (e) => {
		e.preventDefault();
		if (typeof submitFunction === "function") submitFunction(search);
	};
	const handleClearClick = (e) => {
		e.preventDefault();
		setSearch("");
		if (typeof clearFunction === "function") clearFunction();
	};
	return (
		<Paper
			component="form"
			sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 300 }}
			onSubmit={handleSubmitClick}
			elevation={5}>
			<IconButton type="button" disabled sx={{ p: "10px" }} aria-label="search">
				<SearchIcon />
			</IconButton>
			<InputBase
				sx={{ ml: 1, flex: 1 }}
				placeholder={placeholder}
				inputProps={{ "aria-label": "Search Products" }}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				required
			/>
			{displayClearBtn ? (
				<IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={handleClearClick}>
					<ClearRounded />
				</IconButton>
			) : (
				<></>
			)}

			{/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
			{/* <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
				<DirectionsIcon />
			</IconButton> */}
		</Paper>
	);
};

export default SearchBar;
