import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import CodeIcon from "@mui/icons-material/Code";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import axios from "axios";
import DisplayTable from "../../ReusableComponents/DisplayTable";
import moment from "moment";
import dayjs from "dayjs";
import { GlobalState } from "../../../context/GlobalContext";
import FileSaver from "file-saver";
import { isStartDateGraterThanEndDate } from "utils/isStartDateGreaterThanEndDate";
import { modifyExcel } from "utils/excelTemplateEboPayment";

const Abacus = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading1, setDownloading1] = useState(false);
	const [downloading2, setDownloading2] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/abcus_payment_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading1(true);
		try {
			await axios({
				url: `/api/ebo_payments/abcus_payments_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `abacus_detailed_data_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading1(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading1(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setDownloading2(true);
		try {
			await axios({
				url: `/api/ebo_payments/abcus_payment_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `abacus_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "ABACUS");
				setDownloading2(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading2(false);
			console.log(error);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Abacus
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading1}
							startIcon={
								downloading1 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={downloading2}
							startIcon={
								downloading2 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};
const Moncher = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading1, setDownloading1] = useState(false);
	const [downloading2, setDownloading2] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/moncher_payments_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading1(true);
		try {
			await axios({
				url: `/api/ebo_payments/moncher_payments_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `moncher_detailed_data_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading1(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading1(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setDownloading2(true);
		try {
			await axios({
				url: `/api/ebo_payments/moncher_payments_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `moncher_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "Moncher");
				setDownloading2(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading2(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Moncher
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run "}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading1}
							startIcon={
								downloading1 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={downloading2}
							startIcon={
								downloading2 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};
const DreamShiveraMaharashtra = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading1, setDownloading1] = useState(false);
	const [downloading2, setDownloading2] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/dream_shivera_maharashtra_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading1(true);
		try {
			await axios({
				url: `/api/ebo_payments/dream_shivera_maharashtra_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(
					url,
					`dream_shivera_maharashtra_detailed_data_${result.startDate}_to_${result.endDate}.xlsx`
				);
				setDownloading1(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading1(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setDownloading2(true);
		try {
			await axios({
				url: `/api/ebo_payments/dream_shivera_maharashtra_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `dream_shivera_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "DreamShivera-Maharashtra");
				setDownloading2(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading2(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Dream Shivera MH
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading1}
							startIcon={
								downloading1 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={downloading2}
							startIcon={
								downloading2 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

const DreamShiveraRajasthan = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading1, setDownloading1] = useState(false);
	const [downloading2, setDownloading2] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/dream_shivera_rajasthan_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading1(true);
		try {
			await axios({
				url: `/api/ebo_payments/dream_shivera_rajasthan_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(
					url,
					`dream_shivera_rajasthan_detailed_data_${result.startDate}_to_${result.endDate}.xlsx`
				);
				setDownloading1(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading1(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setDownloading2(true);
		try {
			await axios({
				url: `/api/ebo_payments/dream_shivera_rajasthan_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `dream_shivera_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "DreamShivera-Rajasthan");
				setDownloading2(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading2(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Dream Shivera RJ
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel> Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel> End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading1}
							startIcon={
								downloading1 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={downloading2}
							startIcon={
								downloading2 ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

const Shivera = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [summaryDownloading, setSummaryDownloading] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/shivera_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/shivera_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `shivera_full_data_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setSummaryDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/shivera_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `shivera_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "Shivera");
				setSummaryDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setSummaryDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Shivera
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run "}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading}
							startIcon={
								downloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={summaryDownloading}
							startIcon={
								summaryDownloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

const SageBounty = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [summaryDownloading, setSummaryDownloading] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/sage_bounty_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/sage_bounty_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `sage_bounty_full_data_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setSummaryDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/sage_bounty_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `sage_bounty_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "Sage Bounty");
				setSummaryDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setSummaryDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Sage Bounty
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run "}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading}
							startIcon={
								downloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={summaryDownloading}
							startIcon={
								summaryDownloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

const RenePandari = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [summaryDownloading, setSummaryDownloading] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/rene_pandari_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/rene_pandari_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `rene_pandari_full_data_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setSummaryDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/rene_pandari_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `sage_bounty_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "Rene Pandari");
				setSummaryDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setSummaryDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Rene Pandari
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading}
							startIcon={
								downloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={summaryDownloading}
							startIcon={
								summaryDownloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

const Ajanta = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [summaryDownloading, setSummaryDownloading] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/ajanta_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/ajanta_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `ajanta_full_data_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setSummaryDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/ajanta_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `sage_bounty_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "ajanta");
				setSummaryDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setSummaryDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Ajanta
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading}
							startIcon={
								downloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={summaryDownloading}
							startIcon={
								summaryDownloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

const ShreeJi = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();
	const [result, setResult] = useState();
	const [isMismatch, setIsMismatch] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [summaryDownloading, setSummaryDownloading] = useState(false);

	const checkMisMatch = (records) => {
		let isMismatch = false;

		return true;
	};
	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		if (result) setResult();
		setLoading(true);
		setError("");

		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setError("Start date must be smaller than End date");
			setLoading(false);

			return;
		}

		try {
			await axios
				.get(`/api/ebo_payments/shree_ji_run_query?startDate=${startDate}&endDate=${endDate}`)
				.then((res) => {
					setResult({ result: res.data, startDate: startDate, endDate: endDate });
					setLoading(false);
					setStartDate(new Date());
					setEndDate(new Date());
					if (checkMisMatch(res.data)) setIsMismatch(true);
				});
		} catch (error) {
			console.log(error);
			if (error.response) {
				const errData = error.response.data;
				setError(errData.error);
			} else setError("Error Occured !!!");
			setLoading(false);
		}
	};

	const handleDownloadClick = async () => {
		setDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/shree_ji_download_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
				responseType: "blob",
			}).then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `shree_ji_full_data_${result.startDate}_to_${result.endDate}.xlsx`);
				setDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	const handleSummaryDownloadClick = async () => {
		setSummaryDownloading(true);
		try {
			await axios({
				url: `/api/ebo_payments/shree_ji_run_query?startDate=${result.startDate}&endDate=${result.endDate}`,
				method: "GET",
			}).then((res) => {
				// const url = URL.createObjectURL(res.data);
				// FileSaver.saveAs(url, `sage_bounty_summary_data_${result.startDate}_to_${result.endDate}.xlsx`);
				modifyExcel(res.data, "shree-ji");
				setSummaryDownloading(false);
			});
		} catch (error) {
			setIsMismatch(false);
			setSummaryDownloading(false);
			if (error.response)
				if (error.response.status === 404)
					setSnackBar({
						...snackBar,
						message: "No mismatch records were found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				else
					setSnackBar({
						...snackBar,
						message: "Internal Server Error",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
			else
				setSnackBar({
					...snackBar,
					message: "Error :Download Failed",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			setSnackBarOpen(true);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6" textTransform="uppercase">
				Shree-ji
			</Typography>
			{/* form */}
			<Box display="flex" columnGap={2}>
				<Stack spacing={1}>
					<InputLabel>Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
					/>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading && <CircularProgress color="inherit" size="20px" />}>
				{loading ? "Loading..." : "Run"}
			</Button>
			<Typography color="red" variant="subtitle1">
				{" "}
				{error ? error : ""}
			</Typography>

			{result ? (
				// table
				<Box sx={{ width: "100%" }} pb={5}>
					<Typography variant="h6">Result</Typography>
					<Box display="flex" pt={2} pb={2}>
						<Typography p="10px  5px" pr={2}>
							From {moment(result.startDate).format("DD-MM-YYYY")} to{" "}
							{moment(result.endDate).format("DD-MM-YYYY")}
						</Typography>
						<Button
							onClick={handleDownloadClick}
							disabled={downloading}
							startIcon={
								downloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize" }}
							variant="contained">
							Full Data
						</Button>
						<Button
							onClick={handleSummaryDownloadClick}
							disabled={summaryDownloading}
							startIcon={
								summaryDownloading ? (
									<CircularProgress color="inherit" size="20px" />
								) : (
									<ArrowDownwardRoundedIcon />
								)
							}
							sx={{ textTransform: "capitalize", mx: 5 }}
							variant="contained">
							Summary
						</Button>
					</Box>
					{result.result ? (
						<DisplayTable rows={result.result} disableFirstRow={true} />
					) : (
						<Typography>Error: Table Display Failed</Typography>
					)}
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

const QueryRunnner = ({ history, setHistory }) => {
	const [selected, setSelected] = useState(0);

	const queryTypes = [
		{
			key: 0,
			name: "Abacus",
			component: <Abacus history={history} setHistory={setHistory} />,
		},
		{
			key: 1,
			name: "Moncher ",
			component: <Moncher history={history} setHistory={setHistory} />,
		},
		{
			key: 2,
			name: "Dream Shivera MH",
			component: <DreamShiveraMaharashtra history={history} setHistory={setHistory} />,
		},
		{
			key: 3,
			name: "Dream Shivera RJ",
			component: <DreamShiveraRajasthan history={history} setHistory={setHistory} />,
		},
		{
			key: 4,
			name: "Shivera",
			component: <Shivera history={history} setHistory={setHistory} />,
		},
		{
			key: 5,
			name: "Sage Bounty",
			component: <SageBounty history={history} setHistory={setHistory} />,
		},
		{
			key: 6,
			name: "Rene Pandari",
			component: <RenePandari history={history} setHistory={setHistory} />,
		},
		{
			key: 7,
			name: "Ajanta",
			component: <Ajanta history={history} setHistory={setHistory} />,
		},
		{
			key: 8,
			name: "Shree Ji",
			component: <ShreeJi history={history} setHistory={setHistory} />,
		},
	];

	const handleQuerySelect = (key) => {
		setSelected(key);
	};
	return (
		<Box display="flex" height="100%">
			<Box height="100%" width={170} sx={{ borderRight: 1, borderColor: "divider" }}>
				{queryTypes &&
					queryTypes.map((row) => {
						return (
							<ListItemButton
								onClick={() => handleQuerySelect(row.key)}
								sx={{ padding: "2px", margin: "5px", borderRadius: "4px" }}
								selected={row.key === selected}
								key={row.key}>
								<ListItemText sx={{ padding: "2px 18px", fontSize: "13px" }} primary={row.name} />
							</ListItemButton>
						);
					})}
			</Box>

			{queryTypes &&
				queryTypes.map((row) => {
					return (
						<Box
							width="80%"
							height="100%"
							p={2}
							key={row.key}
							display={row.key === selected ? "block" : "none"}>
							{row.component}
						</Box>
					);
				})}
		</Box>
	);
};

export default QueryRunnner;
