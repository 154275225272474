import {
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	IconButton,
	InputLabel,
	Button,
} from "@mui/material";
import { GlobalState } from "context/GlobalContext";
import moment from "moment";
import { Delete } from "@mui/icons-material";
import { useRef, useState } from "react";
import { grey } from "@mui/material/colors";
import { isStartDateGraterThanEndDate } from "utils/isStartDateGreaterThanEndDate";
import DisplayTable from "../DisplayTable/DisplayTable";
import { complianceCertColumns, complianceCertDefData } from "data/supplierPageData";

const ComplianceCertificates = ({
	complianceCerts,
	setComplianceCerts,
	addCertificateFunction,
	heading,
	disableAdd,
	disableEdit,
}) => {
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "90%" },
	};
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const tableCell = {
		component: "th",
		scope: "row",
		align: "center",
	};
	const tableCellHead = {
		align: "center",
	};

	const [newComplianceCert, setNewComplianceCert] = useState(complianceCertDefData);

	// const handleDeleteCertClick = (row) => {
	// 	deleteCertFunction && deleteCertFunction();
	// 	setComplianceCerts(complianceCerts.filter((cert) => row.no !== cert.no));
	// };
	const handleAddCertificate = async (e) => {
		e.preventDefault();
		if (newComplianceCert.file === null || newComplianceCert.compliance_certificate_issuer === "") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Fill All The Fields",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		if (
			isStartDateGraterThanEndDate(
				newComplianceCert.compliance_certificate_issue_date,
				newComplianceCert.compliance_certificate_exp_date
			) === true
		) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Exp Date must be greater than Issue Date",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		const test = await addCertificateFunction(newComplianceCert);
		if (test) setNewComplianceCert(complianceCertDefData);
	};

	return (
		<Box display="flex" width="100%" justifyContent="space-between" flexWrap="wrap" margin="auto">
			{heading && (
				<Typography textAlign="center" variant="h6" p={2} width="100%">
					Compliance Certificates
				</Typography>
			)}
			{/* table */}
			<Box flexGrow={1} width="69%" maxHeight={310} minHeight={100} sx={{ overflowY: "scroll" }}>
				<DisplayTable rows={complianceCerts} columns={complianceCertColumns} downloadUrlColumn="url" />
			</Box>
			{!disableEdit && (
				<Stack
					component="form"
					onSubmit={handleAddCertificate}
					width="30%"
					alignItems="center"
					flexWrap="wrap"
					rowGap={1}
					sx={{ backgroundColor: grey[100], borderRadius: 2 }}>
					<Typography variant="h6" pt={2} width="100%" textAlign="center">
						Add Certificate
					</Typography>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Certificate Issuer:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={newComplianceCert?.compliance_certificate_issuer}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_issuer: e.target.value,
								})
							}
							placeholder="Enter Issuer Name"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Cert Issue Date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={newComplianceCert?.compliance_certificate_issue_date}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_issue_date: e.target.value,
								})
							}
							placeholder="Enter Issue Date"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Cert Expiry Date:</InputLabel>
						<TextField
							size="small"
							type="date"
							value={newComplianceCert?.compliance_certificate_exp_date}
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									compliance_certificate_exp_date: e.target.value,
								})
							}
							placeholder="Enter Expiry Date"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Compliance Certificate:</InputLabel>
						<TextField
							size="small"
							type="file"
							onChange={(e) =>
								setNewComplianceCert({
									...newComplianceCert,
									file: e.target.files[0],
								})
							}
							placeholder="Enter director/owner name"
							required
						/>
					</Stack>
					<Box width="100%" display="flex" justifyContent="center">
						<Button
							variant="contained"
							sx={{ margin: "auto", width: 250, mt: 1 }}
							type="submit"
							disabled={disableAdd ? disableAdd : false}>
							Add Certificate
						</Button>
					</Box>
				</Stack>
			)}
		</Box>
	);
};

export default ComplianceCertificates;
