import { CheckRounded, DeleteRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import ImageUploadHandler from "components/Pim/CommonComponents/ImageUploadHandler/ImageUploadHandler";
import ContactPersonDetails from "components/SupplierPage/SupplierForm/ContactPersonDetails/ContactPersonDetails";
import { GlobalState } from "context/GlobalContext";
import { PimState } from "context/PimContext";
import { sizeTypes } from "data/pimData";
import { EditCompanyDetailsModalTypes, contactPersonData, labourTableColumns } from "data/supplierPageData";
import { useEffect, useState } from "react";

const formStack = {
	width: { xs: "80%", sm: "40%", md: "19%" },
};
const EditImageDataModal = ({ modalOpen, setModalOpen, submitFunction }) => {
	const { selectedProduct } = PimState();

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	let imgWidth = 200;
	let imgHeight = 250;
	const [images, setImages] = useState();
	const [mainImageIndex, setMainImageIndex] = useState(0);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	useEffect(() => {
		const fetch = async () => {
			console.log("ran");
			if (!images && modalOpen === true) {
				try {
					const res = await axios.get(`/api/pim/images_by_style?style_id=${selectedProduct?.STYLE_ID}`);
					console.log(res);
					setImages(
						res.data.map((row, index) => {
							if (row.is_main) setMainImageIndex(index);
							return { ...row, data_url: row.url };
						})
					);
				} catch (error) {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Unable to get images",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			}
		};
		fetch();
	}, [modalOpen]);

	const handleCloseModal = () => {
		setModalOpen(false);
	};
	const handleSelectMainImgClick = (index) => {
		setMainImageIndex(index);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		console.log("mainImageIndex", mainImageIndex);
		if (images[mainImageIndex].is_main) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "No Changes to save",
				severity: "info",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		if (typeof submitFunction === "function") {
			let result = await submitFunction({ ...images[mainImageIndex], is_main: true });
			if (result) {
				setModalOpen(false);
				setImages();
			}
		}
	};
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Typography p={2} pb={0} variant="h6">
				Set Main Image
			</Typography>
			<Stack spacing={2} direction="row" p={2} flexWrap="wrap" rowGap={2}>
				{images?.map((image, index) => (
					<Box key={index} sx={{ position: "relative" }}>
						<img
							style={{
								width: `${imgWidth}px`,
								height: `${imgHeight}px`,
								objectFit: "cover",
								objectPosition: "50% 0%",
								borderRadius: 2,
								zIndex: 2,
							}}
							src={image.data_url}
						/>

						<Button
							sx={{
								position: "absolute",
								left: "0%",
								top: "78%",
								zIndex: 11,
								// background: "#00000038",
								// "&: hover": { background: "#00000083" },
							}}
							variant="contained"
							startIcon={mainImageIndex === index && <CheckRounded color="inherit" />}
							color={mainImageIndex === index ? "success" : "primary"}
							// disabled={index === mainImageIndex}
							onClick={() => handleSelectMainImgClick(index)}>
							{mainImageIndex === index ? " Main" : "Set Main"}
						</Button>
					</Box>
				))}
			</Stack>

			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditImageDataModal;
