import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import { DeleteRounded } from "@mui/icons-material";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { Dialog, DialogActions, Switch, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SearchBar from "components/ReusableComponents/SearchBar";
import {
	attributepriorityTypes,
	designerAttributeObjName,
	groups,
	inputTypes,
	pimDrawerElements,
	pimParamName,
	productColumns,
	productDummyData,
	coloredProduct,
	sizeLevelBuyingObj,
	colorProductDummyData,
	buyingColorAssocObj,
	colorStyleAssocObj,
	colorImgAssociationObjName,
	colorChannelBuyingObj,
} from "data/pimData";
import { grey } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import { PimState } from "context/PimContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TablePagination } from "@mui/material";

import "../Buying.css";

import NumberTextField from "components/ReusableComponents/NumberTextField";
import StyleFilter from "../../../CommonComponents/StyleFilter/StyleFilter";
import SizeBuyingModal from "../SizeBuyingModal/SizeBuyingModal";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const channels = [
	{
		name: "EBO",
		field: "ebo",
	},
	{
		name: "MBO",
		field: "mbo",
	},
	{
		name: "LFS",
		field: "lfs",
	},
	{
		name: "ECOM",
		field: "ecom",
	},
];

const BuyingBox = ({ data }) => {
	let imgWidth = 50;
	let imgHeight = 60;
	let selectedColor = data[buyingColorAssocObj];
	let colorStyle = selectedColor ? selectedColor[colorStyleAssocObj] : {};
	let imageUrl = selectedColor ? (selectedColor[colorImgAssociationObjName] ? selectedColor[colorImgAssociationObjName][0].url : "") : "";

	return (
		<Box width="100%" display="flex" alignItems="center">
			<img
				style={{
					width: `${imgWidth}px`,
					height: `${imgHeight}px`,
					objectFit: "cover",
					objectPosition: "50% 0%",
					borderRadius: 2,
				}}
				src={imageUrl}
			/>
			<Stack pl={1} justifyContent="space-between" rowGap={1}>
				<Typography variant="body1" width="100%" fontSize={18} fontWeight={600}>
					{colorStyle?.style_name}
				</Typography>
				<Box display="flex" columnGap={1} alignItems="center">
					<Chip label={selectedColor?.color} size="small" />
					<Typography variant="body1" fontSize={14}>
						{selectedColor?.pallete}
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};
const RenderStyleColumns = ({ data, column }) => {
	let selectedColor = data[buyingColorAssocObj];
	let colorStyle = selectedColor ? selectedColor[colorStyleAssocObj] : {};
	// let imageUrl = selectedColor
	// 	? selectedColor[colorImgAssociationObjName]
	// 		? selectedColor[colorImgAssociationObjName][0].url
	// 		: ""
	// 	: "";

	return <Typography variant="subtitle2">{colorStyle[column]}</Typography>;
};
const RenderColorColumns = ({ data, column }) => {
	let selectedColor = data[buyingColorAssocObj] ? data[buyingColorAssocObj] : {};

	return <Typography variant="subtitle2">{selectedColor[column]}</Typography>;
};

const defaultFilterData = { division: "APPARELS" };
const defaultSize = { size: "Size" };
const BuyingPage = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const {
		attributes,
		attributeValues,
		products,
		setProducts,
		setSelectedProduct,
		// coloredProduct,
		setcoloredProduct,
	} = PimState();
	const { setLoadingOpen } = GlobalState();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [editModal, setEditModal] = useState(false);
	const [filteredSize, setFilteredSize] = useState([]);
	const [totalBuying, setTotalBuying] = useState([]);
	const navigate = useNavigate();
	const [filter, setFilter] = useState(defaultFilterData);
	const [channel, setChannel] = useState({});
	const [selectedChannelBuy, setSelectedChannelBuy] = useState({});
	const [remainingSizes, setRemainingSizes] = useState([]);
	const [remainingQty, setRemainingQty] = useState(0);
	const [remainingPercentage, setRemainingPercentage] = useState(0);
	const [searchItem, setSearchItem] = useState("");
	const [displayImg, setDisplayImg] = useState(false);
	const [searchActive, setSearchActive] = useState(false);
	const [pendingCount, setPendingCount] = useState(0);
	const { selectedStyle, setSelectedStyle } = PimState();

	const fetch = async (filter, limit) => {
		setLoadingOpen(true);
		try {
			await axios.post("/api/pim/get_total_buying", { filter, limit }).then((res) => {
				const filterData = res?.data.filter((data) => data?.buyingColor?.colorStyle?.season === selectedStyle?.season);
				setTotalBuying(filterData);
				// setTotalBuying(colorProductDummyData);
			});
			setLoadingOpen(false);
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Server Error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};
	useEffect(() => {
		fetch();
	}, []);

	const handleSearch = async (value) => {
		setLoadingOpen(true);

		try {
			const res = await axios.get(`/api/pim/search_style?query=${value}`);
			setTotalBuying(res.data);
			let count = 0;
			res.data.forEach((row) => {
				if (!row.is_published) count++;
			});
			setPendingCount(count);
			setLoadingOpen(false);
			setSearchActive(true);
		} catch (error) {
			console.log(error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Server Error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};
	const clearSearch = () => {
		fetch();
		setSearchActive(false);
	};
	const filterStyleFunction = () => {
		fetch(filter, false);
	};

	useEffect(() => {
		// handle_total_qty();
	}, []);

	const handleImageSwitchChange = (checked) => {
		setDisplayImg(checked);
	};
	const preventMinus = (e) => {
		if (e.code === "Minus" || e.key === "+") {
			e.preventDefault();
		}
	};
	const CustomEditableCell = (props) => {
		const handleInputChange = (event) => {
			const newValue = event.target.value || 0;
			// Add your validation logic here
			// For example, to restrict input to numbers only:
			if (!isNaN(Number(newValue))) {
				props.api.setEditCellValue({
					id: props.id,
					field: props.field,
					value: newValue,
				});
			}
		};
		return (
			<div style={{ width: "100%", height: "100%" }}>
				<input
					value={props.value}
					onChange={handleInputChange}
					onKeyPress={preventMinus}
					min={0}
					style={{ width: "100%", height: "100%", border: "none", outline: "none" }}
				/>
			</div>
		);
	};

	const tablecolumns = [
		{
			field: "image",
			headerName: "Product",
			width: 220,
			...colStyle,
			renderCell: (params) => {
				return <BuyingBox data={params.row} />;
			},
		},
		{
			field: "color",
			headerName: "COLOR",
			width: 90,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderColorColumns column="color" data={row} />;
			},
		},
		{
			field: "pallete",
			headerName: "PALLETE",
			width: 90,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderColorColumns column="pallete" data={row} />;
			},
		},
		{
			field: "style_name",
			headerName: "STYLE",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderStyleColumns column="style_name" data={row} />;
			},
		},
		{
			field: "designer_mrp",
			headerName: "MRP(Designer)",
			width: 100,
			...colStyle,
			renderCell: ({ row }) => {
				return <Typography variant="subtitle1">{row["total_qty"]}</Typography>;
			},
		},
		{
			field: "cogs",
			headerName: "COGS(Approx)",
			width: 100,
			...colStyle,
			renderCell: ({ row }) => {
				return <Typography variant="subtitle1">{row["total_qty"]}</Typography>;
			},
		},
		{
			field: "total_qty",
			headerName: "Total Qty",
			width: 100,
			...colStyle,
			renderCell: ({ row }) => {
				return <Typography variant="subtitle1">{row["total_qty"]}</Typography>;
			},
		},
		...channels.map((channel, index) => ({
			field: channel.field,
			headerName: channel?.name,
			width: 120,
			...colStyle,
			// editable: true,
			type: "number",
			renderEditCell: (params) => <CustomEditableCell {...params} />,
			renderCell: (params) => {
				const rowValue = params.row[colorChannelBuyingObj]?.filter((row) => row.channel === channel.name);
				if (rowValue?.length) return <ChannelColumn channelData={rowValue[0]} field={channel.field} />;
				else
					return (
						<Typography
							variant="subtitle2"
							// color={data && data[0] && data[0]?.published ? "green" : "black"}
							minWidthwidth="20%"
							fontSize={14}>
							0
						</Typography>
					);
			},
		})),
		{ field: "status", headerName: "Status", width: 100, ...colStyle },
		{
			field: "action",
			headerName: "Action",
			attribute_name: "",
			width: 110,
			...colStyle,
			renderCell: (params) => {
				return (
					<Button disabled={handleApproved(params)} variant="contained">
						Approve
					</Button>
				);
			},
		},
		,
	];

	const ChannelColumn = ({ channelData, field }) => {
		return (
			<Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
				<Typography
					variant="subtitle2"
					// color={data && data[0] && data[0]?.published ? "green" : "black"}
					minWidth="40%"
					fontSize={14}>
					{channelData ? channelData?.total_qty : 0}
				</Typography>
				<Box
					sx={{
						borderLeft: "1px solid gray",
						height: "25px",
						margin: "0px 5px",
					}}></Box>
				<Typography
					sx={{ cursor: "pointer" }}
					variant="body1"
					width="50%"
					fontSize={14}
					onClick={() => handleChannelSizeBtnClick(channelData)}>
					<Chip label="Sizes" size="small" />
				</Typography>
			</Box>
		);
	};

	const handleChannelSizeBtnClick = (channelData) => {
		setEditModal(true);
		console.log("data....", channelData);
		// setFilteredSize(selectedData);
		setSelectedChannelBuy(channelData);
		// handleCheckSizes(selectedData);
	};
	const handleCloseChannelBuyClose = (data, field) => {
		setEditModal(false);
		setSelectedChannelBuy();

		// handleCheckSizes(selectedData);
	};

	// const handleSaveSize = (isPublished) => {
	// 	let mainAllData = [];
	// 	totalBuying.forEach((val, index) => {
	// 		if (channel.id === val.id) {
	// 			let arrayData = [];
	// 			val[colorChannelBuyingObj].forEach((vals) => {
	// 				let total = 0;
	// 				if (filteredSize[0].channel === vals.channel) {
	// 					filteredSize[0][sizeLevelBuyingObj].forEach((values) => {
	// 						total += values.total_qty;
	// 					});
	// 					arrayData.push({ ...filteredSize[0], total_qty: total, published: isPublished });
	// 				} else {
	// 					arrayData.push(vals);
	// 				}
	// 			});
	// 			mainAllData.push({ ...val, [colorChannelBuyingObj]: [...arrayData] });
	// 		} else {
	// 			mainAllData.push({ ...val });
	// 		}
	// 	});
	// 	setTotalBuying(mainAllData);
	// 	handle_total_qty_save(mainAllData);
	// 	handleResetSatate();
	// };

	const processRowUpdate = (newRow, original) => {
		let channelss = channels.map((val) => val.field);
		for (let key in newRow) {
			if (newRow[key] !== null && channelss.includes(key.channel)) {
				if (key === "ebo") {
					original = { ...original[colorChannelBuyingObj][0], total_qty: newRow[key] };
				}
			}
		}
		return original;
	};

	const handleResetSatate = () => {
		// setselectedSize(defaultSize);
		setEditModal(false);
		setRemainingSizes([]);
		setRemainingQty(0);
		setRemainingPercentage(0);
	};

	const handlePublish = () => {
		setFilteredSize([{ ...filteredSize[0], published: true }]);
		// handleSaveSize(true);
	};

	const handleApproved = ({ row }) => {
		let isPublished = 0;
		const channelBuy = row[colorChannelBuyingObj];
		channelBuy?.forEach((val) => {
			if (val.is_published) {
				isPublished += 1;
			}
		});
		return isPublished === 4 ? false : true;
	};

	if (remainingPercentage === 0 && (remainingQty > 0 || remainingQty < 0)) {
		let remaining_qty = remainingQty;
		let remaningData = [];
		let negative = remainingQty > 0 ? false : true;
		if (filteredSize.length)
			filteredSize[0][sizeLevelBuyingObj].forEach((val) => {
				if (remaining_qty !== 0) {
					if (negative) {
						remaningData.push({ ...val, total_qty: val.total_qty - 1 });
						remaining_qty += 1;
					} else {
						remaningData.push({ ...val, total_qty: val.total_qty + 1 });
						remaining_qty -= 1;
					}
				} else {
					remaningData.push({ ...val });
				}
			});
		// setFilterData([{ ...filteredSize[0], [sizeLevelBuyingObj]: remaningData }]);
		setRemainingQty(remaining_qty);
	}

	return (
		<Stack width="100%" spacing={1} pt={1}>
			<StyleFilter filter={filter} setFilter={setFilter} submitFunction={filterStyleFunction} />

			<SearchBar placeholder="Search Style" submitFunction={handleSearch} clearFunction={clearSearch} displayClearBtn={searchActive} />
			<Box display="flex" pt={2}>
				<Typography variant="h6">Images</Typography>
				<Switch checked={displayImg} onChange={(e) => handleImageSwitchChange(e.target.checked)} />
			</Box>
			<Stack width="100%" height={400} pt={2}>
				<DataGrid
					rows={totalBuying}
					columns={tablecolumns}
					key={displayImg}
					sx={{
						minHeight: 350,
						maxHeight: 430,
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							// cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							background: grey[300],
							mb: 2,
							border: "none",
							borderRadius: 1,
							// padding: "10px 0px",
						},
					}}
					columnHeaderHeight={35}
					rowHeight={displayImg ? 70 : 50} //   processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={(error) => console.log(error)}
					// showCellVerticalBorder
					// onRowClick={(params, e) => handleStyleSelect(params.row)}
					onRowClick={(params, e) => setChannel(params.row)}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
						columns: {
							columnVisibilityModel: {
								image: displayImg,
								color: !displayImg,
								pallete: !displayImg,
								style_name: !displayImg,
							},
						},
					}}
					// loading={loading}
					disableRowSelectionOnClick
					pageSizeOptions={[10, 50]}
				/>
			</Stack>
			<SizeBuyingModal
				key={selectedChannelBuy}
				selectedChannelBuy={selectedChannelBuy}
				modalOpen={editModal}
				handleCloseModal={handleCloseChannelBuyClose}
				channels={channels}
			/>
		</Stack>
	);
};

export default BuyingPage;
