import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SearchBar from "components/ReusableComponents/SearchBar";
import { buyingColorAssocObj, colorStyleAssocObj, colorImgAssociationObjName } from "data/pimData";
import { grey } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import { PimState } from "context/PimContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CloudUpload, UploadRounded } from "@mui/icons-material";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import styled from "@emotion/styled";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const BuyingBox = ({ data }) => {
	let imgWidth = 50;
	let imgHeight = 60;
	let selectedColor = data[buyingColorAssocObj];
	let colorStyle = selectedColor ? selectedColor[colorStyleAssocObj] : {};
	let imageUrl = selectedColor
		? selectedColor[colorImgAssociationObjName]
			? selectedColor[colorImgAssociationObjName][0].url
			: ""
		: "";

	return (
		<Box width="100%" display="flex" alignItems="center">
			<img
				style={{
					width: `${imgWidth}px`,
					height: `${imgHeight}px`,
					objectFit: "cover",
					objectPosition: "50% 0%",
					borderRadius: 2,
				}}
				src={imageUrl}
			/>
			<Stack pl={1} justifyContent="space-between" rowGap={1}>
				<Typography variant="body1" width="100%" fontSize={18} fontWeight={600}>
					{colorStyle?.style_name}
				</Typography>
				<Box display="flex" columnGap={1} alignItems="center">
					<Chip label={selectedColor?.color} size="small" />
					<Typography variant="body1" fontSize={14}>
						{selectedColor?.pallete}
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};
const RenderStyleColumns = ({ data, column }) => {
	let selectedColor = data[buyingColorAssocObj];
	let colorStyle = selectedColor ? selectedColor[colorStyleAssocObj] : {};

	return <Typography variant="subtitle2">{colorStyle[column]}</Typography>;
};
const RenderColorColumns = ({ data, column }) => {
	let selectedColor = data[buyingColorAssocObj];

	return <Typography variant="subtitle2">{selectedColor[column]}</Typography>;
};

const categoryData = [{ name: "SHIRT" }, { name: "T-SHIRT" }];
const subCategoryData = [{ name: "SOLID" }, { name: "STRIPE" }];
const defaultFilterData = { category: "select", subCategory: "select" };
const TrimsDashboard = () => {
	const {
		attributes,
		attributeValues,
		products,
		setProducts,
		setSelectedProduct,
		// coloredProduct,
		setcoloredProduct,
	} = PimState();
	const { setLoadingOpen } = GlobalState();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [totalBuying, setTotalBuying] = useState([]);
	const navigate = useNavigate();
	const [filterData, setFilterData] = useState(defaultFilterData);

	const [searchItem, setSearchItem] = useState("");
	const [displayImg, setDisplayImg] = useState(false);
	const [uploadModal, setUploadModal] = useState(false);
	const [fileUpload, setFileUpload] = useState(false);
	const [fileToUpload, setFileToUpload] = useState();

	const closeUploadModal = () => {
		setUploadModal(false);
	};

	const fetch = async (filter, limit) => {
		setLoadingOpen(true);
		try {
			await axios.post("/api/pim/get_total_buying", { filter, limit }).then((res) => {
				setTotalBuying(res.data);
				// setTotalBuying(colorProductDummyData);
			});
			setLoadingOpen(false);
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Server Error `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const handleClearFilterClick = () => {
		setFilterData(defaultFilterData);
	};

	const handleImageSwitchChange = (checked) => {
		setDisplayImg(checked);
	};

	const handleFileUpload = async (files) => {
		let form = new FormData();
		form.append("file", files);
		setFileUpload(true);
		// await axios
		// 	.post("/api/data_hub/RR/upload-files", form, {
		// 		headers: { "Content-Type": "multipart/form-data" },
		// 	})
		// 	.then((res) => {
		// 		console.log(res.data);
		// 		let { name, publicUrl } = res.data;
		// 		if (name && publicUrl) {
		// 			setFileUpload(false);
		// 		}
		// 		setSnackBarOpen(true);
		// 		setSnackBar({
		// 			...snackBar,
		// 			message: "File uploaded successfully",
		// 			severity: "success",
		// 			anchorOrigin: { horizontal: "center", vertical: "top" },
		// 		});
		// 	})
		// 	.catch((err) => {
		// 		setSnackBarOpen(true);
		// 		setFileUpload(false);
		// 		setSnackBar({
		// 			...snackBar,
		// 			message: err.message,
		// 			severity: "error",
		// 			anchorOrigin: { horizontal: "center", vertical: "top" },
		// 		});
		// 		return "Error in file Upload";
		// 	});
	};

	// helper function to handle file uploads
	async function handleFileChange(e) {
		const newFile = e.target.files[0];
		const modifiedFile = new File([newFile], newFile.name, { type: newFile.type });
		console.log(modifiedFile);
		// setFiles(modifiedFile);
		await handleFileUpload(modifiedFile);
	}
	const tablecolumns = [
		{
			field: "image",
			headerName: "Product",
			width: 220,
			...colStyle,
			renderCell: (params) => {
				return <BuyingBox data={params.row} />;
			},
		},
		{
			field: "color",
			headerName: "COLOR",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderColorColumns column="color" data={row} />;
			},
		},
		{
			field: "pallete",
			headerName: "PALLETE",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderColorColumns column="pallete" data={row} />;
			},
		},
		{
			field: "style_name",
			headerName: "STYLE",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return <RenderStyleColumns column="style_name" data={row} />;
			},
		},
		{
			field: "designer_mrp",
			headerName: "MRP(Designer)",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return <Typography variant="subtitle1">{row["total_qty"]}</Typography>;
			},
		},
		{
			field: "cogs",
			headerName: "COGS(Approx)",
			width: 140,
			...colStyle,
			editable: true,
			renderCell: ({ row }) => {
				return <Typography variant="subtitle1">{499}</Typography>;
			},
		},
		{
			field: "file",
			headerName: "File",
			attribute_name: "",
			width: 140,
			...colStyle,
			renderCell: ({ row }) => {
				return (
					<Button
						component="label"
						size="small"
						role={undefined}
						variant="outlined"
						tabIndex={-1}
						startIcon={<CloudUpload />}>
						Upload
						<VisuallyHiddenInput
							type="file"
							onClick={(e) => (e.target.value = null)}
							onChange={handleFileChange}
						/>
					</Button>
				);
			},
		},
		{
			field: "stage",
			headerName: "Action",
			attribute_name: "",
			width: 120,
			...colStyle,
			renderCell: ({ row }) => {
				return (
					<Button
						variant="contained"
						size="small"
						startIcon={<UploadRounded />}
						onClick={() => setUploadModal(true)}>
						Publish
					</Button>
				);
			},
		},
		,
	];

	return (
		<Stack width="100%" spacing={1} pt={1}>
			<Box display="flex" justifyContent="space-between">
				<Box display="flex" width="60%" columnGap={2} alignItems="center" p={1} borderRadius={1}>
					<Select
						size="small"
						sx={{ width: 130 }}
						value={filterData?.category}
						onChange={(e) => setFilterData({ ...filterData, col: e.target.value })}>
						<MenuItem value={"select"}>Category</MenuItem>
						{categoryData.map((row) => {
							return (
								<MenuItem key={row.name} value={row.name}>
									{row.name}
								</MenuItem>
							);
						})}
					</Select>
					<Select
						size="small"
						sx={{ width: 150 }}
						value={filterData?.subCategory}
						onChange={(e) => setFilterData({ ...filterData, subCategory: e.target.value })}>
						<MenuItem value={"select"}>Sub-Category</MenuItem>
						{subCategoryData?.map((row) => {
							return (
								<MenuItem key={row.name} value={row.name}>
									{row.name}
								</MenuItem>
							);
						})}
					</Select>
					<Select
						size="small"
						sx={{ width: 150 }}
						value={filterData?.subCategory}
						onChange={(e) => setFilterData({ ...filterData, subCategory: e.target.value })}>
						<MenuItem value={"select"}>Seasons</MenuItem>
						{subCategoryData?.map((row) => {
							return (
								<MenuItem key={row.name} value={row.name}>
									{row.name}
								</MenuItem>
							);
						})}
					</Select>
					<Button variant="contained" onClick={handleClearFilterClick}>
						Clear
					</Button>
				</Box>
			</Box>
			<SearchBar value={searchItem} setValue={(value) => setSearchItem(value)} />
			<Box display="flex" pt={2}>
				<Typography variant="h6">Images</Typography>
				<Switch checked={displayImg} onChange={(e) => handleImageSwitchChange(e.target.checked)} />
			</Box>
			<Stack width="100%" height={400} pt={2}>
				<DataGrid
					rows={totalBuying}
					columns={tablecolumns}
					key={displayImg}
					sx={{
						minHeight: 350,
						maxHeight: 430,
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							// cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							background: grey[300],
							mb: 2,
							border: "none",
							borderRadius: 1,
							// padding: "10px 0px",
						},
					}}
					columnHeaderHeight={35}
					rowHeight={displayImg ? 70 : 50}
					//   processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={(error) => console.log(error)}
					// showCellVerticalBorder
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
						columns: {
							columnVisibilityModel: {
								image: displayImg,
								color: !displayImg,
								pallete: !displayImg,
								style_name: !displayImg,
							},
						},
					}}
					// loading={loading}
					disableRowSelectionOnClick
					pageSizeOptions={[10, 50]}
				/>
			</Stack>

			<Dialog open={uploadModal} onClose={closeUploadModal} fullWidth maxWidth="sm">
				<DialogTitle>{"Upload File"}</DialogTitle>
				<DialogContent></DialogContent>
				<DialogActions>
					<Button onClick={closeUploadModal}>Cancel</Button>
					<Button autoFocus variant="contained">
						Upload
					</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};

export default TrimsDashboard;
