import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Divider, Tooltip } from "@mui/material";
import { GlobalState } from "context/GlobalContext";
import AddBoxIcon from "@mui/icons-material/AddBox";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import Modal from "@mui/material/Modal";
import AddDistributer from "./AddDistributer";
import moment from "moment";

const BRAND_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "RARE RABBIT",
		value: "RARE RABBIT",
	},
	{
		label: "RAREISM",
		value: "RAREISM",
	},
];

const SUB_BRAND_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "ARTICALE",
		value: "ARTICALE",
	},
	{
		label: "GUILD",
		value: "GUILD",
	},
];

const STATUS_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "ACTIVE",
		value: "ACTIVE",
	},
	{
		label: "YTO",
		value: "YTO",
	},
	{
		label: "INACTIVE",
		value: "INACTIVE",
	},
];

const MODE_OF_OPERATION_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "OUTRIGHT",
		value: "OUTRIGHT",
	},
	{
		label: "SOR",
		value: "SOR",
	},
	{
		label: "N/A",
		value: "N/A",
	},
];

const SECONDARY_STOCK_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "YES",
		value: "YES",
	},
	{
		label: "NO",
		value: "NO",
	},
];

const TRADE_GROUP_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "INTER STATE",
		value: "INTER STATE",
	},
	{
		label: "LOCAL",
		value: "LOCAL",
	},
];

const SALES_TERM_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "GST: CGST + SGST",
		value: "GST: CGST + SGST",
	},
	{
		label: "GST: IGST",
		value: "GST: IGST",
	},
	{
		label: "INTERSTATE SALES",
		value: "INTERSTATE SALES",
	},
	{
		label: "LOCAL SALES",
		value: "LOCAL SALES",
	},
];

const CHANNEL_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "MBO",
		value: "MBO",
	},
	{
		label: "LFS",
		value: "LFS",
	},
];

const REGION_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "NORTH",
		value: "NORTH",
	},
	{
		label: "SOUTH",
		value: "SOUTH",
	},
	{
		label: "EAST",
		value: "EAST",
	},
	{
		label: "WEST",
		value: "WEST",
	},
	{
		label: "CENTRAL",
		value: "CENTRAL",
	},
];

const WH_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "MBO-BLR",
		value: "MBO-BLR",
	},
	{
		label: "MBO-DELHI",
		value: "MBO-DELHI",
	},
];

const GRADE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "A",
		value: "A",
	},
	{
		label: "A+",
		value: "A+",
	},
	{
		label: "B",
		value: "B",
	},
	{
		label: "B+",
		value: "B+",
	},
	{
		label: "FO",
		value: "FO",
	},
];

const DISTRIBUTER_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "SHIVERA - VADODARA",
		value: "SHIVERA - VADODARA",
	},
	{
		label: "NEXUS CLOTHIN LLP",
		value: "NEXUS CLOTHIN LLP",
	},
	{
		label: "ABACUS INDIA-MBO",
		value: "ABACUS INDIA-MBO",
	},
	{
		label: "DYNEMIC FOOTWEARS",
		value: "DYNEMIC FOOTWEARS",
	},
	{
		label: "BASIC CLOTHING",
		value: "BASIC CLOTHING",
	},
	{
		label: "ACTIVE CLOTHING",
		value: "ACTIVE CLOTHING",
	},
	{
		label: "GRC GARMENTS",
		value: "GRC GARMENTS",
	},
	{
		label: "JAI LAXMI GARMENTS",
		value: "JAI LAXMI GARMENTS",
	},
	{
		label: "V C MARKETING",
		value: "V C MARKETING",
	},
	{
		label: "Vimal Enterprises",
		value: "Vimal Enterprises",
	},
	{
		label: "SAGAR MARKETING",
		value: "SAGAR MARKETING",
	},
	{
		label: "Adonis Group",
		value: "Adonis Group",
	},
	{
		label: "VIJOSH DISTRIBUTORS",
		value: "VIJOSH DISTRIBUTORS",
	},
	{
		label: "Infinite Ventures",
		value: "Infinite Ventures",
	},
	{
		label: "KOBLERR ENTERPRISES",
		value: "KOBLERR ENTERPRISES",
	},
];

const STORE_CATEGORY_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "APPARELS",
		value: "APPARELS",
	},
	{
		label: "ADDONS",
		value: "ADDONS",
	},
	{
		label: "FOOT WEAR",
		value: "FOOT WEAR",
	},
];

function MboStoreForm({ action, value, mboStores, setMboStores }) {
	const initialState = {
		code: "",
		brand: "SELECT",
		sub_brand: "SELECT",
		status: "SELECT",
		remarks: "",
		site_name: "",
		short_name: "",
		store_category: "SELECT",
		carpet_area: "",
		customer: "",
		address: "",
		city: "",
		state: "",
		pincode: "",
		location: "",
		mode_of_operation: "SELECT",
		secondary_stock: "SELECT",
		trade_group: "SELECT",
		sales_term: "SELECT",
		form_name: "",
		final_asm: "",
		grade: "SELECT",
		distrbuters: "SELECT",
		channel: "SELECT",
		region: "SELECT",
		wh: "SELECT",
		open_date: new Date().toJSON().slice(0, 10),
		base_stock: "",
		standard_mdq: "",
	};

	const [mboFormData, setMboFormData] = useState(action === "UPDATE" ? value : initialState);
	const [distributer, setDistributer] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [open, setOpen] = useState(false);

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	async function fetchDistributer() {
		await axios
			.get("/api/store/get-distributer")
			.then((res) => {
				if (res.status === 200 && res.data) {
					setDistributer(res.data);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	}

	useEffect(() => {
		console.log("value", value);
		value = { ...value, open_date: moment(value.open_date).format("YYYY-MM-DD") };
		fetchDistributer();
	}, []);

	const handleBlur = async (pincode, field) => {
		await axios
			.get(`/api/store/get-state/${mboFormData[pincode]}`)
			.then((res) => {
				const data = res.data;
				if (data[0].state && data[0].state.length > 0) {
					let obj = mboFormData;
					obj[field] = data[0].state;
					setMboFormData({ ...obj });
				} else {
					// Handle case where postal code is not found
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Postal code not found",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			})
			.catch((error) => {
				// Handle error
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Error fetching pincode data",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(mboFormData);
		if (action === "CREATE") {
			await axios
				.post("/api/store/mbo-store-create", mboFormData)
				.then((res) => {
					if (res.status === 200) {
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message:
								mboFormData.channel === "MBO"
									? "MBO store created successfully"
									: "LFS store created successfully",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						console.log("response.data", res.data.result);
						setMboStores([res.data.result, ...mboStores]);
						setMboFormData(initialState);
					}
				})
				.catch((err) => {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: err.message,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					console.log(err);
				});
		} else {
			await axios
				.put(`/api/store/mbo-store-update/${value.id}`, mboFormData)
				.then((res) => {
					if (res.status === 200) {
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message:
								mboFormData.channel === "MBO"
									? "MBO store updated successfully"
									: "LFS store updated successfully",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						setMboStores([...mboStores.map((x) => (x.id === value.id ? mboFormData : x))]);
					}
				})
				.catch((err) => {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Something went wrong please check data and try again",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				});
		}
	};

	return (
		<Box component="form" onSubmit={handleSubmit}>
			<Box direction="row" width="100%" justifyContent="space-around" flexWrap="wrap">
				<Stack maxWidth="1100px" spacing={0} margin="auto" direction={"row"} justifyContent={"space-between"}>
					<Box position={""} sx={{ zIndex: 999 }}>
						<Typography variant="h4" pt={0} width={"100%"}>
							{action === "UPDATE" ? "MBO - LFS Store Update" : "MBO - LFS Store Form"}
						</Typography>
						<Divider sx={{ width: "100%" }}></Divider>
					</Box>
				</Stack>
				<Box
					display="flex"
					flexWrap="wrap"
					pt={4}
					rowGap={3}
					className="vendorInputForm"
					columnGap={6}
					zIndex={-1}
					justifyContent="left">
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Channel</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.channel}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									channel: e.target.value,
								})
							}
							size="small">
							{CHANNEL_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							Store Code <span>*</span>
						</InputLabel>
						<NumberTextField
							size="small"
							value={mboFormData.code}
							onChange={(e) => setMboFormData({ ...mboFormData, code: e.target.value })}
							placeholder="Enter store code"
						/>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							Brand <span>*</span>
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.brand}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									brand: e.target.value,
								})
							}
							size="small">
							{BRAND_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							Sub Brand <span>*</span>
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.sub_brand}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									sub_brand: e.target.value,
								})
							}
							size="small">
							{SUB_BRAND_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Store Category</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.store_category}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									store_category: e.target.value,
								})
							}
							size="small">
							{STORE_CATEGORY_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Carpet Area</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.carpet_area}
							onChange={(e) => setMboFormData({ ...mboFormData, carpet_area: e.target.value })}
							placeholder="Enter carpet area."
						/>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Status </InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.status}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									status: e.target.value,
								})
							}
							size="small">
							{STATUS_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Remarks</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.remarks}
							onChange={(e) => setMboFormData({ ...mboFormData, remarks: e.target.value })}
							placeholder="Enter remarks if any"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Site Name</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.site_name}
							onChange={(e) => setMboFormData({ ...mboFormData, site_name: e.target.value })}
							placeholder="Enter site name"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Short Name</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.short_name}
							onChange={(e) => setMboFormData({ ...mboFormData, short_name: e.target.value })}
							placeholder="Enter short name"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Customer</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.customer}
							onChange={(e) => setMboFormData({ ...mboFormData, customer: e.target.value })}
							placeholder="Enter customer"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>City</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.city}
							onChange={(e) => setMboFormData({ ...mboFormData, city: e.target.value })}
							placeholder="Enter city"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>State</InputLabel>
						<TextField
							disabled
							size="small"
							type="text"
							value={mboFormData.state}
							onChange={(e) => setMboFormData({ ...mboFormData, state: e.target.value })}
							placeholder="Enter state"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Pincode</InputLabel>
						<NumberTextField
							size="small"
							type="text"
							value={mboFormData.pincode}
							onChange={(e) => setMboFormData({ ...mboFormData, pincode: e.target.value })}
							onBlur={() => handleBlur("pincode", "state")}
							placeholder="Enter Pincode"
						/>
					</Stack>

					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Region</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.region}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									region: e.target.value,
								})
							}
							size="small">
							{REGION_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Grade</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.grade}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									grade: e.target.value,
								})
							}
							size="small">
							{GRADE_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={{ ...formStack, width: { md: "65%", sm: "100%" } }} spacing={0}>
						<InputLabel>Location URL</InputLabel>
						<TextField
							size="small"
							type="url"
							value={mboFormData.location}
							onChange={(e) => setMboFormData({ ...mboFormData, location: e.target.value })}
							placeholder="Enter store location url"
						/>
					</Stack>

					<Stack sx={{ ...formStack, width: "100%" }} spacing={0}>
						<InputLabel>Address</InputLabel>
						<TextField
							maxRows={3}
							type="text"
							multiline
							rows={3}
							value={mboFormData.address}
							onChange={(e) => setMboFormData({ ...mboFormData, address: e.target.value })}
							placeholder="Enter address"
						/>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Mode Of Operation</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.mode_of_operation}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									mode_of_operation: e.target.value,
								})
							}
							size="small">
							{MODE_OF_OPERATION_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Secondary Stock</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.secondary_stock}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									secondary_stock: e.target.value,
								})
							}
							size="small">
							{SECONDARY_STOCK_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Trade Group</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.trade_group}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									trade_group: e.target.value,
								})
							}
							size="small">
							{TRADE_GROUP_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							Sales Term <span>*</span>:
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.sales_term}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									sales_term: e.target.value,
								})
							}
							size="small">
							{SALES_TERM_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Form Name</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.form_name}
							onChange={(e) => setMboFormData({ ...mboFormData, form_name: e.target.value })}
							placeholder="Enter form name"
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Final ASM</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.final_asm}
							onChange={(e) => setMboFormData({ ...mboFormData, final_asm: e.target.value })}
							placeholder="Enter final asm name"
						/>
					</Stack>

					<Stack className="vendor-input" sx={{ width: { xs: "80%", sm: "40%", md: "30%" } }} spacing={0}>
						<InputLabel>Distributer</InputLabel>
						<Box display={"flex"} width={"100%"} columnGap={2}>
							<Select
								fullWidth
								disabled={mboFormData.channel === "SELECT"}
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								value={mboFormData.distributers}
								onChange={(e) =>
									setMboFormData({
										...mboFormData,
										distributers: e.target.value,
									})
								}
								size="small">
								{distributer
									.filter((option) => option.channel === mboFormData.channel)
									.map((option) => (
										<MenuItem key={option.distributer_name} value={option.distributer_name}>
											{option.distributer_name}
										</MenuItem>
									))}
							</Select>
							<Tooltip title="Add new distributer">
								<AddBoxIcon sx={{ pt: 1 }} color="action" onClick={() => setOpen(true)} />
							</Tooltip>
						</Box>
					</Stack>

					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>WH</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={mboFormData.wh}
							onChange={(e) =>
								setMboFormData({
									...mboFormData,
									wh: e.target.value,
								})
							}
							size="small">
							{WH_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Open Date</InputLabel>
						<TextField
							size="small"
							type="date"
							value={mboFormData.open_date}
							onChange={(e) => setMboFormData({ ...mboFormData, open_date: e.target.value })}
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Base Stock</InputLabel>
						<TextField
							size="small"
							type="text"
							value={mboFormData.base_stock}
							onChange={(e) => setMboFormData({ ...mboFormData, base_stock: e.target.value })}
							placeholder="Enter base stock"
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Standard MDQ</InputLabel>
						<NumberTextField
							size="small"
							type="text"
							value={mboFormData.standard_mdq}
							onChange={(e) => setMboFormData({ ...mboFormData, standard_mdq: e.target.value })}
							placeholder="Enter standard mdq"
						/>
					</Stack>
				</Box>
				<Stack alignItems={"center"} sx={{ my: 2 }}>
					<Button type="Submit" variant="contained" sx={{ width: "130px", height: "48px" }}>
						<Typography>{action === "UPDATE" ? "Update" : "Create"}</Typography>
					</Button>
				</Stack>
				<Modal
					open={open}
					onClose={() => setOpen(!open)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					sx={{ minHeight: "100%" }}
					disableEnforceFocus>
					<Stack
						sx={{
							width: 1040,
							bgcolor: "background.paper",
							p: 4,
							borderRadius: 2,
							maxHeight: 450,
							overflow: "auto",
							margin: "auto",
							my: 8,
						}}>
						<AddDistributer setDistributer={setDistributer} distributer={distributer} />
					</Stack>
				</Modal>
			</Box>
		</Box>
	);
}

export default MboStoreForm;
