import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Divider, Tooltip } from "@mui/material";
import { GlobalState } from "context/GlobalContext";
import AddIcon from "@mui/icons-material/Add";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import AddBoxIcon from "@mui/icons-material/AddBox";
import NumberTextField from "components/ReusableComponents/NumberTextField";
import { grey } from "@mui/material/colors";

function AddDistributer({ distributer, setDistributer }) {
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const CHANNEL_OPTIONS = [
		{
			label: "SELECT",
			value: "SELECT",
		},
		{
			label: "MBO",
			value: "MBO",
		},
		{
			label: "LFS",
			value: "LFS",
		},
	];

	const [distributerFormData, setDistributerFormData] = useState({ distributer_name: "", channel: "SELECT" });
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (distributerFormData.channel === "SELECT") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "please select a channel for distributer",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		} else if (distributerFormData.distributer_name === "") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "please enter a name for distributer",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		await axios
			.post("/api/store/add-distributer", distributerFormData)
			.then((res) => {
				if (res.status === 200) {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Distributer created successfully",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					console.log(distributer);
					setDistributer([...distributer, distributerFormData]);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	return (
		<Box>
			<Box direction="row" width="100%" justifyContent="space-around" flexWrap="wrap">
				<Stack maxWidth="1100px" spacing={0} margin="auto" direction={"row"} justifyContent={"center"}>
					<Box position={""} sx={{ zIndex: 999 }}>
						<Typography variant="h4" pt={0} width={"100%"}>
							Add Distributer
						</Typography>
						<Divider sx={{ width: "100%" }}></Divider>
					</Box>
				</Stack>
				<Box
					display="flex"
					flexWrap="wrap"
					pt={4}
					rowGap={3}
					className="vendorInputForm"
					columnGap={6}
					zIndex={-1}
					justifyContent="center">
					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							Distributer name <span>*</span>
						</InputLabel>
						<TextField
							required
							size="small"
							value={distributerFormData.distributer_name}
							onChange={(e) =>
								setDistributerFormData({ ...distributerFormData, distributer_name: e.target.value })
							}
							placeholder="Enter distributer name"
						/>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>Channel</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={distributerFormData.channel}
							onChange={(e) =>
								setDistributerFormData({
									...distributerFormData,
									channel: e.target.value,
								})
							}
							size="small">
							{CHANNEL_OPTIONS.map((option) => (
								<MenuItem key={option.label} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Stack>
				</Box>
				<Stack alignItems={"center"} sx={{ my: 2 }}>
					<Button
						type="Submit"
						variant="contained"
						sx={{ width: "130px", height: "48px" }}
						onClick={handleSubmit}>
						<Typography>Create</Typography>
					</Button>
				</Stack>
			</Box>
		</Box>
	);
}

export default AddDistributer;
