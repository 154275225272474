import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

function DisplayDataGrid({ columns, rows, columnWidth }) {
	columns = columns.map((col, index) => {
		return { id: index + 1, field: col, headerName: col };
	});

	rows = rows.map((row, index) => {
		return { ...row, id: index + 1 };
	});

	columns = columns.map((col) => {
		return { ...col, headerClassName: "super-app-theme--header", width: columnWidth ? columnWidth : 130 };
	});

	return (
		<Box sx={{ height: 400, width: "100%" }}>
			<DataGrid
				sx={{
					"& .super-app-theme--header": {
						textTransform: "uppercase",
					},
				}}
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 50,
						},
					},
				}}
				pageSizeOptions={[50, 100]}
				checkboxSelection={false}
				disableRowSelectionOnClick
			/>
		</Box>
	);
}

export default DisplayDataGrid;
