import { DeleteRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import ContactPersonDetails from "components/SupplierPage/SupplierForm/ContactPersonDetails/ContactPersonDetails";
import { sizeTypes } from "data/pimData";
import { EditCompanyDetailsModalTypes, contactPersonData, labourTableColumns } from "data/supplierPageData";
import { useState } from "react";

const formStack = {
	width: { xs: "80%", sm: "40%", md: "19%" },
};
const BarcodeModal = ({ modalOpen, setModalOpen, selected, setSelected, submitFunction }) => {
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const [updatedDetails, setUpdatedDetails] = useState(selected ? selected : contactPersonData);

	const handleCloseModal = () => {
		setUpdatedDetails(contactPersonData);
		setModalOpen(false);
		setSelected();
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		if (typeof submitFunction === "function") {
			let result = await submitFunction(updatedDetails);
			if (result) {
				setSelected();
				setModalOpen(false);
			}
		}
	};
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Box width="95%" margin="auto" pt={2} display="flex" flexWrap="wrap" columnGap={2}>
				<Typography width="100%" variant="h6">
					{selected ? "Edit Barcode Data" : "Add Barcode"}{" "}
				</Typography>
				<Stack sx={formStack} spacing={0}>
					<InputLabel>Barcode:</InputLabel>
					<TextField
						size="small"
						type="text"
						value={updatedDetails.barcode}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, barcode: e.target.value })}
						placeholder="Enter Barcode"
						required
					/>
				</Stack>
				<Stack sx={formStack} spacing={0}>
					<InputLabel>Size:</InputLabel>
					<Select
						value={updatedDetails.size}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, size: e.target.value })}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						size="small"
						required>
						<MenuItem value="select">select</MenuItem>
						{Object.keys(sizeTypes)?.map((row) => {
							let size = sizeTypes[row];
							return (
								<MenuItem key={size.key} value={size.name}>
									{size.name}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
			</Box>

			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export const BarcodeModalWithCost = ({ modalOpen, setModalOpen, selected, setSelected, submitFunction }) => {
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const [updatedDetails, setUpdatedDetails] = useState(selected ? selected : contactPersonData);
	const [no, setNo] = useState([1, 2, 3]);

	const handleCloseModal = () => {
		setUpdatedDetails(contactPersonData);
		setModalOpen(false);
		setSelected();
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		if (typeof submitFunction === "function") {
			let result = await submitFunction(updatedDetails);
			if (result) {
				setSelected();
				setModalOpen(false);
			}
		}
	};
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Box width="95%" margin="auto" pt={2} display="flex" flexWrap="wrap" columnGap={2}>
				<Typography width="100%" variant="h5">
					{selected ? "Edit Barcode Data" : "Add Cost"}{" "}
				</Typography>
				<Stack sx={formStack} spacing={0}>
					<InputLabel>Enter Cost:</InputLabel>
					<TextField
						size="small"
						type="text"
						value={updatedDetails.cost}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, cost: e.target.value })}
						placeholder="Enter Barcode"
						required
					/>
				</Stack>

				<Typography width="100%" variant="h6" pt={2}>
					{"Add Barcodes"}
				</Typography>
				{no.map((row) => {
					return (
						<Box width="100%" display="flex" columnGap={2} alignItems="center">
							<Stack sx={formStack} spacing={0}>
								<InputLabel>Barcode:</InputLabel>
								<TextField
									size="small"
									type="text"
									value={updatedDetails.barcode}
									onChange={(e) => setUpdatedDetails({ ...updatedDetails, barcode: e.target.value })}
									placeholder="Enter Barcode"
									required
								/>
							</Stack>
							<Stack sx={formStack} spacing={0}>
								<InputLabel>Size:</InputLabel>
								<Select
									value={updatedDetails.size}
									onChange={(e) => setUpdatedDetails({ ...updatedDetails, size: e.target.value })}
									MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
									size="small"
									required>
									<MenuItem value="select">select</MenuItem>
									{Object.keys(sizeTypes)?.map((row) => {
										let size = sizeTypes[row];
										return (
											<MenuItem key={size.key} value={size.name}>
												{size.name}
											</MenuItem>
										);
									})}
								</Select>
							</Stack>
							<Box alignSelf="center">
								<IconButton>
									<DeleteRounded />
								</IconButton>
							</Box>
						</Box>
					);
				})}
				<Button variant="contained" sx={{ marginTop: 2 }} onClick={() => setNo([...no, no.length])}>
					Add Barcode
				</Button>
			</Box>

			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default BarcodeModal;
