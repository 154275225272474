import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import {
	EditCompanyDetailsModalTypes,
	companyBasicDetailColumns,
	divisions,
	fileTypesDataObj,
	industryTypeValues,
	labourTableColumns,
	msmeIndustryColumns,
	productSections,
	productTypeValues,
} from "data/supplierPageData";
import { useEffect, useState } from "react";
import LabourDataTable from "../LabourDataTable/LabourDataTable";
import moment from "moment";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import NumberTextField from "components/ReusableComponents/NumberTextField";

const formStack = {
	width: { xs: "80%", sm: "40%", md: "30%" },
};
const formStacklg = {
	width: { xs: "80%", sm: "40%", md: "45%" },
};
const EditCompanyDetails = ({
	type,
	basicDetails,
	setBasicDetails,
	modalOpen,
	setModalOpen,
	isOnboarded,
	submitFunction,
}) => {
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();

	const [updatedDetails, setUpdatedDetails] = useState(basicDetails);
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState([
		{
			name: fileTypesDataObj.INDUSTRY_CERT.name,
			type: fileTypesDataObj.INDUSTRY_CERT.Key,
		},
		{
			name: fileTypesDataObj.GST_CERT.name,
			type: fileTypesDataObj.GST_CERT.Key,
		},
		{
			name: fileTypesDataObj.PANCARD.name,
			type: fileTypesDataObj.PANCARD.Key,
		},
		{
			name: fileTypesDataObj.BLANK_CHEQUE_PHOTO.name,
			type: fileTypesDataObj.BLANK_CHEQUE_PHOTO.Key,
		},
	]);
	const handleFileChange = (file, fileTye) => {
		setFiles(
			files.map((row) => {
				if (row.type === fileTye) {
					return { ...row, file };
				} else return row;
			})
		);
	};
	const handleCloseModal = () => {
		setUpdatedDetails(basicDetails);
		isOnboarded && setModalOpen(false);
	};

	const getSupplierCategories = async (sections, divisions) => {
		try {
			const { data } = await axios.get(
				`/api/supplier/get_categories_by_division_section?${sections ? "sections=" + sections : ""}${
					sections && divisions ? "&" : ""
				}${divisions ? "divisions=" + divisions : ""}`
			);
			setCategories(
				data
					? data?.map((row) => {
							return { name: row.DEPARTMENT };
					  })
					: []
			);
			console.log("data", data);
		} catch (error) {}
	};
	useEffect(() => {
		const fetch = async () => {
			if (
				updatedDetails &&
				((updatedDetails[companyBasicDetailColumns.product_division.key] &&
					updatedDetails[companyBasicDetailColumns.product_division.key] !== "") ||
					(updatedDetails[companyBasicDetailColumns.product_section.key] &&
						updatedDetails[companyBasicDetailColumns.product_section.key] !== ""))
			) {
				await getSupplierCategories(
					updatedDetails[companyBasicDetailColumns.product_section.key],
					updatedDetails[companyBasicDetailColumns.product_division.key]
				);
			}
		};
		fetch();
	}, [updatedDetails]);

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		let obj = {};
		setLoadingOpen(true);
		Object.keys(companyBasicDetailColumns).forEach((col) => (obj[col] = updatedDetails[col]));
		labourTableColumns.forEach((col) => (obj[col] = updatedDetails[col]));
		console.log(obj);

		// clear Industry cert for MSME Industry type
		let filesTosend = files?.filter((row) => {
			if (row.file) return true;
			else return false;
		});
		if (!isOnboarded) obj["is_onboarded"] = true;
		if (typeof submitFunction === "function") await submitFunction({ ...obj, files: filesTosend }, setModalOpen);
		setLoadingOpen(false);
	};
	return (
		<Dialog
			fullWidth
			maxWidth="lg"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Box width="98%" p={2} boxSizing="border-box">
				<Typography textAlign="center" variant="h6" p={2} width="100%">
					{isOnboarded ? "Company Info" : "Welcome!! Please Fill The Details "}
				</Typography>
				<Stack direction="row" width="100%" columnGap="3%" flexWrap="wrap" rowGap={1}>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Company Name:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={updatedDetails?.company_name}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, company_name: e.target.value })}
							placeholder="Enter company name"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Director/Owner Name:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={updatedDetails?.name_of_owner}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, name_of_owner: e.target.value })}
							placeholder="Enter director/owner name"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel htmlFor="companyType">
							Company Type<span>*</span>:
						</InputLabel>
						<Select
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							value={
								updatedDetails && updatedDetails.company_type ? updatedDetails.company_type : "select"
							}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, company_type: e.target.value })}
							size="small"
							required>
							<MenuItem value="select">Select</MenuItem>
							<MenuItem value="LTD">LTD</MenuItem>
							<MenuItem value="PVT LTD">PVT LTD</MenuItem>
							<MenuItem value="LLP">LLP</MenuItem>
							<MenuItem value="PARTNERSHIP">PARTNERSHIP</MenuItem>
							<MenuItem value="PROPRIETOR">PROPRIETOR</MenuItem>
						</Select>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Company Establishment Date</InputLabel>
						<TextField
							size="small"
							type="date"
							value={moment(updatedDetails?.company_establishment_date).format("YYYY-MM-DD")}
							onChange={(e) =>
								setUpdatedDetails({ ...updatedDetails, company_establishment_date: e.target.value })
							}
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Effective Date with The House of Rare </InputLabel>
						<TextField
							size="small"
							type="date"
							value={moment(updatedDetails?.effective_with_thor_date).format("YYYY-MM-DD")}
							onChange={(e) =>
								setUpdatedDetails({ ...updatedDetails, effective_with_thor_date: e.target.value })
							}
							required
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>Office GST Number:</InputLabel>
						<TextField
							type="text"
							size="small"
							value={updatedDetails.gst_no}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, gst_no: e.target.value })}
							placeholder="Enter GST Number"
							required
						/>
					</Stack>

					{!isOnboarded && (
						<Stack sx={formStack} spacing={0}>
							<InputLabel htmlFor="pan_img">Upload Pancard</InputLabel>
							<TextField
								size="small"
								type="file"
								id="pan_img"
								accept=".pdf, .jpeg, .jpg, .png"
								required
								onChange={(event) =>
									handleFileChange(event.target.files[0], fileTypesDataObj.PANCARD.Key)
								}
							/>
						</Stack>
					)}
					{!isOnboarded && (
						<Stack sx={formStack} spacing={0}>
							<InputLabel htmlFor="office_gst_cert">Office GST Certificate</InputLabel>
							<TextField
								size="small"
								type="file"
								id="office_gst_cert"
								accept=".pdf, .jpeg, .jpg, .png"
								required
								onChange={(event) =>
									handleFileChange(event.target.files[0], fileTypesDataObj.GST_CERT.Key)
								}
							/>
						</Stack>
					)}
					{!isOnboarded && (
						<Stack sx={formStack} spacing={0}>
							<InputLabel htmlFor="BLANK_CHEQUE_PHOTO">Blank Cheque Photo</InputLabel>
							<TextField
								size="small"
								type="file"
								id="BLANK_CHEQUE_PHOTO"
								accept=".pdf, .jpeg, .jpg, .png"
								required
								onChange={(event) =>
									handleFileChange(event.target.files[0], fileTypesDataObj.BLANK_CHEQUE_PHOTO.Key)
								}
							/>
						</Stack>
					)}
					<Stack sx={formStack} spacing={0}>
						<InputLabel>Website Link </InputLabel>
						<TextField
							size="small"
							type="text"
							value={updatedDetails.website_link}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, website_link: e.target.value })}
							placeholder="Enter Address"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>{"Location Link (Google Maps)"} </InputLabel>
						<TextField
							size="small"
							type="text"
							value={updatedDetails.gmap_location_link}
							onChange={(e) =>
								setUpdatedDetails({ ...updatedDetails, gmap_location_link: e.target.value })
							}
							placeholder="Enter Link"
							required
						/>
					</Stack>

					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							Monthly Capacity <span>*</span>:
						</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={updatedDetails?.monthly_capacity}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, monthly_capacity: e.target.value })}
							placeholder="Enter Monthly Capacity"
							required
							variant="outlined"
						/>
					</Stack>
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							Capacity for The House Of Rare {"(Monthly)"} <span>*</span>:
						</InputLabel>
						<NumberTextField
							size="small"
							// type="number"
							value={updatedDetails?.monthly_capacity}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, monthly_capacity: e.target.value })}
							placeholder="Enter Monthly Capacity"
							required
						/>
					</Stack>
					{/* <Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							No of Pieces Per Month<span>*</span>:
						</InputLabel>
						<TextField
							size="small"
							value={updatedDetails.no_of_pieces}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, no_of_pieces: e.target.value })}
							placeholder="Enter Labour Count"
							required
						/>
					</Stack> */}
					<Stack className="vendor-input" sx={formStack} spacing={0}>
						<InputLabel>
							No of Labour <span>*</span>:
						</InputLabel>
						<TextField
							size="small"
							value={updatedDetails.no_of_labour}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, no_of_labour: e.target.value })}
							placeholder="Enter Labour Count"
							required
						/>
					</Stack>
					<LabourDataTable
						data={updatedDetails}
						setData={setUpdatedDetails}
						editable={true}
						columns={labourTableColumns}
					/>

					<Typography width="100%"></Typography>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							Nature of Supplier<span>*</span>:
						</InputLabel>
						<Select
							value={
								updatedDetails && updatedDetails.supplier_nature
									? updatedDetails.supplier_nature
									: "select"
							}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, supplier_nature: e.target.value })}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							size="small"
							required>
							<MenuItem value="select">Select</MenuItem>
							<MenuItem value="MANUFACTURER">Manufacturer</MenuItem>
							<MenuItem value="BUYING_OFFICE">Buying Office</MenuItem>
							<MenuItem value="GOVERNMENT_SUPPLIER">Government Supplier</MenuItem>
						</Select>
					</Stack>
					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							Industry Type<span>*</span>:
						</InputLabel>
						<Select
							value={
								updatedDetails && updatedDetails.industry_type ? updatedDetails.industry_type : "select"
							}
							onChange={(e) => setUpdatedDetails({ ...updatedDetails, industry_type: e.target.value })}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							size="small"
							disabled={isOnboarded}
							required>
							<MenuItem value="select">Select</MenuItem>
							{/* <MenuItem value="Small Scale">Small Scale</MenuItem> */}
							<MenuItem value={industryTypeValues.large_scale}>LARGE SCALE</MenuItem>
							{/* <MenuItem value="Government">Government</MenuItem> */}
							{/* <MenuItem value="CONTRACTORS">Contractors</MenuItem> */}
							<MenuItem value={industryTypeValues.msme}>MSME</MenuItem>
							{/* <MenuItem value="OTHERS">Others</MenuItem> */}
						</Select>
					</Stack>
					{/* {!isOnboarded && (
						<Stack sx={formStack} spacing={0}>
							<InputLabel htmlFor="industry_type_cert">Industry Type Certification</InputLabel>
							<TextField
								size="small"
								type="file"
								id="industry_type_cert"
								accept=".pdf, .jpeg, .jpg, .png"
								required
								onChange={(event) =>
									handleFileChange(event.target.files[0], fileTypesDataObj.INDUSTRY_CERT.Key)
								}
							/>
						</Stack>
					)} */}

					{/* MSME custom input Fields */}
					{updatedDetails &&
						updatedDetails[companyBasicDetailColumns.industry_type.key] === industryTypeValues.msme && (
							<>
								{!isOnboarded && (
									<Stack sx={formStack} spacing={0}>
										<InputLabel htmlFor="industry_type_cert">
											Industry Type Certification
										</InputLabel>
										<TextField
											size="small"
											type="file"
											id="industry_type_cert"
											accept=".pdf, .jpeg, .jpg, .png"
											required
											onChange={(event) =>
												handleFileChange(
													event.target.files[0],
													fileTypesDataObj.INDUSTRY_CERT.Key
												)
											}
										/>
									</Stack>
								)}
								<Stack sx={formStack} spacing={0}>
									<InputLabel htmlFor="industry_type_cert">
										{msmeIndustryColumns.industry_cert_issue_date.name}
									</InputLabel>
									<TextField
										size="small"
										type="date"
										value={
											updatedDetails &&
											updatedDetails[msmeIndustryColumns.industry_cert_issue_date.key]
										}
										onChange={(e) =>
											setUpdatedDetails({
												...updatedDetails,
												[msmeIndustryColumns.industry_cert_issue_date.key]: e.target.value,
											})
										}
										placeholder={`Enter ${msmeIndustryColumns.industry_cert_issue_date.name}`}
										required
									/>
								</Stack>
								<Stack sx={formStack} spacing={0}>
									<InputLabel htmlFor="industry_type_cert">
										{msmeIndustryColumns.category_of_unit.name}
									</InputLabel>
									<Select
										value={
											updatedDetails && updatedDetails[msmeIndustryColumns.category_of_unit.key]
												? updatedDetails[msmeIndustryColumns.category_of_unit.key]
												: "select"
										}
										onChange={(e) =>
											setUpdatedDetails({
												...updatedDetails,
												[msmeIndustryColumns.category_of_unit.key]: e.target.value,
											})
										}
										MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
										size="small"
										required>
										<MenuItem value="select">Select</MenuItem>
										<MenuItem value="MANUFACTURER">MICRO</MenuItem>
										<MenuItem value="BUYING_OFFICE">SMALL </MenuItem>
										<MenuItem value="GOVERNMENT_SUPPLIER">MEDIUM</MenuItem>
									</Select>
								</Stack>
								<Stack sx={formStack} spacing={0}>
									<InputLabel htmlFor="industry_type_cert">
										{msmeIndustryColumns.entrepreneur_memorandum_no_part_2.name}
									</InputLabel>
									<TextField
										size="small"
										value={
											updatedDetails &&
											updatedDetails[msmeIndustryColumns.entrepreneur_memorandum_no_part_2.key]
										}
										onChange={(e) =>
											setUpdatedDetails({
												...updatedDetails,
												[msmeIndustryColumns.entrepreneur_memorandum_no_part_2.key]:
													e.target.value,
											})
										}
										placeholder={`Enter ${msmeIndustryColumns.entrepreneur_memorandum_no_part_2.name}`}
										required
									/>
								</Stack>
							</>
						)}

					{/* product divisions */}
					<Stack sx={formStacklg} spacing={0}>
						<InputLabel htmlFor="industry_type_cert">
							{companyBasicDetailColumns.product_division.name}
						</InputLabel>
						<Autocomplete
							multiple
							key={updatedDetails}
							id="tags-outlined"
							options={
								divisions
									? Object.keys(divisions).map((row) => {
											return { name: divisions[row] };
									  })
									: [{ name: "" }]
							}
							onChange={(e, value) => {
								setUpdatedDetails({
									...updatedDetails,
									[companyBasicDetailColumns.product_division.key]: value?.reduce((acc, row, i) => {
										return `${acc}${i > 0 ? "," : ""}${row?.name}`;
									}, ""),
								});
								// console.log("value", updatedDetails);
							}}
							value={
								updatedDetails && updatedDetails[companyBasicDetailColumns.product_division.key]
									? updatedDetails[companyBasicDetailColumns.product_division.key]
											?.split(",")
											.map((row) => {
												return { name: row };
											})
									: []
							}
							getOptionLabel={(option) => option.name}
							// getOptionDisabled={(option) => {
							// 	console.log("updatedDetails", option);
							// 	return (
							// 		updatedDetails &&
							// 		typeof updatedDetails[companyBasicDetailColumns.product_division.key] ===
							// 			"string" &&
							// 		updatedDetails[companyBasicDetailColumns.product_division.key]
							// 			?.split(",")
							// 			.filter((row) => row === option.name).length > 0
							// 	);
							// }}
							// defaultValue={]}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={`Select ${companyBasicDetailColumns.product_division.name}`}
								/>
							)}
						/>
					</Stack>
					<Stack sx={formStacklg} spacing={0}>
						<InputLabel htmlFor="industry_type_cert">
							{companyBasicDetailColumns.product_section.name}
						</InputLabel>
						<Autocomplete
							multiple
							key={updatedDetails}
							id="tags-outlined"
							options={
								divisions
									? Object.keys(productSections).map((row) => {
											return { name: productSections[row] };
									  })
									: [{ name: "" }]
							}
							onChange={(e, value) => {
								setUpdatedDetails({
									...updatedDetails,
									[companyBasicDetailColumns.product_section.key]: value?.reduce((acc, row, i) => {
										return `${acc}${i > 0 ? "," : ""}${row?.name}`;
									}, ""),
								});
								// console.log("value", updatedDetails);
							}}
							value={
								updatedDetails && updatedDetails[companyBasicDetailColumns.product_section.key]
									? updatedDetails[companyBasicDetailColumns.product_section.key]
											?.split(",")
											.map((row) => {
												return { name: row };
											})
									: []
							}
							getOptionLabel={(option) => option.name}
							// getOptionDisabled={(option) => {
							// 	console.log("updatedDetails", option);
							// 	return (
							// 		updatedDetails &&
							// 		typeof updatedDetails[companyBasicDetailColumns.product_section.key] === "string" &&
							// 		updatedDetails[companyBasicDetailColumns.product_section.key]
							// 			?.split(",")
							// 			.filter((row) => row === option.name).length > 0
							// 	);
							// }}
							// defaultValue={]}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={`Select ${companyBasicDetailColumns.product_section.name}`}
								/>
							)}
						/>
					</Stack>

					<Stack sx={formStack} spacing={0}>
						<InputLabel>
							{companyBasicDetailColumns.product_type.name}
							<span>*</span>:
						</InputLabel>
						<Select
							value={
								updatedDetails && updatedDetails[companyBasicDetailColumns.product_type.key]
									? updatedDetails[companyBasicDetailColumns.product_type.key]
									: "select"
							}
							onChange={(e) =>
								setUpdatedDetails({
									...updatedDetails,
									[companyBasicDetailColumns.product_type.key]: e.target.value,
								})
							}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							size="small"
							required>
							<MenuItem value="select">Select</MenuItem>
							<MenuItem value={productTypeValues.knits}>{productTypeValues.knits}</MenuItem>
							<MenuItem value={productTypeValues.woven}>{productTypeValues.woven}</MenuItem>
						</Select>
					</Stack>
					<Stack sx={formStacklg} spacing={0}>
						<InputLabel>
							{companyBasicDetailColumns.product_categories.name}
							<span>*</span>:
						</InputLabel>
						<Autocomplete
							multiple
							key={updatedDetails || categories}
							id="tags-outlined"
							options={categories ? categories : [{ name: "" }]}
							onChange={(e, value) => {
								setUpdatedDetails({
									...updatedDetails,
									[companyBasicDetailColumns.product_categories.key]: value?.reduce((acc, row, i) => {
										return `${acc}${i > 0 ? "," : ""}${row?.name}`;
									}, ""),
								});
								// console.log("value", updatedDetails);
							}}
							value={
								updatedDetails && updatedDetails[companyBasicDetailColumns.product_categories.key]
									? updatedDetails[companyBasicDetailColumns.product_categories.key]
											?.split(",")
											.map((row) => {
												return { name: row };
											})
									: []
							}
							getOptionLabel={(option) => option.name}
							// getOptionDisabled={(option) => {
							// 	// console.log("updatedDetails", option);
							// 	return (
							// 		updatedDetails &&
							// 		typeof updatedDetails[companyBasicDetailColumns.product_categories.key] ===
							// 			"string" &&
							// 		updatedDetails[companyBasicDetailColumns.product_categories.key]
							// 			?.split(",")
							// 			.filter((row) => row === option.name).length > 0
							// 	);
							// }}
							// defaultValue={]}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={`Select ${companyBasicDetailColumns.product_categories.name}`}
								/>
							)}
						/>
					</Stack>
				</Stack>
			</Box>
			<DialogActions>
				{isOnboarded && (
					<Button variant="outlined" onClick={handleCloseModal}>
						Cancel
					</Button>
				)}
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					{isOnboarded ? "Save" : "Submit"}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditCompanyDetails;
