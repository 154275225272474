import { AddRounded, DeleteRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import ContactPersonDetails from "components/SupplierPage/SupplierForm/ContactPersonDetails/ContactPersonDetails";
import { PimState } from "context/PimContext";
import { groupsColumnRows, inputTypes, sizeTypes, groups, attributeGroups, constraints } from "data/pimData";
import { EditCompanyDetailsModalTypes, contactPersonData, labourTableColumns } from "data/supplierPageData";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import styled from "@emotion/styled";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { AuthState } from "context/AuthContext";

const CreateColorModal = ({ modalOpen, setModalOpen, submitFunction, selectedStyle }) => {
	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	const initialState = {
		color: "",
		pallete: "",
		status: "",
		color_fashionability: "",
	};

	const { attributesWithValuesGroups } = PimState();
	const [updatedDetails, setUpdatedDetails] = useState(contactPersonData);
	const [color, setColor] = useState({});
	const user = AuthState();
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleCloseModal = () => {
		setUpdatedDetails(contactPersonData);
		setModalOpen(false);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const { publicUrl } = await handleImageFileUpload();

		console.log(publicUrl);
		if (!publicUrl) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "please upload an color image",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setColor({
			...color,
			status: "CREATED",
			created_by: user.email,
			style_id: selectedStyle.id,
			color_url: publicUrl,
		});
		await axios
			.post("/api/pim/create_color", {
				color,
			})
			.then((res) => {
				if (res.status === 201) {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "color created successfully",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				} else if (res.status === 422) {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: res && res.data ? res.data.message : "Something went wrong",
						severity: "warning",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			})
			.catch((err) => {
				console.log(err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.response.data ? err.response.data.message : "Internal server error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});

		setTimeout(console.log(color), 3000);
	};

	const handleImageFileUpload = async () => {
		const formData = new FormData();
		if (!color.file) {
			return "";
		}
		formData.append("colorImages", color.file);

		const res = await axios.post("/api/pim/upload_color_images", formData, {
			headers: {
				"content-type": "multipart/form-data",
			},
		});
		return res.data;
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Box
				width="95%"
				margin="auto"
				pt={2}
				display="flex"
				flexWrap="wrap"
				columnGap={2}
				justifyContent="space-around">
				<Typography width="100%" variant="h6">
					Create Color
				</Typography>
				<Box
					component="label"
					width="100px"
					margin={2}
					height="100px"
					border={`1px solid ${grey[200]}`}
					display="flex"
					alignItems="center"
					justifyContent="center"
					overflow="hidden"
					position="relative">
					{color.url ? (
						<img src={color.url} style={{ width: "100%", position: "absolute" }} />
					) : (
						<AddRounded />
					)}

					<VisuallyHiddenInput
						type="file"
						onChange={(e) =>
							setColor({
								...color,
								file: e.target.files[0],
								url: URL.createObjectURL(e.target.files[0]),
							})
						}
					/>
				</Box>

				{attributesWithValuesGroups[attributeGroups.COLOR.key].map((row, index) => {
					return (
						<Stack sx={{ width: "25%" }} spacing={2} key={index}>
							<InputLabel>{row.name}:</InputLabel>
							{row.input_type === inputTypes.enter ? (
								<TextField
									size="small"
									type="text"
									disabled={row.disabled ? true : false}
									value={color[row?.key]}
									onChange={(e) =>
										setColor({
											...color,
											[row.key]: e.target.value,
										})
									}
									placeholder={`Enter ${row.name}`}
									required
								/>
							) : (
								<Select
									size="small"
									fullWidth
									MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
									value={color[row.key] ? color[row.key] : "select"}
									onChange={(e) =>
										setColor({
											...color,
											[row.key]: e.target.value,
										})
									}
									required={row?.type[constraints.notNull]}>
									<MenuItem value={"select"}>Select</MenuItem>
									{row.attributeMasterValues?.map((value) => {
										return (
											<MenuItem key={value.key} value={value.key}>
												{value.value}
											</MenuItem>
										);
									})}
								</Select>
							)}
						</Stack>
					);
				})}
			</Box>

			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateColorModal;
