import moment from "moment";

export const webDataParamName = "tab";
export const webDataDrawerElements = {
	contact_us: {
		name: "Contact Us",
		key: "contact_us",
		display: true,
	},
	join_us: {
		name: "Join Us",
		key: "join_us",
		display: true,
	},
};
