import "./login.css";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import RareBoxLogo from "../../../Logos/RareBoxLogo";
import { useEffect, useState } from "react";
import { GlobalState } from "../../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
	const { snackBar, setSnackBar, setSnackBarOpen, setLoginUser, setLoadingOpen } = GlobalState();
	const navigate = useNavigate();

	useEffect(() => {
		if (localStorage.getItem("user_token")) navigate("/explore");
	}, []);

	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");

	const handleLoginClick = async (e) => {
		e.preventDefault();
		setLoadingOpen(true);
		if (userName === "" || password === "") {
			setSnackBarOpen(true);
			setSnackBar({ ...snackBar, message: "Enter both username or password", severity: "error" });
			setLoadingOpen(false);
			return;
		}
		// Login Logic
		try {
			await axios.post("/api/auth/login", { email: userName, password }).then((res) => {
				const userdata = res.data.user;
				setLoginUser(userdata);
				localStorage.setItem("user_token", userdata.token);
				navigate("/explore");
				setLoadingOpen(false);
			});
		} catch (error) {
			setSnackBarOpen(true);
			const response = error.response;
			console.log(response);
			if (response && response.data && response.data.status === "error")
				setSnackBar({ ...snackBar, message: response.data.error, severity: "error" });
			else setSnackBar({ ...snackBar, message: "Login failed Server Error", severity: "error" });
			setLoadingOpen(false);
			return;
		}
	};

	return (
		<Box
			display="flex"
			sx={{ width: { xs: "90%", sm: "80%" }, margin: "auto" }}
			height="100vh"
			alignItems="center"
			justifyContent="space-around">
			<Box sx={{ width: "30%" }} display={{ xs: "none", sm: "block" }}>
				<RareBoxLogo />
			</Box>
			{/* form */}
			<Stack
				component="form"
				onSubmit={handleLoginClick}
				width={{ xs: "95%", sm: "45%", md: "40%" }}
				boxShadow={1}
				spacing={2}
				p={3}
				borderRadius={2}>
				<Box sx={{ width: "200px" }} p={2} display={{ xs: "block", sm: "none" }} alignSelf="center">
					<RareBoxLogo />
				</Box>
				<Typography variant="h5" textAlign="center">
					Login
				</Typography>
				<TextField
					onChange={(e) => setUserName(e.target.value)}
					value={userName}
					size="small"
					id="outlined-password-input"
					label="Email"
					type="text"
					autoComplete=""
				/>
				<TextField
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					size="small"
					id="outlined-password-input"
					label="Password"
					type="password"
					autoComplete=""
				/>
				<Button variant="contained" fullWidth type="submit">
					Login
				</Button>
			</Stack>
		</Box>
	);
};

export default Login;
