import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
	attributepriorityTypes,
	barcodeTableColumns,
	colorsChannelObjName,
	colorsObjName,
	designerAttributeObjName,
	designerTableColumns,
	fabricTableColumns,
	groups,
	inputTypes,
	orderData,
	orderTableAttributeColumns,
	pimDescCatAssociationObj,
	pimDrawerElements,
	pimParamName,
	priority,
	productColumns,
	productDetailsColumns,
	productDummyData,
	productImagesObjName,
	styleTableColumns,
	trimsGroupTableColumns,
} from "data/pimData";
import { useEffect, useState } from "react";
import DisplayInfoWithEditTab from "components/Pim/CommonComponents/DisplayInfoWithEditTab";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import BarcodeModal, { BarcodeModalWithCost } from "../../BarcodeModal/BarcodeModal";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import { AddRoad, DeleteRounded, EditRounded } from "@mui/icons-material";
import { PimState } from "context/PimContext";
import CreateColorModal from "../../CreateColorModal/CreateColorModal";
import { rootProtectedPath, services } from "data/authData";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import EditImageDataModal from "../../EditImageDataModal/EditImageDataModal";
import AddRounded from "@mui/icons-material/AddRounded";
import AddNewCosting from "components/OwnProductionCosting/OwnProductionCostingDashbord/AddNewCosting/AddNewCosting";
import AddNewImageModal from "../../AddNewImageModal/AddNewImageModal";
import ImageSlider from "components/Pim/CommonComponents/ImageSlider/ImageSlider";
import DisplayDatagrid from "./DisplayDatagrid/DisplayDatagrid";
import { Checkbox, MenuItem, Select, TextField } from "@mui/material";
import { AuthState } from "context/AuthContext";
import moment from "moment";
import { type } from "@testing-library/user-event/dist/type";
import SelectInputField from "components/Pim/CommonComponents/InputComponents/SelectInputField";

const colStyle = {
	headerAlign: "center",
	align: "center",
};
const valueOptions = {
	[fabricTableColumns.type.key]: ["MAIN", "CONTRAST", "LINING"],
	[fabricTableColumns.blend.key]: ["COTTON RAYON", "85% VISCOS 15% NYLON", "96% POLY 4% LYCRA"],
	[fabricTableColumns.construction.key]: ["COTTON RAYON", "85% VISCOS 15% NYLON", "96% POLY 4% LYCRA"],
	[fabricTableColumns.fabric_category.key]: ["BEDFORD", "NONWOVEN", "VELVETTE"],
	[fabricTableColumns.source_category.key]: ["DENIM", "MILL MADE", "PROCESS", "DIGITAL PRINT"],
	[fabricTableColumns.weave.key]: ["PLAIN", "MIXED STOCK", "OXFORD", "PLAIN SORT NO PD2261"],
	[fabricTableColumns.width.key]: ["110CM", "112CM", '24"D', "122", "144"],
};
const trimsValueOptions = {
	[trimsGroupTableColumns.category.key]: [
		{
			name: "BUTTONS",
			key: "BUTTONS",
			[pimDescCatAssociationObj]: [
				{
					[trimsGroupTableColumns.description.key]: "GASTRA BUTTON 3340BT",
					[trimsGroupTableColumns.consumption.key]: 1,
					[trimsGroupTableColumns.consumption_unit.key]: "PCS",
					[trimsGroupTableColumns.garment_qty.key]: 1,
					[trimsGroupTableColumns.wastage_percent.key]: 0,
				},
			],
		},
		{
			name: "SIZE LABEL",
			key: "SIZE LABEL",
			[pimDescCatAssociationObj]: [
				{
					[trimsGroupTableColumns.description.key]: "POE WOVEN SIZE LABEL-157",
					[trimsGroupTableColumns.consumption.key]: 1,
					[trimsGroupTableColumns.consumption_unit.key]: "NOS",
					[trimsGroupTableColumns.garment_qty.key]: 1,
					[trimsGroupTableColumns.wastage_percent.key]: 0,
				},
			],
		},
		{
			name: "POLYBAG",
			key: "POLYBAG",
			[pimDescCatAssociationObj]: [
				{
					[trimsGroupTableColumns.description.key]: `"Z B" PL 150G 9WX12L" S/K`,
					[trimsGroupTableColumns.consumption.key]: 1,
					[trimsGroupTableColumns.consumption_unit.key]: "PCS",
					[trimsGroupTableColumns.garment_qty.key]: 1,
					[trimsGroupTableColumns.wastage_percent.key]: 0,
				},
			],
		},
	],
};

const TrimsGroupPopup = () => {
	return <></>;
};

const FabricAndTrimsTab = ({ foreignKey }) => {
	const { setSnackBar, setSnackBarOpen, snackBar } = GlobalState();
	const { user } = AuthState();
	const { attributes, attributeValues, selectedStyle, setSelectedStyle } = PimState();

	useEffect(() => {
		const fetch = async () => {
			// if (!selectedStyl) {
			// 	await axios.get("/").then((res) => setProductData(res.data));
			// } else setProductData(selectedStyle);
		};
		fetch();
	}, [selectedStyle]);

	const navigate = useNavigate();

	const [selectedColor, setSelectedColor] = useState();
	const defFabricObj = Object.keys(fabricTableColumns).reduce((acc, row) => {
		acc[fabricTableColumns[row].key] = "SELECT";
		return acc;
	}, {});
	const defTrimGroupObj = Object.keys(trimsGroupTableColumns).reduce((acc, row) => {
		acc[trimsGroupTableColumns[row].key] = "SELECT";
		return acc;
	}, {});
	const [fabricToAdd, setFabricToAdd] = useState(defFabricObj);
	const [trimGroupToadd, setTrimGroupToAdd] = useState(defTrimGroupObj);
	const [selectedTrimCategory, setSelectedTrimCategory] = useState();
	const [colorWiseCheck, setColorWiseCheck] = useState(false);
	const [sizeWiseCheck, setSizeWiseCheck] = useState(false);

	const fabricColumns = [
		{
			...fabricTableColumns.type,
			width: 120,
			...colStyle,
			headerName: fabricTableColumns.type.name,
			field: fabricTableColumns.type.key,
		},
		{
			...fabricTableColumns.blend,
			type: "singleSelect",
			editable: true,
			valueOptions: valueOptions[fabricTableColumns.blend.key],
			width: 120,
			...colStyle,
			headerName: fabricTableColumns.blend.name,
			field: fabricTableColumns.blend.key,
		},
		{
			...fabricTableColumns.source_category,
			field: fabricTableColumns.source_category.key,
			headerName: fabricTableColumns.source_category.name,
			editable: true,
			type: "singleSelect",
			valueOptions: valueOptions[fabricTableColumns.source_category.key],
			width: 120,
			editable: true,
			...colStyle,
		},
		{
			...fabricTableColumns.fabric_category,
			field: fabricTableColumns.fabric_category.key,
			headerName: fabricTableColumns.fabric_category.name,
			editable: true,
			type: "singleSelect",
			valueOptions: valueOptions[fabricTableColumns.fabric_category.key],
			width: 120,
			...colStyle,
		},
		{
			...fabricTableColumns.weave,
			field: fabricTableColumns.weave.key,
			editable: true,
			headerName: fabricTableColumns.weave.name,
			type: "singleSelect",
			valueOptions: valueOptions[fabricTableColumns.weave.key],
			width: 120,
			...colStyle,
		},
		{
			...fabricTableColumns.construction,
			field: fabricTableColumns.construction.key,
			editable: true,
			headerName: fabricTableColumns.construction.name,
			type: "singleSelect",
			valueOptions: valueOptions[fabricTableColumns.construction.key],
			width: 120,
			...colStyle,
		},
		{
			...fabricTableColumns.width,
			field: fabricTableColumns.width.key,
			headerName: fabricTableColumns.width.name,
			editable: true,
			type: "singleSelect",
			valueOptions: valueOptions[fabricTableColumns.width.key],
			width: 120,
			...colStyle,
			// renderCell: (params) => {
			// 	return (
			// 		<IconButton>
			// 			<DeleteRounded />
			// 		</IconButton>
			// 	);
			// },
		},
	];
	const trimsGroupColumns = [
		{
			...trimsGroupTableColumns.category,
			width: 120,
			...colStyle,
			headerName: trimsGroupTableColumns.category.name,
			field: trimsGroupTableColumns.category.key,
		},
		{
			...trimsGroupTableColumns.color_level,
			width: 120,
			...colStyle,
			headerName: trimsGroupTableColumns.color_level.name,
			field: trimsGroupTableColumns.color_level.key,
		},
		{
			...trimsGroupTableColumns.size_level,
			headerName: trimsGroupTableColumns.size_level.name,
			field: trimsGroupTableColumns.size_level.key,
			width: 120,
			...colStyle,
		},
		{
			...trimsGroupTableColumns.consumption,
			field: trimsGroupTableColumns.consumption.key,
			headerName: trimsGroupTableColumns.consumption.name,
			width: 120,
			...colStyle,
		},
		{
			...trimsGroupTableColumns.consumption_unit,
			field: trimsGroupTableColumns.consumption_unit.key,
			headerName: trimsGroupTableColumns.consumption_unit.name,
			width: 120,
			...colStyle,
		},
		{
			...trimsGroupTableColumns.description,
			field: trimsGroupTableColumns.description.key,
			headerName: trimsGroupTableColumns.description.name,
			width: 120,
			...colStyle,
		},
		{
			...trimsGroupTableColumns.wastage_percent,
			field: trimsGroupTableColumns.wastage_percent.key,
			headerName: trimsGroupTableColumns.wastage_percent.name,
			width: 120,
			...colStyle,
		},

		{
			...fabricTableColumns.width,
			field: fabricTableColumns.width.key,
			headerName: fabricTableColumns.width.name,
			editable: true,
			type: "singleSelect",
			valueOptions: valueOptions[fabricTableColumns.width.key],
			width: 120,
			...colStyle,
			// renderCell: (params) => {
			// 	return (
			// 		<IconButton>
			// 			<DeleteRounded />
			// 		</IconButton>
			// 	);
			// },
		},
	];

	const [fabricArr, setFabricArr] = useState([]);
	const [trimGrpArr, setTrimGrpArr] = useState([]);

	// modal var

	const handleAddFabricClick = () => {
		let obj = fabricToAdd;
		if (!obj || !obj[fabricTableColumns.type.key]) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Select Fabric Type `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setFabricArr([...fabricArr, { ...fabricToAdd, isNew: true, id: new Date().valueOf() }]);
	};
	const handleAddTrimGroup = () => {
		let obj = trimGroupToadd;
		if (
			!obj ||
			obj[trimsGroupTableColumns.category.key] === inputTypes.select ||
			obj[trimsGroupTableColumns.description.key] === inputTypes.select
		) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Select Details `,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setTrimGrpArr([...trimGrpArr, { ...trimGroupToadd, isNew: true, id: new Date().valueOf() }]);
	};

	if (!selectedStyle)
		return (
			<Stack width="100%" spacing={2}>
				{/* Fabric */}
				<Box width="85%">
					<Typography pb={0} variant="h6">
						Fabric Sheet
					</Typography>
					{/* <Divider orientation="horizontal" variant="fullWidth" /> */}
					<Box display="flex" p={2} justifyContent="space-between">
						<Box
							sx={{
								alignSelf: "flex-start",
								display: "flex",
								flexWrap: "wrap",
								alignItems: "center",
								columnGap: 2,
							}}>
							<Select
								sx={{ width: 170 }}
								size="small"
								fullWidth
								variant="outlined"
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								value={
									fabricToAdd && fabricToAdd[fabricTableColumns.type.key]
										? fabricToAdd[fabricTableColumns.type.key]
										: "SELECT"
								}
								onChange={(e) =>
									setFabricToAdd({ ...fabricToAdd, [fabricTableColumns.type.key]: e.target.value })
								}>
								<MenuItem value={"SELECT"}>
									<span style={{ color: grey[400] }}>Select Type</span>
								</MenuItem>
								{valueOptions[fabricTableColumns.type.key]?.map((row) => {
									return (
										<MenuItem key={row} value={row}>
											{row}
										</MenuItem>
									);
								})}
							</Select>

							<Box>
								<Button variant="contained" onClick={handleAddFabricClick}>
									Add Fabric
								</Button>
							</Box>
						</Box>
						<Box>
							<Button variant="contained" onClick={handleAddFabricClick}>
								Save
							</Button>
						</Box>
					</Box>
					<div width="100%" spacing={2}>
						<DataGrid
							columns={fabricColumns}
							rows={fabricArr ? fabricArr : []}
							sx={{
								height: 350,
								// border: "none",
								"& .MuiDataGrid-row": {
									// borderBottom: "1px solid lightgray",
									cursor: "pointer",
									// border: "none",
								},
								"& .MuiDataGrid-columnHeader": {
									cursor: "pointer",
								},
								"& .MuiDataGrid-cell:focus-within": {
									outline: `solid`,
								},
								"& .MuiDataGrid-columnHeaders": {
									background: grey[100],
									mb: 2,
									// border: "none",
									borderRadius: 1,
									// padding: "10px 0px",
								},
							}}
							onCellEditStop={(params, event) => {
								// if (params.reason === GridCellEditStopReasons.) {
								// 	event.defaultMuiPrevented = true;
								// }
							}}
							columnHeaderHeight={35}
							rowHeight={40}
							showCellVerticalBorder
							// onRowClick={(params, e) => {
							// 	if (typeof onRowClick === "function") onRowClick(params.row, e);
							// }}
							initialState={{
								pagination: {
									paginationModel: { pageSize: 10 },
								},
							}}
							pageSizeOptions={[10, 50]}
						/>
					</div>
				</Box>
				{/* Accessories */}
				<Box width="85%">
					<Typography pb={0} variant="h6">
						Accessories
					</Typography>
					{/* <Divider orientation="horizontal" variant="fullWidth" /> */}
					<Box display="flex" mt={2} mb={2} justifyContent="space-between" alignItems="center">
						<Box
							sx={{
								alignSelf: "flex-start",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								columnGap: 2,
								p: 1,
								background: grey[200],
								borderRadius: 1,
							}}>
							<Select
								sx={{ width: 170 }}
								size="small"
								fullWidth
								variant="outlined"
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								value={
									trimGroupToadd && trimGroupToadd[trimsGroupTableColumns.category.key]
										? trimGroupToadd[trimsGroupTableColumns.category.key]
										: "SELECT"
								}
								onChange={(e) => {
									let selectedTrimGrp = trimsValueOptions[
										trimsGroupTableColumns.category.key
									]?.filter((row) => row?.key === e.target.value);
									setSelectedTrimCategory(selectedTrimGrp ? selectedTrimGrp[0] : null);
									setTrimGroupToAdd({
										...trimGroupToadd,
										[trimsGroupTableColumns.category.key]: e.target.value,
									});
								}}>
								<MenuItem value={"SELECT"}>
									<span style={{ color: grey[400] }}>Select Category</span>
								</MenuItem>
								{trimsValueOptions[trimsGroupTableColumns.category.key]?.map((row) => {
									return (
										<MenuItem key={row.key} value={row.key}>
											{row.name}
										</MenuItem>
									);
								})}
							</Select>
							<Select
								sx={{ width: 170 }}
								size="small"
								fullWidth
								variant="outlined"
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								value={
									trimGroupToadd && trimGroupToadd[trimsGroupTableColumns.description.key]
										? trimGroupToadd[trimsGroupTableColumns.description.key]
										: "SELECT"
								}
								onChange={(e) => {
									let selectedTrimGrpDesc = selectedTrimCategory[pimDescCatAssociationObj]?.filter(
										(row) => row[trimsGroupTableColumns.description.key] === e.target.value
									);
									if (selectedTrimGrpDesc)
										setTrimGroupToAdd({ ...trimGroupToadd, ...selectedTrimGrpDesc[0] });
								}}>
								<MenuItem value={"SELECT"}>
									<span style={{ color: grey[400] }}>Select Description</span>
								</MenuItem>
								{selectedTrimCategory &&
									selectedTrimCategory[pimDescCatAssociationObj]?.map((row) => {
										return (
											<MenuItem
												key={row[trimsGroupTableColumns.description.key]}
												value={row[trimsGroupTableColumns.description.key]}>
												{row[trimsGroupTableColumns.description.key]}
											</MenuItem>
										);
									})}
							</Select>
							<Box display="flex" alignItems="center">
								<Checkbox
									checked={
										trimGroupToadd && trimGroupToadd[trimsGroupTableColumns.color_level.key]
											? trimGroupToadd[trimsGroupTableColumns.color_level.key]
											: false
									}
									onChange={(e) =>
										setTrimGroupToAdd({
											...trimGroupToadd,
											[trimsGroupTableColumns.color_level.key]: e.target.checked ? true : false,
										})
									}
									inputProps={{ "aria-label": "controlled" }}
								/>
								<Typography variant="body1">Color wise</Typography>
							</Box>
							<Box display="flex" alignItems="center">
								<Checkbox
									checked={
										trimGroupToadd && trimGroupToadd[trimsGroupTableColumns.size_level.key]
											? trimGroupToadd[trimsGroupTableColumns.size_level.key]
											: false
									}
									onChange={(e) =>
										setTrimGroupToAdd({
											...trimGroupToadd,
											[trimsGroupTableColumns.size_level.key]: e.target.checked ? true : false,
										})
									}
									inputProps={{ "aria-label": "controlled" }}
								/>
								<Typography variant="body1">Size wise</Typography>
							</Box>
							<Box>
								<Button variant="contained" onClick={handleAddTrimGroup}>
									Add Accessory
								</Button>
							</Box>
						</Box>
						<Box>
							<Button variant="contained" onClick={handleAddFabricClick}>
								Save
							</Button>
						</Box>
					</Box>
					<div width="100%" spacing={2}>
						<DataGrid
							columns={trimsGroupColumns}
							rows={trimGrpArr ? trimGrpArr : []}
							sx={{
								height: 350,
								// border: "none",
								"& .MuiDataGrid-row": {
									// borderBottom: "1px solid lightgray",
									cursor: "pointer",
									// border: "none",
								},
								"& .MuiDataGrid-columnHeader": {
									cursor: "pointer",
								},
								"& .MuiDataGrid-cell:focus-within": {
									outline: `solid`,
								},
								"& .MuiDataGrid-columnHeaders": {
									background: grey[100],
									mb: 2,
									// border: "none",
									borderRadius: 1,
									// padding: "10px 0px",
								},
							}}
							onCellEditStop={(params, event) => {
								// if (params.reason === GridCellEditStopReasons.) {
								// 	event.defaultMuiPrevented = true;
								// }
							}}
							columnHeaderHeight={35}
							rowHeight={40}
							showCellVerticalBorder
							// onRowClick={(params, e) => {
							// 	if (typeof onRowClick === "function") onRowClick(params.row, e);
							// }}
							initialState={{
								pagination: {
									paginationModel: { pageSize: 10 },
								},
							}}
							pageSizeOptions={[10, 50]}
						/>
					</div>
				</Box>
			</Stack>
		);
	else return <div></div>;
};

export default FabricAndTrimsTab;
