import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import MenuIcon from "@mui/icons-material/Menu";
import RareBoxLogoWhite from "../../../Logos/RareBoxLogoWhite";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import Profile from "../../Profile/Profile";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
	const navigate = useNavigate();
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar>
					<Box width="130px" mr="auto" onClick={() => navigate("/explore")}>
						<RareBoxLogoWhite />
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default Navbar;
