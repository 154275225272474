import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import { DownloadDoneRounded, DownloadRounded } from "@mui/icons-material";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import FileSaver from "file-saver";

const tablecolumns = [
	{ field: "name", headerName: "Name", width: 160, headerAlign: "center", align: "center" },
	{ field: "email", headerName: "Email", width: 220, headerAlign: "center", align: "center" },
	{ field: "contact", headerName: "Contact", width: 200, headerAlign: "center", align: "center" },
	{ field: "resumelink", headerName: "Resume", width: 346, headerAlign: "center", align: "center" },
];

const datas = [
	{name: 'Name', key: 'name', url: false},
	{name: 'Email', key: 'email', url: false}, 
	{name: 'Contact', key: 'contact', url: false},
	{name: 'Current CTC', key: 'currentCtc',url: false},
	{name: 'Current Location', key: 'currentLocation',url: false}, 
	{name: 'Department', key: 'department',url: false}, 
	{name: 'Education', key: 'education',url: false},
	{name: 'Expected CTC', key: 'expectedCtc',url: false},
	{name: 'Experience', key: 'experience',url: false},
	{name: 'Gender', key: 'gender',url: false},
	{name: 'LinkedIn Link', key: 'linkedinLink',url: true},
	{name: 'Notice Period', key: 'notice',url: false},
	{name: 'Preferred Location', key: 'preferredLocation',url: false},
	{name: 'Resume', key: 'resumelink',url: true},
]


const JoinUs = () => {
	const [data, setData] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [open, setOpen] = React.useState(false);
	const [selectedRow, setSelectedRow] = useState({})

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

	useEffect(() => {
		// Reference to your Firebase database
		const fetch = async () => {
			try {
				const res = await axios.get("/api/website/join_us");
				setData(res.data.result);
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Network Error",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		};
		fetch();
	}, []);
	const handleDownloadClick = async () => {
		try {
			await axios.get("/api/website/download_joinus", {responseType: "blob"}).then((res) => {
				console.log(res);
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, "JoinUs.xlsx");
			});
		} catch {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "No data to download",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	const handleRowClick = (params) => {
		setSelectedRow(params.row)
		handleClickOpen()
	}
	const DisplayDetailes = (key, value, url) => {
		let urlName = ''
		if (url && value) {
			const name = value.split("/")
			urlName = name[name.length - 1]
		}
		return (
			<Typography width={'100%'} sx={{ margin: '10px 0'}} >
				<span>
					{key}
				</span>
				:
				{url ? <a style={{marginLeft: '5px', fontWeight: '500'}} href={value} target="_blank" >{urlName} </a>  : <span style={{marginLeft: '5px', fontWeight: '500'}}>
					{value}
				</span>}
			</Typography>
		)
	}
	return (
		<Stack maxWidth="1100px" spacing={3} margin="auto">
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h4" width="100%">
					Join US
				</Typography>
				<Button variant="contained" startIcon={<DownloadRounded />} onClick={handleDownloadClick}>
					Download
				</Button>
			</Box>

			{/* table */}
			<Stack width="100%">
				<DataGrid
					rows={data}
					columns={tablecolumns}
					sx={{
						minHeight: 430,
						"& .MuiDataGrid-row": {
							cursor: "pointer",
						},
						"& .MuiDataGrid-cell": {
							padding: "10px",
						},
					}}
					rowHeight={40}
					// rowM
					// showCellVerticalBorder
					showColumnVerticalBorder
					onRowClick={(params) => {
						handleRowClick(params)
					}}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 10 },
						},
					}}
					autoHeight
					disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Stack>
			{/* edit modal */}
			{/* <Dialog open={modalOpen} onClose={handleEditModalClose} scroll="body" maxWidth="md" fullWidth>
				<Box display="flex" justifyContent="space-between" p="5px 10px" alignItems="center">
					<Box display="flex" alignItems="center">
						<DialogTitle>Supplier Information</DialogTitle>
					</Box>
					<IconButton sx={{ width: "50px", height: "50px" }} onClick={handleEditModalClose}>
						<ClearRounded sx={{ transform: "scale(1.3)" }} />
					</IconButton>
				</Box>
				<Box p={2}>
					<SupplierInfoModal
						modalEditable={modalEditable}
						allSuppliers={allSuppliers}
						setAllSuppliers={setAllSuppliers}
						selectedSupplier={selectedSupplier}
						setSelectedSupplier={setSelectedSupplier}
						setModalOpen={setModalOpen}
						setModalEditable={setModalEditable}
					/>
				</Box>
			</Dialog> */}
			<React.Fragment>
				<Dialog
					fullWidth
					open={open}
					onClose={handleClose}
					maxWidth="sm"
				>
					<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
						Detailes
					</DialogTitle>
					<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent dividers>
						{datas.map((val) =>
						{return selectedRow[val.key] !== '' && DisplayDetailes(val.name, selectedRow[val.key],val.url)}
						)}
					</DialogContent>
				</Dialog>
			</React.Fragment>
		</Stack>
	);
};

export default JoinUs;
