import styled from "@emotion/styled";
import { AddRounded } from "@mui/icons-material";
import { Box, Button, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import { PimState } from "context/PimContext";
import { colorTableColumns, pimAttrValAssociationObj } from "data/pimData";
import { useEffect, useState } from "react";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});
const ColorItem = ({ colors, setColors, currentColor }) => {
	const [color, setColor] = useState(currentColor);
	const { attributesWithValues } = PimState();

	useEffect(() => {
		if (colors.length > 0)
			setColors([
				...colors.map((row) => {
					if (row.color === currentColor.color && row.pallete === currentColor.pallete) {
						return color;
					} else return row;
				}),
			]);
	}, [color]);

	const handleDeleteColorClick = (color) => {
		setColors(colors.filter((row) => row.color === color.color && row.pallete === color.pallete));
	};

	const contactFormStack = {
		width: { xs: "80%", sm: "40%", md: "19%" },
	};
	return (
		<Stack direction="row" width="100%" flexWrap="wrap" rowGap={1} spacing={2} alignItems="flex-end" p={2}>
			<Box
				component="label"
				width="50px"
				height="50px"
				border={`1px solid ${grey[200]}`}
				display="flex"
				alignItems="center"
				justifyContent="center"
				overflow="hidden"
				position="relative">
				{currentColor.url ? (
					<img src={currentColor.url} style={{ width: "100%", position: "absolute" }} />
				) : (
					<AddRounded />
				)}

				<VisuallyHiddenInput
					type="file"
					onChange={(e) =>
						setColor({ ...color, file: e.target.files[0], url: URL.createObjectURL(e.target.files[0]) })
					}
				/>
			</Box>
			<Box display="flex" columnGap={2}>
				{colorTableColumns?.map((column) => {
					const colData = attributesWithValues?.filter((row) => row.key === column.key)[0]
						? attributesWithValues?.filter((row) => row.key === column.key)[0][pimAttrValAssociationObj]
						: [];

					return (
						<Stack spacing={0}>
							<InputLabel>{column.name}:</InputLabel>
							<Select
								value={currentColor[column.key]}
								// onChange={(e) => setNewColor({ ...newColor, [column.key]: e.target.value })}
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								size="small"
								disabled={true}
								sx={{ width: 150 }}
								required>
								<MenuItem value="select">Select {column.name}</MenuItem>
								{/* <MenuItem value="BLUE">BLUE</MenuItem> */}
								{colData?.map((row) => {
									return <MenuItem value={row.key}>{row.value}</MenuItem>;
								})}
							</Select>
						</Stack>
					);
				})}
			</Box>
			<Box>
				<Button
					variant="contained"
					onClick={handleDeleteColorClick}
					sx={{ width: 100, marginLeft: "auto", display: "" }}>
					Delete
				</Button>
			</Box>
		</Stack>
	);
};

const ColorsSection = ({ colors, setColors }) => {
	const defNewColor = { color: "select", pallete: "select" };
	const [newColor, setNewColor] = useState(defNewColor);
	const { attributesWithValues, attributeValues } = PimState();
	const { setSnackBarOpen, setSnackBar, snackBar } = GlobalState();

	const handleAddColorClick = (color) => {
		// console.log(attributesWithValues?.filter((row) => row.key === "color"));
		if (color.color === "select" || color.pallete === "select") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Select Color and pallete",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		const isPresent = colors.filter((row) => row.color === color.color && row.pallete === color.pallete);
		if (isPresent.length > 0) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Color is already present",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		setColors([...colors, color]);
		setNewColor(defNewColor);
	};

	return (
		<Box width="100%">
			<Stack spacing={1}>
				{colors?.map((color) => {
					return <ColorItem colors={colors} setColors={setColors} currentColor={color} />;
				})}
			</Stack>

			<Box display="flex" columnGap={2} pt={3} key={attributeValues}>
				{colorTableColumns?.map((column) => {
					const colData = attributesWithValues?.filter((row) => row.key === column.key)[0]
						? attributesWithValues?.filter((row) => row.key === column.key)[0][pimAttrValAssociationObj]
						: [];

					return (
						<Select
							value={newColor[column.key]}
							onChange={(e) => setNewColor({ ...newColor, [column.key]: e.target.value })}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							size="small"
							sx={{ width: 200 }}
							required>
							<MenuItem value="select">Select {column.name}</MenuItem>
							{/* <MenuItem value="BLUE">BLUE</MenuItem> */}
							{colData?.map((row) => {
								return <MenuItem value={row.key}>{row.value}</MenuItem>;
							})}
						</Select>
					);
				})}

				<Button variant="contained" onClick={() => handleAddColorClick(newColor)}>
					Add Color
				</Button>
			</Box>
		</Box>
	);
};

export default ColorsSection;
