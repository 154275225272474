import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { stateList } from "../../../../../data/stateMasterList";

const ContactPersonDetails = ({ person, setPerson }) => {
	// useEffect(() => {
	// 	if (contactPersons.length > 0)
	// 		setContactPersons([
	// 			...contactPersons.map((row) => {
	// 				if (row.no === currentPerson.no) {
	// 					return person;
	// 				} else return row;
	// 			}),
	// 		]);
	// }, [person]);

	const date = new Date();
	console.log(person);
	const contactFormStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	return (
		<Stack direction="row" width="100%" flexWrap="wrap" rowGap={1} p={1} columnGap="3%">
			<Stack sx={contactFormStack} spacing={0}>
				<InputLabel>Department:</InputLabel>
				<Select
					value={person?.department}
					onChange={(e) => setPerson({ ...person, department: e.target.value })}
					MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
					size="small"
					required>
					<MenuItem value="select">Select Contact Type</MenuItem>
					<MenuItem value="Management/Director">Management/Director</MenuItem>
					<MenuItem value="Finance">Finance</MenuItem>
					<MenuItem value="Merchandiser">Merchandiser</MenuItem>
				</Select>
			</Stack>
			<Stack sx={contactFormStack} spacing={0}>
				<InputLabel>Name:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={person?.name}
					onChange={(e) => setPerson({ ...person, name: e.target.value })}
					placeholder="Enter Name"
					required
				/>
			</Stack>
			<Stack sx={contactFormStack} spacing={0}>
				<InputLabel>Email:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={person?.email}
					onChange={(e) => setPerson({ ...person, email: e.target.value })}
					placeholder="Enter Email"
					required
				/>
			</Stack>
			<Stack sx={contactFormStack} spacing={0}>
				<InputLabel>Phone No:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={person?.phone_no}
					onChange={(e) => setPerson({ ...person, phone_no: e.target.value })}
					placeholder="Enter Phone No"
					required
				/>
			</Stack>
			<Stack sx={contactFormStack} spacing={0}>
				<InputLabel>
					Gender<span>*</span>:
				</InputLabel>
				<Select
					value={person?.gender}
					onChange={(e) => setPerson({ ...person, gender: e.target.value })}
					MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
					size="small"
					required>
					<MenuItem value="select">select</MenuItem>
					<MenuItem value="Male">Male</MenuItem>
					<MenuItem value="Female">Female</MenuItem>
					<MenuItem value="Not want to disclose">Not want to disclose</MenuItem>
				</Select>
			</Stack>

			<Stack sx={contactFormStack} spacing={0}>
				<InputLabel>Position:</InputLabel>
				<TextField
					size="small"
					type="text"
					value={person?.position}
					onChange={(e) => setPerson({ ...person, position: e.target.value })}
					placeholder="Enter Position"
					required
				/>
			</Stack>
		</Stack>
	);
};

export default ContactPersonDetails;
