import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { attributeGroups, attributeValueAssociationName, inputTypes, styleTableColumns } from "data/pimData";
import { PimState } from "context/PimContext";
import { AddRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const StyleFilter = ({ filter, setFilter, submitFunction, disableData }) => {
	const { attributesWithValuesGroups } = PimState();

	const handleFilterClick = () => {
		if (typeof submitFunction === "function") submitFunction();
	};
	const handleSelectChange = (val, col) => {
		setFilter(() => {
			if (val === "select") delete filter[col];
			else filter[col] = val;

			return { ...filter };
		});
	};
	return (
		<Box display="flex" justifyContent="space-around" sx={{ background: grey[200], p: 1, borderRadius: 2 }} alignItems="center">
			<Box
				display="flex"
				width="85%"
				columnGap={1}
				alignItems="center"
				flexWrap="wrap"
				sx={{ columnGap: "2%", rowGap: 2 }}
				// sx={{ background: "var(--grayBgColor)" }}
				p={1}
				borderRadius={1}>
				<Typography variant="h6" color={"grey"} width="100%">
					Filter
				</Typography>
				{attributesWithValuesGroups[attributeGroups.STYLE.key]?.map((row) => {
					console.log("sadkcbkasbdc", row);
					if (row["input_type"] === inputTypes.select)
						return (
							<Select
								size="small"
								sx={{ width: "16%" }}
								value={filter && filter[row.key] ? filter[row.key] : "select"}
								disabled={disableData && disableData.includes(row.key)}
								MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
								onChange={(e) => handleSelectChange(e.target.value, row.key)}>
								<MenuItem value={"select"}>{row.name}(ALL)</MenuItem>
								{row[attributeValueAssociationName]?.map((row) => {
									return (
										<MenuItem key={row.value} value={row.key}>
											{row.value}
										</MenuItem>
									);
								})}
							</Select>
						);
					else return <></>;
				})}
			</Box>
			<Button variant="contained" size="large" sx={{ height: "50px" }} startIcon={<FilterAltIcon />} onClick={handleFilterClick}>
				Filter
			</Button>
		</Box>
	);
};

export default StyleFilter;
