import { Box, Button, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import { DownloadDoneRounded, DownloadRounded } from "@mui/icons-material";
import FilterInputs from "../FilterInputs/FilterInputs";
import moment from "moment";
import FileSaver from "file-saver";
import { AuthState } from "context/AuthContext";
import { admin, custom } from "data/authData";

const tablecolumns = [
	{ field: "name", headerName: "Name", width: 160, headerAlign: "center", align: "center" },
	{ field: "email", headerName: "Email", width: 200, headerAlign: "center", align: "center" },
	{ field: "contact", headerName: "Contact", width: 200, headerAlign: "center", align: "center" },
	{ field: "message", headerName: "Message", width: 300, headerAlign: "center", align: "center" },
];

const WithCOGS = ({ cost_rate }) => {
	const [filter, setFilter] = useState({});
	const [download, setDownload] = useState(false);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const { user } = AuthState();

	const formSubmit = async (e) => {
		e.preventDefault();
		setDownload(true);
		if (cost_rate === true && (!user.role[custom.COGS.key] || !user.role[admin])) {
			setSnackBar({
				...snackBar,
				message: "Error :Access Denied",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
			setDownload(false);
			return;
		}
		try {
			const fileName = `item_master_${cost_rate ? "COGS_" : ""}_${user.email.split("@")[0]}_${moment().format(
				"DD-MM-YYYY"
			)}_${moment().get("hour")}H_${moment().get("minute")}M_${moment().get("second")}S`;
			await axios
				?.get(
					`https://us-central1-adroit-standard-379209.cloudfunctions.net/item_master?cost_rate=${cost_rate}&file_name=${fileName}&${Object.keys(
						filter
					).map((key) => `${key}=${filter[key]}&`)}`
				)
				.then((res) => {
					// const url = URL.createObjectURL(res.data);
					console.log(filter);
					window.location.href = res.data;
					// FileSaver.saveAs(url, `item_master_${moment().format("YYYY-MM-DD")}.xlsx`);
				});
		} catch (error) {
			setSnackBar({
				...snackBar,
				message: "Error :Download Failed",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setSnackBarOpen(true);
		}

		setDownload(false);
	};

	return (
		<Stack maxWidth="1100px" spacing={3} margin="auto">
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h4" width="100%">
					Download Item Master
				</Typography>
				{/* <Button variant="contained" startIcon={<DownloadRounded />} onClick={handleDownloadClick}>
					Download
				</Button> */}
			</Box>

			{/* table */}
			<Stack width="100%" spacing={2} component="form" onSubmit={formSubmit}>
				<Typography variant="h6" width="100%">
					Select Filters
				</Typography>
				<FilterInputs updatedData={filter} setUpdatedData={setFilter} />
				<Box>
					<Button
						disabled={download}
						startIcon={download ? <CircularProgress color="inherit" /> : <DownloadRounded />}
						variant="contained"
						sx={{ textTransform: "capitalize" }}
						type="submit"
						size="large">
						{download ? "Preparing File..." : "Download"}
					</Button>
					<Typography variant="subtitle1" pt={1}>
						{download ? "Please wait this may take few moments.." : ""}
					</Typography>
				</Box>
			</Stack>
			{/* edit modal */}
		</Stack>
	);
};

export default WithCOGS;
