import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Grid,
	InputLabel,
	Skeleton,
	Stack,
	TextField,
} from "@mui/material";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { grey } from "@mui/material/colors";
import { DataGrid, isLeaf } from "@mui/x-data-grid";
import { StoreContext } from "../EboStore";

// const columns = [
// 	{
// 		field: "store-code",
// 		headerName: "STORE-CODE",
// 		width: 100,
// 		headerClassName: "super-app-theme--header",
// 		valueGetter: (params) => `${params.row.rr_code || ""}${params.row.rr_number || ""}`,
// 	},
// 	{
// 		field: "brand",
// 		headerName: "BRAND",
// 		headerClassName: "super-app-theme--header",
// 		width: 150,
// 	},
// 	{
// 		field: "store_name",
// 		headerName: "STORE NAME",
// 		headerClassName: "super-app-theme--header",
// 		width: 130,
// 	},
// 	{
// 		field: "name_of_franchise_company",
// 		headerName: "FRANCHISE NAME",
// 		headerClassName: "super-app-theme--header",
// 		width: 180,
// 	},
// 	{
// 		field: "address",
// 		headerClassName: "super-app-theme--header",
// 		headerName: "ADDRESS",
// 		width: 220,
// 	},
// 	{
// 		field: "state",
// 		headerClassName: "super-app-theme--header",
// 		headerName: "STATE",
// 		type: "string",
// 		width: 130,
// 	},
// 	{
// 		field: "city",
// 		headerClassName: "super-app-theme--header",
// 		headerName: "CITY",
// 		type: "string",
// 		width: 130,
// 	},
// 	{
// 		field: "pincode",
// 		headerClassName: "super-app-theme--header",
// 		headerName: "PINCODE",
// 		width: 160,
// 	},
// ];

// function ActiveStore({ isLoading }) {
// 	// const [activeStore, setActiveStore] = useState([]);
// 	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
// 	const [open, setOpen] = useState(false);
// 	const [value, setValue] = useState({});
// 	const [downloading, setDownloading] = useState(false);
// 	const { activeStore, setActiveStore, pending, setPending } = StoreContext();

// 	const getRowClassName = (params) => {
// 		// Conditionally apply a custom class to the row based on a condition
// 		return params.row.is_active ? "" : "highlighted-row";
// 	};

// 	return (
// 		<>
// 			{activeStore.length ? (
// 				<Stack width="100%" height={400} pt={3} my={1}>
// 					<DataGrid
// 						// autoHeight
// 						getRowClassName={getRowClassName}
// 						sx={{
// 							"& .super-app-theme--header": {
// 								backgroundColor: grey[300],
// 							},
// 							minHeight: "65vh",
// 							cursor: "pointer",
// 						}}
// 						rows={activeStore}
// 						columns={columns}
// 						initialState={{
// 							pagination: {
// 								paginationModel: { page: 0, pageSize: 5 },
// 							},
// 						}}
// 						pageSizeOptions={[5, 10]}
// 						disableRowSelectionOnClick
// 						onRowClick={(params) => {
// 							setValue(params.row);
// 							setOpen(!open);
// 						}}
// 					/>
// 					<Modal
// 						open={open}
// 						onClose={() => setOpen(!open)}
// 						aria-labelledby="modal-modal-title"
// 						aria-describedby="modal-modal-description"
// 						disableEnforceFocus>
// 						<StoreDetail
// 							data={value}
// 							activeStore={activeStore}
// 							setActiveStore={setActiveStore}
// 							setOpen={setOpen}
// 						/>
// 					</Modal>
// 					<Stack
// 						sx={{
// 							justifyContent: "flex-end",
// 							m: 3,
// 							width: 130,
// 							position: "sticky",
// 							left: 10,
// 						}}></Stack>
// 				</Stack>
// 			) : isLoading ? (
// 				<Grid container spacing={1} mt={2}>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"100px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>
// 					<Grid item xs={12}>
// 						<Skeleton variant="text" width="100%" height={"40px"} />
// 					</Grid>

// 					{/* Add more grid items as needed */}
// 				</Grid>
// 			) : (
// 				<Stack align={"center"} margin={5}>
// 					No Store Records Found
// 				</Stack>
// 			)}
// 		</>
// 	);
// }

// export default ActiveStore;

function StoreDetail({ data, activeStore, setActiveStore, setOpen, action, open }) {
	const initialState = {
		footware_allocated_area: 0,
		maximum_stock_holding_ss: 0,
		maximum_stock_holding_aw: 0,
		minimum_stock_holding: 0,
		backend_stock_quantity_aw: 0,
		backend_stock_quantity_ss: 0,
		frontend_stock: 0,
	};
	const [vmData, setVmData] = useState(action === "VM" ? data : initialState);
	const [loading, setLoading] = useState(false);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	useEffect(() => {
		setVmData(data.eboStoreVmData);
	}, [data]);

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	const FIELDS = [
		{ key: "footware_allocated_area", value: "Footware allocated area (SQ ft)" },
		{ key: "maximum_stock_holding_ss", value: "Maximum stock holding ss" },
		{ key: "maximum_stock_holding_aw", value: "Maximum stock holding aw" },
		{ key: "minimum_stock_holding", value: "Minimum stock holding" },
		{ key: "backend_stock_quantity_aw", value: "Backend stock quantity aw" },
		{ key: "backend_stock_quantity_ss", value: "Backend stock quantity ss" },
		{ key: "frontend_stock", value: "Frontend stock" },
	];

	const handleCloseModal = () => {
		setOpen(false);
	};

	const handleUpdate = async (e) => {
		// to be written once finalized
		e.preventDefault();
		let id = data.id;
		const body = {
			...data,
			eboStoreVmData: vmData,
		};
		console.log(id);
		setLoading(true);
		await axios
			.put(`/api/store/update-vm-details/${id}`, body)
			.then((res) => {
				if (res.status === 200) {
					const { store } = res.data;
					setActiveStore([...activeStore.map((x) => (x.id === id ? body : x))]);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "updated successfully",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoading(false);
					setOpen(false);
				} else {
					setSnackBarOpen(true);
					setLoading(false);
					setSnackBar({
						...snackBar,
						message: res.error,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			})
			.catch((err) => {
				setLoading(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	return (
		<Dialog open={open} maxWidth="md" onClose={handleCloseModal} component="form" onSubmit={handleUpdate} fullWidth>
			<DialogTitle>
				<Typography fontSize={26} align={"center"}>
					VM Details For Store {data.rr_code}
					{data.rr_number}.
				</Typography>
			</DialogTitle>
			<Box width="95%" margin="auto" pt={2} display="flex" flexWrap="wrap" columnGap={2} rowGap={2}>
				{FIELDS.map(({ key, value }) => (
					<Stack sx={formStack} spacing={0} key={key}>
						<InputLabel>{value}:</InputLabel>
						<TextField
							size="small"
							type="text"
							value={vmData[key]}
							placeholder={`Enter ${value}`}
							onChange={(e) => setVmData({ ...vmData, [key]: e.target.value })}
						/>
					</Stack>
				))}
			</Box>
			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button
					autoFocus
					variant="contained"
					sx={{ mr: 1 }}
					type="submit"
					disabled={loading}
					startIcon={loading ? <CircularProgress color="inherit" size="25px" /> : ""}>
					{loading ? "Saving" : "Save"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default StoreDetail;
