import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CircularProgressWithLabel = ({ value, size }) => {
	const valueInt = Math.round(Number(value));
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<CircularProgress
				color={size ? "success" : "info"}
				size={size ? `${size}px` : "30px"}
				variant="determinate"
				value={value}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Typography
					variant="caption"
					component="div"
					color="text.secondary"
					fontSize={size && size > 30 ? "12px" : "10px"}>{`${valueInt}%`}</Typography>
			</Box>
		</Box>
	);
};

export default CircularProgressWithLabel;
