import {
	Box,
	Button,
	Dialog,
	DialogActions,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import { EditCompanyDetailsModalTypes, financialDetailsColumns, labourTableColumns } from "data/supplierPageData";
import { useState } from "react";
import LabourDataTable from "../LabourDataTable/LabourDataTable";
import { stateList } from "data/stateMasterList";

const EditFinancialDetails = ({ data, setData, modalOpen, setModalOpen, submitFunction }) => {
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const [updatedDetails, setUpdatedDetails] = useState(data);

	const handleCloseModal = () => {
		setUpdatedDetails(data);
		setModalOpen(false);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		let obj = {};
		Object.keys(financialDetailsColumns).forEach((col) => (obj[col] = updatedDetails[col]));
		if (typeof submitFunction === "function") submitFunction(obj, setModalOpen);
	};
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Stack
				direction="row"
				width="98%"
				justifyContent="space-between"
				flexWrap="wrap"
				rowGap={1}
				p={2}
				boxSizing="border-box">
				<Typography variant="h6" width="100%">
					Edit Financial Details{" "}
				</Typography>
				{/* Bank Name */}
				<Stack sx={formStack} spacing={0}>
					<InputLabel htmlFor="bankName">Pancard No:</InputLabel>
					<TextField
						size="small"
						type="text"
						id="bankName"
						value={updatedDetails?.pan_no}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, pan_no: e.target.value })}
						placeholder="Enter Pan No"
						required
					/>
				</Stack>
				<Stack sx={formStack} spacing={0}>
					<InputLabel htmlFor="bankName">Bank Name:</InputLabel>
					<TextField
						size="small"
						type="text"
						id="bankName"
						value={updatedDetails?.bank_name}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, bank_name: e.target.value })}
						placeholder="Enter Bank Name"
						required
					/>
				</Stack>

				{/* IFSC Code */}
				<Stack sx={formStack} spacing={0}>
					<InputLabel htmlFor="ifscCode">IFSC Code:</InputLabel>
					<TextField
						size="small"
						type="text"
						id="ifscCode"
						value={updatedDetails?.bank_ifsc_code}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, bank_ifsc_code: e.target.value })}
						placeholder="Enter IFSC Code"
						required
					/>
				</Stack>

				{/* Bank Account Number */}
				<Stack sx={formStack} spacing={0}>
					<InputLabel htmlFor="bankAccountNumber">Bank Account Number:</InputLabel>
					<TextField
						size="small"
						type="text"
						id="bankAccountNumber"
						value={updatedDetails?.bank_acc_no}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, bank_acc_no: e.target.value })}
						placeholder="Enter Bank Account Number"
						required
					/>
				</Stack>

				{/* MICR Code */}
				<Stack sx={formStack} spacing={0}>
					<InputLabel>MICR Code:</InputLabel>
					<TextField
						size="small"
						type="text"
						id="micrCode"
						value={updatedDetails?.micr_code}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, micr_code: e.target.value })}
						placeholder="Enter MICR Code"
						required
					/>
				</Stack>

				{/* RTGS Code */}
				<Stack sx={formStack} spacing={0}>
					<InputLabel htmlFor="rtgsCode">RTGS Code:</InputLabel>
					<TextField
						size="small"
						type="text"
						id="rtgsCode"
						value={updatedDetails?.rtgs_code}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, rtgs_code: e.target.value })}
						placeholder="Enter RTGS Code"
						required
					/>
				</Stack>
				{/* <Stack sx={formStack} spacing={0}>
					<InputLabel htmlFor="blank_check_photo">Blank Check Photo</InputLabel>
					<TextField
						size="small"
						type="file"
						id="blank_check_photo"
						accept=".pdf, .jpeg, .jpg, .png"
						required
						onChange={(event) => handleFileChange(event, "blank_check_photo")}
					/>
				</Stack> */}
				{/* <Stack sx={formStack} spacing={0}>
					<InputLabel htmlFor="rtgsCode">Payment Term:</InputLabel>
					<TextField
						size="small"
						type="text"
						id="rtgsCode"
						value={updatedDetails?.payment_term}
						onChange={(e) => setUpdatedDetails({ ...updatedDetails, payment_term: e.target.value })}
						placeholder="Enter Payment Term"
						required
					/>
				</Stack> */}
			</Stack>

			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditFinancialDetails;
