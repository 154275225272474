const RareBoxLogoWhite = () => {
    return ( 
<svg  viewBox="0 0 351 69" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6875 4.46728C23.3633 4.46728 28.3594 5.85399 31.6719 8.62353C34.9844 11.397 36.6406 15.1977 36.6406 20.0298C36.6406 23.4985 35.7969 26.4048 34.1094 28.7485C32.4297 31.0845 30.0703 32.8345 27.0312 33.9985C23.9883 35.1665 20.4297 35.7485 16.3594 35.7485L37.4688 62.9517H30.2188L9.51562 35.7485H5.85938V62.9517H0V4.46728H16.6875ZM16.3594 30.0454C20.8672 30.0454 24.3594 29.2329 26.8281 27.6079C29.2969 25.9751 30.5312 23.4477 30.5312 20.0298C30.5312 17.0415 29.4727 14.6509 27.3594 12.8579C25.2422 11.0688 22.0664 10.1704 17.8281 10.1704H5.85938V30.0454H16.3594Z" fill="white"/>
<path d="M91.2071 62.9517L82.254 43.7329H56.5821L47.629 62.9517H41.5196L68.8008 4.46728H70.1915L97.3165 62.9517H91.2071ZM59.1915 38.186H79.6446L69.4571 16.2798L59.1915 38.186Z" fill="white"/>
<path d="M121.489 4.46728C128.165 4.46728 133.161 5.85399 136.473 8.62353C139.786 11.397 141.442 15.1977 141.442 20.0298C141.442 23.4985 140.598 26.4048 138.911 28.7485C137.231 31.0845 134.872 32.8345 131.833 33.9985C128.79 35.1665 125.231 35.7485 121.161 35.7485L142.27 62.9517H135.02L114.317 35.7485H110.661V62.9517H104.802V4.46728H121.489ZM121.161 30.0454C125.669 30.0454 129.161 29.2329 131.63 27.6079C134.098 25.9751 135.333 23.4477 135.333 20.0298C135.333 17.0415 134.274 14.6509 132.161 12.8579C130.044 11.0688 126.868 10.1704 122.63 10.1704H110.661V30.0454H121.161Z" fill="white"/>
<path d="M153.009 4.46728H186.399V10.0923H158.868V28.5767H186.399V34.1235H158.868V57.2485H186.399V62.9517H153.009V4.46728Z" fill="white"/>
<path d="M208.949 4.46728C212.906 4.46728 216.23 5.08446 218.918 6.31103C221.613 7.52978 223.613 9.19774 224.918 11.311C226.219 13.4282 226.871 15.897 226.871 18.7173C226.871 21.3345 226.246 23.6977 224.996 25.811C223.746 27.9282 221.871 29.6665 219.371 31.0298C227.566 33.7915 231.668 38.8384 231.668 46.1704C231.668 49.1626 230.922 51.9321 229.434 54.4829C227.941 57.0376 225.754 59.0884 222.871 60.6392C219.996 62.1821 216.574 62.9517 212.605 62.9517H196.574V4.46728H208.949ZM206.34 28.9829C210.902 28.9829 214.441 28.1548 216.965 26.4985C219.496 24.8423 220.762 22.2485 220.762 18.7173C220.762 16.1157 219.824 14.0415 217.949 12.4985C216.074 10.9477 213.453 10.1704 210.09 10.1704H202.434V28.9829H206.34ZM211.48 57.2485C214.625 57.2485 217.254 56.7485 219.371 55.7485C221.484 54.7407 223.043 53.397 224.043 51.7173C225.051 50.0298 225.559 48.2095 225.559 46.2485C225.559 43.811 224.891 41.7251 223.559 39.9829C222.234 38.2446 220.297 36.9282 217.746 36.0298C215.191 35.1352 212.125 34.686 208.543 34.686H202.434V57.2485H211.48Z" fill="white"/>
<path d="M325.2 32.811L307.856 4.46728H314.7L328.7 27.2798L342.794 4.46728H349.56L332.122 32.811L350.7 62.9517H343.856L328.7 38.436L313.388 62.9517H306.544L325.2 32.811Z" fill="white"/>
<path d="M260.296 2.29458C253.352 4.03436 246.619 5.66541 245.251 5.99162C240.832 7.07898 242.936 10.6673 251.143 16.6478L258.823 22.0846L267.976 20.0186L277.13 17.9526L277.445 13.0595C277.971 6.31783 276.814 1.31595 274.71 0.228585C273.552 -0.423834 268.502 0.337322 260.296 2.29458Z" fill="white"/>
<path d="M281.128 1.85962C280.181 2.18583 279.76 4.7955 279.76 9.79738V17.0827L288.387 15.0167C293.121 13.9294 297.225 12.842 297.435 12.6245C297.751 12.1896 286.178 3.38193 283.442 1.96835C282.916 1.64214 281.759 1.64214 281.128 1.85962Z" fill="white"/>
<path d="M235.887 14.7993C235.151 16.8653 236.413 48.8338 237.36 50.3561C237.992 51.5522 246.724 50.6823 252.511 48.9425L256.298 47.8552L255.877 36.6553C255.562 30.5661 255.246 25.0205 255.036 24.3681C254.51 22.4108 240.938 13.0595 238.623 13.0595C237.466 13.0595 236.308 13.8206 235.887 14.7993Z" fill="white"/>
<path d="M287.651 18.2788L280.286 20.1274V24.803C280.391 27.304 280.496 33.2845 280.602 37.9601L280.812 46.5503L289.334 52.5308C294.384 56.1191 298.487 58.2939 299.434 57.9677C301.854 56.989 300.381 19.6924 297.856 17.6264C296.909 16.974 295.962 16.3216 295.646 16.4303C295.331 16.4303 291.754 17.3002 287.651 18.2788Z" fill="white"/>
<path d="M268.923 22.8458C258.823 25.6729 258.718 25.7817 258.718 37.0902C258.718 47.9639 258.192 47.5289 267.45 45.2455C276.814 42.962 277.656 41.7659 277.656 30.2398C277.656 25.0205 277.34 20.671 276.919 20.7798C276.393 20.7798 272.921 21.7584 268.923 22.8458Z" fill="white"/>
<path d="M266.609 48.8338L259.244 50.5736L258.928 56.0104C258.508 63.0783 259.454 68.1889 261.348 68.95C262.19 69.2762 269.134 67.9714 276.814 66.0142C289.018 62.8608 290.807 62.2084 291.122 60.1424C291.438 58.2939 289.86 56.5541 283.863 52.3134C279.655 49.2687 275.762 46.8765 275.131 46.8765C274.499 46.9853 270.712 47.8552 266.609 48.8338Z" fill="white"/>
<path d="M245.041 54.1619C241.253 55.1405 238.202 56.4453 238.202 56.989C238.202 57.424 241.569 60.3598 245.777 63.4045C255.246 70.1461 256.614 69.6024 256.614 59.1637C256.614 51.1172 256.614 51.1172 245.041 54.1619Z" fill="white"/>
</svg>

 );
}
 
export default RareBoxLogoWhite;