import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { myntra_reco_drawer } from "data/myntraRecoData";
import MyntraSummary from "./myntraSummary";
import UploadMyntraFiles from "./uploadMyntraFiles";
import MyntraRecoProvider from "context/MyntraReco";
import MyntraDashboard from "./myntraDashboard";
import RecoDocuments from "./recoDocuments";

export default function Myntra_reco() {
	const [tab, setTab] = useOutletContext();
	const navigate = useNavigate();
	const tabComponents = [
		{
			...myntra_reco_drawer.dashboard,
			component: <MyntraDashboard />,
		},
		{
			...myntra_reco_drawer.upload_files,
			component: <UploadMyntraFiles />,
		},
		{
			...myntra_reco_drawer.summary,
			component: <MyntraSummary />,
		},
		{
			...myntra_reco_drawer.documents,
			component: <RecoDocuments />,
		},
	];
	return (
		<div>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<div>
					<Typography variant="h5" margin={"20px"} fontSize={28} textTransform="uppercase">
						Myntra Reco
					</Typography>
				</div>
				<Button startIcon={<KeyboardBackspaceRounded />} variant="outlined" onClick={() => navigate("/explore")} sx={{ m: 2 }}>
					Back
				</Button>
			</div>
			{
				<MyntraRecoProvider>
					<div>
						{tabComponents &&
							tabComponents.map((row) => (
								<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
									{row.component}
								</Box>
							))}
					</div>
				</MyntraRecoProvider>
			}
		</div>
	);
}
