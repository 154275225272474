export const roundDecimalValueUptoTwo = (number) => {
	// for two digit
	// return Math.round((number + Number.EPSILON) * 100) / 100;
	// for one digit
	return Math.round((number + Number.EPSILON) * 10) / 10;
};
export const roundDecimalValueUptoTwoDigit = (number) => {
	// for two digit
	return Math.round((number + Number.EPSILON) * 100) / 100;
	// for one digit
	// return Math.round((number + Number.EPSILON) * 10) / 10;
};
