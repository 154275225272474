import { Autocomplete, Chip, InputLabel, ListItem, TextField } from "@mui/material";
import React, { useState } from "react";

function InputWithChips({
	setAttributeFormData,
	attributeFormData,
	setToAdd,
	toAdd,
	action,
	onDelete,
	handleAtrValue,
}) {
	return (
		<Autocomplete
			clearIcon={false}
			options={[]}
			freeSolo
			multiple
			disabled={attributeFormData.input_type === "INPUT"}
			onChange={(e) => {
				if (e.key === "Backspace") {
					setToAdd([]);
				}
			}}
			renderTags={(value, props) =>
				toAdd.map((option, index) => (
					<Chip
						label={option}
						{...props({ index })}
						onDelete={() => {
							onDelete(option);
						}}
					/>
				))
			}
			renderInput={(params) => (
				<TextField
					{...params}
					maxRows={5}
					multiline
					size="small"
					placeholder="Enter Attribute values"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							action === "UPDATE"
								? handleAtrValue(e)
								: setAttributeFormData({
										...attributeFormData,
										value: [...attributeFormData.value, e.target.value],
								  });
						}
					}}
				/>
			)}
		/>
	);
}

export default InputWithChips;
