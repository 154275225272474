import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { AuthState } from "../../context/AuthContext";
import { Chip } from "@mui/material";

const Profile = ({ setModalOpen }) => {
	const { user } = AuthState();

	return (
		<Stack
			sx={{ backgroundColor: "white", width: "300px", minHeight: "200px", padding: "20px" }}
			borderRadius={2}
			// alignItems="center"
			spacing={2}>
			<Box width="100%">
				<IconButton onClick={() => setModalOpen(false)} size="large" sx={{ mr: 2, float: "right" }}>
					<ClearRoundedIcon />
				</IconButton>
			</Box>

			<Box pl={2}>
				<AccountCircleIcon sx={{ transform: "scale(3.4)", margin: "10px" }} />
			</Box>

			<Typography variant="h6">
				{user.name} - {user.designation}
			</Typography>
			<Typography variant="h6">{user.email} </Typography>
			<Typography variant="h6">Department:{user.department} </Typography>
			<Typography variant="h6">Employee ID:{user.employee_id} </Typography>
			<Box display="flex" columnGap={1} width="100%" alignItems="center">
				<Typography variant="h6">Roles : </Typography>
				<div style={{width: '233px'}} >
					{Object.keys(user.role).map((row) => {
						return <Chip sx={{margin: '2px 0px'}} label={row} />;
					})}
				</div>
			</Box>
		</Stack>
	);
};

export default Profile;
