import { Tooltip } from "@mui/material";
import { roundDecimalValueUptoTwo } from "../../utils/roundDecimalValue";

export const InLakhsDisplay = ({ number, sx }) => {
	// fontsize must be in Integer
	const noInLakhs = roundDecimalValueUptoTwo(number / 100000);
	const style = { fontSize: 14, fontWeight: "bold" };
	return (
		<Tooltip title={number}>
			<div>
				<span style={sx ? { ...style, ...sx } : { fontSize: 16, fontWeight: "bold" }}> {noInLakhs}</span>
				<span style={sx ? { ...style, ...sx, fontSize: sx.fontSize - 4 } : style}>L</span>
			</div>
		</Tooltip>
	);
};

export const AmountConverter = ({ number, sx }) => {
	// fontsize must be in Integer
	let amt = parseInt(Math.abs(number));
	let amtStr = amt.toString();
	let realisedAmt = 0;
	let init = "";
	const style = { fontSize: 14, fontWeight: "bold" };
	if (amtStr.length <= 3) {
		realisedAmt = number;
		init = "";
	} else if (amtStr.length <= 5) {
		realisedAmt = number / 1000;
		init = "K";
	} else if (amtStr.length <= 7) {
		realisedAmt = number / 100000;
		init = "L";
	} else if (amtStr.length >= 8) {
		realisedAmt = number / 10000000;
		init = "Cr";
	}
	return (
		<Tooltip title={number} arrow placement="top">
			<div>
				<span style={{ ...sx, fontSize: 16 }}>
					{typeof realisedAmt === "number" ? (Number.isInteger(realisedAmt) ? realisedAmt.toString() : realisedAmt.toFixed(2)) : 0}
				</span>
				<span style={{ ...sx, fontSize: 14 }}>{init}</span>
			</div>
		</Tooltip>
	);
};
