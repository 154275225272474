export const itemMasterTabParamName = "tab";
export const itemMasterDrawerElements = {
	without_cogs: {
		name: "Item master",
		key: "without_cogs",
		display: true,
	},
	with_cogs: {
		name: "Item master (+COGS)",
		key: "with_cogs",
		display: true,
	},
};
