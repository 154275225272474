import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { roundDecimalValueUptoTwo } from "utils/roundDecimalValue";

const LabourDataTable = ({ data, setData, columns, editable }) => {
	let obj = {};
	const handleDataChange = (value, colName) => {
		obj[colName] = value;
		setData({ ...data, ...obj });
		console.log(data);
	};
	return (
		<Table sx={{ width: 400, border: `1px solid ${grey[300]}`, mt: 2 }} size="small" aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell>Labour Roll*:</TableCell>
					<TableCell>Count</TableCell>
					<TableCell>percent</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<TableCell component="th" scope="row">
						On company Roll
					</TableCell>
					<TableCell component="th" scope="row">
						<TextField
							value={data[columns[0]]}
							onChange={(e) => handleDataChange(Number(e.target.value), columns[0])}
							sx={{ width: 80 }}
							size="small"
							variant="standard"
							disabled={!editable}
						/>
					</TableCell>
					<TableCell component="th" scope="row">
						{data.no_of_labour > 0
							? roundDecimalValueUptoTwo((data[columns[0]] / data.no_of_labour) * 100)
							: 0}
						%
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell component="th" scope="row">
						On Contract
					</TableCell>
					<TableCell component="th" scope="row">
						<TextField
							value={data[columns[1]]}
							onChange={(e) => handleDataChange(Number(e.target.value), columns[1])}
							sx={{ width: 80 }}
							size="small"
							variant="standard"
							disabled={!editable}
						/>
					</TableCell>

					<TableCell component="th" scope="row">
						{data.no_of_labour > 0
							? roundDecimalValueUptoTwo((data[columns[1]] / data.no_of_labour) * 100)
							: 0}
						%
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

export default LabourDataTable;
