import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { GlobalState } from "../../../../context/GlobalContext";
import { DialogActions } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function AddNewCosting({ allmonthlyCostings, setAllmonthlyCostings, setModalOpen }) {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const [costing, setCosting] = useState({});
	const [month, setMonth] = useState(dayjs());

	const [monthError, setMonthError] = useState("");

	const handleMonthChange = async (date) => {
		setMonth(date);
		const result = await axios.get(`/api/production_costing/is_present?date=${date}`);
		if (result.data.isPresnt) {
			setMonthError("Costing for this month already exists");
		} else {
			setMonthError("");
		}
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();

		if (monthError !== "") {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: monthError,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}

		await axios
			.post("/api/production_costing", { ...costing, month: dayjs(month).toDate() })
			.then((res) => {
				setSnackBarOpen(true);
				console.log(res);
				setSnackBar({
					...snackBar,
					message: "Costing Created",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setAllmonthlyCostings([res.data.data, ...allmonthlyCostings]);
				setModalOpen(false);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable to update supplier details ",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};

	return (
		<Box component="form" onSubmit={onFormSubmit} sx={{ maxWidth: "1100px", margin: "auto" }}>
			<Box direction="row" width="100%" justifyContent="space-around" flexWrap="wrap">
				<Box
					display="flex"
					flexWrap="wrap"
					pt={2}
					rowGap={2}
					className="vendorInputForm"
					justifyContent="space-around">
					<Stack className="vendor-input" sx={formStack} spacing={1}>
						<InputLabel>
							Select Month <span>*</span>:
						</InputLabel>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								views={["month", "year"]}
								slotProps={{ textField: { size: "small" } }}
								value={month}
								onChange={(value) => handleMonthChange(value)}
							/>
						</LocalizationProvider>
						<span className="error">{monthError}</span>
					</Stack>

					<Stack sx={formStack} spacing={1}>
						<InputLabel>Salary Liability:</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.salary_liability}
							onChange={(e) => setCosting({ ...costing, salary_liability: Number(e.target.value) })}
							placeholder="Enter Monthly Capacity"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Electricity:</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.electricity}
							onChange={(e) => setCosting({ ...costing, electricity: Number(e.target.value) })}
							placeholder="Enter Monthly Capacity"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Electricity Warehouse:</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.electricity_warehouse}
							onChange={(e) => setCosting({ ...costing, electricity_warehouse: Number(e.target.value) })}
							placeholder="Enter Electricity"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Diesel Charges:</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.diesel}
							onChange={(e) => setCosting({ ...costing, diesel: Number(e.target.value) })}
							placeholder="Enter Diesel Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Petty Cash:</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.petty_cash}
							onChange={(e) => setCosting({ ...costing, petty_cash: Number(e.target.value) })}
							placeholder="Enter Diesel Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Leave Encashment:</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.leave_encashment}
							onChange={(e) => setCosting({ ...costing, leave_encashment: Number(e.target.value) })}
							placeholder="Enter Leave Encashment Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Bonus</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.bonus}
							onChange={(e) => setCosting({ ...costing, bonus: Number(e.target.value) })}
							placeholder="Enter Bonus Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Gratuity</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.gratuity}
							onChange={(e) => setCosting({ ...costing, gratuity: Number(e.target.value) })}
							placeholder="Enter Gratuity Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Notice Period Deduction</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.notice_period_deduction}
							onChange={(e) =>
								setCosting({ ...costing, notice_period_deduction: Number(e.target.value) })
							}
							placeholder="Enter Notice Period Deduction Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Stp Mantainance Charges</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.stp_mantainance_charges}
							onChange={(e) =>
								setCosting({ ...costing, stp_mantainance_charges: Number(e.target.value) })
							}
							placeholder="Enter Stp Mantainance Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Insurance</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.insurance}
							onChange={(e) => setCosting({ ...costing, insurance: Number(e.target.value) })}
							placeholder="Enter Insurance Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Internet Charges</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.internet}
							onChange={(e) => setCosting({ ...costing, internet: Number(e.target.value) })}
							placeholder="Enter Internet Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Other Expences (Approximare)</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.other_expences}
							onChange={(e) => setCosting({ ...costing, other_expences: Number(e.target.value) })}
							placeholder="Enter Other Expences"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Piece Work Charges</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.piece_work_charges}
							onChange={(e) => setCosting({ ...costing, piece_work_charges: Number(e.target.value) })}
							placeholder="Enter Piece Work Charges"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Ho Rareism Tailor</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.ho_Rareism_tailor}
							onChange={(e) => setCosting({ ...costing, ho_Rareism_tailor: Number(e.target.value) })}
							placeholder="Enter Ho Rareism Tailor Expences"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Total Salary</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.total_salary}
							onChange={(e) => setCosting({ ...costing, total_salary: Number(e.target.value) })}
							placeholder="Enter Total Salary Expences"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Re Pack RTV Prod</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.re_pack_RTVprod}
							onChange={(e) => setCosting({ ...costing, re_pack_RTVprod: Number(e.target.value) })}
							placeholder="Enter Re Pack RTV Prod Expences"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Re Barcoding Wh Goods</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.re_barcoding_wh_goods}
							onChange={(e) => setCosting({ ...costing, re_barcoding_wh_goods: Number(e.target.value) })}
							placeholder="Enter Re Barcoding Wh Goods"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>RE Chk Salsa</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.re_chk_salsa}
							onChange={(e) => setCosting({ ...costing, re_chk_salsa: Number(e.target.value) })}
							placeholder="Enter RE Chk Salsa"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>RR Sample Prod</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.rr_sample_prod}
							onChange={(e) => setCosting({ ...costing, rr_sample_prod: Number(e.target.value) })}
							placeholder="Enter RR Sample Prod"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Total Production Cost</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.total_prod_cost}
							onChange={(e) => setCosting({ ...costing, total_prod_cost: Number(e.target.value) })}
							placeholder="Enter Total Production Cost"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Production For Month</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.production_for_month}
							onChange={(e) => setCosting({ ...costing, production_for_month: Number(e.target.value) })}
							placeholder="Enter Production For Month"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Cost Per Pc</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.cost_per_pc}
							onChange={(e) => setCosting({ ...costing, cost_per_pc: Number(e.target.value) })}
							placeholder="Enter Cost Per Pc"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Average Tailor Expences</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.average_tailor}
							onChange={(e) => setCosting({ ...costing, average_tailor: Number(e.target.value) })}
							placeholder="Enter Average Tailor Expences"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Average Total Strength</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.average_total_strength}
							onChange={(e) => setCosting({ ...costing, average_total_strength: Number(e.target.value) })}
							placeholder="Enter Average Total Strength"
							required
						/>
					</Stack>
					<Stack sx={formStack} spacing={1}>
						<InputLabel>Total Production For Month</InputLabel>
						<TextField
							size="small"
							type="number"
							value={costing.total_producetion_for_month}
							onChange={(e) =>
								setCosting({ ...costing, total_production_for_month: Number(e.target.value) })
							}
							placeholder="Enter Total Production For Month"
							required
						/>
					</Stack>
				</Box>
			</Box>

			<DialogActions sx={{ p: 3 }}>
				<Button variant="contained" type="submit">
					Create Costing
				</Button>
			</DialogActions>
		</Box>
	);
}

export default AddNewCosting;
