import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
	attributeGroups,
	attributepriorityTypes,
	barcodeTableColumns,
	designerAttributeObjName,
	designerTableColumns,
	groups,
	inputTypes,
	orderData,
	orderTableAttributeColumns,
	pimDrawerElements,
	pimParamName,
	priority,
	productColumns,
	productDetailsColumns,
	productDummyData,
	productImagesObjName,
	styleTableColumns,
} from "data/pimData";
import { useEffect, useState } from "react";
import DisplayInfoWithEditTab from "components/Pim/CommonComponents/DisplayInfoWithEditTab";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import BarcodeModal, { BarcodeModalWithCost } from "../../BarcodeModal/BarcodeModal";
import { DataGrid } from "@mui/x-data-grid";
import { AddRoad, DeleteRounded, EditRounded } from "@mui/icons-material";
import { PimState } from "context/PimContext";
import CreateColorModal from "../../CreateColorModal/CreateColorModal";
import { rootProtectedPath, services } from "data/authData";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import EditImageDataModal from "../../EditImageDataModal/EditImageDataModal";
import AddRounded from "@mui/icons-material/AddRounded";
import AddNewCosting from "components/OwnProductionCosting/OwnProductionCostingDashbord/AddNewCosting/AddNewCosting";
import AddNewImageModal from "../../AddNewImageModal/AddNewImageModal";
import ImageSlider from "components/Pim/CommonComponents/ImageSlider/ImageSlider";
import CogsSection from "./CogsSection/CogsSection";

const colStyle = {
	headerAlign: "center",
	align: "center",
};

const MDMTab = () => {
	const { setSnackBar, setSnackBarOpen, snackBar } = GlobalState();
	const { attributesWithValuesGroups, selectedStyle, setSelectedStyle } = PimState();

	const navigate = useNavigate();

	const [styleData, setStyleData] = useState(selectedStyle);

	const [allCogs, setAllCogs] = useState([]);

	// modal var

	const [mdmDataEditModal, setMdmDataEditModal] = useState(false);

	const handleSaveEditedData = (data) => {
		console.log("save fun ran");
	};

	if (selectedStyle)
		return (
			<Stack width="100%">
				{/* MDM Fields */}
				<Box pt={2} pb={5} display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={2}>
					<Box width="85%">
						<Typography pb={1} variant="h6">
							{groups.mdm.name}
						</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
					</Box>
					<Box>
						<Button variant="contained" onClick={() => setMdmDataEditModal(true)}>
							Edit
						</Button>
						{/* <IconButton sx={{ ml: 2 }} size="large" onClick={() => handleArrowDownClick(groups.mdm.key)}>
							{activeGroup === groups.mdm.key ? (
								<KeyboardArrowUpRoundedIcon sx={{ transform: "scale(1.6)" }} />
							) : (
								<KeyboardArrowDownRoundedIcon sx={{ transform: "scale(1.6)" }} />
							)}
						</IconButton> */}
					</Box>

					<Box width="100%">
						<DisplayInfoWithEditTab
							columnData={attributesWithValuesGroups[attributeGroups.MDM.key]
								?.filter((row) => row.group === groups.mdm.key)
								.sort((a, b) => a.sort_no - b.sort_no)}
							// columnData={attributesWithValuesGroups[attributepriorityTypes.high.index]}
							data={styleData}
							setData={setStyleData}
							modalOpen={mdmDataEditModal}
							setModalOpen={setMdmDataEditModal}
							submitFunction={handleSaveEditedData}
						/>
					</Box>
					<Box width="60%" border={`1px solid ${grey[200]}`} p={1} borderRadius={1}>
						<Typography variant="h6" p={0}>
							Cost Rates
						</Typography>
						<Divider orientation="horizontal" variant="fullWidth" />
						<CogsSection allCogs={allCogs} setAllCogs={setAllCogs} />
					</Box>
				</Box>
			</Stack>
		);
	else return <div></div>;
};

export default MDMTab;
