import { DeleteRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import ImageUploadHandler from "components/Pim/CommonComponents/ImageUploadHandler/ImageUploadHandler";
import ContactPersonDetails from "components/SupplierPage/SupplierForm/ContactPersonDetails/ContactPersonDetails";
import { GlobalState } from "context/GlobalContext";
import { PimState } from "context/PimContext";
import { isMainImg, sizeTypes } from "data/pimData";
import { EditCompanyDetailsModalTypes, contactPersonData, labourTableColumns } from "data/supplierPageData";
import { useEffect, useState } from "react";

const formStack = {
	width: { xs: "80%", sm: "40%", md: "19%" },
};
const AddNewImageModal = ({ modalOpen, setModalOpen, submitFunction, defaultImages }) => {
	const { selectedProduct } = PimState();

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "30%" },
	};
	const [newImages, setNewImages] = useState(defaultImages && defaultImages.length ? defaultImages : []);
	const [mainImageIndex, setMainImageIndex] = useState(0);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleCloseModal = () => {
		setModalOpen(false);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();

		if (typeof submitFunction === "function") {
			const updatedFormat = newImages.map((row, i) => {
				return {
					file: row.file,
					data_url: row.data_url,
					[isMainImg]: mainImageIndex === i,
				};
			});
			console.log(updatedFormat);
			let result = await submitFunction(updatedFormat);
			if (result) {
				setModalOpen(false);
				setNewImages([]);
			}
		}
	};
	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={modalOpen}
			onClose={handleCloseModal}
			component="form"
			onSubmit={handleFormSubmit}>
			<Stack>
				<ImageUploadHandler
					images={newImages}
					setImages={setNewImages}
					mainImageIndex={mainImageIndex}
					setMainImageIndex={setMainImageIndex}
					disableButtons={false}
				/>
			</Stack>

			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddNewImageModal;
