import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GlobalState } from "../../context/GlobalContext";

export default function LoadingScreen() {
	const { Loadingopen, setLoadingOpen } = GlobalState();

	return (
		<div>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
				open={Loadingopen}
				// onClick={() => setLoadingOpen(!Loadingopen)}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
