import { ClearRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import SelectInputField from "components/Pim/CommonComponents/InputComponents/SelectInputField";
import TextInputField from "components/Pim/CommonComponents/InputComponents/TextInputField";
import { attributeValueAssociationName, constraints, inputTypes } from "data/pimData";
import { useEffect, useState } from "react";

const InfoModal = ({ modalOpen, setModalOpen, sqlStoreData, columns, submitFunction }) => {
	const handleCloseModal = () => {
		setModalOpen(false);
		setData();
	};
	const [data, setData] = useState(sqlStoreData);
	useEffect(() => {
		setData(sqlStoreData);
	}, [sqlStoreData]);

	const onFormSubmit = async (e, publish) => {
		console.log(sqlStoreData, "sqlStoreData");
		console.log(publish, "targe");
		if (e) e.preventDefault();
		if (typeof submitFunction === "function")
			await submitFunction({ ...sqlStoreData, ...data }, publish ? true : false);
	};
	return (
		<Dialog
			open={modalOpen}
			onClose={handleCloseModal}
			scroll="body"
			maxWidth="sm"
			fullWidth
			component="form"
			onSubmit={onFormSubmit}>
			<DialogTitle>Edit Store Details</DialogTitle>
			<Box sx={{ padding: "0px 20px 20px 20px" }}>
				<Stack sx={{ width: "200px" }} spacing={1}>
					<InputLabel
					// sx={{ width: "38%", p: 1, background: grey[600], overflow: "hidden", color: grey[100] }}
					>
						Select Status
					</InputLabel>
					<Select
						size="small"
						fullWidth
						// variant="standard"
						placeholder={`Enter Status`}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						value={data && data[columns?.status.key] ? data[columns?.status.key] : "select"}
						onChange={(e) => setData({ ...data, [columns?.status.key]: e.target.value })}>
						<MenuItem value={"select"}>
							<span style={{ color: grey[400] }}>Select</span>{" "}
						</MenuItem>
						{columns?.status?.options?.map((row) => {
							return (
								<MenuItem key={row.label} value={row.key}>
									{row.label}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
				<Stack sx={{ width: "300px" }} spacing={1}>
					<InputLabel
					// sx={{ width: "38%", p: 1, background: grey[600], overflow: "hidden", color: grey[100] }}
					>
						Remarks
					</InputLabel>
					<TextField
						size="small"
						fullWidth
						multiline
						rows={2}
						// variant="standard"
						placeholder={`Enter `}
						// MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						value={data && data[columns?.reason.key] ? data[columns?.reason.key] : ""}
						onChange={(e) => setData({ ...data, [columns?.reason.key]: e.target.value })}
					/>
				</Stack>
			</Box>
			<DialogActions>
				<Button variant="outlined" onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						onFormSubmit(null, true);
					}}>
					Publish
				</Button>
				<Button variant="contained" type="submit">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InfoModal;
